import { actionType } from "../actionType";

const stateReducer = (state = {}, action) => {
    if (action.type === actionType.FETCH_STATES) {
        return { state, ...action.payload };
    }
    return state;
};

export default stateReducer;
