import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
import { Helmet } from "react-helmet";
// import DownloadLink from "react-download-link";


import BMT1 from '../assets/img/icons/benefit-metatrade-1.svg';
import BMT2 from '../assets/img/icons/benefit-metatrade-2.svg';
import BMT3 from '../assets/img/icons/benefit-metatrade-3.svg';
import BMT4 from '../assets/img/icons/benefit-metatrade-4.svg';
import BMT5 from '../assets/img/icons/benefit-metatrade-5.svg';
import BMT6 from '../assets/img/icons/benefit-metatrade-6.svg';


class TradingPlatform extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="MetaTrader 4 Forex trading platform|Free Download|LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "mt4,demo account,register,retail,forex,broker,open a trading account, trading account, forex broker" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "MetaTrader 4 Forex trading platform|Free Download|LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "MetaTrader 4 Forex trading platform|Free Download|LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "Register with LeoPrime and start downloading the MT4 trading platform which is customized to suit all your devices for free." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay  trading-platform-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase ">Leading Forex</h1>
                            <h1 className="display-3 text-shadow text-uppercase"><span>Trading Platform</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <Row className="justify-content-md-center">
                            <Col xs={12} md={12} lg={3} className="mb-3">
                                {/* <div className="d-flex flex-column justify-content-center">
                                    <div className="iphone-x">
                                        <i></i>
                                        <b></b>
                                        <s>10:24</s>
                                    </div>
                                </div> */}
                                <img src={require('../assets/img/trading-platform/trading-platform-img-1.jpg')} alt="Metatrader 4" className="img-fluid" />
                            </Col>
                            <Col xs={12} md={12} lg={9} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center">
                                    <h2>
                                        Metatrader 4 Platform
                                    </h2>
                                    <p>We go hand in hand with everyday changes that are happening in this industry. LeoPrime offers you the freedom to trade on all devices with the industry's leading trading platform MetaTrader 4 at your convenience. The MetaTrader 4 trading platform is designed for Forex trading. With MetaTrader 4, traders can analyze financial markets, perform advanced trading operations, run trading robots (Expert Advisors) and copy deals of other traders.
                                    </p>
                                    <Row>
                                        <Col lg>
                                            <ul className="liststyle">
                                                <li>Round-the-clock access to the market</li>
                                                <li>3 chart types and 9 timeframes (periods)</li>
                                                <li>Real-time symbol quotes</li>
                                                <li>One-click trading</li>
                                                <li>Intra-device compatibility</li>
                                                <li>Multiple charts in 19 timeframes</li>
                                                <li className="mb-2">Expert Advisors (EAs) for automated trading</li>
                                            </ul>
                                        </Col>
                                        <Col lg>
                                            <ul className="liststyle">
                                                <li>3 execution modes and 4 pending order types</li>
                                                <li>Access to the MQL4 community</li>
                                                <li>30+ pre-installed indicators and 24 analytical objects</li>
                                                <li>Customizable alerts</li>
                                                <li>Connection to 2000+ broker servers</li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="bg-gray hmeOurBenefit">
                    <Col lg={10} className="mx-auto">
                        <Row>
                            <Col xs={12} md={4} lg={4} className="d-flex align-items-center">
                                <div>
                                    <h2>
                                        Benefits of
                                        <span className="d-block">Metatrader 4</span>
                                    </h2>
                                    <p>MetaTrader 4 offers leading trading and analytical technologies, as well as additional services. It has everything you need for Forex trading.</p>
                                    <ul className="liststyle">
                                        <li>Extremely user-friendly interface</li>
                                        <li>Advanced charting capabilities</li>
                                        <li>Incorporates advanced communication tools</li>
                                        <li>Highly secure platform</li>
                                        <li>Supports all currency crosses</li>
                                        <li>Customization</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} md={8} lg={{ size: 7, offset: 1 }}>
                                <Row className="my-3">
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-5">
                                        <img src={BMT1} alt="Metatrader 4" />
                                        <p>Extremely user-friendly interface</p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-5">
                                        <img src={BMT2} alt="Metatrader 4" />
                                        <p>Advanced charting <span className="d-block">capabilities</span></p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-5">
                                        <img src={BMT3} alt="Metatrader 4" />
                                        <p>Incorporates advanced communication tools</p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-5">
                                        <img src={BMT4} alt="Metatrader 4" />
                                        <p>Highly secure <span className="d-block">platform</span></p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-5">
                                        <img src={BMT5} alt="Metatrader 4" />
                                        <p>Supports all currency <span className="d-block">crosses</span></p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-5">
                                        <img src={BMT6} alt="Metatrader 4" />
                                        <p>Customization</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </section>
                <section className="hmeOurBenefit">
                    <Col lg={6} className="mx-auto">
                        <Row>
                            <Col xs={12} md={12} lg={12} className="text-center">
                                <h2 className="mb-5">
                                    Download Metatrader 4
                                    <span className="d-block">Trading Platform</span>
                                </h2>
                                <img src={require('../assets/img/home/mt4-mt5-image.png')} alt="MT4 and MT5" className="img-fluid" />
                                <Row>
                                    <Col xs={12} lg>
                                        <div className='animated-arrow'>
                                            <span className='the-arrow -left'>
                                                <span className='shaft'></span>
                                            </span>
                                            <span className='main'>
                                                <span className='text'>
                                                    <a href="https://download.mql5.com/cdn/web/leo.inc.limited/mt4/leo4setup.exe" download>For Windows</a>
                                                </span>
                                                <span className='the-arrow -right'>
                                                    <span className='shaft'></span>
                                                </span>
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={12} lg>
                                        <div className='animated-arrow'>
                                            <span className='the-arrow -left'>
                                                <span className='shaft'></span>
                                            </span>
                                            <span className='main'>
                                                <span className='text'> 
                                                    <a href="#">For Mac</a>
                                                </span>
                                                <span className='the-arrow -right'>
                                                    <span className='shaft'></span>
                                                </span>
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={12} lg>
                                        <div className='animated-arrow'>
                                            <span className='the-arrow -left'>
                                                <span className='shaft'></span>
                                            </span>
                                            <span className='main'>
                                                <span className='text'>
                                                    <a href="https://play.google.com/store/apps/details?id=com.leoprime.android" download>For Android</a>
                                                </span>
                                                <span className='the-arrow -right'>
                                                    <span className='shaft'></span>
                                                </span>
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={12} lg>
                                        <div className='animated-arrow'>
                                            <span className='the-arrow -left'>
                                                <span className='shaft'></span>
                                            </span>
                                            <span className='main'>
                                                <span className='text'>
                                                    {/* <DownloadLink
                                                        label="For ios"
                                                        filename="https://download.mql5.com/cdn/web/leo.prime.services/mt5/leoprime5setup.exe"
                                                        exportFile={() => "My cached data"}
                                                    /> */}
                                                    <a href="https://apps.apple.com/us/app/metatrader-4/id496212596" download>For IOS</a>
                                                </span>
                                                <span className='the-arrow -right'>
                                                    <span className='shaft'></span>
                                                </span>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </section>
                <ReadyToTrade />
            </div>
        );
    }
}
export default TradingPlatform;