import React from 'react'
// import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import OpenAccount from '../assets/img/icons/open-account.svg';
import VerifyDocuments from '../assets/img/icons/verify-documents.svg';
import AddFunds from '../assets/img/icons/add-funds.svg';
import StartTrading from '../assets/img/icons/start-trading.svg'

export default function ReadyToTrade() {
    return (
        <section className="hmeOurBenefit">
            <Col lg={10} className="mx-auto">
                <Row>
                    <Col xs={12} md={12} lg={12} className="text-center">
                        <Row>
                            <Col className="mb-4">
                                <h2 className="mb-0">
                                    Ready to trade?
                                </h2>
                                <p>It's quick and easy - Get started with a small deposit by passing our simple verification process.
                                </p>
                            </Col>
                        </Row>
                        <Row className="mt-3 readyToTrade">
                            <div className="container">
                                <Row>
                                    <Col xs={6} md={6} lg={3} className="pr-4 mb-4">

                                        <img src={OpenAccount} alt="OpenAccount" />
                                        <p>
                                            <a href={('https://www.leoprime.com/register')} className='animated-arrow' >
                                                <span className='the-arrow -left'>
                                                    <span className='shaft'></span>
                                                </span>
                                                <span className='main'>
                                                    <span className='text'>
                                                        Open Account
                                                    </span>
                                                    <span className='the-arrow -right'>
                                                        <span className='shaft'></span>
                                                    </span>
                                                </span>
                                            </a></p>

                                    </Col>
                                    <Col xs={6} md={6} lg={3} className="pr-4 mb-4">
                                        <img src={VerifyDocuments} alt="VerifyDocuments" />
                                        <p><a href={('https://www.leoprime.com/register')} className='animated-arrow' >
                                            <span className='the-arrow -left'>
                                                <span className='shaft'></span>
                                            </span>
                                            <span className='main'>
                                                <span className='text'>
                                                    Verify Documents
                                                </span>
                                                <span className='the-arrow -right'>
                                                    <span className='shaft'></span>
                                                </span>
                                            </span>
                                        </a></p>
                                    </Col>
                                    <Col xs={6} md={6} lg={3} className="pr-4 mb-4">
                                        <img src={AddFunds} alt="AddFunds" />
                                        <p><a href={('https://www.leoprime.com/register')} className='animated-arrow' >
                                            <span className='the-arrow -left'>
                                                <span className='shaft'></span>
                                            </span>
                                            <span className='main'>
                                                <span className='text'>
                                                    Add Funds
                                                </span>
                                                <span className='the-arrow -right'>
                                                    <span className='shaft'></span>
                                                </span>
                                            </span>
                                        </a></p>
                                    </Col>
                                    <Col xs={6} md={6} lg={3} className="pr-4 mb-4">
                                        <img src={StartTrading} alt="StartTrading" />
                                        <p><a href={('https://www.leoprime.com/register')} className='animated-arrow' >
                                            <span className='the-arrow -left'>
                                                <span className='shaft'></span>
                                            </span>
                                            <span className='main'>
                                                <span className='text'>
                                                    Start Trading
                                                </span>
                                                <span className='the-arrow -right'>
                                                    <span className='shaft'></span>
                                                </span>
                                            </span>
                                        </a></p>
                                    </Col>
                                </Row>
                            </div>
                        </Row>
                        <Row>
                            <Col>
                                <a href={('https://www.leoprime.com/register')} className="primary-button-lg my-2" >Open Live Account</a>
                                <p>
                                    or try free <a href={('https://www.leoprime.com/register')} className="text-dark"> <u>Demo Account</u></a>
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </section>
    )
}
