import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
import { Helmet } from "react-helmet";

class PrivacyPolicy extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="Privacy Policy|Forex with LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "privacy,policy,leoprime" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Privacy Policy|Forex with LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Privacy Policy|Forex with LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "This Privacy Policy explains the methods that LeoPrime uses to protect its clients' information." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay privacy-policy-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase">Privacy </h1>
                            <h1 className="display-3 text-shadow text-uppercase"><span>Policy</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <Col lg={12}>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    Personal Information
                                </h4>
                                <p className="w-100">When you apply for / or maintain a live trading account with LeoPrime, we collect personal information about you for business purposes, such as evaluating your financial profile, processing your requests and transactions, informing you about products and services that may be of interest, and providing customer service. Such information may include but is not limited to:
                                </p>
                                <p>Application Information: Information which you provide to LeoPrime on applications and other forms, such as your name, address, birth date, ID Number(s), occupation, assets, and income.
                                </p>
                                <p>Transaction Information: Information about your transactions with us and with our affiliates as well as information about our communications with you. Examples include your account balances, trading activity, your inquiries, and our responses.
                                </p>
                                <p>Verification Information: Information which is necessary in verifying your identity, such as a passport or driver’s license.
                                </p>
                                <p>Examples also include background information about you we receive from public records or from other entities not affiliated with LeoPrime. Established financial regulations require us to collect certain information and take actions necessary to verify your identity.
                                </p>
                            </Row>
                            {/* <Row className="mb-3">
                                <h4 className="w-100">
                                    International Business Companies License
                            </h4>
                                <p className="w-100">Leo Prime Service Limited registered in the Marshall Islands (registration number 95149) and regulated in accordance with Marshall Islands Business Corporation Act. The Company’s address: Ajeltake Road, Ajeltake Island, Majuro, Marshall Islands MH96960. 
</p>
                            </Row> */}
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    Cookies
                                </h4>
                                <p className="w-100 mb-0">Cookies are small files containing information that a website uses to track its visitors. LeoPrime may set and access LeoPrime cookies on your computer, enabling us to learn which advertisements and promotions bring users to our website. LeoPrime or any of its divisions may use cookies in connection with LeoPrime's products and services to track your activities on our websites. Such information that we collect and share would be anonymous and not personally identifiable.
                                </p>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    Security Technology
                                </h4>
                                <p className="w-100 mb-0">LeoPrime utilizes Secure Socket Layer(s) (SSL) encryption technology in order to protect certain information that you submit. SSL encryption technology protects against having your information intercepted by a second and or third party other than LeoPrime while it is being transmitted to LeoPrime. LeoPrime strives to ensure that our website(s) is secure and that it meets with industry standards. We also use other safeguards such as firewalls, authentication systems (e.g., passwords and personal identification numbers), and access control mechanisms to control unauthorized access to systems and data.
                                </p>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    Sharing Information with our affiliates
                                </h4>
                                <p className="w-100 mb-0">LeoPrime may share personal information described above with our affiliates for business purposes, such as, but not limited to, servicing customer accounts and informing customers about new products and services, or to aid in the trading activity of the company, its affiliates, or employees, and as permitted by applicable law. Our affiliates may include companies controlled or owned by us as well as companies that have an ownership interest in our company. The information we share with affiliates may include any of the information described above, such as your name, address, trading and account information. Our affiliates maintain the privacy of your information to the same extent LeoPrime does in accordance with this Policy.
                                </p>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    Sharing Information with Third Parties
                                </h4>
                                <p className="w-100 mb-0">LeoPrime does not disclose your personal information to third parties, except as described in this Policy. Third party disclosures may include sharing such information with non-affiliated companies that perform support services for your account or facilitate your transactions with LeoPrime, including those that provide professional, legal, or accounting advice to LeoPrime. Non-affiliated companies that assist LeoPrime in providing services to you are required to maintain the confidentiality of such information to the extent they receive it and to use your personal information only in the course of providing such services and only for the purposes that LeoPrime dictates. We may also disclose your personal information to third parties to fulfill your instructions or pursuant to your expressed consent. We want you to know that LeoPrime will not sell your personal information.
                                </p>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    OPT Out
                                </h4>
                                <p className="w-100 mb-0">There is no outstanding obligation to provide LeoPrime with any quantity of the personal information we may request, however, failure to provide the required personal information may result in LeoPrime being unable to open and maintain an account or provide additional service. While LeoPrime strives to ensure all information we hold on one of our clients is accurate, complete and up-to-date, you can assist us considerably by notifying us promptly upon the change of any personal information. If you do not wish to have your personal information disclosed to our Affiliates or other Third Parties as described in this Policy, please contact us via e-mail by way of the LeoPrime Helpdesk at: compliance@leoprime.com, Or send your request via regular postal mail to: Leo Prime Services Ltd, G.S.Complex, 1st floor, Office Number 12, Providence, Mahe, Seychelles.
                                </p>
                                <p>Please be advised, that if any such instructions are received by LeoPrime, we will no longer be able to further service your account(s) and must close any and all open and funded accounts you hold with the company, Leo Prime Services Ltd

                                </p>
                            </Row>
                        </Col>
                    </div>
                </section>
                <ReadyToTrade />
            </div>
        );
    }
}
export default PrivacyPolicy;