import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
// redux
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import reducers from './store/reducers';
// import {IntlProvider} from 'react-intl';
import Wrapper from "./components/Wrapper";
import { AppContextProvider } from "./providers/context";
// create store
const store = createStore(reducers, applyMiddleware(thunk));
ReactDOM.render(
    <BrowserRouter basename="/">
        <Provider store={store}>
            <Wrapper>
                <AppContextProvider>
                    <App />
                </AppContextProvider>
            </Wrapper>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
