import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Table } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
import TradingInstrumentTabs from '../components/TradingInstrumentTabs'
import { Helmet } from "react-helmet";
export default class TradingInstrumentSpotMetal extends Component {
render() {
return (
<div>
   <Helmet
   title="Gold Trading | Commodity Trading | Trade Gold | LeoPrime"
   meta={[
   { name: 'author', content: "LeoPrime" },
   { name: 'keywords', content: "retail,forex,broker,LeoPrime,trading, forex trading, online" },
   { name: 'twitter:site', content: "LeoPrime" },
   { name: 'twitter:creator', content: "LeoPrime" },
   { name: 'twitter:title', content: "Gold Trading | Commodity Trading | Trade Gold | LeoPrime" },
   { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
   { property: 'og:title', content: "Gold Trading | Commodity Trading | Trade Gold | LeoPrime" },
   { property: 'og:site_name', content: "LeoPrime" },
   { property: 'og:type', content: "Forex Trading" },
   { property: 'og:url', content: "https://leoprime.com/" },
   { property: 'og:description', content: "LeoPrime is an Next-Generation Multi Asset Broker who aims to offer top-notch services that are extensively in need for Forex traders for an experience easier." },
   { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
   { property: 'og:site_name', content: "LeoPrime" },
   { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
   ]}
   >
   </Helmet>
   <div className="innerpageBanner">
      <div className="d-flex align-items-center dark-overlay trading-instrument-banner" >
         <div className="col-xl-10 mx-auto text-white">
            <h1 className="display-3 text-shadow text-uppercase ">Trading</h1>
            <h1 className="display-3 text-shadow text-uppercase"><span>Instrument</span></h1>
         </div>
      </div>
   </div>
   <TradingInstrumentTabs />
   <section className="pt-0">
      <div className="container">
         <Row className="justify-content-md-center">
            <Col xs={12} md={12} lg={4} className="mb-3 d-flex flex-column justify-content-center mt-5">
            <img src={require('../assets/img/account-types/account-type-left.png')} alt="Account Types" className="img-fluid" />
            </Col>
            <Col xs={12} md={12} lg={8} className="mb-3 d-flex align-items-center">
            <div className="d-flex flex-column justify-content-center pl-lg-5">
               <h2 className="mb-4">
                  Spot Metal Contract Specification
               </h2>
               <p>Gold (XAU) and Silver (XAG) are priceless tradable commodities offered by LeoPrime at the best-of-market Spread and spot execution and are a great way to expand your portfolio. During times of economic havoc, these metals offer insights into economic well-being. Be a spot metal trader with LeoPrime and broaden your horizons.
               </p>
            </div>
            </Col>
         </Row>
      </div>
   </section>
   <section className="hmeOurBenefit pt-0">
      <div className="container">
         <Col xs={12} lg className="mb-3 trading_table" id="tabs">
         <Tabs defaultActiveKey="forex" transition={false} className="nav-fill">
            <Tab eventKey="forex" title="Classic">
               <Table borderless responsive hover>
                  <thead>
                     <tr className="row m-0 bg-gray">
                        <th className="d-inline-block col-lg-1">Symbol</th>
                        <th className="d-inline-block col-lg-3 text-left">Description</th>
                        <th className="d-inline-block col-lg-1">Average Spread*</th>
                        <th className="d-inline-block col-lg-3">
                           Margin Requirements
                           <br />
                           <div className="row">
                              <div className="col">1:100</div>
                              <div className="col">1:500 </div>
                              <div className="col">1:1000</div>
                           </div>
                        </th>
                        <th className="d-inline-block col-lg-2">
                           Swap
                           <br />
                           <div className="row">
                              <div className="col">Long</div>
                              <div className="col">Short </div>
                           </div>
                        </th>
                        <th className="d-inline-block col-lg-2 text-center">Stop Level ***</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">XAGUSDs</td>
                        <td className="d-inline-block col-lg-3" data-label="Description">Silver Vs US Dollar	</td>
                        <td className="col-lg-1" data-label="Average Spread*">2.1	</td>
                        <td className="col-lg-1" data-label="Margin Requirements 1:100">1%
                        </td>
                        <td className="col-lg-1" data-label="Margin Requirements 1:500">0.20%
                        </td>
                        <td className="col-lg-1" data-label="Margin Requirements 1:1000">0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">-5.75</td>
                        <td className="col-lg-1" data-label="Swap Short">-1.69</td>
                        <td className="d-inline-block col-lg-2 text-center" data-label="Stop Level ***">1</td>
                     </tr>
                     <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">XAUUSDs</td>
                        <td className="d-inline-block col-lg-3" data-label="Description"> Gold Vs US Dollar</td>
                        <td className="col-lg-1" data-label="Average Spread*">1.7</td>
                        <td className="col-lg-1" data-label="Margin Requirements 1:100">1%</td>
                        <td className="col-lg-1" data-label="Margin Requirements 1:500">0.20%</td>
                        <td className="col-lg-1" data-label="Margin Requirements 1:1000">0.10%</td>
                        <td className="col-lg-1" data-label="Swap Long">-9.76</td>
                        <td className="col-lg-1" data-label="Swap Short"> -2.31 </td>
                        <td className="d-inline-block col-lg-2 text-center" data-label="Stop Level ***">5</td>
                     </tr>
                  </tbody>
               </Table>
            </Tab>
            <Tab eventKey="pro" title="Pro">
               <Table borderless responsive hover>
               <thead>
                     <tr className="row m-0 bg-gray">
                        <th className="d-inline-block col-lg-1">Symbol</th>
                        <th className="d-inline-block col-lg-3 text-left">Description</th>
                        <th className="d-inline-block col-lg-1">Average Spread*</th>
                        <th className="d-inline-block col-lg-3">
                           Margin Requirements
                           <br />
                           <div className="row">
                              <div className="col">1:100</div>
                              <div className="col">1:200 </div>
                              <div className="col">1:500	</div>
                           </div>
                        </th>
                        <th className="d-inline-block col-lg-2">
                           Swap
                           <br />
                           <div className="row">
                              <div className="col">Long</div>
                              <div className="col">Short </div>
                           </div>
                        </th>
                        <th className="d-inline-block col-lg-2 text-center">Stop Level ***</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">XAGUSDp</td>
                        <td className="d-inline-block col-lg-3" data-label="Description">Silver Vs US Dollar</td>
                        <td className="col-lg-1" data-label="Average Spread*">0.9</td>
                        <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 1%
                        </td>
                        <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 0.50%
                        </td>
                        <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:500"> 0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">-5.75</td>
                        <td className="col-lg-1" data-label="Swap Short">-1.69</td>
                        <td className="d-inline-block col-lg-2 text-center" data-label="Stop Level ***">1</td>
                     </tr>
                     <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">XAUUSDp</td>
                        <td className="d-inline-block col-lg-3" data-label="Description">Gold Vs US Dollar</td>
                        <td className="col-lg-1" data-label="Average Spread*">0.5</td>
                        <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 1%
                        </td>
                        <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 0.50%
                        </td>
                        <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:500"> 0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">-9.76</td>
                        <td className="col-lg-1" data-label="Swap Short">-2.31</td>
                        <td className="d-inline-block col-lg-2 text-center" data-label="Stop Level ***">5</td>
                     </tr>
                  </tbody>
               </Table>
            </Tab>
            <Tab eventKey="ecn" title="ECN">
               <Table borderless responsive hover>
               <thead>
                                 <tr className="row m-0 bg-gray">
                                    <th class="d-inline-block col-lg-1">Symbol</th>
                                    <th class="d-inline-block col-lg-4 text-left">Description</th>
                                    <th class="d-inline-block col-lg-1">Average Spread*</th>
                                    <th class="d-inline-block col-lg-2">
                                       Margin Requirements
                           <br />
                                       <div class="row">
                                          <div class="col">1:100</div>
                                          <div class="col">1:200 </div>
                                       </div>
                                    </th>
                                    <th class="d-inline-block col-lg-2">
                                       Swap
                           <br />
                                       <div class="row">
                                          <div class="col">Long</div>
                                          <div class="col"> Short </div>
                                       </div>
                                    </th>
                                    <th class="d-inline-block col-lg-2 text-center">Stop Level ***</th>
                                 </tr>
                              </thead>
                  <tbody>
                     <tr className="row m-0">
                        <td className="d-inline-block col-lg-1" data-label="Symbol">XAGUSD</td>
                        <td className="d-inline-block col-lg-4" data-label="Description">Silver Vs US Dollar	</td>
                        <td className="d-inline-block col-lg-1" data-label="Average Spread*">0.5	</td>
                        <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100">1%</td>
                        <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200">0.50%</td>
                        <td className="d-inline-block col-lg-1" data-label="Swap Long">-5.75</td>
                        <td className="d-inline-block col-lg-1" data-label="Swap Short">-1.69 </td>
                        <td className="d-inline-block col-lg-2 text-center" data-label="Stop Level ***">1</td>
                     </tr>
                     <tr className="row m-0">
                        <td className="d-inline-block col-lg-1" data-label="Symbol">XAUUSD</td>
                        <td className="d-inline-block col-lg-4" data-label="Description"> Gold Vs US Dollar		</td>
                        <td className="d-inline-block col-lg-1" data-label="Average Spread*">0.1	</td>
                        <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100">1%</td>
                        <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:500">0.50%</td>
                        <td className="d-inline-block col-lg-1" data-label="Swap Long">-9.76</td>
                        <td className="d-inline-block col-lg-1" data-label="Swap Short"> -2.31</td>
                        <td className="d-inline-block col-lg-2 text-center" data-label="Stop Level ***">5</td>
                     </tr>
                  </tbody>
               </Table>
            </Tab>
            <Tab eventKey="cent" title="Cent">
               <Table borderless responsive hover>
                  <thead>
                     <tr className="row m-0 bg-gray">
                        <th className="col-lg-1">Symbol</th>
                        <th className="col-lg-3 text-left">Description</th>
                        <th className="col-lg-1">Average Spread*</th>
                        <th className="d-inline-block col-lg-3">
                           Margin Requirements
                           <br />
                           <div className="row">
                              <div className="col">1:100</div>
                              <div className="col">1:500 </div>
                              <div className="col">1:1000	</div>
                           </div>
                        </th>
                        <th className="d-inline-block col-lg-2">
                           Swap
                           <br />
                           <div className="row">
                              <div className="col">Long</div>
                              <div className="col">Short </div>
                           </div>
                        </th>
                        <th className="d-inline-block col-lg-2 text-center">Stop Level ***</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">XAGUSDc</td>
                        <td className="d-inline-block col-lg-3" data-label="Description">Silver Vs US Dollar</td>
                        <td className="col-lg-1" data-label="Average Spread*">2.1	</td>
                        <td className="col-lg-1" data-label="Margin Requirements 1:100">1%</td>
                        <td className="col-lg-1" data-label="Margin Requirements 1:500">0.20%</td>
                        <td className="col-lg-1" data-label="Margin Requirements 1:1000">0.10%</td>
                        <td className="col-lg-1" data-label="Swap Long">-5.75</td>
                        <td className="col-lg-1" data-label="Swap Short">-1.69	 </td>
                        <td className="d-inline-block col-lg-2 text-center" data-label="Stop Level ***">5</td>
                     </tr>
                     <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">XAUUSDc</td>
                        <td className="d-inline-block col-lg-3" data-label="Description"> Gold Vs US Dollar</td>
                        <td className="col-lg-1" data-label="Average Spread*">1.7</td>
                        <td className="col-lg-1" data-label="Margin Requirements 1:100">1%</td>
                        <td className="col-lg-1" data-label="Margin Requirements 1:500">0.20%</td>
                        <td className="col-lg-1" data-label="Margin Requirements 1:1000">0.10%</td>
                        <td className="col-lg-1" data-label="Swap Long">-9.76</td>
                        <td className="col-lg-1" data-label="Swap Short"> -2.31 </td>
                        <td className="d-inline-block col-lg-2 text-center" data-label="Stop Level ***">10</td>
                     </tr>
                  </tbody>
               </Table>
            </Tab>
         </Tabs>
         <p className="text-lg-right text-center"><small>*Values are subjected to change
            </small>
         </p>
         </Col>
      </div>
   </section>
   <ReadyToTrade />
</div>
);
}
}