import React, { useState, useEffect } from "react"
// import React, { useState, useEffect, useContext } from "react"

// import Nav from '../ui/Nav';
import { Link } from 'react-router-dom';
import { Fade } from 'react-reveal';
import SideBar from '../components/SideBar';
// import { Element, scroller } from 'react-scroll';
import ReactFlagsSelect from 'react-flags-select';
import { Col, Row } from 'react-bootstrap';
import openacc from '../assets/img/icons/open-live-account.svg';
import Popup from "reactjs-popup";
import BurgerIcon from "../components/BurgerIcon";
import Menu from "../components/Nav";

// import { Context } from "../components/Wrapper";



export default function Header(props) {

    // const context = useContext(Context);
    const ref = React.createRef();
    const [scroll, setScroll] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 10)
        })
    }, [])
    const contentStyle = {
        background: "rgba(255,255,255,1)",
        width: "100%",
        border: "none",
        // margin: "70px auto 0"
    };
    return (
        <Fade top delay={100}>
            <header className={scroll ? "header scrolled" : "header"}>
                <Row className="bg-white">
                    <Col lg={10} className="mx-auto">
                        <nav className="navbar navbar-light">
                            <Link className="header__logo-link" to="/">
                                <div className="logo"></div>
                            </Link>
                            <div className="d-none d-lg-block">
                                <div className="form-inline">
                                    {/* <select value={context.locale} onChange={context.selectLanguage}>
                                        <option value='en'>English</option>
                                        <option value='fr'>French</option>
                                        <option value='ar'>Arabic</option>
                                    </select> */}
                                    <ReactFlagsSelect defaultCountry="US" className="menu-flags my-2"
                                        countries={["US", "GB", "FR"]}
                                        placeholder="Select Language"
                                        showSelectedLabel={false}
                                        showOptionLabel={false}
                                        disabled={true} />
                                    <a href="https://www.leoprime.com/user/" target="blank" className="secondary-button mx-2">Login</a>
                                    <a href="https://www.leoprime.com/register" target="blank" className="secondary-button-outline"> <img src={openacc} alt='' className="pr-2" />   Open Live Account</a>
                                    <Popup
                                        trigger={open => (<BurgerIcon open={open} />)}
                                        modal
                                        // overlayStyle={{ background: "rgba(255,255,255,1" }}
                                        contentStyle={contentStyle}
                                        closeOnDocumentClick={false}

                                    >
                                        {close => <Menu close={close} />}
                                    </Popup>
                                </div>
                            </div>
                            <div className="d-block d-lg-none mobBtn">
                                <div className="form-inline">
                                    <ReactFlagsSelect defaultCountry="US" className="menu-flags my-2 d-none d-lg-block"
                                        countries={["US", "GB", "FR"]}
                                        placeholder="Select Language"
                                        showSelectedLabel={false}
                                        showOptionLabel={false} />
                                    <a href="https://www.leoprime.com/user/" target="blank" className="secondary-button mx-2">Login</a>
                                    <a href="https://www.leoprime.com/register" target="blank" className="secondary-button-outline"> <img src={openacc} alt='' className="pr-2" />   Open Live Account</a>
                                    <Popup
                                        modal
                                        // overlayStyle={{ background: "rgba(255,255,255,1" }}
                                        contentStyle={contentStyle}
                                        closeOnDocumentClick={false}
                                        trigger={open => <BurgerIcon ref={ref} open={open} />}
                                    >
                                        {close => <Menu close={close} />}
                                    </Popup>
                                </div>
                            </div>
                        </nav>
                        <SideBar />
                    </Col>
                </Row>
            </header>
        </Fade>
    );
}
