import React, { Component } from 'react'
import { Row, Col, Card, CardDeck } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
// import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";


import { connect } from "react-redux";
import { getMonitoringList } from "../store/actions/monitoringData";
import Progress from "../components/Progress";
// import Alert from "@material-ui/lab/Alert";
// import AlertTitle from "@material-ui/lab/AlertTitle";
// import Pagination from '@material-ui/lab/Pagination';
import { getUrlPart } from '../utils/getUrlPart'


class Monitoring extends Component {
    state = {
        isLoading: true, // used for loader
        hasURL: false, // used for loader
    };

    constructor(props) {
        super(props);
        this.fetchMonitoringData({ urlkey: getUrlPart('user') });
    }


    fetchMonitoringData = (param) => {
        if (getUrlPart('user')) {
            this.props.getMonitoringList(param, (res) => {
                this.setState({ isLoading: false });
                this.setState({ hasURL: true });

            });
        }
        else {
            this.setState({ isLoading: false });
            this.setState({ hasURL: true });
        }

    };

    render() {
        return (
            <div>
                <Helmet
                    title="Monitoring"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "Monitoring" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Monitoring" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Monitoring" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "LeoPrime Monitoring" },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >

                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay margin-leverage-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase ">Monitoring
                            </h1>
                            {/* <h1 className="display-3 text-shadow text-uppercase"><span>Leverage</span></h1> */}
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container text-center">
                   
                            <p className="w-100">Our monitoring section provides you with information about the client’s trades. <br/>Listed below are the trading details of traders, who have allowed the monitoring of their trades.  
                            </p>
                    </div>
                </section>
                {

                    this.state.hasURL ?
                        !this.state.isLoading ?
                            <section>
                                <Col lg={10} className="mx-auto">
                                    <Row>
                                        <Col className="mb-3">
                                            <CardDeck>
                                                <Card className="col-md-8">
                                                    <Card.Body className="px-2">
                                                        <h4>Account Stats</h4>
                                                        <Row>
                                                            <Col>
                                                                <Row>
                                                                    <Col>
                                                                        Total Account Profit USD
                                                                    </Col>
                                                                    <Col className="text-right">
                                                                        {this.props.MonitoringList.basic.total_account_profitability_in}
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col>
                                                                        Total Open Orders
                                                                    </Col>
                                                                    <Col className="text-right">
                                                                        {this.props.MonitoringList.basic.total_open_orders}
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        Total Closed  Orders                                                                    </Col>
                                                                    <Col className="text-right">
                                                                        {this.props.MonitoringList.basic.total_closed_orders}
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col>

                                                                <Row>
                                                                    <Col>
                                                                        Current Equity In USD
                                                                    </Col>
                                                                    <Col className="text-right">
                                                                        {this.props.MonitoringList.basic.current_equity_in_usd}
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        Profile Factor
                                                                    </Col>
                                                                    <Col className="text-right">
                                                                        {this.props.MonitoringList.basic.profit_factor}
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="col-md-4">
                                                    <Card.Body className="px-2">
                                                        <h4>Account Data</h4>
                                                        <Row>
                                                            <Col>
                                                                Country
                                                            </Col>
                                                            <Col className="text-right">
                                                                {this.props.MonitoringList.general.country}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                Currency
                                                            </Col>
                                                            <Col className="text-right">
                                                                {this.props.MonitoringList.general.currency}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                Leverage
                                                            </Col>
                                                            <Col className="text-right">
                                                                {this.props.MonitoringList.general.leverage}
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </CardDeck>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-3">
                                            <CardDeck>
                                                <Card>
                                                    <Card.Body className="px-2">
                                                        <Col>
                                                            <h4>Advanced Stats</h4>
                                                            <Row>
                                                                <Col>
                                                                    Deposit &amp; Withdrawal in USD
                                                                </Col>
                                                                <Col className="text-right">
                                                                    {this.props.MonitoringList.advance.deposit_withdrawal_in_usd}

                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    Floating P/L in USD
                                                                </Col>
                                                                <Col className="text-right">
                                                                    {this.props.MonitoringList.advance.floating_p_l_in_usd}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    Margin in USD
                                                                </Col>
                                                                <Col className="text-right">
                                                                    {this.props.MonitoringList.advance.margin_in_usd}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    Overall Profit
                                                                </Col>
                                                                <Col className="text-right">
                                                                    {this.props.MonitoringList.advance.overall_profit}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    Overall Loss
                                                                </Col>
                                                                <Col className="text-right">
                                                                    {this.props.MonitoringList.advance.overall_loss}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    Total Traders
                                                                </Col>
                                                                <Col className="text-right">
                                                                    {this.props.MonitoringList.advance.total_trades}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    Total short position
                                                                </Col>
                                                                <Col className="text-right">
                                                                    {this.props.MonitoringList.advance.total_short_position}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    Total long position
                                                                </Col>
                                                                <Col className="text-right">
                                                                    {this.props.MonitoringList.advance.total_long_position}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    Profit traders and profit traders %
                                                                </Col>
                                                                <Col className="text-right">
                                                                    {this.props.MonitoringList.advance.profit_trade.percentage}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    Loss traders and loss traders %                                                                </Col>
                                                                <Col className="text-right">
                                                                    {this.props.MonitoringList.advance.loss_trade.percentage}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    Highest profit trade in USD
                                                                </Col>
                                                                <Col className="text-right">
                                                                    {this.props.MonitoringList.advance.highest_profit_trade_in_usd}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    Highest loss trader in USD
                                                                </Col>
                                                                <Col className="text-right">
                                                                    {this.props.MonitoringList.advance.highest_loss_trade_in_usd}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    Average Profit trade
                                                                </Col>
                                                                <Col className="text-right">
                                                                    {this.props.MonitoringList.advance.average_profit_trade}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    Average Loss trade
                                                                </Col>
                                                                <Col className="text-right">
                                                                    {this.props.MonitoringList.advance.average_loss_trade}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Card.Body>

                                                </Card>
                                            </CardDeck>
                                        </Col>
                                    </Row>
                                </Col>
                            </section> : <Progress /> : ""
                }
                <ReadyToTrade />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    console.log(state)
    return {
        MonitoringList: state.monitoringList
    }
};
export default connect(
    mapStateToProps,
    {
        getMonitoringList,
    })(Monitoring);