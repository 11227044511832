import { actionType } from "../actionType";

const sendEmailReducer = (state = {}, action) => {
    if (action.type === actionType.POST_SEND_EMAIL) {
        return { ...state, ...action.payload };
    }
    return state;
};

export default sendEmailReducer;
