import React, { Component } from 'react'
import { Row, Col, Card, Table } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
// import {Link} from 'react-router-dom';
import Slider from 'react-rangeslider'
import { Helmet } from "react-helmet";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import marginRule from '../config/marginRule';

// const META = [
//     {name: 'author', content: "LeoPrime"},
//     {
//         name: 'keywords',
//         content: "high leverage,leading,forexbroker,retail, account,forex,broker,LeoPrime,trader, forex trader, online"
//     },
//     {name: 'twitter:site', content: "LeoPrime"},
//     {name: 'twitter:creator', content: "LeoPrime"},
//     {name: 'twitter:title', content: "Leading forex broker | Top forex broker | Forex broker with high leverage"},
//     {name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg"},
//     {property: 'og:title', content: "Leading forex broker | Top forex broker | Forex broker with high leverage"},
//     {property: 'og:site_name', content: "LeoPrime"},
//     {property: 'og:type', content: "Forex Trading"},
//     {property: 'og:url', content: "https://leoprime.com/"},
//     {
//         property: 'og:description',
//         content: "LeoPrime is a regulated Forex broker with high leverage range upto a maximum of 1:1000. A complete list of margin requirements and leverage can be viewed in Margin & Trading requirements."
//     },
//     {property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg"},
//     {property: 'og:site_name', content: "LeoPrime"},
//     {name: 'viewport', content: 'width=device-width, maximum-scale=1'},
// ];

export default class MarginLeverageRules extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            leverage: 100, // Default of Classic Data
            minLeverage: 100, // Default of Classic Data
            maxLeverage: 1000, // Default of Classic Data
            equity: 50000, // Default of Classic Data
            minEquity: 100, // Default of Classic Data
            maxEquity: 50000, // Default of Classic Data
            equityLabel: '>50000', // Default of Classic Data
            rule: 'classic',
            rules: marginRule
        };

        this.updateRangeValue('classic'); // set a Classic Data
    }

    // Listen Leverage change and update Equity.
    handleLeverageChange = (value) => {
        this.setState({
            leverage: value,
        });
        this.setEquity(value);
        if (value > 500)
            this.setLeverage(1000);
    };

    // Listen Equity change and update Leverage.
    handleEquityChange = (value) => {
        this.setState({
            equity: value,
            equityLabel: value,
        });

        this.setLeverage(value);
    };

    // Listen Group change event and update Leverage and Equity based on slab.
    handleGroupChange = (event) => {
        const value = event.target.value;
        this.updateRangeValue(value);
    };

    //  update Leverage and Equity Range based on slab.
    updateRangeValue = (value) => {
        const slab = this.state.rules[value];

        this.setState({
            rule: value,
            leverage: slab.rules['100'].leverage,
            minLeverage: slab.min_leverage,
            maxLeverage: slab.max_leverage,
            equity: slab.rules['100'].max_slab,
            minEquity: slab.min_equity,
            maxEquity: slab.max_equity,
            equityLabel: slab.rules['100'].slab,
        });
    };

    // update Leverage
    setLeverage = (value) => {
        const slab = this.findSlab(value);

        if (slab != null) {
            this.setState({
                leverage: slab.leverage,
            });

            if (this.isLastNode(slab)) {
                this.setState({
                    equityLabel: slab.slab,
                });
            }
        }
    };

    // Find a Slab based on equity for update Leverage
    findSlab = (value) => {
        let slab = null;

        Object.keys(this.state.rules[this.state.rule].rules).forEach((key) => {
            const rule = this.state.rules[this.state.rule].rules[key];
            if (value >= rule.min_slab && (value < rule.max_slab || this.isLastNode(rule))) {
                slab = rule;
            }
        });

        return slab
    };

    // detect a slab is Last or not
    isLastNode = (rule) => {
        return rule.slab.charAt(0) === '>'
    };

    // Update a Equity
    setEquity = (leverage) => {
        const rules = this.state.rules[this.state.rule].rules;
        const slab = leverage > 500 ? 1000 : leverage;
        this.setState({
            equity: rules[slab].max_slab,
            equityLabel: rules[slab].slab
        })
    };

    render() {
        return (
            <div>
                {/* <Helmet
                    title="Leading forex broker | Top forex broker | Forex broker with high leverage"
                    meta={META}
                >
                </Helmet> */}
                <Helmet
                    title="Leading forex broker | Top forex broker | Forex broker with high leverage"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "high leverage,leading,forexbroker,retail, account,forex,broker,LeoPrime,trader, forex trader, online" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Leading forex broker | Top forex broker | Forex broker with high leverage" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Leading forex broker | Top forex broker | Forex broker with high leverage" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "LeoPrime is a regulated Forex broker with high leverage range upto a maximum of 1:1000. A complete list of margin requirements and leverage can be viewed in Margin & Trading requirements." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>

                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay margin-leverage-banner">
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase ">Margin and </h1>
                            <h1 className="display-3 text-shadow text-uppercase"><span>Leverage</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <div className="text-center">
                            <h2 className="mb-4 w-100">
                                Margin and Leverage
                                <span className="d-block">Rules</span>
                            </h2>
                            <p className="w-100">Margin is the total amount of money required as a “good faith deposit”
                            in order to create a place for your trade. It depends on the account type you hold,
                            trading instrument’s liquidity and volatility, and it is updated periodically for price
                            oscillations. The Financial instrument which allows you to take a position that is worth
                            more in the market than an initial outlay is a leveraged product. Different leveraged
                            products work in different ways, but all amplify the potential profit and loss for a
                            trader. Leveraged products will mostly require you to pay a margin.
                            </p>
                            <Col className="text-center mt-3 mb-5">
                                <a href={('https://www.leoprime.com/register')} className="primary-button-lg my-2" >Open Live Account</a>
                                <p>
                                    or try free <a href={('https://www.leoprime.com/register')} className="text-dark"> <u>Demo Account</u></a>
                                </p>
                            </Col>
                        </div>
                    </div>
                </section>
                <section className="bg-gray">
                    <div className="container">
                        <Row>
                            <Col lg={2}>
                                <FormControl>
                                    <RadioGroup name="margin-rule"
                                        value={this.state.rule}
                                        onChange={this.handleGroupChange}>
                                        <FormControlLabel value="classic"
                                            control={<Radio color="primary" />}
                                            label="Classic" />
                                        <FormControlLabel value="ecn"
                                            control={<Radio color="primary" />}
                                            label="ECN" />
                                        <FormControlLabel value="pro"
                                            control={<Radio color="primary" />}
                                            label="Pro" />
                                        <FormControlLabel value="cent"
                                            control={<Radio color="primary" />}
                                            label="Cent" />
                                    </RadioGroup>
                                </FormControl>
                            </Col>
                            <Col lg={3}>
                                <div className='slider-horizontal'>
                                    <Slider
                                        step={100}
                                        min={this.state.minLeverage}
                                        max={this.state.maxLeverage}
                                        value={this.state.leverage}
                                        orientation='horizontal'
                                        onChange={this.handleLeverageChange}
                                    />
                                </div>
                                <h4>1:{this.state.leverage}</h4>
                                <p>Maximum Leverage</p>
                            </Col>
                            <Col lg={3}>
                                <div className='slider-horizontal'>
                                    <Slider
                                        step={100}
                                        min={this.state.minEquity}
                                        max={this.state.maxEquity}
                                        value={this.state.equity}
                                        orientation='horizontal'
                                        onChange={this.handleEquityChange}
                                    />
                                </div>
                                <h4>{this.state.equityLabel} {this.state.rules[this.state.rule].currency}</h4>
                                <p>Maximum Equity</p>
                            </Col>
                            <Col lg={3} className="text-center">
                                <h1>{(100 / this.state.leverage).toFixed(2)}%</h1>
                                <p>Margin Requirements</p>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section>
                    <Col lg={10} className="mx-auto">
                        <Row className="justify-content-md-center">
                            <Col xs={12} md={12} lg={7} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center">
                                    <h2>
                                        Maximum Leverage
                                    </h2>
                                    <div className="table-responsive marginLeveTbl">
                                        <Table borderless hover>
                                            <thead>
                                                <tr className="border-bottom">
                                                    <th colRow="2">
                                                        Maximum forex leverage
                                                </th>
                                                    <th colSpan="4">Equity</th>
                                                </tr>
                                                <tr className="border-bottom">
                                                    <th></th>
                                                    <th>Cent (in Cents)</th>
                                                    <th>Classic (in USD)</th>
                                                    <th>Pro (in USD)</th>
                                                    <th>ECN (in USD)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1:1000</td>
                                                    <td>upto 25000</td>
                                                    <td>1 to 500</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                </tr>
                                                <tr>
                                                    <td>1:500</td>
                                                    <td>25001 to 50000</td>
                                                    <td>501 to 10000</td>
                                                    <td>Upto 10000</td>
                                                    <td>-</td>
                                                </tr>
                                                <tr>
                                                    <td>1:400</td>
                                                    <td>50001 to 100000</td>
                                                    <td>10001 to 20000</td>
                                                    <td>10001 to 30000</td>
                                                    <td>-</td>
                                                </tr>
                                                <tr>
                                                    <td>1:300</td>
                                                    <td>100001 to 150000</td>
                                                    <td>20001 to 30000</td>
                                                    <td>30001 to 50000</td>
                                                    <td>-</td>
                                                </tr>
                                                <tr>
                                                    <td>1:200</td>
                                                    <td>150001 to 200000</td>
                                                    <td>30001 to 50000</td>
                                                    <td>50001 to 100000</td>
                                                    <td>Upto 30000</td>
                                                </tr>
                                                <tr>
                                                    <td>1:100</td>
                                                    <td>above 200000</td>
                                                    <td>>50000</td>
                                                    <td>> 100000</td>
                                                    <td>>30000</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={5} className="mb-3 project-tab" id="tabs">
                                <Row className="h-100">
                                    <div className="d-flex flex-column justify-content-center">
                                        <Card className="boxShadow">
                                            <Card.Body>
                                                <h5>Margin Rules</h5>
                                                <p>Margin requirements can be different according to the currency pairs
                                                and shall be subjected to change depending on the economic
                                                conditions. Margin requirements are calculated based on a maximum
                                                leverage of 1:100. A complete list of margin requirements by
                                                currency pair can be viewed here.
                                                </p>
                                                <h5 className="mt-4">Leverage Rules</h5>
                                                <p>Leverage is a byproduct of margin and allows an individual to control
                                                larger trade sizes. Traders will use this tool as a way to magnify
                                                their returns. It’s imperative to stress that losses are also
                                                magnified when leverage is used. Therefore, it is important to
                                                understand that leverage needs to be controlled.
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </section>
                <ReadyToTrade />
            </div>
        );
    }
}
