import { actionType } from "../actionType";

const socialTradingHistReducer = (state = {}, action) => {
    if (action.type === actionType.FETCH_SOCIAL_TRADING_HIST) {
        return { ...state, ...action.payload };
    }
    return state;
};

export default socialTradingHistReducer;
