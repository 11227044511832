import { actionType } from "../actionType";
import apiConfig from '../../config/apiConfig';

export const fetchGradeLiquidity = (cb) => async (dispatch) => {
    await apiConfig.get('quotes/jq')
        .then((response) => {
            dispatch({ type: actionType.FETCH_GRADE_LIQUIDITY, payload: response.data });
            cb({ status: true });
        }).catch((error) => {
            console.log("error", error.message);
        });
};
