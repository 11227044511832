import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
import { Helmet } from "react-helmet";
class TermsConditions extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="Terms and Condition | Forex with LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "terms,conditions,legal,document,leoprime" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Terms and Condition | Forex with LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Terms and Condition | Forex with LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "Start trading with the Next-Generation Multi Asset Broker LeoPrime." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay regulation-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase text-dark">Terms And </h1>
                            <h1 className="display-3 text-shadow text-uppercase text-dark"><span>Conditions</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <Col lg={12}>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    Terms of Use
                            </h4>
                                <p className="w-100">These Terms of Use (of the "Agreement") are applicable to the web pages, services, content, databases and information (collectively referred to as the "Services") together with the agreements and software provided by LeoPrime to which you may have access by using LeoPrime's website or, upon request, in hard copy.
</p>
                                <p>The content and information included in these Services are provided by LeoPrime and its third party suppliers (collectively, the "Information Providers").
</p>
                                <p>These Services are made available by LeoPrime subject to the terms and conditions stated below.
</p>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    Agreement                            </h4>
                                <p>By using the Services, you are confirming your consent to be bound by the terms of this Agreement. If you do not agree, LeoPrime is not willing to provide you with access to the Services and you should immediately discontinue your use of the Services. You agree that LeoPrime may modify this Agreement at any time in its sole discretion and without prior notice to you. Such changes will be posted online and will be effective upon posting. You should review this Agreement periodically to ensure familiarity with its then-current terms and conditions.
</p>
                                <p>Your continued use of the Services shall constitute your acceptance of this Agreement and your continued use of the Services following any modification of this Agreement shall constitute your acceptance to the Agreement, as amended. If you do not agree to the changes, you must notify LeoPrime in writing or by telephone of your refusal and discontinue your use of the Services.
</p>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    Use of Services
                            </h4>
                                <p className="w-100 mb-0">No warranties made as to content; no responsibilities to update: LeoPrime makes no warranty, express or implied, concerning this service. The services provided by us and our third party providers are on an "AS IS" basis at your sole risk.
</p>
                                <p>LeoPrime expressly disclaims any implied warranty of merchantability or fitness for a particular purpose, including any warranty for the use or the results of the use of the services with respect to their correctness, quality, accuracy, completeness, reliability, performance, timeliness, or continued availability.
</p>
                                <p>These Services are intended for your personal, non-commercial use.
</p>
                                <p>By using the above-mentioned Services and the software provided by LeoPrime, you acknowledge and agree that:
</p>
                                <p>You are permitted to store, display, analyze, modify, reformat, and print the information made available to you via these services only for your own use. You are not permitted to publish, transmit, or otherwise reproduce this information, in whole or in part, in any format to any third party without the express written consent of LeoPrime. In addition, you are not permitted to alter, obscure, or remove any copyright, trademark or any other notices that are provided to you in connection with the information. You are required to read and abide by any additional terms and conditions that may be posted on this service from time to time concerning information obtained from specific third party providers. Such third party providers shall have no liability to you for monetary damages on account of the information provided to you via this service.
</p>
                                <p>You shall not use the Information for any unlawful or unauthorized purpose. The use and interpretation of the Services requires skill and judgment, and you shall at all-time exercise your own judgment in the use of the Services. You are responsible for all statements made and acts or omissions that occur while your user identification name (or "User Name") and passwords are being used. You are responsible for protecting and securing your User Name and Password from unauthorized use and disclosure. If you become aware of, or believe there has been, any breach of security for any of your information stored on the LeoPrime's website, such as the theft or unauthorized use of your User Name, Password, or any other information, you will notify LeoPrime immediately. The Services and the software have been prepared for informational purposes only without regard to any particular user's investment objectives, financial situation, or means, and LeoPrime is not soliciting any action based upon it.
</p>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    Disclaimer of warrenties
                            </h4>
                                <p className="w-100 mb-0">LeoPrime and the Information Provider(s) expressly disclaim all warranties of any kind, express or implied, including without limitation any warranty of merchantability, fitness for a particular purpose or non-infringement.
</p>
                                <p>Although the Services provided have been obtained from sources deemed to be reliable, the Services are provided by LeoPrime and/or any third-party Information Provider(s) provide the Services without responsibility for accuracy and correctness.
</p>
                                <p>By using the Services you agree that errors and/or omissions contained in such information shall not be made the basis for any claim, demand or cause of action against LeoPrime or any Information Provider(s).
</p>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    No Liability for Content and No Liability arising from Use
                            </h4>
                                <p className="w-100 mb-0">Neither LeoPrime nor any of its third-party Information Providers shall be liable for any direct, indirect, incidental, special or consequential damages arising out of or relating to this Agreement or resulting from the use or the inability to use the Services, including but not limited to damages for loss of profits, use, data or other intangible damages, even if such party has been advised of the possibility of such damages.
</p>
                                <p>LeoPrime shall have no liability, contingent or otherwise, to you or to third parties, for the correctness, quality, accuracy, timeliness, reliability, performance, continued availability, completeness or delays, omissions, or interruptions in the delivery of the data and services available on this Website or for any other aspect of the performance of this service or for any failure or delay in the execution of any transactions through this service.
</p>
                                <p>In no event will LeoPrime be liable for any special, indirect, incidental, or consequential damages which may be incurred or experienced on account of you using the data or services made available on this Website, even if LeoPrime has been advised of the possibility of such damages.
</p>
                                <p>LeoPrime will not bear any responsibility to inform you of any difficulties experienced by LeoPrime or third parties, with respect to the use of the services or to take any action in connection therewith.
</p>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    System outages, Slowdowns and Capacity Limitations/Delays in Order Routing, Executions and Trade Reports
                            </h4>
                                <p className="w-100 mb-0">As a result of high Internet traffic, transmission problems, systems capacity limitations, and other problems, you may, at times, experience difficulty accessing the Website or communicating with LeoPrime through the Internet or other electronic and wireless services. Any computer system or other electronic device, whether, it is yours, an Internet service provider's or LeoPrime's can experience unanticipated outages or slowdowns, or have capacity limitations. No representations made as to other sites or links.
</p>
                                <p>This service may provide links to certain Internet sites (the "Sites") sponsored and maintained by third parties. LeoPrime is providing such links solely as a convenience to you. Accordingly, LeoPrime makes no representations concerning the content of the Sites.
</p>
                                <p>The fact that LeoPrime has provided a link to the Site does not constitute an endorsement, authorization, sponsorship, or affiliation by LeoPrime with respect to the Site, its owners, or its providers. LeoPrime has not tested any information, software, or a product found on any of the Sites and therefore does not make any representations with respect thereto, including any representations regarding the content or sponsors of the Site, or the suitability or appropriateness of the products or transactions described therein.
</p>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    Miscellaneous                            </h4>
                                <p className="w-100 mb-0">By agreeing to the Terms of Use of the Services and the software you acknowledge and agree that LeoPrime, in the interest of security, reserves the right to monitor your use of the Services on the website and that the resulting information may be used by LeoPrime for internal business purposes.
</p>
                            </Row>
                        </Col>
                    </div>
                </section>
                <ReadyToTrade />
            </div>
        );
    }
}
export default TermsConditions;