import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
import { Helmet } from "react-helmet";

class AmlPolicy extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="AML Policy|Forex with LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "aml,policy,leoprime" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "AML Policy|Forex with LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "AML Policy|Forex with LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "LeoPrime does everything required to ensure compliance with laws against money laundering and financing terrorism." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay aml-policy-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase">AML & KYC </h1>
                            <h1 className="display-3 text-shadow text-uppercase"><span>Policy</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <Col lg={12}>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    Anti-Money Laundering
                            </h4>
                                <p className="w-100">With financial crime being prevalent than before, it is vital that both companies and governments develop tactics to curb it. Firms must comply with the Bank Secrecy Act and its implementing regulations. Probably the most common way of doing so is to implement anti-money laundering policies that prevent the smuggling of illegally-obtained funds. The purpose of the AML rules is to help detect and report suspicious activity including the predicate offenses to money laundering and terrorist financing, such as securities fraud and market manipulation. Most countries now have their own anti-money laundering policies, and many require that all financial institutions strictly abide by these policies in order to support efforts against financial crime.
</p>
                                <p>Anti-money laundering policy is a strategy used by an organization to prevent and effectively combat money laundering and other financial crimes. Anti-money laundering policies typically require most entities that complete financial transactions to keep thorough records of their clients’ accounts and activities. If they come across any information that appears to be suspicious, they are required to report it to the government for further investigation. Financial institutions are crucial for the collection of financial intelligence, and the public sector greatly depends on them in order to compile data.
</p>
                                <p>Money laundering is defined as the process where the identity of the proceeds of crime is so disguised that it gives an impression of legitimate income. Criminals specifically target financial services firms through which they attempt to launder criminal proceeds without the firms’ knowledge or suspicion.
</p>
                                <p>LeoPrime AML Policy is designed to prevent money laundering by meeting the legal standards on combating money laundering and terrorism financing, including the need to have adequate systems and controls in place to mitigate the risk of the firm being used to facilitate financial crime. This AML Policy sets out the standards which must be complied with and includes:
</p>
                                <ul className="liststyle">
                                    <li>Appointing a Money Laundering Reporting Officer (MLRO) who has a sufficient level of seniority and independence, and who has responsibility for oversight of compliance with the relevant legislation, regulations, rules and industry guidance
</li>
                                    <li>Establishing and maintaining a Risk-Based Approach (RBA) to the assessment and management of money laundering and terrorist financing risks faced by the firm.
</li>
                                    <li>Establishing and maintaining risk-based Customer Due Diligence (CDD), identification, verification and Know Your Customer (KYC) procedures, including enhanced due diligence for customers presenting a higher risk, such as Politically Exposed Persons (PEPs).
 </li>
                                    <li>Establishing and maintaining risk-based systems and procedures for the monitoring of on-going customer activity.
</li>
                                    <li>Establishing procedures for reporting suspicious activity internally and to the relevant law enforcement authorities as appropriate.
</li>
                                    <li>Maintaining appropriate records for the minimum prescribed periods.
</li>
                                    <li>Providing training for and raising awareness among all relevant employees.
</li>
                                </ul>
                                <p>LeoPrime is committed to the highest standards of the Anti-Money Laundering (AML) compliance and Counter-Terrorism Financing (CTF). To help the government fight the funding of terrorism and money laundering activities, law requires all financial institutions to obtain, verify, and record information that identifies each person opening an account
</p>
                                <p>Money laundering – the process of converting funds, received from illegal activities (such as fraud, corruption, terrorism, etc.), into other funds or investments that look legitimate to hide or distort the real source of funds.
</p>
                                <p>The process of money laundering can be divided into three sequential stages:
</p>
                            </Row>
                            <Row className="mb-3">
                                <ul className="liststyle">
                                    <li>Layering. Funds are transferred or moved to other accounts and other financial instruments. It is performed to disguise the origin and disrupt the indication of the entity that made the multiple financial transactions. Moving funds around and changing in their form makes it complicated to trace the money being laundered.
</li>
                                    <li>Integration. Funds get back into circulation as legitimate to purchase goods and services.
</li>
                                    <li>Placement. At this stage, funds are converted into financial instruments, such as checks, bank accounts, and money transfers, or can be used for purchasing high-value goods that can be resold. They can also be physically deposited into banks and non-bank institutions (e.g., currency exchangers). To avoid suspicion by the company, the launderer may as well make several deposits instead of depositing the whole sum at once, this form of placement is called smurfing.
</li>
                                </ul>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    Know your Customer and due diligence
                            </h4>
                                <p className="w-100 mb-0">The formal identification of customers on entry into commercial relations is a vital element, both for the regulations relating to money laundering and for the KYC policy. Because of the company’s commitment to the AML and KYC policies, each client of the company has to finish a verification procedure. Before LeoPrime starts any cooperation with the client, the company ensures that satisfactory evidence is produced or such other measures that will produce satisfactory evidence of the identity of any customer or counterparty are taken. The company as well applies heightened scrutiny to clients, who are residents of other countries, identified by credible sources as countries, having inadequate AML standards or that may represent a high risk for crime and corruption and to beneficial owners who resides in and whose funds are sourced from named countries.
</p>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    Individual Clients
                            </h4>
                                <p className="w-100 mb-0">During the process of registration, each client provides personal information, specifically: full name; date of birth; country of origin; and complete residential address. The following documents are required in order to verify the personal information: A client sends the following documents (in case the documents are written in non-Latin characters: to avoid any delays in the verification process, it is necessary to provide a notarized translation of the document in English) because of the requirements of KYC and to confirm the indicated information:
</p>
                                <ul className="liststyle">
                                    <li>Current valid passport (showing the first page of the local or international passport, where the photo and the signature are clearly visible) (or)
</li>
                                    <li>Driving licence which bears a photograph (or)
</li>
                                    <li>National identity card (showing both front and back pages).
</li>
                                    <li>Documents proving current permanent address (such as utility bills, bank statements, etc.) containing the client’s full name and place of residence. These documents should not be older than 3 months from the date of filing.
</li>
                                </ul>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    Corporate Client
                            </h4>
                                <p className="w-100 mb-0">In case the applicant company is listed on a recognised or approved stock exchange or when there is independent evidence to show that the applicant is a wholly owned subsidiary or a subsidiary under the control of such a company, no further steps to verify identity will normally be required. In case the company is unquoted and none of the principal directors or shareholders already has an account with LeoPrime, the following documentations must be provided:
</p>
                                <ul className="liststyle">
                                    <li>Certificate of Incorporation or any national equivalent.
</li>
                                    <li>Memorandum and Articles of Association and statutory statement or any national equivalent.
</li>
                                    <li>Certificate of good standing or other proof of registered address of the company.
</li>
                                    <li>Resolution of the board of directors to open an account and confer authority on those who will operate it.
</li>
                                    <li>Copies of powers of attorney or other authorities given by the directors in relation to the company.
</li>
                                    <li>Proof of identity of directors in case he/she will deal with LeoPrime on behalf of the Customer (according to the Individual identity verification rules described above).
</li>
                                </ul>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    Tracking Client Activity
                            </h4>
                                <p className="w-100 mb-0">In addition to gathering information from the clients, LeoPrime continues to monitor the activity of every client to identify and prevent any suspicious transactions. A suspicious transaction is known as a transaction that is inconsistent with the client’s legitimate business or the usual client’s transaction history known from client activity monitoring. LeoPrime has implemented the system of monitoring the named transactions (both automatic and, if needed, manual) to prevent using the company’s services by criminals.
</p>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    Record Keeping
                            </h4>
                                <p className="w-100 mb-0">Records must be kept of all transaction data and data obtained for the purpose of identification, as well as of all documents related to money laundering topics (e.g. files on suspicious activity reports, documentation of AML account monitoring, etc.). Those records are kept for a minimum of 7 years after the account is closed.
</p>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    Deposit and withdrawal requirements
                            </h4>
                                <p className="w-100 mb-0">All deposits and withdrawals on trading accounts held with LeoPrime the following strict requirements:
</p>
                                <ul className="liststyle">
                                    <li>Due to AML / CTF laws, cannot receive or deposit funds to third parties.
</li>
                                    <li>Funds sent to LeoPrime must be from a bank account, Credit/Debit card or Alternative Payment Method  under the same name as the trading account name with LeoPrime.
</li>
                                    <li>All funds withdrawn from a trading account must go to a bank account, Credit/Debit card or Alternative Payment Method  under the same name as the trading account name with LeoPrime.
</li>
                                    <li>All withdrawal requests are processed on First come First serve basis according to the funding source of origination. For example, a deposit is made via Debit/Credit Card; then a subsequent withdrawal request is received. The amount of funds sent back to the relevant Debit/Credit Card, when a withdrawal request is received, may not exceed the original amount deposited from same. Any profits made in excess of the deposited amount will be transferred to a nominated bank account; which must be held in the same name as your trading account.
</li>
                                </ul>
                            </Row>
                        </Col>
                    </div>
                </section>
                <ReadyToTrade />
            </div>
        );
    }
}
export default AmlPolicy;