import React, { Component } from "react";
import { Row, Col, Tabs, Tab, Table } from "react-bootstrap";
import ReadyToTrade from "../components/ReadyToTrade";
import TradingInstrumentTabs from "../components/TradingInstrumentTabs";
import { Helmet } from "react-helmet";
export default class TradingInstrumentForex extends Component {
  render() {
    return (
      <div>
        <Helmet
          title="Forex Trading Instruments | Top forex broker | LeoPrime"
          meta={[
            { name: "author", content: "LeoPrime" },
            {
              name: "keywords",
              content:
                "high leverage,leading,forexbroker,retail,account,forex,broker,LeoPrime,trading, forex trading, online",
            },
            { name: "twitter:site", content: "LeoPrime" },
            { name: "twitter:creator", content: "LeoPrime" },
            {
              name: "twitter:title",
              content:
                "Forex Trading Instruments | Top forex broker | LeoPrime",
            },
            {
              name: "twitter:image",
              content:
                "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg",
            },
            {
              property: "og:title",
              content:
                "Forex Trading Instruments | Top forex broker | LeoPrime",
            },
            { property: "og:site_name", content: "LeoPrime" },
            { property: "og:type", content: "Forex Trading" },
            { property: "og:url", content: "https://leoprime.com/" },
            {
              property: "og:description",
              content:
                "Choose from the wide range of trading instruments from the Leading Forex Broker LeoPrime.",
            },
            {
              property: "og:image",
              content:
                "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg",
            },
            { property: "og:site_name", content: "LeoPrime" },
            {
              name: "viewport",
              content: "width=device-width, maximum-scale=1",
            },
          ]}
        ></Helmet>
        <div className="innerpageBanner">
          <div className="d-flex align-items-center dark-overlay trading-instrument-banner">
            <div className="col-xl-10 mx-auto text-white">
              <h1 className="display-3 text-shadow text-uppercase ">Trading</h1>
              <h1 className="display-3 text-shadow text-uppercase">
                <span>Instrument</span>
              </h1>
            </div>
          </div>
        </div>
        <TradingInstrumentTabs />
        <section className="pt-0">
          <div className="container">
            <Row className="justify-content-md-center">
              <Col
                xs={12}
                md={12}
                lg={4}
                className="mb-3 d-flex flex-column justify-content-center mt-5"
              >
                <img
                  src={require("../assets/img/account-types/account-type-left.png")}
                  alt="Account Types"
                  className="img-fluid"
                />
              </Col>
              <Col
                xs={12}
                md={12}
                lg={8}
                className="mb-3 d-flex align-items-center"
              >
                <div className="d-flex flex-column justify-content-center pl-lg-5">
                  <h2 className="mb-4">Forex Contract Specification</h2>
                  <p>
                    The Forex market being the most liquid market deals with the
                    majority of the world currencies in circulation today. The
                    key element for an online forex trader is to find the right
                    online forex broker who offers a diverse list of financial
                    products at their best available market prices. LeoPrime
                    offers you with incomparable service and the tightest Spread
                    in the market, starting from 0.1 pips. Join us and trade in
                    the sea of financial tools, where you can cherry pick your
                    own favorite financial instruments and make the maximum out
                    of it.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section className="hmeOurBenefit pt-0">
          <div className="container">
            <Col xs={12} lg className="mb-3 trading_table" id="tabs">
              <Tabs
                defaultActiveKey="forex"
                transition={false}
                className="nav-fill"
              >
                <Tab eventKey="forex" title="Classic">
                  <Table borderless responsive hover>
                    <thead>
                      <tr className="row m-0 bg-gray">
                        <th className="col-lg-1">Symbol</th>
                        <th className="col-lg-4 text-left">Description</th>
                        <th className="col-lg-1">Average Spread*</th>
                        <th className="d-inline-block col-lg-3 text-center">
                          Margin Requirements
                          <br />
                          <div className="row">
                            <div className="col">1:100</div>
                            <div className="col">1:500 </div>
                            <div className="col">1:1000 </div>
                          </div>
                        </th>
                        <th className="d-inline-block col-lg-2 text-center">
                          Swap
                          <br />
                          <div className="row">
                            <div className="col">Long</div>
                            <div className="col">Short </div>
                          </div>
                        </th>
                        <th className="col-lg-1">Stop Level ***</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          AUDUSDs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Australian Dollar vs US Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.8
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.77
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.64
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURUSDs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs US Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.6
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -1.15
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.79
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPUSDs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs US Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.7
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -1.97
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.47
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          NZDUSDs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          New Zealand Dollar vs US Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.8
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.33
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.3
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDCADs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Canadian Dollar{" "}
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.8{" "}
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.97
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -5.52
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDCHFs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.7
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.37
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -9.05
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDJPYs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.8{" "}
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.67{" "}
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -6.71
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          AUDCHFs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Australian Dollar vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.3{" "}
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.53
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -7.27
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          AUDJPYs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Australian Dollar vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.2
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.19
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -4.97
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          AUDNZDs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Australian Dollar vs New Zealand Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.4{" "}
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -1.58
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.8
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          CADCHFs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Canadian Dollar vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.1
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.98
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -5.89
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          CADJPYs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Canadian Dollar vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.1
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.65
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -4.21
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          CHFJPYs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Swiss Frank vs Japanese Yen{" "}
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.5
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -2.82
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.18
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURAUDs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Australian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.2
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -3.35
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          0.81
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURCADs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Canadian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.5
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -2.92
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.06
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURCHFs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.1
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          5%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          1.0%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.5%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.59
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -3.45
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURGBPs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Great Britain Pound{" "}
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.9
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -2.45
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.49
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURJPYs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2{" "}
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -2.29
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.35
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURNZDs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs New Zealand Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.7
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -5.4
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.01
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPAUDs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs Australian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.3
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -3.92
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.76
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPCADs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs Canadian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.2
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -4.01
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.47
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPCHFs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.6{" "}
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          1.52
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -9.8
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPJPYs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.6
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -1.5
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -5.34
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPNZDs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs New Zealand Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.6{" "}
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -3.93
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.99{" "}
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          NZDCADs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          New Zealand Dollar vs Canadian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.7
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.13
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -4.75
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          NZDCHFs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          New Zealand Dollar vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.2{" "}
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.26
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -7.68
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          NZDJPYs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          New Zealand Dollar vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.4
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -1.68
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -6.18
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURNOKs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Norwegian Krone
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          19.65
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          1%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.20%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -18.612
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          2.36
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURNOKs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Norwegian Krone
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          19.65
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          2%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.40%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -18.612
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          2.36
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      {/* <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURPLNs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Poland Zloty
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          15.67
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          2%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.40%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -14.814
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          4.63{" "}
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr> */}
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURSEKs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Swedish Krona
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          14.5{" "}
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          2%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.40%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -14.418
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          2.41
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURTRYs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Turkish Lira
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          24.05
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          2%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.40%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -50.4
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          4.12
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDHKDs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Hong Kong Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          13.2{" "}
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          2%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.40%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -4.95
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -7.33
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDHUFs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Hungarian Forint
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          20.57
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          2%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.40%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -8.32
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          2.81
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDMXNs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Mexican Peso
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          46.2
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          2%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.40%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -403.51
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          126.14
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDNOKs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Norwegian Krone
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          31.3
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          2%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.40%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          1.19
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -12.02
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      {/* <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDPLNs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Poland Zloty
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          18.5{" "}
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          2%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.40%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -6.01
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.19
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr> */}
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDSEKs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Swedish Krona
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          39.7{" "}
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          2%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.40%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          1.21
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -13.28
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDTRYs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Turkish Lira
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          38.6
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          2%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.40%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -4.4
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -99.4
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDZARs
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs South African Rand
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          35.6
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          2%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          0.40%
                        </td>
                        <td
                          className="col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -208.79
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          87.1
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      {/*                                  
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       EURDKKs
                        </td>
                                    <td className="col-lg-4" data-label="Description">Euro vs Danish Krone
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">12.1
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:100">2%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:500">0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:1000">0.20%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -3.06
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -5.68
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       EURHKDs
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Euro vs Hong Kong Dollar
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       14.9
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:100">2%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:500">0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:1000">0.20%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -19.7
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       1.63
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       EURSGDs
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Euro vs Singapore Dollar
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       12.3
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:100">2%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:500">0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:1000">0.20%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -1.71
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -1.38
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       EURZARs
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Euro vs South African Rand
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       64.3
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:100">2%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:500">0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:1000">0.20%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -251.99
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       101.02
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       GBPDKKs
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Great Britain Pound vs Danish Krone
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       28.34
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:100">2%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:500">0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:1000">0.20%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       3.69
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -18.64
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       GBPNOKs
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Great Britain Pound vs Norwegian Krone
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       46.8
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:100">2%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:500">0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:1000">0.20%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -0.19
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -13.21
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       GBPSEKs
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Great Britain Pound vs Swedish krona
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       206.5
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:100">2%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:500">0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:1000">0.20%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       0.9
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -14.68
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       GBPSGDs
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Great Britain Pound vs Singapore Dollar
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       17.95
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:100">2%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:500">0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:1000">0.20%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       0.31
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -3.85
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       GBPTRYs
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Great Britan Pound vs Turkish Lira
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       41.3
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:100">2%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:500">0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:1000">0.20%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -6.08
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -119.8
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       NOKSEKs
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Norwegian Krone vs Swedish krona
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       13.2
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:100">2%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:500">0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:1000">0.20%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       0.02
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -1.76
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       USDCNHs
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       US Dollar vs Chinese Renminbi
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       13.6
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:100">2%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:500">0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:1000">0.20%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -44.05
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       11.32
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       USDDKKs
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       US Dollar vs Danish Krone
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       15.4
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:100">2%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:500">0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:1000">0.20%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       5.21
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -16.39
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       USDSGDs
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       US Dollar vs Singapore Dollar
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       13.63
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:100">2%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:500">0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:1000">0.20%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -2.28
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -4.09
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                               */}
                    </tbody>
                  </Table>
                </Tab>
                <Tab eventKey="pro" title="Pro">
                  <Table borderless responsive hover>
                    <thead>
                      <tr className="row m-0 bg-gray">
                        <th className="col-lg-1">Symbol</th>
                        <th className="col-lg-4 text-left">Description</th>
                        <th className="col-lg-1">Average Spread*</th>
                        <th className="d-inline-block col-lg-3 text-center">
                          Margin Requirements
                          <br />
                          <div className="row">
                            <div className="col">1:100</div>
                            <div className="col">1:200 </div>
                            <div className="col">1:500 </div>
                          </div>
                        </th>
                        <th className="d-inline-block col-lg-2 text-center">
                          Swap
                          <br />
                          <div className="row">
                            <div className="col">Long</div>
                            <div className="col">Short </div>
                          </div>
                        </th>
                        <th className="col-lg-1">Stop Level ***</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          AUDUSDp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Australian Dollar vs US Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          0.8
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -1.92
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.32
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURUSDp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs US Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          0.6
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -3.67
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          0.02
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPUSDp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs US Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.1
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -3.37
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.71
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          NZDUSDp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          New Zealand Dollar vs US Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          0.9
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -1.36
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.38
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDCADp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Canadian Dollar{" "}
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -2.19
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.39
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDCHFp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.83
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -3.56
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDJPYp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          0.7{" "}
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.97{" "}
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -3.49
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          AUDCADp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Australian Dollar vs Canadian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.8
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -1.45
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.93
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          AUDCHFp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Australian Dollar vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.8
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.3
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.37
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          AUDJPYp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Australian Dollar vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.5
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.9
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.8
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          AUDNZDp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Australian Dollar vs New Zealand Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.1{" "}
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -2.45
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.8
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          CADCHFp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Canadian Dollar vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.5
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.13
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.97
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          CADJPYp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Canadian Dollar vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.5
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.63
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.68
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          CHFJPYp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Canadian Dollar vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.5
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.63
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.68
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURAUDp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Australian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.3
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -4.78
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          0.18
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURCADp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Canadian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.9
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -4.22
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.06
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURCHFp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.73
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -1.15
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.61
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURGBPp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Great Britain Pound{" "}
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.3
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -3.321
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.38
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURJPYp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.61
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -2.88
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.99
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURNZDp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs New Zealand Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.8
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -6.18
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          0.11
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPAUDp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs Australian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.8
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -3.51
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -3.71
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPCADp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs Canadian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.6{" "}
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -3.76
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -3.02
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPCHFp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.4
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.4
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -4.64
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPJPYp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.2
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -1.62
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.9
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPNZDp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs New Zealand Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.9{" "}
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -4.6
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.7
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          NZDCADp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          New Zealand Dollar vs Canadian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.8
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.99
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.1
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          NZDCHFp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          New Zealand Dollar vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.2
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.51
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.57
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          NZDJPYp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          New Zealand Dollar vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.7
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.64
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.89
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURNOKp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Norwegian Krone
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          12.15
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.40%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -18.61
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          2.36
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      {/* <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURPLNp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Poland Zloty
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          8.17
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.40%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -14.81
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          4.63
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr> */}
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURSEKp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Swedish Krona
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          7
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.40%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -14.42
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          2.41
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURTRYp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Turkish Lira
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          16.55
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.40%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -50.4
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          4.12
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDHKDp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Hong Kong Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          5.7
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.40%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -4.95
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -7.33
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDHUFp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Hungarian Forint
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          13.07
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.40%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -8.32
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          2.81
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDMXNp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Mexican Peso
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          38.7
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.40%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -403.51
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          126.14
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDNOKp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Norwegian Krone
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          23.8
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.40%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          1.19
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -12.02
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      {/* <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDPLNp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Poland Zloty
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          11
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.40%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -6.01
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.19
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr> */}
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDSEKp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Swedish Krona
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          32.2
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.40%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          1.21
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -13.28
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDTRYp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Turkish Lira
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          31.1
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.40%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -4.4
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -99.4
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDZARp
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs South African Rand
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          28.1
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.40%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -208.79
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          87.1
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      {/*                                  
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       EURDKKp
                        </td>
                                    <td className="col-lg-4" data-label="Description">Euro vs Danish Krone
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">4.6
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:500"> 0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -3.06
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -5.68
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       EURHKDp
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Euro vs Hong Kong Dollar
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       7.4
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:500"> 0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -19.7
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       1.63
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       EURSGDp
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Euro vs Singapore Dollar
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       4.8
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:500"> 0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -1.71
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -1.38
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       EURZARp
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Euro vs South African Rand
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       56.8
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:500"> 0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -251.99
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       101.02
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       GBPDKKp
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Great Britain Pound vs Danish Krone
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       20.84
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:500"> 0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       3.69
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -18.64
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       GBPSEKp
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Great Britain Pound vs Swedish krona
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       199
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:500"> 0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       0.9
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -14.68
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       GBPSGDp
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Great Britain Pound vs Singapore Dollar
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       10.45
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:500"> 0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       0.31
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -3.85
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       GBPTRYp
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Great Britan Pound vs Turkish Lira
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       33.8
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:500"> 0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -6.08
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -119.8
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       NOKSEKp
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Norwegian Krone vs Swedish krona
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       5.7
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:500"> 0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       0.02
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -1.76
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       USDCNHp
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       US Dollar vs Chinese Renminbi
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       6.1
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:100">2%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:200">0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:500">0.20%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -44.05
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       11.32
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       USDDKKp
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       US Dollar vs Danish Krone
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       7.9
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:500"> 0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       5.21
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -16.39
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       USDSGDp
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       US Dollar vs Singapore Dollar
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       6.13
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:500"> 0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -2.28
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -4.09
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                              */}
                    </tbody>
                  </Table>
                </Tab>
                <Tab eventKey="ecn" title="ECN">
                  <Table borderless responsive hover>
                    <thead>
                      <tr className="row m-0 bg-gray">
                        <th className="col-lg-1">Symbol</th>
                        <th className="col-lg-4 text-left">Description</th>
                        <th className="col-lg-1">Average Spread*</th>
                        <th className="d-inline-block col-lg-2">
                          Margin Requirements
                          <br />
                          <div className="row">
                            <div className="col">1:100</div>
                            <div className="col">1:200 </div>
                          </div>
                        </th>
                        <th className="d-inline-block col-lg-2">
                          Swap
                          <br />
                          <div className="row">
                            <div className="col">Long</div>
                            <div className="col">Short </div>
                          </div>
                        </th>
                        <th className="col-lg-1">Stop Level ***</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          AUDUSD
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Australian Dollar vs US Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          0.4
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.77
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.64
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURUSD
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs US Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          0.2
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -1.15
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.79
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPUSD
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs US Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          0.7
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -1.97
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.47
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          NZDUSD
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          New Zealand Dollar vs US Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          0.5
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.33
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.3
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDCAD
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Canadian Dollar{" "}
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          0.6
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.97
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -5.52
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDCHF
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          0.6
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.37
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -9.05
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDJPY
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          0.3{" "}
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.67{" "}
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -6.71
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          AUDCAD
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Australian Dollar vs Canadian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.2
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.02
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.67
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          AUDCHF
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Australian Dollar vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.2
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.53
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -7.27
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          AUDJPY
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Australian Dollar vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          0.9
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.19
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -4.97
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          AUDNZD
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Australian Dollar vs New Zealand Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.5{" "}
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -1.58
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.8
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          CADCHF
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Canadian Dollar vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          0.9
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.98
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -5.89
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          CADJPY
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Canadian Dollar vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          0.9
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.65
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -4.21
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          CHFJPY
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Swiss Frank vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.9
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -2.82
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.18
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURAUD
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Australian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.7
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -3.35
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          0.81
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURCAD
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Canadian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.3
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -2.92
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.06
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURCHF
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.13
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.59
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -3.45
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURGBP
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Great Britain Pound{" "}
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          0.7
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -2.45
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.49
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURJPY
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.01
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -2.29
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.35
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURNZD
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs New Zealand Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.2
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -5.4
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.01
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPAUD
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs Australian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.2
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -3.92
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.76
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPCAD
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs Canadian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2{" "}
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -4.01
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.47
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPCHF
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.8
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          1.52
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -9.8
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPJPY
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.6
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -1.5
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -5.34
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPNZD
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs New Zealand Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.3{" "}
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -3.93
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.99
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          NZDCAD
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          New Zealand Dollar vs Canadian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.2
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.13
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -4.75
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          NZDCHF
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          New Zealand Dollar vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          0.6
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.26
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -7.68
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          NZDJPY
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          New Zealand Dollar vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.1
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -1.68
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -6.18
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURNOK
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Norwegian Krone
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          9.7
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -18.61
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          2.36
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      {/* <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURPLN
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Poland Zloty
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          5.67
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -14.814
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          4.63
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr> */}
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURSEK
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Swedish Krona
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          4.5
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -14.42
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          2.41
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURTRY
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Turkish Lira
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          14.05
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -50.4
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          4.12
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDHKD
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Hong Kong Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          3.2
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -4.95
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -7.33
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDHUF
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Hungarian Forint
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          10.57
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -8.32
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          2.81
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDMXN
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Mexican Peso
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          36.2
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -403.51
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          126.14
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDNOK
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Norwegian Krone
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          21.3
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          1.19
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -12.02
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      {/* <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDPLN
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Poland Zloty
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          8.5
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          1%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -6.01
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.19
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr> */}
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDSEK
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Swedish Krona
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          29.7
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          1.21
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -13.28
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDTRY
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Turkish Lira
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          28.6
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -4.4
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -99.4
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDZAR
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs South African Rand
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          25.6
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -208.79
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          87.1
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          20
                        </td>
                      </tr>
                      {/* 
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       EURDKK
                        </td>
                                    <td className="col-lg-4" data-label="Description">Euro vs Danish Krone
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">2.1
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -3.06
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -5.68
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       EURHKD
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Euro vs Hong Kong Dollar
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       4.9
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -19.7
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       1.63
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       EURSGD
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Euro vs Singapore Dollar
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       2.3
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -1.71
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -1.38
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       EURZAR
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Euro vs South African Rand
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       54.3
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -251.99
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       101.02
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       GBPDKK
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Great Britain Pound vs Danish Krone
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       18.34
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       3.69
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -18.64
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       GBPNOK
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Great Britain Pound vs Norwegian Krone
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       36.8
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -0.19
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -13.21
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       GBPSEK
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Great Britain Pound vs Swedish krona
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       196.5
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       0.9
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -14.68
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       GBPSGD
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Great Britain Pound vs Singapore Dollar
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       7.95
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       0.31
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -3.85
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       GBPTRY
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Great Britan Pound vs Turkish Lira
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       31.3
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -6.08
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -119.8
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       NOKSEK
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Norwegian Krone vs Swedish krona
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       3.2
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       0.02
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -1.76
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       USDCNH
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       US Dollar vs Chinese Renminbi
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       3.6
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:100">2%
                        </td>
                                    <td className="col-lg-1" data-label="Margin Requirements 1:200">0.40%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -44.05
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       11.32
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       USDDKK
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       US Dollar vs Danish Krone
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       5.4
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       5.21
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -16.39
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       USDSGD
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       US Dollar vs Singapore Dollar
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       3.63
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 1%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -2.28
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -4.09
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       20
                        </td>
                                 </tr>
                               */}
                    </tbody>
                  </Table>
                </Tab>
                <Tab eventKey="cent" title="Cent">
                  <Table borderless responsive hover>
                    <thead>
                      <tr className="row m-0 bg-gray">
                        <th className="col-lg-1">Symbol</th>
                        <th className="col-lg-4 text-left">Description</th>
                        <th className="col-lg-1">Average Spread*</th>
                        <th className="d-inline-block col-lg-3 text-center">
                          Margin Requirements
                          <br />
                          <div className="row">
                            <div className="col">1:100</div>
                            <div className="col">1:500 </div>
                            <div className="col">1:1000 </div>
                          </div>
                        </th>
                        <th className="d-inline-block col-lg-2 text-center">
                          Swap
                          <br />
                          <div className="row">
                            <div className="col">Long</div>
                            <div className="col">Short </div>
                          </div>
                        </th>
                        <th className="col-lg-1">Stop Level ***</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          AUDUSDc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Australian Dollar vs US Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.8
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -1.92
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.32
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURUSDc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs US Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.6
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -3.67
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          0.02
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPUSDc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs US Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.7
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -3.37
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.71
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          NZDUSDc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          New Zealand Dollar vs US Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.8
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -1.36
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.38
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDCADc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Canadian Dollar{" "}
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.8
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -2.19
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.39
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDCHFc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.7
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.83
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -3.56
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDJPYc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.8{" "}
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.97{" "}
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -3.49
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          AUDCADc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Australian Dollar vs Canadian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.5
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -1.45
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.93
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          AUDCHFc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Australian Dollar vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.3
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.3
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.37
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          AUDJPYc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Australian Dollar vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.2
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.9
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.8
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          AUDNZDc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Australian Dollar vs New Zealand Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.4
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -2.45
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.8
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          CADCHFc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Canadian Dollar vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.1
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.13
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.97
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          CADJPYc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Canadian Dollar vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.1
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.63
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.68
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          CHFJPYc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Swiss Frank vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.5
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -3.42
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.18
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURAUDc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Australian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.2
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -4.78
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          0.18
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURCADc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Canadian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.5
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -4.22
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.06
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURCHFc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.1
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -1.15
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.61
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURGBPc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Great Britain Pound{" "}
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.9
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -3.32
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.38
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURJPYc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.61
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -2.88
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -0.99
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURNZDc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs New Zealand Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.7
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -6.18
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          0.11
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPAUDc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs Australian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.3
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -3.51
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -3.71
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPCADc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs Canadian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.2
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -3.76
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -3.02
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPCHFc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.6
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.4
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -4.64
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPJPYc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.6
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -1.62
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.9
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          GBPNZDc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Great Britain Pound vs New Zealand Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.6{" "}
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -4.6
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.7
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          NZDCADc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          New Zealand Dollar vs Canadian Dollar
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.7
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.99
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.10
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          NZDCHFc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          New Zealand Dollar vs Swiss Franc
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          1.2
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.50%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          0.51
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -2.57
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          10
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          NZDJPYc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          New Zealand Dollar vs Japanese Yen
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          2.4
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          1%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.10%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -0.64
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -1.89
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURNOKc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Norwegian Krone
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          19.65
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.40%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -18.61
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          2.36
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURSEKc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Swedish Krona
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          14.5
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.40%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -14.42
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          2.41
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      {/* <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">EURSGDc</td>
                                    <td className="col-lg-4" data-label="Description">Euro vs Swedish Krona
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">12.3</td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:500"> 0.40%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:1000"> 0.20%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">-1.71</td>
                                    <td className="col-lg-1" data-label="Swap Short">-1.38
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">12
                        </td>
                                 </tr> */}
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          EURTRYc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          Euro vs Turkish Lira
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          24.05
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.40%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -50.4
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          4.12
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      {/* <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       GBPSGDc
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Great Britain Pound vs Singapore Dollar
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       17.95
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:500"> 0.40%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:1000"> 0.20%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       0.31
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -3.85
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       12
                        </td>
                                 </tr>
                                 <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       GBPTRYc
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       Great Britan Pound vs Turkish Lira
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       41.3
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:500"> 0.40%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:1000"> 0.20%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -6.08
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -119.8
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       12
                        </td>
                                 </tr>
                                  */}
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDMXNc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Mexican Peso
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          46.2
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.40%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -403.51
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          126.14
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDNOKc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Norwegian Krone
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          31.3
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.40%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          1.19
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -12.02
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDSEKc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs Swedish Krona
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          39.7
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.40%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          1.21
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          -13.28
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                      {/* <tr className="row m-0">
                                    <td className="col-lg-1" data-label="Symbol">
                                       USDSGDc
                        </td>
                                    <td className="col-lg-4" data-label="Description">
                                       US Dollar vs Singapore Dollar
                        </td>
                                    <td className="col-lg-1" data-label="Average Spread*">
                                       13.63
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 2%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:500"> 0.40%
                        </td>
                                    <td className="d-inline-block col-lg-1" data-label="Margin Requirements 1:1000"> 0.20%
                        </td>
                                    <td className="col-lg-1" data-label="Swap Long">
                                       -2.28
                        </td>
                                    <td className="col-lg-1" data-label="Swap Short">
                                       -4.09
                        </td>
                                    <td className="col-lg-1" data-label="Stop Level ***">
                                       12
                        </td>
                                 </tr> */}
                      <tr className="row m-0">
                        <td className="col-lg-1" data-label="Symbol">
                          USDZARc
                        </td>
                        <td className="col-lg-4" data-label="Description">
                          US Dollar vs South African Rand
                        </td>
                        <td className="col-lg-1" data-label="Average Spread*">
                          35.6
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          2%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.40%
                        </td>
                        <td
                          className="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.20%
                        </td>
                        <td className="col-lg-1" data-label="Swap Long">
                          -208.79
                        </td>
                        <td className="col-lg-1" data-label="Swap Short">
                          87.1
                        </td>
                        <td className="col-lg-1" data-label="Stop Level ***">
                          12
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Tab>
              </Tabs>
              <p className="text-lg-right text-center">
                <small>*Values are subjected to change</small>
              </p>
            </Col>
          </div>
        </section>
        <ReadyToTrade />
      </div>
    );
  }
}
