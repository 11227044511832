import { actionType } from "../actionType";
import apiConfig from '../../config/apiCCConfig';

export const getSocialTradingViews = (param, cb) => async (dispatch) => {
    let url = 'api/v3/public/strategy/view';

    await apiConfig.post(url, param)
        .then((response) => {
            dispatch({ type: actionType.FETCH_SOCIAL_TRADING_VIEW, payload: response.data });
            cb(response.data);
        })
};
export const getSocialTradingStatistics = (param, cb) => async (dispatch) => {
    let url = 'api/v3/public/strategy/statistics';

    await apiConfig.post(url, param)
        .then((response) => {
            dispatch({ type: actionType.FETCH_SOCIAL_TRADING_STAT, payload: response.data });
            cb(response.data);
        })
};
export const getSocialTradingTrade = (param, cb) => async (dispatch) => {
    let url = 'api/v3/public/strategy/activity';

    await apiConfig.post(url, param)
        .then((response) => {
            dispatch({ type: actionType.FETCH_SOCIAL_TRADING_HIST, payload: response.data });
            cb(response.data);
        })
};
export const getSocialTradingTradeClose = (param, cb) => async (dispatch) => {
    let url = 'api/v3/public/strategy/activity';

    await apiConfig.post(url, param)
        .then((response) => {
            dispatch({ type: actionType.FETCH_SOCIAL_TRADING_HIST_CLS, payload: response.data });
            cb(response.data);
        })
};
export const getSocialTradingStatisticsGraph = (param, cb) => async (dispatch) => {
    let url = 'api/v3/public/strategy/statistics/growth';

    await apiConfig.post(url, param)
        .then((response) => {
            dispatch({ type: actionType.FETCH_SOCIAL_TRADING_STAT_GRAP, payload: response.data });
            cb(response.data);
        })
};
export const getSocialTradingProfitGraph = (param, cb) => async (dispatch) => {
    let url = 'api/v3/public/strategy/statistics/profit';

    await apiConfig.post(url, param)
        .then((response) => {
            dispatch({ type: actionType.FETCH_SOCIAL_TRADING_PROFIT_GRAP, payload: response.data });
            cb(response.data);
        })
};
export const getSocialTradingBalanceGraph = (param, cb) => async (dispatch) => {
    let url = 'api/v3/public/strategy/statistics/balance';

    await apiConfig.post(url, param)
        .then((response) => {
            dispatch({ type: actionType.FETCH_SOCIAL_TRADING_BALANCE_GRAP, payload: response.data });
            cb(response.data);
        })
};