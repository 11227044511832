import React, { Component } from 'react'
// import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Helmet } from "react-helmet";

import ReadyToTrade from '../components/ReadyToTrade'
export default class ToolEconomicCalendar extends Component {

    componentDidMount() {
        this.loadScript('https://c.mql5.com/js/widgets/calendar/widget.js?6')
            .then(() => {

                // eslint-disable-next-line no-undef
                new economicCalendar({ width: "100%", height: "500px", mode: 2 });
            });
    }

    // load a script and listen a event
    loadScript(scriptUrl) {
        const script = document.createElement('script');
        script.src = scriptUrl;
        document.body.appendChild(script);

        return new Promise((res, rej) => {
            script.onload = function () {
                res();
            };
            script.onerror = function () {
                rej();
            }
        });
    }

    render() {
        return (
            <div>
                <Helmet
                    title="Economic Calendar|Forex Trading|LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "economic,calendar,forextrading,mt4,MT4,MetaTrader, retail,forex,broker,LeoPrime,trader, forex trader, online" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Economic Calendar|Forex Trading|LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Economic Calendar|Forex Trading|LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "Use our economic calendar to keep track of market-moving events and announcements. LeoPrimes’ economic calendar covers all the important events and releases that affect the forex market." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay economic-calendar-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase ">Economic</h1>
                            <h1 className="display-3 text-shadow text-uppercase"><span>Calendar</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <Col lg={10} className="mx-auto">
                        <Row className="justify-content-md-center">
                            <Col xs={12} md={12} lg={6} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center">

                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={12} className="mb-3 project-tab" id="tabs">
                                <div id="economicCalendarWidget" />
                            </Col>
                        </Row>
                    </Col>
                </section>
                <ReadyToTrade />
            </div>
        );
    }
}
