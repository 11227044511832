import React, { Component } from 'react'
import { Row, Col, Media } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import { Sparklines, SparklinesLine } from 'react-sparklines';
import GooglePlayImg from '../assets/img/google-play-icon.png';
import AppStoreImg from '../assets/img/app-store-icon.png';
import { Helmet } from "react-helmet";
export default class SocialTradingInvestor extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="Copy Trading Investor | Copy Trading Investment Platform"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "Copy Trading Investor, Copy Trading Investment, Copy Trade Minimum Investment, Copy Trading Investment Platform" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Copy Trading Investor | Copy Trading Investment Platform" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Copy Trading Investor | Copy Trading Investment Platform" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "LeoPrime Copy Trading Platform allows Investors to Copy Trades performed by top strategists in the financial markets." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay social-trading-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase text-dark">Become an Investor
                            </h1>
                            <h1 className="display-3 text-shadow text-uppercase text-dark"><span>Choose.Copy.Earn </span></h1>
                            <Row>
                                <Col lg={6}>
                                    <p>LeoPrime offers transparent, secured, flexible and powerful Copy Trading. With our Copy trading you are given the access to invest in leading traders and copy their strategies to make profits, rather than taking long hours to build up your own strategy. Thus, you are given the opportunity to diversify your portfolio with minimum exposure to risk.</p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <Row className="justify-content-md-center">
                            <Col lg={{ span: 4, offset: 1 }} className="mb-3 d-flex flex-column justify-content-center mt-5">
                                <img src={require('../assets/img/social-trading/social-trading-rank-img-1.png')} alt="Copy Trading" className="img-fluid" />
                            </Col>
                            <Col lg={7} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center pl-lg-5">
                                    <h2 className="mb-4 w-100 text-lg-left text-center">
                                        Want to turn a strategy into a profit?
                                        Join Copy Trading. Copy Pro Traders.

                                    </h2>
                                    <div className=" text-lg-left text-center">
                                        <p>LeoPrime gives you the freedom to monitor trades at any time & take complete control of your account. You can create a unique & balanced trading portfolio by automatically copying trades, withdrawing funds instantly, choosing your Master based on the risk-factors (Risk-factors will be mentioned) and most importantly saving a lot of time by simply copying the trading styles of the Master you choose.</p>
                                    </div>
                                    <ul>
                                        <li>•	You don't have to be an experienced trader</li>
                                        <li>•	Choose the best Master trader from a pool of experts</li>
                                        <li>•	Benefit from the experience of traders you choose.</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        {/* <Row className="mt-5 text-center">
                            <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                <div className="w-100">
                                    <img src={require('../assets/img/icons/social-trading-icon-1.svg')} alt="Copy Trading" />
                                    <p>Choose from the list of most profitable traders by viewing in-depth stats of each available trader to copy.</p>
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                <div className="w-100">
                                    <img src={require('../assets/img/icons/social-trading-icon-2.svg')} alt="Copy Trading" />
                                    <p>Option to invest in multiple strategies from several accounts, you don't have to be a financial market expert.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                <div className="w-100">
                                    <img src={require('../assets/img/icons/social-trading-icon-3.svg')} alt="Copy Trading" />
                                    <p>Benefit from a diversified portfolio, less working hours and profit from professional auto copy trades.
                                    </p>
                                </div>
                            </Col>
                        </Row> */}
                    </div>
                </section>
                <section className="bg-gray hmeOurBenefit">
                    <Col lg={10} className="mx-auto">
                        <h2 className="mb-5 text-lg-left text-center">
                            Exclusive Platform for Investors
                        </h2>
                        <Row>
                            <Col lg="12" className="text-lg-left text-center">
                                <Row>
                                    <Col lg={4} md={6} className="mb-3">
                                        <h6>Auto copy trades</h6>
                                        <p>You need not be a market expert.</p>
                                    </Col>
                                    <Col lg={4} md={6} className="mb-3">
                                        <h6>Diversify your portfolio
                                        </h6>
                                        <p>Choose your Traders from a pool of professionals.
                                        </p>
                                    </Col>
                                    <Col lg={4} md={6} className="mb-3">
                                        <h6>Uninterrupted order execution
                                        </h6>
                                        <p>Copied trades are executed immediately in your trading accounts</p>
                                    </Col>
                                    <Col lg={4} md={6} className="mb-3">
                                        <h6>Control over your trades
                                        </h6>
                                        <p>You can stop copying service or reduce your investment anytime.</p>
                                    </Col>
                                    <Col lg={4} md={6} className="mb-3">
                                        <h6>Instant set up in a few quick steps
                                        </h6>
                                        <p>No additional verification required.
                                        </p>
                                    </Col>
                                    <Col lg={4} md={6} className="mb-3">
                                        <h6>Automatic and secured
                                        </h6>
                                        <p>Secure payment methods to choose from.</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </section>
                <section>
                    <Col lg={10} className="mx-auto">
                        <h2 className="mb-5 w-100 text-center">
                            How Copy Trading Works
                        </h2>
                        <Row className="my-3">

                            <Col lg={4} className="pr-4 mb-3">
                                <Media>
                                    <h1 className="mr-3">1</h1>
                                    <Media.Body>
                                        <h6 className="text-muted">Sign up and load your wallet</h6>
                                        <p>Investing in the financial market made easier with LeoPrime's social trading. When you sign up you are allocated with a wallet to keep your uninvested funds. Deposit to your wallet with your convenient payment method. You can also internally transfer funds to the wallet from your existing trading account.</p>
                                    </Media.Body>
                                </Media>
                            </Col>
                            <Col lg={4} className="pr-4 mb-3">
                                <Media>
                                    <h1 className="mr-3">2</h1>
                                    <Media.Body>
                                        <h6 className="text-muted">Copy the best traders</h6>
                                        <p>Browse from our list of top pro traders ranked according to their performances. Choose the trader of your choice and click 'Copy' to duplicate their trades as per your equity to gain from the profits. You can even try copying multiple strategies and efficiently manage your invested funds.</p>
                                    </Media.Body>
                                </Media>
                            </Col>
                            <Col lg={4} className="pr-4 mb-3">
                                <Media>
                                    <h1 className="mr-3">3</h1>
                                    <Media.Body>
                                        <h6 className="text-muted">Keep a watch and enjoy the profits</h6>
                                        <p>You can choose from a pool of traders to copy the strategies that work for you. By doing this, you not only diversify your portfolio and but also create a channel for a stable passive income. You have full control over the process and can modify/stop copying trades whenever you want. You can view detailed trading statistics of your copied trades from your Personal Area-'Portfolio value'.</p>
                                    </Media.Body>
                                </Media>
                            </Col>
                        </Row>


                    </Col>
                </section>
                <section className="bg-gray">
                    <div className="container">
                        <Row className="justify-content-md-center">
                            <h2 className="mb-4 w-100 text-center">
                                Copy Trading from
                                <span className="d-block">The Mobile App!</span>
                            </h2>
                            <Col lg={6} className="mb-3 d-flex flex-column justify-content-center mt-5">
                                <img src={require('../assets/img/social-trading/social-trading-img-3.png')} alt="Copy Trading" className="img-fluid" />
                            </Col>
                            <Col lg={6} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center pl-lg-5">
                                    <ul className="liststyle">
                                        <li>Investing made simple with LeoPrime's Copy Trading app</li>
                                        <li>Diversify your portfolio and investments on the go</li>
                                        <li>Make an informed investing decision after analyzing the performance of our Traders</li>
                                        <li>Keep a track of your invested funds and manage risks in real-time</li>
                                    </ul>
                                    <Row className="mt-4">
                                        <a href="https://play.google.com/store/apps/details?id=com.leoprime.copytrade" target="blank" rel="noreferrer" >
                                            <img src={GooglePlayImg} alt="google play" className="img-fluid mr-3" width="120" />
                                        </a>
                                        <a href="https://apps.apple.com/in/app/leoprime-copytrade/id1574483334" target='_blank' rel="noreferrer" >
                                            <img src={AppStoreImg} alt="app store" className="img-fluid" width="120" />
                                        </a>

                                    </Row>
                                </div>
                            </Col>

                        </Row>


                    </div>
                </section>
            </div>
        );
    }
}
