import { actionType } from "../actionType";
import apiConfig from '../../config/apiAnalysisConfig';

export const getBlog = () => async (dispatch) => {
    try {
        const response = await apiConfig.get('wp-json/wp/v2/analysis');
        dispatch({ type: actionType.FETCH_BLOG, payload: response.data });
    } catch (error) {
        console.log(error);
    }
};
