import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
import { Helmet } from "react-helmet";
class RefundPolicy extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="Refund Policy | Forex with LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "Refund policy,leoprime" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Refund Policy | Forex with LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Refund Policy | Forex with LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "This Refund Policy is developed to minimize the legal and financial risk for LeoPrime customers" },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay privacy-policy-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase">Refund</h1>
                            <h1 className="display-3 text-shadow text-uppercase"><span>Policy</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <Col lg={12}>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                    Leo Prime Refund policy
                            </h4>
                                <p className="w-100">At LeoPrime we value our customer’s and are committed for providing world class services. In event of order cancellation due to non-compliance or client’s request, the amount is subjected to be refunded only after the convenience fees deduction. Once the deal is confirmed LeoPrime is not responsible for any loss/ profit arise due to fluctuation of the currency prices. In case the Company classifies activity of the Client as inappropriate, or contradicting to the usual purpose of use of company services where there is direct, or indirect, illegal, or unfair intention, the Company has the right to work within this document, without informing the Client beforehand.
</p>
                                <p>The policy of return of money was developed for the purpose of reduction of financial and legal risks of the Company, observance of the principles of the Policy of counteraction of money-laundering and counteraction of financing of terrorist activity. The company has the right unilaterally block an entrance to the private profile, to suspend the trade activity on accounts, to cancel the demand for input-output, or to make return of money if the source of money or activity of the Client contradicts to the Policy of money laundering and financing of terrorism. The company has the right at certain objective reasons and in case of need to make return of money, received through any payment system, including credit/debit cards. Thus, return of money will be executed on wallet, and bank details which the Client used at input of money. The company will take all necessary measures to prevent and block both input, and withdrawal by third parties of money from the customer account. Input and output of money from the account can be carried out only by the owner of this account.
</p>
                                <ul className="liststyle">
                                    <li>Company refund your money within a week and it’s depends on a bank procedure.
</li>
                                    <li>Company refund your money with same format that you choose in a creating account.
</li>
                                    <li>You provide your order detail and justify your reason then company decides and gives you money back.
 </li>
                                    <li>Refund transaction fee deduct from client’s money if transaction fee required otherwise client get back complete amount that he/she paid.
</li>
                                    <li>The client has the rights to close his/ her account at any time he/she wishes to.
</li>
                                    <li>If the account of the client has been suspended due to the violation of the current Terms of services or due to any other abuse detected by the client, the refund is not provided under any circumstances.
</li>
                                    <li>A refund request can be made in case in which the account had been deposited into, but no orders were executed by the client.
</li>
                                    <li>Withdrawals from trading accounts which have never had any active trades on them, will be charged minimum amount as fees to cover the processing and administrative costs.
</li>
                                </ul>
                            </Row>
                            <Row className="mb-3">
                                <p className="w-100 mb-0">In order to start the return, process the Client shall submit a cancellation request by emailing to support@leoprime.com. A cancellation request shall contain the following information at least but not limited to:
</p>
                                <ul className="liststyle">
                                    <li>Full name of the Client.
</li>
                                    <li>Residential address.
</li>
                                    <li>Contact e-mail and phone number(-s).
</li>
                                    <li>Trading account number(-s).
</li>
                                    <li>Initial payment amount, date of payment, used payment method (i.e. credit/debit cards/Bank wire, E-Payments etc.,).
</li>
                                    <li>Payment identification number (if any).
</li>
                                    <li>The reasons of the cancellation subject to the conditions stated below.
</li>
                                </ul>
                            </Row>
                            <Row className="mb-3">
                                <p className="w-100 mb-0">All the information in a cancellation request submitted to the Company shall be identical to that originally submitted in the initial payment.


</p>
<p>All received cancellation requests to be dealt with by the Company on the following terms and conditions:

</p>
                                <ul className="liststyle">
                                    <li>All cancellation requests shall be for genuine and acceptable reasons, and those reasons shall be described in detail by the Client in the cancellation request.

</li>
                                    <li>Residential address.
</li>
                                    <li>All cancellation requests shall be submitted within 5 to 7 business days from the moment of the initial payment; the Company shall notify the Client in cases where it is not possible to process the cancellation request due to card scheme or payment institution rules.

</li>
                                    <li>All cancellation requests shall be processed within 3 business days as per term set by the Client Agreement for claims related to non-trading operations or exceptionally more; the Company shall notify the Client in cases when additional time is required.

</li>
                                    <li>Once the request has been approved, please allow an additional 5 to 7 business days for the funds to show in your account.

</li>
                                    <li>All cancellation requests shall be reviewed only if the requested amount does not exceed initial payment amount and as well as free margin of trading account.

</li>
                                    <li>Any charges deducted by the payment institution and also any loss or expense, if suffered or incurred by the Company due to adverse exchange rate fluctuation to be covered from the return amount.

</li>
<li>There is no additional cost if you would like to cancel your account, just place a withdrawal request, and inform us about your desire to close your account. Withdrawals will be processed as per the withdrawal procedure.
</li>
                                </ul>
                            </Row>
                        </Col>
                    </div>
                </section>
                <ReadyToTrade />
            </div>
        );
    }
}
export default RefundPolicy;