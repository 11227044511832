import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Row, Col, Media } from 'react-bootstrap';
// import thirtybonuspdf from '../documents/bonus/30bonus.pdf';
import Countdown from '../components/Countdown';
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { getPastDemoContest } from "../store/actions/demoContest";
import Progress from "../components/Progress";
// import Pagination from '@material-ui/lab/Pagination';
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { NavLink } from 'react-router-dom';

class Contest extends Component {
    state = {
        isLoading: true, // used for loader
    };

    constructor(props) {
        super(props);
        this.fetchDemoContest({});
    }


    fetchDemoContest = (param) => {
        this.props.getPastDemoContest(param, (rs) => {
            this.setState({ isLoading: false });
        });
    };

    renderPastContestRow = (row) => {
        return (
            <Col lg={4} className="my-4">
                <Media>
                    <img src={row.image}
                        alt="leoprime contest"
                        className="d-none d-lg-block mr-3" />
                    <Media.Body className="d-flex align-items-start flex-column">
                        <h6>{row.contestName}</h6>
                        <p className="m-0">{row.accountType}</p>
                        <div className="mt-auto">
                            <h5 className="mt-3 m-0">USD {row.prize}*</h5>
                            <p className="m-0">Available Prize</p>
                        </div>
                        <NavLink className='animated-arrow' exact to={"/contest-details?id=" + row.contestId}>
                        <span className='the-arrow -left'>
                            <span className='shaft'></span>
                        </span>
                        <span className='main'>
                            <span className='text'>
                            More Details
                                {/* <NavLink exact to={"/contest-details?id=" + row.contestId}>More Details</NavLink> */}
                            </span>
                            <span className='the-arrow -right'>
                                <span className='shaft'></span>
                            </span>
                        </span>
                    </NavLink>
                    </Media.Body>
                </Media>
            </Col>
        );
    };

    renderUpcomingContestRow = (row) => {
        return (
            <div className="row mt-5">
                <div className="col-lg-6">
                    <img src={row.image} alt={row.contestId} className="mb-3 img-fluid" />
                    <a href={('https://www.leoprime.com/demo-contest-registration/')} className="primary-button-lg mx-auto" >Register</a>
                    <div className="clearfix"></div>
                    <NavLink className='animated-arrow' exact to={"/contest-details?id=" + row.contestId}>
                        <span className='the-arrow -left'>
                            <span className='shaft'></span>
                        </span>
                        <span className='main'>
                            <span className='text'>
                            More Details
                                {/* <NavLink exact to={"/contest-details?id=" + row.contestId}>More Details</NavLink> */}
                            </span>
                            <span className='the-arrow -right'>
                                <span className='shaft'></span>
                            </span>
                        </span>
                    </NavLink>
                </div>
                <div className="col-lg-6">
                    <h5>{row.contestName}</h5>
                    <Countdown timeTillDate={row.registrationDetails.timestop} timeFormat="YYYY-MM-DD, h:mm" />
                    <h6 className="my-3"><b>Registration Ongoing</b></h6>
                </div>
            </div>
        );
    };
    renderongoingContestRow = (row) => {
        return (
            <div className="row mt-5">
                <div className="col-lg-6">
                    <img src={row.image} alt={row.contestId} className="mb-3 img-fluid" />
                    <a href={('https://www.leoprime.com/user/')} className="primary-button-lg mx-auto" >Login</a>
                    <div className="clearfix"></div>
                    <NavLink className='animated-arrow' exact to={"/contest-details?id=" + row.contestId}>
                        <span className='the-arrow -left'>
                            <span className='shaft'></span>
                        </span>
                        <span className='main'>
                            <span className='text'>
                            More Details
                                {/* <NavLink exact to={"/contest-details?id=" + row.contestId}>More Details</NavLink> */}
                            </span>
                            <span className='the-arrow -right'>
                                <span className='shaft'></span>
                            </span>
                        </span>
                    </NavLink>
                </div>
                <div className="col-lg-6">
                    <h5>{row.contestName}</h5>
                    <Countdown timeTillDate={row.registrationDetails.timestop} timeFormat="YYYY-MM-DD, h:mm a" />

                    <h6 className="my-3"><b>Contest Ongoing</b></h6>

                </div>
            </div>
        );
    };
    render() {
        return (
            <div>
                <Helmet
                    title="Contest | Leoprime’s Contest"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "Getting a hold of Forex Trading? Compete in a Contest and Win for your skill!" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Contest | Leoprime’s Contest" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Contest | Leoprime’s Contest" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "Getting a hold of Forex Trading? Compete in a Contest and Win for your skill!" },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay contest-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase">Trading
                            </h1>
                            <h1 className="display-3 text-shadow text-uppercase"><span>Contest</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container text-center">
                        <Col lg={10} className="mx-auto">
                            <Row>
                                <h2 className="mb-3 w-100">
                                    Become a champion with LeoPrime’s
                                    <span className="d-block"> exclusive Demo contest</span>
                                </h2>
                                <p className="w-100 mb-4">Forex demo contests are one of the most exciting ways to test your strategies, sharpen your skills, and grow your confidence as a trader. Unleash your trading skills to win & unlock real-money prizes.
                                </p>
                                {/* <a href={('https://www.leoprime.com/demo-contest-registration/')} className="primary-button-lg mx-auto" >Join The Contest</a> */}
                            </Row>
                        </Col>
                    </div>
                </section>
                {
                    !this.state.isLoading ?
                        this.props.PastContest.UpcomingContest ?
                            <section className="bg-gray">
                                <div className="container text-center">
                                    <h2>
                                        Upcoming Demo Contest
                                    </h2>
                                    <p>Get ready to demonstrate your trading skills & seize the exclusive chance of winning real-money prizes. LeoPrime’s yet another super exciting demo contest is on its way to choose & reward the best trading champion.
                                    </p>
                                    <div className="row">
                                        {
                                            this.props.PastContest.UpcomingContest.accounts.map((row) => {
                                                return this.renderUpcomingContestRow(row)
                                            })
                                        }
                                    </div>
                                </div>
                            </section>
                            : ''
                        : <Progress />
                }
                {
                    !this.state.isLoading ?
                        this.props.PastContest.ongoingContest ?
                            <section className="bg-gray">
                                <div className="container text-center">
                                    <h2>
                                        OnGoing Demo Contest
                                    </h2>
                                    <p>Register for the contest, get a demo account with a balance of USD 5000 & leverage of 1:200. Compete, show your skills & embrace your win with the biggest equity on your trading account, within a trading week.
                                    </p>
                                    <div className="row">
                                        {
                                            this.props.PastContest.ongoingContest.accounts.map((row) => {
                                                return this.renderongoingContestRow(row)
                                            })
                                        }
                                    </div>
                                </div>
                            </section>
                            : ''
                        : <Progress />
                }
                <section className="hmeOurBenefit">
                    <Col lg={8} className="mx-auto">
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <h2 className="text-center mb-0">
                                The exclusive benefits of LeoPrime’s Demo Contest 
                                </h2>
                                {/* <Row>
                                    <Col className="text-center">
                                        <p>Become our prestigious IB and earn, under our inviting rebate structure, for each client you refer us.</p>
                                    </Col>
                                </Row> */}
                                <Row className="mt-5">
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                        <h5>Easily withdrawable cash prizes </h5>
                                        <p>Prove your trading superiority amongst a pool of traders. Become the winner & receive impressive real-money prizes.</p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                        <h5>Zero deposit </h5>
                                        <p>Get a demo account with a balance of USD 5000. Simply start trading using the virtual money, and get your hands-on real cash, if you win.</p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                        <h5>Participate every month</h5>
                                        <p>LeoPrime comes up with a Demo Contest every month to keep up the competitive spirit & to motivate all kinds of traders to discover their own abilities, participate more, and learn more.</p>
                                    </Col>
                                   
                                   
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </section>

                <section className="hmeOurBenefit">
                    <Col lg={8} className="mx-auto">
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <h2 className="text-center mb-0">
                                How the contest works? 
                                </h2>
                               
                                <Row>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                    <img src={require('../assets/img/icons/signup-icon.png')} alt="lp bonus" className="img-fluid mt-5" />
                                        <h5>Register  </h5>
                                        <p>Open a new demo contest account by clicking the register button found on the demo contest page.</p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                    <img src={require('../assets/img/icons/payment-icon.png')} alt="lp bonus" className="img-fluid mt-5" />
                                        <h5>Receive the deposit amount</h5>
                                        <p>Get an amount of USD 5000 deposited in your demo contest account.</p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                    <img src={require('../assets/img/icons/arrow-earning-icon.png')} alt="lp bonus" className="img-fluid mt-5" />
                                        <h5>Trade & win</h5>
                                        <p>Trade like a Pro. Win attractive & easily withdrawable real-money prizes.</p>
                                    </Col>
                                   
                                    <Col lg={12} className="my-5 text-center">
                                        {/* <a href={('https://www.leoprime.com/demo-contest-registration/')} className="primary-button-lg mx-auto" >Join The Contest</a> */}
                                        {/* <p>
                                            <a href="/" target="blank" className="text-dark">  <u>*Terms &amp; Conditions apply</u></a>
                                        </p> */}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </section>

                <section className="hmeOurBenefit">
                    <Col lg={10} className="mx-auto">
                        <Row>
                            <Col lg={12}>
                                <p>Showing list of Contest</p>
                                <hr />
                            </Col>
                            {
                                !this.state.isLoading ?
                                    this.props.PastContest.PastContest.accounts ?
                                        this.props.PastContest.PastContest.accounts.map((row) => {
                                            return this.renderPastContestRow(row)
                                        }) : <Alert severity="info">
                                            <AlertTitle>Info</AlertTitle>
                                            No Past Contest Available!
                                        </Alert>
                                    : <Progress />
                            }
                        </Row>
                    </Col>
                </section>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        PastContest: state.pastContestList
    }
};
export default connect(
    mapStateToProps,
    {
        getPastDemoContest
    })(Contest);
