import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
// import ReadyToTrade from '../components/ReadyToTrade'
// import BannerSlider from '../components/BannerSlider'
import 'react-phone-input-2/lib/style.css'
// import ReactPhoneInput from "react-phone-input-2";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { fetchGradeLiquidity } from "../store/actions/gradeLiquidityAction";
import DiscoverBanner from '../assets/img/home/home-banner.png';
import InstantWithdrawalImg from '../assets/img/home/pips-Spread-img.svg';
import FSARegulatedImg from '../assets/img/home/liquidity-img.svg';
import ExtLowSpreadsImg from '../assets/img/home/Multi-Asset-Img.svg';
import LeverageImg from '../assets/img/home/automatic-withdrawal-img.svg';
import TradeOnImg from '../assets/img/home/trade-on-img.svg';
import TradingPlatformImg from '../assets/img/home/trading-platform-img.png';
import GooglePlayImg from '../assets/img/google-play-icon.png';
import AppStoreImg from '../assets/img/app-store-icon.png';
// import { FormattedMessage } from 'react-intl';
class Home extends Component {
    constructor(props) {
        super(props);
        // initial call
        this.state = {
            phone: "",
            timerId: null,
            isLoading: true
        };
        this.props.fetchGradeLiquidity((rs) => {
            this.setState({ isLoading: false });
        });
    }
    handleOnChange = value => {
        console.log(value);
        this.setState({ phone: value }, () => {
            console.log(this.state.phone);
        });
    };
    // refresh Grade Liquidity on every 10 seconds
    componentDidMount() {
        this.setState({
            timerId: setInterval(() => {
                this.props.fetchGradeLiquidity((rs) => {
                })
            }, 10 * 1000)
        });
    }
    componentWillUnmount() {
        if (this.timerId != null) {
            clearInterval(this.state.timerId); // clear interval when page closed
            this.setState({ timerId: null })
        }
    }
    // Return a grade if match
    renderGradeLiquidity(name) {
        if (!this.props.liquidity.hasOwnProperty(name)) {
            return;
        }
        const item = this.props.liquidity[name];
        return (
            <tr>
                <td>{name.toUpperCase()}</td>
                <td>{item.buy}/{item.sell}</td>
                <td>{this.round(item.sell - item.buy)}</td>
            </tr>
        );
    }
    round(value) { // round a value with
        return Number(Math.round(value + 'e' + 5) + 'e-' + 5);
    }
    render() {
        // if (this.state.isLoading) {
        //     return '<div/>';
        // }
        return (
            <div>
                <Helmet
                    title="Online Forex Trading | Retail Forex Broker | CFD and Forex Trading"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "retail,forex,broker,LeoPrime,trading, forex trading, online" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Online Forex Trading | Retail Forex Broker | CFD and Forex Trading" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Online Forex Trading | Retail Forex Broker | CFD and Forex Trading" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "LeoPrime is an Next generation retail forex broker who aims to offer top-notch services that are extensively in need for Forex traders for an experience easier." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <section className="homeBanner">
                    <Col lg={10} className="mx-auto">
                        <Row className="justify-content-md-center">
                            <div className="bannerTxtShadow">
                                <h1>DISCOVER</h1>
                                <h1>DISCOVER</h1>
                                <h1>DISCOVER</h1>
                            </div>
                            <Col xs={12} md={12} lg={5} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center w-100">
                                    <div className="bannerTitle">
                                        <h1>
                                            DISCOVE<span>R</span>
                                        </h1>
                                        <h5 style={{ paddingTop: 100 }}>THE MARKETS</h5>
                                        {/* <h5><FormattedMessage id="app.content" /></h5> */}
                                        <a href={('https://www.leoprime.com/register')} className="btn-outline-black mt-3" >Open Live Account</a>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={7} className="mb-3">
                                <img src={DiscoverBanner} alt="" className="img-fluid" />
                            </Col>
                        </Row>
                    </Col>
                </section >
                <section className="secondSection-bg">
                    <Col lg={10} className="mx-auto">
                        <div className="BenefitShadow">
                            <h1>BENEFITS</h1>
                        </div>
                        <div className="sectionTitle">
                            <h5 class="padding-50">OUR</h5>
                            <h1>
                                BENEFITS
                            </h1>
                            {/* <h1>
                                <FormattedMessage id="app.header" />
                            </h1> */}
                        </div>
                        <Row className="justify-content-md-center">
                            <Col xs={12} md={12} lg={5} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center w-100">
                                    <div className="secondHeading-gray-bg">
                                        <h3>Spread <span>01</span></h3>
                                        <h5><span className="firtst-letter">sp</span>read  <span className="secSubTitle">Starts from</span></h5>
                                        <p>Spread starts at 0.0 pips with no hidden markups. Leo offers the lowest and highly competitive spreads across all major & minor currency pairs, even at times of high volatility.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={{ span: 6, offset: 1 }} className="mb-3 d-flex align-items-center">
                                <img src={InstantWithdrawalImg} alt="" className="img-fluid mx-auto" />
                            </Col>
                        </Row>
                    </Col>
                </section>
                <section>
                    <Col lg={10} className="mx-auto">
                        <Row className="justify-content-md-center">
                            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} lg={{ span: 5, order: 1, offset: 1 }} className="mb-3 d-flex align-items-center">
                                <img src={FSARegulatedImg} alt="" className="img-fluid mx-auto" />
                            </Col>
                            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} lg={{ span: 5, order: 2, offset: 1 }} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center w-100">
                                    <div className="secondHeading-gray-bg">
                                        <h3>FSA  <span>02</span></h3>
                                        <h5><span className="firtst-letter">In</span>stitutional   <span className="secSubTitle">Grade Liquidity</span></h5>
                                        <p>Leo Inc aspires to provide deep liquidity pools and super-tight pricing. Our user-friendly platform helps the retail clients & the institutional clientele quickly achieve their vision.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </section>
                <section>
                    <Col lg={10} className="mx-auto">
                        <Row className="justify-content-md-center">
                            <Col xs={12} md={12} lg={5} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center w-100">
                                    <div className="secondHeading-gray-bg">
                                        <h3>Multi  <span>03</span></h3>
                                        <h5><span className="firtst-letter">M</span>ulti Asset  <span className="secSubTitle">Broker</span></h5>
                                        <p>Leo Inc is a multi-asset brokerage firm that offers 65+ trading instruments including- Forex, Spot Metals, Indices, Energies, etc. Experience the best market execution on all our products.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={{ span: 6, offset: 1 }} className="mb-3 d-flex align-items-center">
                                <img src={ExtLowSpreadsImg} alt="" className="img-fluid mx-auto" />
                            </Col>
                        </Row>
                    </Col>
                </section>
                <section>
                    <Col lg={10} className="mx-auto">
                        <Row className="justify-content-md-center">
                            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} lg={{ span: 5, order: 1, offset: 1 }} className="mb-3 d-flex align-items-center">
                                <img src={LeverageImg} alt="" className="img-fluid mx-auto" />
                            </Col>
                            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} lg={{ span: 5, order: 2, offset: 1 }} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center w-100">
                                    <div className="secondHeading-gray-bg">
                                        <h3>Auto  <span>04</span></h3>
                                        <h5><span className="firtst-letter">A</span>utomatic <span className="secSubTitle">Withdrawal System</span></h5>
                                        <p>At Leo Inc, we not only understand the value of our client’s money but also understand the value of their time. Thus, we provide Instant, Transparent, and Automated Withdrawals. </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </section>
                <section className="ExperienceSection-bg">
                    <Col lg={10} className="mx-auto">
                        <Row className="my-5">
                            <Col xs={12} lg={{ span: 6, offset: 5 }}>
                                <div className="ExperienceTitle">
                                    <h1>
                                        <span>Ex</span>perience
                                        <span className="ExperienceSub-title">Our products</span>
                                    </h1>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center ExperienceBottom my-5">
                            <Col md={4} lg>
                                <h5>1. Forex</h5>
                                <p>Leo Inc is a reliable Forex broker that offers the best quoted price for all our diverse list of financial instruments.</p>
                                <p><Link exact="true" to="/trading-instrument-forex" className='animated-arrow' >
                                    <span className='the-arrow -left'>
                                        <span className='shaft'></span>
                                    </span>
                                    <span className='main'>
                                        <span className='text'>
                                            Learn More
                                        </span>
                                        <span className='the-arrow -right'>
                                            <span className='shaft'></span>
                                        </span>
                                    </span>
                                </Link></p>
                            </Col>
                            <Col md={4} lg >
                                <h5>2. Energies</h5>
                                <p>Leo Inc makes it easy for you by allowing trading of spot energy contracts including- Crude oil, Brent & Natural Gas.</p>
                                <p><Link exact="true" to="/trading-instruments-energies" className='animated-arrow' >
                                    <span className='the-arrow -left'>
                                        <span className='shaft'></span>
                                    </span>
                                    <span className='main'>
                                        <span className='text'>
                                            Learn More
                                        </span>
                                        <span className='the-arrow -right'>
                                            <span className='shaft'></span>
                                        </span>
                                    </span>
                                </Link></p>
                            </Col>
                            <Col md={4} lg >
                                <h5>3. Spot Metals</h5>
                                <p>Leo Inc offers the best-of-market spreads and spot execution, thus paves a great way to expand your portfolio. </p>
                                <p><Link exact="true" to="/trading-instruments-spot-metal" className='animated-arrow' >
                                    <span className='the-arrow -left'>
                                        <span className='shaft'></span>
                                    </span>
                                    <span className='main'>
                                        <span className='text'>
                                            Learn More
                                        </span>
                                        <span className='the-arrow -right'>
                                            <span className='shaft'></span>
                                        </span>
                                    </span>
                                </Link></p>
                            </Col>
                            <Col md={4} lg >
                                <h5>4. Indices</h5>
                                <p>When trading in Leo Inc stock indices CFDs, you will be able to diversify your trading strategies globally.</p>
                                <p><Link exact="true" to="/trading-instruments-indices" className='animated-arrow' >
                                    <span className='the-arrow -left'>
                                        <span className='shaft'></span>
                                    </span>
                                    <span className='main'>
                                        <span className='text'>
                                            Learn More
                                        </span>
                                        <span className='the-arrow -right'>
                                            <span className='shaft'></span>
                                        </span>
                                    </span>
                                </Link></p>
                            </Col>
                            <Col md={4} lg >
                                <h5>5. Stocks</h5>
                                <p>Leo Inc offers you Stocks with Market Execution on some of the world&#39;s most popular stocks.
                                </p>
                                <p><Link exact="true" to="/trading-instruments-stocks" className='animated-arrow' >
                                    <span className='the-arrow -left'>
                                        <span className='shaft'></span>
                                    </span>
                                    <span className='main'>
                                        <span className='text'>
                                            Learn More
                                        </span>
                                        <span className='the-arrow -right'>
                                            <span className='shaft'></span>
                                        </span>
                                    </span>
                                </Link></p>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <div className="sectionBottomTxt-bg">
                            Experience
                        </div>
                    </Col>
                </section>
                <section className="RightSection-Title">
                    <div className="sectionRotate-bg">
                        Trade on
                    </div>
                    <Col lg={10} className="mx-auto">
                        <Row className="justify-content-md-center">
                            <Col xs={{ span: 12, order: 2 }} md={{ span: 5, order: 1 }} lg={{ span: 5, order: 1 }} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center w-100 text-center">
                                    <img src={TradeOnImg} alt="Explore" className="img-fluid" />
                                    <p>Available for iOS and Android devices.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 12, order: 1 }} md={{ span: 7, order: 2 }} lg={{ span: 7, order: 2 }} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center w-100">
                                    <div className="RightTxtTitle">
                                        <h1 className="text-lg-right">
                                            Explore
                                            <span className="ExperienceSub-title">our Mobile App, On-the-go </span>
                                        </h1>
                                        <h3>Mobile</h3>
                                    </div>
                                    <div className="exploreList">
                                        <Col>
                                            <Row className="mb-4 exListview">
                                                <Col>
                                                    <h5>	MT4 <br />Accounts</h5>
                                                </Col>
                                                <Col>
                                                    <h5> 	Multi-payment <br />
                                                        Gateways</h5>
                                                </Col>
                                            </Row>
                                            <Row className="mb-4 exListview">
                                                <Col>
                                                    <h5>	High Data  <br />
                                                        Security</h5>
                                                </Col>
                                                <Col>
                                                    <h5>	Live updates at your  <br />
                                                        Fingertips</h5>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mt-4 text-lg-right text-center">
                                                    <a href="https://play.google.com/store/apps/details?id=com.leoprime.android" target="blank">
                                                        <img src={GooglePlayImg} alt="google play" className="img-fluid mr-3" width="120" />
                                                    </a>
                                                    <a href="https://apps.apple.com/in/app/leoprime/id1543420769" target="blank">
                                                        <img src={AppStoreImg} alt="app store" className="img-fluid" width="120" />
                                                    </a>
                                                </Col>
                                            </Row>
                                            {/* <Row className="mt-4">
                                            <Col className="d-flex flex-column justify-content-start w-100 align-items-start">
                                                <ReactPhoneInput
                                                    country={'in'}
                                                    inputExtraProps={{
                                                        name: "phone",
                                                        required: true,
                                                        autoFocus: true
                                                    }}
                                                    defaultCountry={"sg"}
                                                    value={this.state.phone}
                                                    onChange={this.handleOnChange}
                                                />
                                                <p>This will not be shared with others</p>
                                            </Col>
                                            <Col className="d-flex flex-column justify-content-center w-100 align-items-center">
                                                <a href={('https://www.leoprime.com/register')} className="btn-outline-black" >Open Live Account</a>
                                                <p>Get App link by SMS</p>
                                            </Col>
                                        </Row> */}
                                        </Col>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </section>
                {/* <section className="TradingPlatSection-bg">
                    <Col lg={10} className="mx-auto">
                        <Row className="my-5">
                            <Col xs={12} lg={{ span: 8, offset: 4 }}>
                                <div className="ExperienceTitle">
                                    <h1>
                                        <span>Ex</span>perience
                                        <span className="ExperienceSub-title">Our products</span>
                                    </h1>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </section> */}
                <section className="TradePlatformSection-bg">
                    <Col lg={10} className="mx-auto">
                        <div className="BenefitShadow">
                            <h1>Trading</h1>
                            <h1 className="leftTxt">Trading</h1>
                        </div>
                        <div className="sectionTitle">
                            <h1>
                                Trading
                            </h1>
                            <h5 class="padding-50">PLATFORM</h5>
                        </div>
                        <Row className="justify-content-md-center">
                            <Col xs={12} md={12} lg={5} className="d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center w-100">
                                    <Row>
                                        <Col className="metaTradeCol">
                                            <h3>METATRADER</h3>
                                            <h1>4</h1>
                                            <p><Link exact="true" to="/trading-platform" className='animated-arrow' >
                                                <span className='the-arrow -left'>
                                                    <span className='shaft'></span>
                                                </span>
                                                <span className='main'>
                                                    <span className='text'>
                                                        Learn More
                                                    </span>
                                                    <span className='the-arrow -right'>
                                                        <span className='shaft'></span>
                                                    </span>
                                                </span>
                                            </Link></p>
                                        </Col>
                                        {/* <Col className="metaTradeCol">
                                    <h3>METATRADER</h3>
                                    <h1>5</h1>
                                    <p><Link exact="true" to="/trading-platform-mt5" className='animated-arrow' >
                                        <span className='the-arrow -left'>
                                            <span className='shaft'></span>
                                        </span>
                                        <span className='main'>
                                            <span className='text'>
                                                Learn More
                                            </span>
                                            <span className='the-arrow -right'>
                                                <span className='shaft'></span>
                                            </span>
                                        </span>
                                    </Link></p>
                                </Col> */}
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={7} className="mb-3 d-flex align-items-center">
                                <img src={TradingPlatformImg} alt="" className="img-fluid mx-auto" />
                            </Col>
                        </Row>
                        <div className="BenefitShadow">
                            <h1 className="rightTxt">Trading</h1>
                        </div>
                    </Col>
                </section>
                {/* <ReadyToTrade /> */}
            </div >
        );
    }
}
const mapStateToProps = state => {
    return {
        liquidity: state.gradeLiquidity
    };
};
export default connect(mapStateToProps, { fetchGradeLiquidity })(Home);
