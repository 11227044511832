import { actionType } from "../actionType";

const monitoringListReducer = (state = {}, action) => {
    if (action.type === actionType.FETCH_MONITORING_LIST) {
        return { ...state, ...action.payload };
    }
    return state;
};

export default monitoringListReducer;
