import React, { Component } from 'react';
import { Col, Tabs, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReadyToTrade from '../components/ReadyToTrade'
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from 'react-accessible-accordion';
class Faq extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="FAQ | Leading forex broker | LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "faqs,FAQs,high leverage,leading,forexbroker,retail account,forex,broker,LeoPrime,trader, forex trader, online" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "FAQ | Leading forex broker | LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "FAQ | Leading forex broker | LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "LeoPrime is a World-Class  Next-Generation Multi Asset Broker offering quality & honest trading services.Have a question about Forex Trading? Get all your answers from our extensive library of Frequently Asked Questions (FAQs)" },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay faq-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase">Frequently Asked  </h1>
                            <h1 className="display-3 text-shadow text-uppercase"><span>Questions</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <Col xs={12} md={12} lg={12} className="mb-3 project-tab" id="tabs">
                            <Tabs defaultActiveKey="aboutleoprime" transition={false} className="nav-fill">
                                <Tab eventKey="aboutleoprime" title="About Leoprime">
                                    <Accordion className="mt-5">
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What is LeoPrime?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    LeoPrime is an authorized retail forex broker including energies, indices, and spot metals providing trading services for our clients. Providing best trading possibilities to our clients allowing both scalpers and traders using expert advisors unrestricted access to its liquidity, LeoPrime has positioned itself as the forex broker of choice for traders.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What are the advantages of LeoPrime?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    LeoPrime is known for its superior trading conditions and lightning fast execution, and provides all the tools and services needed for clients of any level to realise their trading ambitions. We provide technical analysis that helps investors and traders predict the uptrend, downtrend, and sideways moves of the market with the help of chart analysis. LeoPrime offers various account types, trading software and tools that help our clients to trade forex online. We are proud to offer live cent account which allows you to practice trading or to test your new strategies and indicators with least amount of deposit. We are in the easiest way in transferring funds via Local Bank transfer, FasaPay, China Union Pay, Bitcoins and more.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What license does LeoPrime hold?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    LeoPrime, a brand name of Leo Inc, is registered in Seychelles (Co. No. 8424165-1) authorised and regulated with Securities Dealers License by the Financial Service Authority (FSA) – Seychelles, with license number SD032.
                                                </p>
                                                {/* <p>And also Leo Prime Service Limited registered in the Marshall Islands (registration number 95149) and regulated in accordance with International Business Companies License under the arm of Marshall Islands Business Corporation Act. (MH96960).
                                                </p> */}
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Where is LeoPrime HQ located?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    Our HQ is based in and commencing its business in Seychelles, East Africa.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Who are your liquidity providers?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    LeoPrime acquires liquidity from different top tier-1 banks, providing competitive Spread to our clients.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How can I open an account with LeoPrime?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    Click the below link <a href={('https://www.leoprime.com/register')}>
                                                        https://www.leoprime.com/register </a>
                                                </p>
                                                <p>Enter your details in the mandatory fields given in the registration page by choosing your account type.
                                                </p>
                                                <p>Click on open account and the credentials will be sent to your registered email.
                                                </p>
                                                <p>You can login into your personal area cabinet using your logins.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What is a Personal Area?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    A Personal Area is a trader's personal section of our website. It contains information about all of their accounts (trading, demo, and archived) that have been opened using the same email address. In their Personal Area, a trader can make deposits, internal transfer within accounts, withdraw funds, take advantage of analytics, change passwords, and select desired settings. You can also raise a ticket by clicking “Tickets” for your queries. And you can also have access to your IB Partnership Dashboard to build up your network of active signed-up clients and gain along with your network. You can also click on “Subscribe” to receive more updates and upcoming contests in our platform. To sign in your Personal Area click the link,
                                                </p>
                                                <p><a href={('https://www.leoprime.com/login')}>
                                                    www.leoprime.com/login </a></p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What should I do when I see the error "This E-mail-ID was already exists" or "Invalid"?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    If you receive “E-mail already exists” error, it means that you are already registered with us. Log in to your personal area using the same email ID to access your accounts.
                                                </p>
                                                <p>If the password is invalid, kindly check your credentials from your email and retype the password instead copying and pasting it which might sometimes copy spaces as well.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How can I upload my documents?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    To upload your KYC documents, you can login into your personal area cabinet and select Upload documents under “My Profile” Tab. For KYC verification we accept ID Proof (Driving License, Nationality Card, or Passport) and Address Proofs (Recent utility bills, Bank statements) which should be dated with last 3 months. Proof documents that you upload should be in the format of .jpeg, .png, or .pdf file which is not password protected and the files also should be within 5MB. Proof uploaded will be verified within 24-48hours.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Is my personal documents secured with you?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    We use highly secure technology to protect your personal data and financial transactions. Your Personal Area is SSL-secured and protected with 128-bit encryption to make your browsing safe and your data inaccessible to any third parties.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How do I open a Demo account?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    If you are our new client, you can create a new Demo account from the link. URL: <a href={('https://www.leoprime.com/register')}>https://www.leoprime.com/register</a>
                                                </p>
                                                <p>If you are already registered with us you can open a new Demo account by clicking on “Demo” button under “My Account” option from your personal area and click on “+” and choose your demo account type. As an initial deposit 5000$ will be deposited in your account and you can also add more funds to your demo account by clicking Demo account -> Deposit.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What are the live accounts types offered by LeoPrime?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    We offer 4 accounts types, i.e., Classic, Pro, ECN, and Cent.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What are the difference between Classic, ECN, Pro and Cent?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    The difference between the account types by clicking this link,
                                                    <Link to="/account-types">
                                                        https://www.leoprime.com/account-types </Link>
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How can I open a live account?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    Please login to your personal area cabinet, select "My Account" tab and select live option. Click "+" and choose your account type (Classic, ECN, Pro or cent). Live account will be opened and the MT4 credentials will be sent to your registered e-mail address.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Do you allow using Expert Advisers (EA’s)?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    Yes, LeoPrime allows Ethical Expert Advisers for trading.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What currency is allowed for trading in LeoPrime?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    In LeoPrime, the base currency to trade is USD.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Where can I find the customer agreement?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    You can find the customer agreement by clicking this link,
                                                    <Link to="/legal-doc">
                                                        https://www.leoprime.com/legal-doc</Link>
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How do I change the password of my Personal Area cabinet?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    If you want to change the password, Login to your personal area cabinet and select “My profile” and select Change Password.
                                                </p>
                                                <p>If your password is forgotten, click on “Login” in your LeoPrime home Page and select forget password. By entering your registered E-mail your password reset link will be sent to your E-mail and you can change the password of your Personal Area Cabinet.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How to change the e-mail ID and Contact number?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    If the e-mail ID and Contact number has to be changed, an e-mail has to be sent to <a href="mailto:support@leoprime.com">support@leoprime.com</a> from your registered e-mail ID. Re-verification of the proofs is mandatory after changing the e-mail and contact number.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    I am from US, do you accept US clients?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    According to Dodd-Frank Act passed by US congress, the CFTC (Commodity Futures Trading Commission) no longer allows us to let US residents open trading account with us. We apologize for the Inconvenience. Leo Inc also does not offer its services to the residents of certain jurisdictions such as: USA, Belgium, North Korea, Japan, Iraq and Israel as we strictly follow all international regulations.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How can I approach for support?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    We provide 24*5customer support. You can contact us for support via live chat or you can send us an email at
                                                    <a href={('mailto:support@leoprime.com')}>
                                                        &nbsp;    support@leoprime.com </a>
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </Tab>
                                <Tab eventKey="mt4" title="About MetaTrader 4">
                                    <Accordion className="mt-5">
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What software should I use for trading in LeoPrime?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    LeoPrime offers MT4 trading terminal to trade. You can download and install the MT4 terminal at
                                                    <Link to="/trading-platform">
                                                        https://www.leoprime.com/trading-platform </Link>
                                                </p>
                                                <p>by choosing your desired OS.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What are the server names for your MT4 server?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    MT4 Live Server: LEO-Live
                                                    <br />MT4 Demo Server: LEO-Demo
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How to login into MT4 terminal?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    After creating your live account, the MT4 credentials will be sent to your registered E-mail. Login to your MT4 terminal with your Login ID and Master password by choosing the server.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How many decimal places do you quote prices?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    LeoPrime quotes currency prices up-to 5 decimal places.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How can I add market watch in MT4 terminal?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    In your MT4 terminal click on View->Market watch or simply press Ctrl+M.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How much commission is charged for using LeoPrime MT4 terminal?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    LeoPrime doesn’t charge any fees for trading on MT4 terminal.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How can I use Expert Adviser (EA’s) in MT4 terminal?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div>
                                                    <h3> Adding an EA to the Data Folder: </h3>
                                                    1. In the MetaTrader 4 platform please navigate to File --&gt; Open Data Folder
                                                    <br />
                                                    2. Expert Advisors in MetaTrader 4 <br />
                                                    3. Once the folder opens double click on “MQL4” <br />
                                                    4. Then select the “Experts” folder <br />
                                                    5. Copy the EA that you want to install into the “Experts” folder that opened
                                                    and contains some pre-installed EAs. <br /> <br />
                                                    Please Note: After installing a new EA make sure to restart the MetaTrader 4
                                                    platform.
                                                    <h3> Activating an EA:</h3>
                                                    1. Drag the EA from the Expert Advisors section of the Navigator window onto the
                                                    chart. This should open the settings window <br />
                                                    2. In the settings window navigate to the “Common” tab. Please make sure that
                                                    “Allow Live Trading” and “Allow DLL imports” are both checked. <br />
                                                    3. Then press “OK” <br />
                                                    4. In the top tool bar you will see a button that says “Auto-Trading” Make sure
                                                    that it is clicked. <br /> <br />
                                                    If all steps were followed correctly the top right corner of the chart should
                                                    have a “smiley face” <br /> <br />

                                                    Please Note: Any third party EA’s could have special instructions. Make sure to
                                                    contact the source where the EA came from for proper instructions on the
                                                    particular EA.
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Why aren’t all of the available instruments displayed in ‘Market Watch’ window?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    MT4 allows only certain number of currency pairs to be displayed in your Market Watch. In order to see the full list of available instruments, please right click on the market watch and select “Show All” option. You can also view the server time on the top of the market watch.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Why does MT4 shows “Invalid account”?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    Kindly Re-type the login credentials (we suggest you to enter in the fields rather that copy paste) and select the server. Or kindly check your internet connection.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How can I place the orders in MT4 terminal?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    By clicking “New Order” in your MT4 terminal you can place your buy/sell orders. Orders can be placed at market executions and in pending orders.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Where can I find the Spread in MT4 terminal?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    Right click on the Market Watch window, and select Spread. Currency Spread will be added to the Market Watch window.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How can I modify or delete the orders in MT4 terminal?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    In case of changing the orders that you have placed, Double click on the order that you have to change. A pop-up menu will appear that will allow you to select ‘Modify or Delete Order’. It enables you to change the price at which the order is triggered, to re-set the expiry date of the order, to set stop-loss and take-profit levels or to delete the order altogether.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How can I close my orders in MT4 terminal?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    First locate the order in the MT4 ‘Terminal’ window at the bottom of your MT4 screen. Right-click on the order you wish to close and select ‘Close Order’, or double-click on the order to bring up the ‘Order’ window and close it from there. Alternatively, you can close a position by clicking the ‘X’ symbol at the bottom right corner of the order column in MT4 terminal.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Where can I find the full trading history in MT4 terminal?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    In your MT4 terminal, click on “Account history” and right click -> All History. Trading history period can also be customized. You can also save the reports by right clicking -> Save reports and you can save your reports to the desired folder.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How can I change the MT4 password in case of lost/Forgotten?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    You can change the MT4 password in the Personal area cabinet. Click on Settings-> MT4 password.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    The price on the chart is different than the price appearing on my trading terminal. Why?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    The charts show the Bid price by default. A Buy position is opened with the Ask price and closed with the Bid price. You have to add the spread on the Bid price to find the Ask price. Therefore the difference in price is due to the spread.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What is the difference between the balance and the equity that appears on my trading platform?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    The balance illustrates your closed positions’ Profit/Loss, whereas the Equity is the real time calculation of Profit/Loss i.e. taking into account both open and closed positions.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What is the difference between the balance and the equity that appears on my trading platform?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    The balance illustrates your closed positions’ Profit/Loss, whereas the Equity is the real time calculation of Profit/Loss i.e. taking into account both open and closed positions.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Why is New Order option blocked?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    Please, check what password you have used for login: Master or Investor. When an investor password is used, the client terminal allows only viewing orders, trade possibilities are disabled. Try logging with your Master password to execute trades.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    I placed a pending order and was deleted. Why?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    Your pending order will be deleted from the system if the free margin (Equity – Used Margin) is not enough to cover the margin required for opening the specific order.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </Tab>
                                <Tab eventKey="aboutforex" title="About Forex">
                                    <Accordion className="mt-5">
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What is hedging?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    Hedging is a strategy to protect one’s position from an adverse move in a currency pair. Forex traders can be referring to one of two related strategies when they engage in hedging.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What is Arbitrage?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    Arbitrage is basically buying a security in one market and simultaneously selling it in another market.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How does Leverage affect pip value?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    A standard forex account has specific lots and pips units. A lot is the minimum quantity of a security that may be traded, while a pip is the smallest amount by which a currency quote can change. Typically, one lot is worth $100,000 and a pip unit is stated in the amount if $0.0001 for US dollar related currency pairs. Pip value is the effect that one-pip change has on a dollar amount.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Why is currency always quoted in pairs?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    Currency pairs are quoted as such because a currency is only valuable in relation to another currency.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Why isn’t the EUR/USD currency pair quotes as USD/EUR?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    In a currency pair, the first currency in the pair is called the base currency and the second is called the quote currency. Currency pairs can be separated into two types, direct and indirect. In a direct quote the domestic currency is the base currency, while the foreign currency is the quote currency. An indirect quote is just the opposite: the foreign currency is the base currency and the domestic currency is the quote currency.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What are the rules for placing stop and limit orders in forex?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    A limit order allows an investor to set the minimum or maximum price at which they would like to buy or sell, while a stop order allows an investor to specify the particular price at which they would like to buy or sell. There are no rules that regulate how traders can use stop and limit orders to manage their positions.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What are the rules for placing stop and limit orders in forex?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                A limit order allows an investor to set the minimum or maximum price at which they would like to buy or sell, while a stop order allows an investor to specify the particular price at which they would like to buy or sell. There are no rules that regulate how traders can use stop and limit orders to manage their positions.
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What is the difference between pips, points and ticks?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    Point, tick and pip are terms used to describe price changes in the financial markets. A point represents the smallest possible price change on the left side of a decimal point, while a tick represents the smallest possible price change on the right side of a decimal point. A pip is similar to a tick, also representing the smallest change to the right of the decimal, but it is endemic to the forex market.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What are the types of order of executions you provide?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    We provide Market executions (Buy, Sell) and Pending order executions (Buy Limit, Sell Limit, Buy Stop and Sell Stop) for our clients to enter the market.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </Tab>
                                <Tab eventKey="abouttradingacc" title="About Trading Accounts">
                                    <Accordion className="mt-5">
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How can I open a trading account?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    Click on the “Open a Live Account” option from our website open page and choose then account type of your wish and fill your basic information on the registration page to create your personal area cabinet. The credentials for your personal area cabinet will be sent to your email and once you login to your cabinet, you can create your Live trading account by clicking the “Create New Trading account” option and your MT4 login credentials will be sent your email.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What trading account types do you offer?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    We offer currently offer four account types which are Cent , Classic, Pro and ECN
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What is the Minimum Deposit/Withdrawal for a trading account?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    It is $10 for Cent and $10 for Classic account, while for ECN account it is $1000
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Do you offer Islamic accounts?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    Yes, we do offer Islamic/Swap free accounts.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How long can I use my demo account?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    At LeoPrime demo accounts do not have an expiry date, and so you can use them as long as you want. Demo accounts that have been inactive for longer than 90 days from the last login will be closed. However, you can open a new demo account at any time. Please note that maximum of 8 active demo accounts can be created with one email ID from the personal area cabinet.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Do you allow scalping?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    Yes, we do allow scalping
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What leverage do you offer?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                We offer a leverage up to 1:1000 for our clients.
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </Tab>
                            </Tabs>
                        </Col>
                    </div>
                </section>
                <ReadyToTrade />
            </div>
        );
    }
}
export default Faq;