import { actionType } from "../actionType";

const fxCalculatorReducer = (state = {}, action) => {

    switch (action.type) {
        case actionType.FETCH_FX_CALCULATOR:
            return { ...state, ...action.payload };
        case actionType.POST_FX_CALCULATOR:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export default fxCalculatorReducer;
