import React, { Component } from 'react'
import { Row, Col, Media, Form } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import { Sparklines, SparklinesLine } from 'react-sparklines';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { getSocialTradingRanks } from "../store/actions/socialTradingRankAction";
import Progress from "../components/Progress";
import Pagination from '@material-ui/lab/Pagination';
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { NavLink } from 'react-router-dom';

const META = [
    { name: 'author', content: "LeoPrime" },
    { name: 'keywords', content: "Copy Trader Ranking, Copy Trading Rank, Copy Traders Ranking" },
    { name: 'twitter:site', content: "LeoPrime" },
    { name: 'twitter:creator', content: "LeoPrime" },
    { name: 'twitter:title', content: "Copy Traders Ranking | LeoPrime" },
    { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
    { property: 'og:title', content: "Copy Traders Ranking | LeoPrime" },
    { property: 'og:site_name', content: "LeoPrime" },
    { property: 'og:type', content: "Forex Trading" },
    { property: 'og:url', content: "https://leoprime.com/" },
    {
        property: 'og:description',
        content: "LeoPrime’s Trader Ranking feature helps investors to choose & copy from the list of Master Traders, based on their ranking & performance.."
    },
    { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
    { property: 'og:site_name', content: "LeoPrime" },
    { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
];

class SocialTradingRank extends Component {
    state = {
        isLoading: true, // used for loader
        type: 'rank' // Risk Type ALL is Default
    };

    constructor(props) {
        super(props);

        this.fetchSocialTradingRanks({
            type: this.state.type
        });
    }

    // Listen a event of a Page Change Event and load a Trading rank
    handlePageChange = (event, value) => {
        const param = { "page": { "number": value }, type: this.state.type };
        this.setState({ isLoading: true });
        this.props.getSocialTradingRanks(param, (rs) => {
            this.setState({ isLoading: false });

        });
    };

    // Listen a event of a Page Change Event
    handleRiskTypeChange = (event) => {
        this.setState({ isLoading: true });
        this.setState({ type: event.target.value });
        this.fetchSocialTradingRanks({
            type: event.target.value
        })
    };

    // Get Social Trading Ranks from server and load in to table
    fetchSocialTradingRanks = (param) => {
        this.props.getSocialTradingRanks(param, (rs) => {
            this.setState({ isLoading: false });
        });
    };

    renderRow = (row) => {
        return (
            <Tr key={'row-' + row.nickname}>
                <Td>
                    <Media>
                        <Media.Body>
                            <p className="d-none d-lg-block">{row.details.rank > 0 ? 'Rank' : ""}</p>
                            <h6>{row.details.rank > 0 ? '#' + row.details.rank : ""}</h6>
                            <p className="d-none d-lg-block"></p>
                            <h6>&nbsp;</h6>

                        </Media.Body>
                    </Media>
                </Td>
                <Td>
                    <Media>
                        <img src={row.strategyimage}
                            alt="Copy Trading"
                            className="d-none d-lg-block mr-3 socialTradImg" />
                        <Media.Body className="pt-2">
                            <NavLink className='animated-arrow' exact to={"/copy-trading-rank-details?id=" + row.nickname}><h6>{row.nickname}</h6> </NavLink>
                            <p>{row.country.name}</p>
                        </Media.Body>
                    </Media>
                </Td>
                <Td>
                    <Media>
                        <Media.Body className="pt-2">
                            <p className="d-none d-lg-block">Risk</p>
                            <h6 className={row.details.risk === "Low" ? "text-success" : row.details.risk === "High" ? "text-danger" : "text-warning"}>{row.details.risk}</h6>
                        </Media.Body>
                    </Media>
                </Td>
                <Td>
                    <Media>
                        <Media.Body className="pt-2">
                            <p className="d-none d-lg-block">Absolute Gain</p>
                            <h6 className="text-success">{row.details.totalgain}%</h6>
                        </Media.Body>
                    </Media>
                </Td>
                <Td>
                    <Media>
                        <Media.Body className="pt-2">
                            <p className="d-none d-lg-block">Drawdown</p>
                            <h6>{row.details.drawdown}</h6>
                        </Media.Body>
                    </Media>
                </Td>
                <Td>
                    <Media>
                        <Media.Body className="pt-2">
                            <p className="d-none d-lg-block">Total Pips</p>
                            <h6>{row.details.totalpips}</h6>
                        </Media.Body>
                    </Media>
                </Td>
                <Td>
                    <Media>
                        <Media.Body className="pt-2">
                            <p className="d-none d-lg-block">Trades</p>
                            {/* <h6>{row.details.trades}</h6> */}
                            <h6>{row.details.tradecount}</h6>
                        </Media.Body>
                    </Media>
                </Td>
                <Td>
                    <Media>
                        <Media.Body className="pt-2">
                            <p className="d-none d-lg-block">Revenue Share</p>
                            <h6>{row.details.commission} %</h6>
                        </Media.Body>
                    </Media>
                </Td>

                <Td>
                    <Media>
                        <Media.Body className="pt-2">
                            <p className="d-none d-lg-block">&nbsp;</p>
                            <button className="primary-button" type="button"> Copy</button>
                        </Media.Body>
                    </Media>
                </Td>
            </Tr>
        );
    };

    render() {
        return (
            <div>
                <Helmet
                    title="Copy Traders Ranking | LeoPrime"
                    meta={META}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay social-trading-banner">
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase text-dark">Copy</h1>
                            <h1 className="display-3 text-shadow text-uppercase text-dark"><span>Trading</span></h1>
                            <Row>
                                <Col lg={6}>
                                    <p>
                                        Making an informed decision before investing made easy with LeoPrime's 'Trader Ranking' feature. Our traders are monitored continuously and tracked. We then display the list of our 'Master Traders' ranked based on some of the key performance indicators of professional trading. The 'Investor' picks the most suitable 'Master Trader' to auto-copy the trades. Our 'Master Trader' benefits from the commissions earned when investors copy the trades.
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="col-xl-10 mx-auto">
                        <Row className="justify-content-md-center">
                            <Col className="social-trading">
                                <div className="d-flex flex-column flex-md-row align-items-center  mb-3 border-bottom">
                                    <h2 className="mr-md-auto pb-3">
                                        Master Rating
                                    </h2>
                                    <div>
                                        <Form>
                                            <Form.Group controlId="exampleForm.ControlSelect1">
                                                <Form.Control as="select" name="type"
                                                    value={this.state.type}
                                                    onChange={this.handleRiskTypeChange}>
                                                    <option value="rank">Rank</option>
                                                    <option value="topgainers">Top Gainers</option>
                                                    <option value="mostpopular">Most Popular</option>
                                                    <option value="commission">Commission</option>
                                                    <option value="drawdown">Draw Down</option>
                                                    {/* <option value="trades">Trades</option> */}
                                                </Form.Control>
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </div>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th>&nbsp;</Th>
                                            <Th>
                                                <img src={require('../assets/img/user.png')}
                                                    alt="Copy Trading"
                                                    className="mr-2" />
                                            </Th>
                                            <Th>Total Gain</Th>
                                            <Th>Drawdown</Th>
                                            <Th>Total Pips</Th>
                                            <Th>Trades</Th>
                                            <Th>Revenue Share</Th>
                                            <Th>Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            !this.state.isLoading ?
                                                this.props.socialTradings.data ?
                                                    this.props.socialTradings.data.map((row) => {
                                                        return this.renderRow(row)
                                                    }) : <Alert severity="info">
                                                        <AlertTitle>Info</AlertTitle>
                                                        Master Rating Records are not Available!
                                                    </Alert>
                                                : <Progress />
                                        }
                                    </Tbody>
                                </Table>
                                <div className="float-right mt-3">
                                    {
                                        !this.state.isLoading && this.props.socialTradings.current_page ?
                                            <Pagination count={this.props.socialTradings.last_page}
                                                page={this.props.socialTradings.current_page}
                                                onChange={this.handlePageChange}
                                                variant="outlined"
                                                shape="rounded"
                                                color="primary" />
                                            : ''
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        socialTradings: state.socialTradingRanks.details
    }
};

export default connect(
    mapStateToProps,
    {
        getSocialTradingRanks
    })(SocialTradingRank);
