import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
// import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
export default class AccoutTypesDemoAccount extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="Open Demo Trading Account | leading forex broker | LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "demo account,register,retail,forex,broker,open a trading account, trading account, forex broker" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Open Demo Trading Account | leading forex broker | LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Open Demo Trading Account | leading forex broker | LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "Demo trading account is the perfect way to practice forex trading and familiarise yourself with the trading platforms in a risk-free environment. Start today!" },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay account-types-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase ">Account</h1>
                            <h1 className="display-3 text-shadow text-uppercase"><span>Types</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <Row className="justify-content-md-center">
                            <Col lg={{ span: 4, offset: 1 }} className="mb-3 d-flex flex-column justify-content-center mt-5">
                                <img src={require('../assets/img/account-types/account-type-left-2.png')} alt="AccountTypes" className="img-fluid" />
                            </Col>
                            <Col lg={7} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center pl-lg-5">
                                    <h2 className="mb-4 w-100">
                                        Demo Account
                                    </h2>
                                    <p className="w-100">If you are new to currency trading or an experienced forex trader, Demo trading account is a perfect way to practice forex trading and get familiarised yourself with our trading platforms in a risk-free environment at LeoPrime.
</p>
                                </div>
                            </Col>
                            <Col className="text-center mt-5">
                                <p>Register for free and get complete access to our MT4 platform
</p>
                                <a href={('https://www.leoprime.com/register')} className="primary-button-lg my-2" >Open Demo Account</a>
                                <p>
                                    I agree to the Privacy Policy and its terms and conditions 
                                    <span className="d-block">
                                    Already have an account? 
                                    
                                    <a href={('https://www.leoprime.com/user/')}  target="blank" >&nbsp; <u> LOGIN HERE</u></a>

                                </span>
                                    </p>
                            </Col>
                        </Row>
                    </div>
                </section>
                <ReadyToTrade />
            </div>
        );
    }
}
