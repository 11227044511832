import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
import ForexCalForm from "../components/ForexCalForm";
import { connect } from "react-redux";
import { calculateFx, calculateFxMargin, getForexCalculator } from "../store/actions/forexCalculatorAction";
import { Helmet } from "react-helmet";
import tool1 from '../assets/img/icons/tool-icon-1.svg';
import tool2 from '../assets/img/icons/tool-icon-2.svg';
import tool3 from '../assets/img/icons/tool-icon-3.svg';
import tool4 from '../assets/img/icons/tool-icon-4.svg';
import tool5 from '../assets/img/icons/tool-icon-5.svg';
import tool6 from '../assets/img/icons/tool-icon-6.svg';

class ForexCalculator extends Component {
    state = {
        isLoading: true,
        isCalculating: false,
        isRefreshing: false,
        form: {
            symbol: '',
            currency: '',
            leverage: '',
            lot: '1.0'
        }
    };

    constructor(props) {
        super(props);
        this.getFxCalculator();
    }

    // get calculator data
    getFxCalculator() {
        this.props.getForexCalculator((data) => {
            this.setState({ isLoading: false });
            this.setState(preState => ({
                ...preState,
                form: {
                    ...preState.form,
                    'currency': data.currency[0].id,
                    'leverage': data.leverage[0].id,
                }
            }));
        });
    }

    // refresh data on change input
    calculateFxData(param) {
        this.setState({ isRefreshing: true });
        this.props.calculateFx(param, () => {
            this.setState({ isRefreshing: false });
        });
    }

    // listen change event and refresh a calculator form data
    handleInputFieldChange = (event, name) => {
        const { target } = event;
        this.setState(preState => ({
            ...preState,
            form: {
                ...preState.form,
                [name]: target.value
            }
        }), () => {
            if (this.state.form.symbol !== '') {
                this.calculateFxData(this.state.form)
            }
        });
    };

    // calculate Required Margin, PIP value, Swag Long and Swag Short
    calculateForexData = () => {
        this.setState({ isCalculating: true });

        this.props.calculateFxMargin(this.prepareRequest(), () => {
            this.setState({ isCalculating: false });
        });
    };

    // prepare request for calculate Required Margin, PIP value, Swag Long and Swag Short
    prepareRequest = () => {
        let formData = new FormData();    //form data object
        formData.append('margin', this.props.forexCalculatorData.margin);   //append the values with key, value pair
        formData.append('pair', this.preparePair(this.props.forexCalculatorData.pair));
        formData.append('pip', this.props.forexCalculatorData.pip);
        formData.append('sl', this.props.forexCalculatorData.sl);
        formData.append('ss', this.props.forexCalculatorData.ss);
        return formData;
    };

    // convert pair object into coma separated string
    preparePair = (pairs) => {
        let pair = [];
        for (let key in pairs) {
            if (pairs.hasOwnProperty(key)) {
                pair.push(pairs[key].toUpperCase());
            }
        }
        return pair.join(',');
    };

    // render forex calculator form
    renderCalculatorForm = () => {
        if (this.state.isLoading) {
            return '';
        }

        const { form } = this.state;
        return (
            <form noValidate autoComplete="off">
                <Row>
                    <Col lg={{ span: 11, offset: 1 }}>
                        <ForexCalForm
                            fxData={this.props.forexCalculatorData}
                            handleInput={this.handleInputFieldChange}
                            handleSubmit={this.calculateForexData}
                            isCalculating={this.state.isCalculating}
                            isRefreshing={this.state.isRefreshing}
                            form={form} />
                    </Col>
                </Row>
            </form>
        );
    };

    render() {
        return (
            <div>
                <Helmet
                    title="Forex Trading Tools | Best Forex Trading tools | Forex Calculator"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "trader’s calculator,forex calculator,forex,leoprime" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Forex Trading Tools | Best Forex Trading tools | Forex Calculator" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Forex Trading Tools | Best Forex Trading tools | Forex Calculator" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "Our online calculator allows the trader’s to calculate all the basics of the trading positions including: Margin, Point Profit, Swap Long & Swap Short, Volume and Lot Price." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >

                </Helmet>

                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay forex-calculator-banner">
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase ">Forex </h1>
                            <h1 className="display-3 text-shadow text-uppercase"><span>Calculator</span></h1>
                        </div>
                    </div>
                </div>

                <section>
                    <Col lg={10} className="mx-auto">
                        <div className="container">
                            <Row className="text-center">
                                <Col lg={11} className="mx-auto">
                                    <h2 className="mb-4 w-100">
                                        LeoPrime’s Forex Calculator
                                    </h2>
                                    <p className="w-100">
                                        Our online calculator allows the trader’s to calculate all the
                                        basics of the trading positions including:
                                    </p>
                                    <p>
                                        Margin, Point Profit, Swap Long & Swap Short, Volume and Lot Price.
                                    </p>
                                </Col>
                            </Row>
                        </div>

                        <Row className="mt-5">
                            <Col xs={12} md={12} lg={7} className="mb-3">
                                {this.renderCalculatorForm()}
                            </Col>
                            <Col xs={12} md={12} lg={4} className="mt-5 mx-auto">
                                <Card className="boxShadow">
                                    <Card.Body>
                                        <h5>Result</h5>
                                        <table className="table table-borderless account_types_table">
                                            <tbody>
                                                <tr>
                                                    <td>Required margin</td>
                                                    <td>
                                                        <strong>
                                                            {this.props.fxResultArr.margin ? this.props.fxResultArr.margin : 0}
                                                        </strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Pip Value</td>
                                                    <td>
                                                        <strong>
                                                            {this.props.fxResultArr.pip ? this.props.fxResultArr.pip : 0}
                                                        </strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Swap Long</td>
                                                    <td>
                                                        <strong>
                                                            {this.props.fxResultArr.sl ? this.props.fxResultArr.sl : 0}
                                                        </strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Swap Short</td>
                                                    <td>
                                                        <strong>
                                                            {this.props.fxResultArr.ss ? this.props.fxResultArr.ss : 0}
                                                        </strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </section>
                <section className="hmeOurBenefit">
                    <Col lg={8} className="mx-auto">
                        <Row>
                            <Col xs={12} md={12} lg={12} className="text-center">
                                <Row>
                                    <Col className="mb-4">
                                        <h2 className="mb-0">
                                            More Tools
                                        </h2>
                                    </Col>
                                </Row>
                                <Row className="mt-5 readyToTrade">
                                    <div className="container">
                                        <Row>
                                            <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                                <img src={tool1} alt="Money" />
                                                <p><Link className='animated-arrow' to='/economic-calendar'>
                                                    <span className='the-arrow -left'>
                                                        <span className='shaft' />
                                                    </span>
                                                    <span className='main'>
                                                        <span className='text'>
                                                            Economic Calendar
                                                        </span>
                                                        <span className='the-arrow -right'>
                                                            <span className='shaft' />
                                                        </span>
                                                    </span>
                                                </Link></p>
                                            </Col>
                                            <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                                <img src={tool2} alt="Money" />
                                                <p>
                                                    <a target="blank" className='animated-arrow' href={('https://news.leoprime.com/')} >

                                                        <span className='the-arrow -left'>
                                                            <span className='sh  aft' />
                                                        </span>
                                                        <span className='main'>
                                                            <span className='text'>
                                                                Forex News
                                                            </span>
                                                            <span className='the-arrow -right'>
                                                                <span className='shaft' />
                                                            </span>
                                                        </span>

                                                    </a>
                                                </p>
                                            </Col>
                                            <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                                <img src={tool3} alt="Money" />

                                                <p>
                                                    <a target="blank" className='animated-arrow' href={('https://analysis.leoprime.com/')} >

                                                        <span className='the-arrow -left'>
                                                            <span className='sh  aft' />
                                                        </span>
                                                        <span className='main'>
                                                            <span className='text'>
                                                                Technical Analysis
                                                            </span>
                                                            <span className='the-arrow -right'>
                                                                <span className='shaft' />
                                                            </span>
                                                        </span>

                                                    </a>
                                                </p>

                                            </Col>
                                            <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                                <img src={tool4} alt="Money" />
                                                <p><Link className='animated-arrow' to='/forex-calculator'>
                                                    <span className='the-arrow -left'>
                                                        <span className='shaft' />
                                                    </span>
                                                    <span className='main'>
                                                        <span className='text'>
                                                            Forex Calculator
                                                        </span>
                                                        <span className='the-arrow -right'>
                                                            <span className='shaft' />
                                                        </span>
                                                    </span>
                                                </Link></p>
                                            </Col>
                                            <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                                <img src={tool5} alt="Money" />
                                                <p>
                                                    <a target="blank" className='animated-arrow' href={('https://blog.leoprime.com/')} >
                                                        <span className='the-arrow -left'>
                                                            <span className=' shaft' />
                                                        </span>
                                                        <span className='main'>
                                                            <span className='text'>
                                                                Trading Hours
                                                            </span>
                                                            <span className='the-arrow -right'>
                                                                <span className='shaft' />
                                                            </span>
                                                        </span>
                                                    </a>
                                                </p>
                                            </Col>
                                            <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                                <img src={tool6} alt="Money" />
                                                <p><Link className='animated-arrow' to='/vps-hosting'>
                                                    <span className='the-arrow -left'>
                                                        <span className='shaft' />
                                                    </span>
                                                    <span className='main'>
                                                        <span className='text'>
                                                            Leoprime VPS
                                                        </span>
                                                        <span className='the-arrow -right'>
                                                            <span className='shaft' />
                                                        </span>
                                                    </span>
                                                </Link></p>
                                            </Col>
                                        </Row>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </section>
                <ReadyToTrade />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        forexCalculatorData: state.forexCalculator,
        fxResultArr: state.fxResult,
    };
};

export default connect(
    mapStateToProps,
    {
        getForexCalculator,
        calculateFx,
        calculateFxMargin
    }
)(ForexCalculator);
