import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
import { Helmet } from "react-helmet";
class Regulations extends Component {
    render() {
        return (
            <div>
                 <Helmet
                    title="Regulation | Forex with LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "regulation,why,leoprime,register,retail,forex,broker,open a trading account, trading account, forex broker" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Regulation | Forex with LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Regulation | Forex with LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "LeoPrime is an Next-Generation Multi Asset Broker who aims to offer top-notch services that are extensively in need for Forex traders for an experience easier. Forex with LeoPrime." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner mb-5">
                    <div className="d-flex align-items-center dark-overlay regulation-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase text-dark">License of the  </h1>
                            <h1 className="display-3 text-shadow text-uppercase text-dark"><span>LeoPrime Group</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <Col lg={12}>
                            <Row className="mb-3">
                                <Col lg={8}>
                                    <h4 className="w-100">
                                        Financial Service Authority
                            </h4>
                                    <p className="w-100">LeoPrime, brand name of Leo Inc, is registered in Seychelles (Co. No. 8424165-1) authorised and regulated with Securities Dealers License by the Financial Service Authority (FSA) – Seychelles, with license number SD032. Leo Inc is regulated by Financial Service Authority (FSA) – Seychelles and it can legally deal with and carry out all systems of financial services as permitted by FSA - Seychelles.
</p>
                                </Col>
                                <Col lg={4} className="text-center">
                                    <img src={require('../assets/img/regulations/Seychelles-flag.png')} alt="Account Types" className="img-fluid" />
                                </Col>
                            </Row>
                            {/* <Row className="mb-3">
                                <Col lg={8}>
                                    <h4 className="w-100">
                                        International Business Companies License
                            </h4>
                                    <p className="w-100">Leo Prime Service Limited registered in the Marshall Islands (registration number 95149) and regulated in accordance with Marshall Islands Business Corporation Act. The Company’s address: Ajeltake Road, Ajeltake Island, Majuro, Marshall Islands MH96960. 
</p>
                                </Col>
                                <Col lg={4} className="text-center">
                                <img src={require('../assets/img/regulations/Marshall-flag.png')} alt="Account Types" className="img-fluid" />
                                </Col>
                            </Row> */}
                            <Row className="mb-3">
                               <Col>
                               <h4 className="w-100">
                                    Insurance
                            </h4>
                                <p className="w-100 mb-0">Leo Inc (SC):
</p>
                                <p className="w-100">Professional Indemnity Insurance is maintained with MUA Seychelles Insurance in Seychelles
</p>
                               </Col>
                            </Row>
                        </Col>
                    </div>
                </section>
                <ReadyToTrade />
            </div>
        );
    }
}
export default Regulations;