import React, { Component } from "react";
import { Row, Col, Tabs, Tab, Table } from "react-bootstrap";
import ReadyToTrade from "../components/ReadyToTrade";
import TradingInstrumentTabs from "../components/TradingInstrumentTabs";
import { Helmet } from "react-helmet";
export default class TradingInstrumentEnergies extends Component {
  render() {
    return (
      <div>
        <Helmet
          title="Indices Trading | Stock Indices | Index Trading Platform."
          meta={[
            { name: "author", content: "LeoPrime" },
            {
              name: "keywords",
              content:
                "retail,forex,broker,LeoPrime,trading, forex trading, online",
            },
            { name: "twitter:site", content: "LeoPrime" },
            { name: "twitter:creator", content: "LeoPrime" },
            {
              name: "twitter:title",
              content:
                "Indices Trading | Stock Indices | Index Trading Platform.",
            },
            {
              name: "twitter:image",
              content:
                "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg",
            },
            {
              property: "og:title",
              content:
                "Indices Trading | Stock Indices | Index Trading Platform.",
            },
            { property: "og:site_name", content: "LeoPrime" },
            { property: "og:type", content: "Forex Trading" },
            { property: "og:url", content: "https://leoprime.com/" },
            {
              property: "og:description",
              content:
                "LeoPrime is an Next-Generation Multi Asset Broker who aims to offer top-notch services that are extensively in need for Forex traders for an experience easier.",
            },
            {
              property: "og:image",
              content:
                "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg",
            },
            { property: "og:site_name", content: "LeoPrime" },
            {
              name: "viewport",
              content: "width=device-width, maximum-scale=1",
            },
          ]}
        ></Helmet>
        <div className="innerpageBanner">
          <div className="d-flex align-items-center dark-overlay trading-instrument-banner">
            <div className="col-xl-10 mx-auto text-white">
              <h1 className="display-3 text-shadow text-uppercase ">Trading</h1>
              <h1 className="display-3 text-shadow text-uppercase">
                <span>Instrument</span>
              </h1>
            </div>
          </div>
        </div>
        <TradingInstrumentTabs />
        <section className="pt-0">
          <div className="container">
            <Row className="justify-content-md-center">
              <Col
                xs={12}
                md={12}
                lg={4}
                className="mb-3 d-flex flex-column justify-content-center mt-5"
              >
                <img
                  src={require("../assets/img/account-types/account-type-left.png")}
                  alt="Account Types"
                  className="img-fluid"
                />
              </Col>
              <Col
                xs={12}
                md={12}
                lg={8}
                className="mb-3 d-flex align-items-center"
              >
                <div className="d-flex flex-column justify-content-center pl-lg-5">
                  <h2 className="mb-4">Indices Contract Specification</h2>
                  <p>
                    LeoPrime's Stock indices CFDs offer access to a whole range
                    of new markets. When trading in our stock indices CFDs, you
                    will be able to diversify your trading strategies as well as
                    take advantage of different opportunities across global
                    equity markets. The stock indices CFDs of LeoPrime have no
                    hidden markups, no requotes and cover from a wide selection
                    of 6 major international stock indices.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section className="hmeOurBenefit pt-0">
          <div className="container">
            <Col xs={12} lg className="mb-3 trading_table" id="tabs">
              <Tabs
                defaultActiveKey="forex"
                transition={false}
                className="nav-fill"
              >
                <Tab eventKey="forex" title="Classic">
                  <Table borderless responsive hover>
                    <thead>
                      <tr className="row m-0 bg-gray">
                        <th class="d-inline-block col-lg-1">Symbol</th>
                        <th class="d-inline-block col-lg-3 text-left">
                          Description
                        </th>
                        <th class="d-inline-block col-lg-1">Average Spread*</th>
                        <th class="d-inline-block col-lg-3">
                          Margin Requirements
                          <br />
                          <div class="row">
                            <div class="col">1:100</div>
                            <div class="col">1:500 </div>
                            <div class="col">1:1000 </div>
                          </div>
                        </th>
                        <th class="d-inline-block col-lg-2">
                          Swap
                          <br />
                          <div class="row">
                            <div class="col">Long</div>
                            <div class="col"> Short </div>
                          </div>
                        </th>
                        <th class="d-inline-block col-lg-2 text-center">
                          Stop Level ***
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="row m-0">
                        <td class="d-inline-block col-lg-1" data-label="Symbol">
                          FRA40s
                        </td>
                        <td
                          class="d-inline-block col-lg-3"
                          data-label="Description"
                        >
                          France 40 Index
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Average Spread*"
                        >
                          8
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          3%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.6%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.3%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:100"
                        >
                          {" "}
                          -1.01
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:200"
                        >
                          {" "}
                          -1.25
                        </td>
                        <td
                          class="d-inline-block col-lg-2 text-center"
                          data-label="Stop Level ***"
                        >
                          10
                        </td>
                      </tr>
                      {/* <tr class="row m-0">
                        <td class="d-inline-block col-lg-1" data-label="Symbol">
                          GER30s
                        </td>
                        <td
                          class="d-inline-block col-lg-3"
                          data-label="Description"
                        >
                          {" "}
                          The German stock index DAX 30
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Average Spread*"
                        >
                          9
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          3%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.6%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.3%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:100"
                        >
                          {" "}
                          -1.07
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:200"
                        >
                          -1.38
                        </td>
                        <td
                          class="d-inline-block col-lg-2 text-center"
                          data-label="Stop Level ***"
                        >
                          {" "}
                          10
                        </td>
                      </tr> */}
                      <tr class="row m-0">
                        <td class="d-inline-block col-lg-1" data-label="Symbol">
                          UK100s
                        </td>
                        <td
                          class="d-inline-block col-lg-3"
                          data-label="Description"
                        >
                          FTSE 100 Index
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Average Spread*"
                        >
                          8
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          3%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.60%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.30%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:100"
                        >
                          -0.55
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:200"
                        >
                          {" "}
                          -0.51
                        </td>
                        <td
                          class="d-inline-block col-lg-2 text-center"
                          data-label="Stop Level ***"
                        >
                          10
                        </td>
                      </tr>
                      <tr class="row m-0">
                        <td class="d-inline-block col-lg-1" data-label="Symbol">
                          USA30s
                        </td>
                        <td
                          class="d-inline-block col-lg-3"
                          data-label="Description"
                        >
                          Dow Jones Industrial Average
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Average Spread*"
                        >
                          4
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          3%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.60%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.30%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:100"
                        >
                          {" "}
                          -3.98
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:200"
                        >
                          {" "}
                          -3.28
                        </td>
                        <td
                          class="d-inline-block col-lg-2 text-center"
                          data-label="Stop Level ***"
                        >
                          5
                        </td>
                      </tr>
                      <tr class="row m-0">
                        <td class="d-inline-block col-lg-1" data-label="Symbol">
                          USA100s
                        </td>
                        <td
                          class="d-inline-block col-lg-3"
                          data-label="Description"
                        >
                          NASDAQ 100 Index
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Average Spread*"
                        >
                          5
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          3%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.60%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.30%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:100"
                        >
                          -7.99
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:200"
                        >
                          {" "}
                          -7.1
                        </td>
                        <td
                          class="d-inline-block col-lg-2 text-center"
                          data-label="Stop Level ***"
                        >
                          10
                        </td>
                      </tr>
                      <tr class="row m-0">
                        <td class="d-inline-block col-lg-1" data-label="Symbol">
                          USA500s
                        </td>
                        <td
                          class="d-inline-block col-lg-3"
                          data-label="Description"
                        >
                          The Standard & Poor's 500
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Average Spread*"
                        >
                          6
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          3%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.60%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:1000"
                        >
                          {" "}
                          0.30%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:100"
                        >
                          -6.4
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:200"
                        >
                          -5.65
                        </td>
                        <td
                          class="d-inline-block col-lg-2 text-center"
                          data-label="Stop Level ***"
                        >
                          10
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Tab>
                <Tab eventKey="pro" title="Pro">
                  <Table borderless responsive hover>
                    <thead>
                      <tr className="row m-0 bg-gray">
                        <th className="d-inline-block col-lg-1">Symbol</th>
                        <th className="d-inline-block col-lg-3 text-left">
                          Description
                        </th>
                        <th className="d-inline-block col-lg-1">
                          Average Spread*
                        </th>
                        <th className="d-inline-block col-lg-3">
                          Margin Requirements
                          <br />
                          <div className="row">
                            <div className="col">1:100</div>
                            <div className="col">1:200 </div>
                            <div className="col">1:500 </div>
                          </div>
                        </th>
                        <th className="d-inline-block col-lg-2">
                          Swap
                          <br />
                          <div className="row">
                            <div className="col">Long</div>
                            <div className="col">Short </div>
                          </div>
                        </th>
                        <th className="d-inline-block col-lg-2 text-center">
                          Stop Level ***
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="row m-0">
                        <td class="d-inline-block col-lg-1" data-label="Symbol">
                          FRA40p
                        </td>
                        <td
                          class="d-inline-block col-lg-3"
                          data-label="Description"
                        >
                          {" "}
                          France 40 Index
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Average Spread*"
                        >
                          4.6
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          3%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1.50%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.60%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap Long"
                        >
                          -1.01
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap Short"
                        >
                          {" "}
                          -1.25{" "}
                        </td>
                        <td
                          class="d-inline-block col-lg-2 text-center"
                          data-label="Stop Level ***"
                        >
                          10
                        </td>
                      </tr>
                      {/* <tr class="row m-0">
                        <td class="d-inline-block col-lg-1" data-label="Symbol">
                          GER30p
                        </td>
                        <td
                          class="d-inline-block col-lg-3"
                          data-label="Description"
                        >
                          {" "}
                          The German stock index DAX 30
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Average Spread*"
                        >
                          2
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          3%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1.50%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.60%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap Long"
                        >
                          -1.07
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap Short"
                        >
                          {" "}
                          -1.38
                        </td>
                        <td
                          class="d-inline-block col-lg-2 text-center"
                          data-label="Stop Level ***"
                        >
                          10
                        </td>
                      </tr> */}
                      <tr class="row m-0">
                        <td class="d-inline-block col-lg-1" data-label="Symbol">
                          UK100p
                        </td>
                        <td
                          class="d-inline-block col-lg-3"
                          data-label="Description"
                        >
                          {" "}
                          FTSE 100 Index
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Average Spread*"
                        >
                          3
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          3%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1.50%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.60%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap Long"
                        >
                          -0.55
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap Short"
                        >
                          {" "}
                          -0.51
                        </td>
                        <td
                          class="d-inline-block col-lg-2 text-center"
                          data-label="Stop Level ***"
                        >
                          10
                        </td>
                      </tr>
                      <tr class="row m-0">
                        <td class="d-inline-block col-lg-1" data-label="Symbol">
                          USA30p
                        </td>
                        <td
                          class="d-inline-block col-lg-3"
                          data-label="Description"
                        >
                          {" "}
                          Dow Jones Industrial Average
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Average Spread*"
                        >
                          2
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          3%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1.50%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.60%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap Long"
                        >
                          -3.98
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap Short"
                        >
                          {" "}
                          -3.28
                        </td>
                        <td
                          class="d-inline-block col-lg-2 text-center"
                          data-label="Stop Level ***"
                        >
                          5
                        </td>
                      </tr>
                      <tr class="row m-0">
                        <td class="d-inline-block col-lg-1" data-label="Symbol">
                          USA100p
                        </td>
                        <td
                          class="d-inline-block col-lg-3"
                          data-label="Description"
                        >
                          {" "}
                          NASDAQ 100 Index
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Average Spread*"
                        >
                          4
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          3%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1.50%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.60%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap Long"
                        >
                          -7.99
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap Short"
                        >
                          {" "}
                          -7.1
                        </td>
                        <td
                          class="d-inline-block col-lg-2 text-center"
                          data-label="Stop Level ***"
                        >
                          5
                        </td>
                      </tr>
                      <tr class="row m-0">
                        <td class="d-inline-block col-lg-1" data-label="Symbol">
                          USA500p
                        </td>
                        <td
                          class="d-inline-block col-lg-3"
                          data-label="Description"
                        >
                          {" "}
                          The Standard & Poor's 500
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Average Spread*"
                        >
                          3
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          3%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1.50%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:500"
                        >
                          {" "}
                          0.60%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap Long"
                        >
                          -6.4
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap Short"
                        >
                          {" "}
                          -5.65
                        </td>
                        <td
                          class="d-inline-block col-lg-2 text-center"
                          data-label="Stop Level ***"
                        >
                          10
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Tab>
                <Tab eventKey="ecn" title="ECN">
                  <Table borderless responsive hover>
                    <thead>
                      <tr className="row m-0 bg-gray">
                        <th class="d-inline-block col-lg-1">Symbol</th>
                        <th class="d-inline-block col-lg-4 text-left">
                          Description
                        </th>
                        <th class="d-inline-block col-lg-1">Average Spread*</th>
                        <th class="d-inline-block col-lg-2">
                          Margin Requirements
                          <br />
                          <div class="row">
                            <div class="col">1:100</div>
                            <div class="col">1:200 </div>
                          </div>
                        </th>
                        <th class="d-inline-block col-lg-2">
                          Swap
                          <br />
                          <div class="row">
                            <div class="col">Long</div>
                            <div class="col"> Short </div>
                          </div>
                        </th>
                        <th class="d-inline-block col-lg-2 text-center">
                          Stop Level ***
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="row m-0">
                        <td class="d-inline-block col-lg-1" data-label="Symbol">
                          FRA40
                        </td>
                        <td
                          class="d-inline-block col-lg-4"
                          data-label="Description"
                        >
                          France 40 Index
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Average Spread*"
                        >
                          2
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          3%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          1.50%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:100"
                        >
                          {" "}
                          -0.01
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:200"
                        >
                          {" "}
                          -1.25
                        </td>
                        <td
                          class="d-inline-block col-lg-2 text-center"
                          data-label="Stop Level ***"
                        >
                          {" "}
                          10
                        </td>
                      </tr>
                      {/* <tr class="row m-0">
                        <td class="d-inline-block col-lg-1" data-label="Symbol">
                          {" "}
                          GER30
                        </td>
                        <td
                          class="d-inline-block col-lg-4"
                          data-label="Description"
                        >
                          The German stock index DAX 30
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Average Spread*"
                        >
                          {" "}
                          2{" "}
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          3%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          1.50%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:100"
                        >
                          -1.07
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:200"
                        >
                          -1.38
                        </td>
                        <td
                          class="d-inline-block col-lg-2 text-center"
                          data-label="Stop Level ***"
                        >
                          {" "}
                          1
                        </td>
                      </tr> */}
                      <tr class="row m-0">
                        <td class="d-inline-block col-lg-1" data-label="Symbol">
                          {" "}
                          UK100
                        </td>
                        <td
                          class="d-inline-block col-lg-4"
                          data-label="Description"
                        >
                          FTSE 100 Index
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Average Spread*"
                        >
                          {" "}
                          3{" "}
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          3%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          1.50%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:100"
                        >
                          -0.55
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:200"
                        >
                          -0.51
                        </td>
                        <td
                          class="d-inline-block col-lg-2 text-center"
                          data-label="Stop Level ***"
                        >
                          {" "}
                          10
                        </td>
                      </tr>
                      <tr class="row m-0">
                        <td class="d-inline-block col-lg-1" data-label="Symbol">
                          {" "}
                          USA30
                        </td>
                        <td
                          class="d-inline-block col-lg-4"
                          data-label="Description"
                        >
                          {" "}
                          Dow Jones Industrial Average
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Average Spread*"
                        >
                          {" "}
                          2
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          3%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1.50%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:100"
                        >
                          {" "}
                          -3.98
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:200"
                        >
                          {" "}
                          -3.28
                        </td>
                        <td
                          class="d-inline-block col-lg-2 text-center"
                          data-label="Stop Level ***"
                        >
                          5
                        </td>
                      </tr>
                      <tr class="row m-0">
                        <td class="d-inline-block col-lg-1" data-label="Symbol">
                          {" "}
                          USA100{" "}
                        </td>
                        <td
                          class="d-inline-block col-lg-4"
                          data-label="Description"
                        >
                          {" "}
                          NASDAQ 100 Index
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Average Spread*"
                        >
                          {" "}
                          4{" "}
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          3%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1.50%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:100"
                        >
                          {" "}
                          -7.99
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:200"
                        >
                          {" "}
                          -7.10
                        </td>
                        <td
                          class="d-inline-block col-lg-2 text-center"
                          data-label="Stop Level ***"
                        >
                          {" "}
                          10
                        </td>
                      </tr>
                      <tr class="row m-0">
                        <td class="d-inline-block col-lg-1" data-label="Symbol">
                          {" "}
                          UK100
                        </td>
                        <td
                          class="d-inline-block col-lg-4"
                          data-label="Description"
                        >
                          {" "}
                          The Standard & Poor's 500
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Average Spread*"
                        >
                          {" "}
                          3{" "}
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:100"
                        >
                          {" "}
                          3%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Margin Requirements 1:200"
                        >
                          {" "}
                          1.50%
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:100"
                        >
                          {" "}
                          -6.40
                        </td>
                        <td
                          class="d-inline-block col-lg-1"
                          data-label="Swap 1:200"
                        >
                          {" "}
                          -5.65
                        </td>
                        <td
                          class="d-inline-block col-lg-2 text-center"
                          data-label="Stop Level ***"
                        >
                          {" "}
                          10
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Tab>
              </Tabs>
              <p className="text-lg-right text-center">
                <small>*Values are subjected to change</small>
              </p>
            </Col>
          </div>
        </section>
        <ReadyToTrade />
      </div>
    );
  }
}
