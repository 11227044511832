import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import { NavLink } from 'react-router-dom';
import { Row, Col, Table, Card } from 'react-bootstrap';
// import Countdown from '../components/Countdown';
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { getDemoContestList, getDemoContestRank } from "../store/actions/demoContest";
import Progress from "../components/Progress";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Pagination from '@material-ui/lab/Pagination';

import { getUrlPart } from '../utils/getUrlPart'

import contestDetails1 from '../assets/img/icons/contest-details-1.svg';
import contestDetails2 from '../assets/img/icons/contest-details-2.svg';
import contestDetails3 from '../assets/img/icons/contest-details-3.svg';
import contestDetails4 from '../assets/img/icons/contest-details-4.svg';
import contestDetails5 from '../assets/img/icons/contest-details-5.svg';

import contestDetailsW1 from '../assets/img/icons/contest-details-win-1.svg';
import contestDetailsW2 from '../assets/img/icons/contest-details-win-2.svg';
import contestDetailsW3 from '../assets/img/icons/contest-details-win-3.svg';

class ContestDetails extends Component {
    state = {
        isLoading: true, // used for loader
        isLoadingRank: true, // used for loader
        hasURL: false, // used for loader

    };

    constructor(props) {
        super(props);
        this.fetchDemoContest({ contestid: getUrlPart('id') });
    }

    // Listen a event of a Page Change Event and load a Trading rank
    handlePageChange = (event, value) => {
        this.setState({ isLoadingRank: true });
        const param = { "page": { "number": value }, contestid: getUrlPart('id') };
        this.props.getDemoContestRank(param, (res) => {
            this.setState({ isLoadingRank: false });
        });
    };


    fetchDemoContest = (param) => {
        if (getUrlPart('id')) {
            this.props.getDemoContestList(param, (res) => {
                this.setState({ isLoading: false });
                this.setState({ hasURL: true });

            }).catch((error) => {
                this.setState({ hasURL: true });
            });
            this.props.getDemoContestRank(param, (res) => {
                this.setState({ isLoadingRank: false });
            });
        }
        else {
            this.setState({ isLoading: false });
            this.setState({ isLoadingRank: false });
            this.setState({ hasURL: true });
        }
    };


    renderRow = (row, index) => {
        return (
            <tr>
                <td>{index}</td>
                <td>{row.nickname}</td>
                <td>{row.accountnumber}</td>
                <td>{row.equity}</td>
            </tr>
        );
    };

    render() {

        return (
            <div>
                <Helmet
                    title="Contest | Leoprime’s Contest"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "Getting a hold of Forex Trading? Compete tadnin a Contest and Win for your skill!" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Contest | Leoprime’s Contest" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Contest | Leoprime’s Contest" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "Getting a hold of Forex Trading? Compete in a Contest and Win for your skill!" },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay contest-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase">Trading
                            </h1>
                            <h1 className="display-3 text-shadow text-uppercase"><span>Contest</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container text-center">
                        <Col lg={10} className="mx-auto">
                            <Row>
                                <h2 className="mb-3 w-100">
                                    Getting a hold of Forex Trading?
                                    Compete in a Contest and Win for your skill!
                                </h2>
                                <p className="w-100 mb-4">Become our prestigious IB and earn, under our inviting rebate structure, for each client you refer us.
                                </p>
                                {/* <a href={('https://www.leoprime.com/demo-contest-registration/')} className="primary-button-lg mx-auto" >Join The Contest</a> */}
                            </Row>
                        </Col>
                    </div>
                </section>

                {this.state.hasURL ?
                    !this.state.isLoading ?



                        this.props.PastContest.Contest ?

                            <section className="bg-gray">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2>
                                                {this.props.PastContest.Contest.title}
                                            </h2>
                                            <p> {this.props.PastContest.Contest.description}
                                            </p>
                                        </div>
                                        <div className="col-md-5 offset-md-1">
                                            <img src={this.props.PastContest.Contest.image} alt={this.props.PastContest.Contest.link} className="mb-3 img-fluid" />
                                        </div>
                                    </div>
                                    <Col className="mt-5">
                                        <div className="justify-content-md-center text-center">
                                            <Row className="justify-content-md-center text-center">
                                                <Col xs={6} md lg className="mb-4View">
                                                    <img src={contestDetails1} alt="Trading Instrument" />
                                                    <p className="mb-0">Contest Period</p>
                                                    <strong>{this.props.PastContest.Contest.contestDate}</strong>
                                                </Col>
                                                <Col xs={6} md lg className="mb-4View">
                                                    <img src={contestDetails2} alt="Trading Instrument" />
                                                    <p className="mb-0">Date winners receive prizes	</p>
                                                    <strong>{this.props.PastContest.Contest.winner.date}</strong>
                                                </Col>
                                                <Col xs={6} md lg className="mb-4View">
                                                    <img src={contestDetails3} alt="Trading Instrument" />
                                                    <p className="mb-0">Account Type	</p>
                                                    <strong>{this.props.PastContest.Contest.type}	</strong>
                                                </Col>
                                                <Col xs={6} md lg className="mb-4View">
                                                    <img src={contestDetails4} alt="Trading Instrument" />
                                                    <p className="mb-0">Leverage</p>
                                                    <strong>{this.props.PastContest.Contest.leverage}</strong>
                                                </Col>
                                                <Col xs={12} md lg className="mb-4View">
                                                    <img src={contestDetails5} alt="Trading Instrument" />
                                                    <p className="mb-0">Number of participants</p>
                                                    <strong>{this.props.RankList.total}</strong>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <p className="mt-5 text-center">
                                        To download the Terms and Condition for the contest
                                        <a href={this.props.PastContest.Contest.tnd} target="blank" className="text-info"> &nbsp; CLICK HERE</a>
                                    </p>
                                </div>
                            </section>

                            : ''
                        : <Progress /> : ''
                }


                {
                    this.state.hasURL ?
                        !this.state.isLoading ?
                            this.props.PastContest.Contest ?
                                !this.props.PastContest.Contest.winner.winner1.name == "" ?
                                    <section className="hmeOurBenefit">
                                        <Col lg={6} className="mx-auto">
                                            <Row>
                                                <Col xs={12} md={12} lg={12}>
                                                    <Row>
                                                        <Col lg={6} className="mx-auto">
                                                            <Card className="boxShadow bgPrimary">
                                                                <Card.Body>
                                                                    <Row>
                                                                        <Col lg={4} sm className="text-center">
                                                                            <img src={contestDetailsW1} alt="winner" className="winner-img" />
                                                                        </Col>
                                                                        <Col lg={8} sm className="text-lg-left text-center">
                                                                            <h6 className="mb-0">{this.props.PastContest.Contest.winner.winner1.name}</h6>
                                                                            {/* <p className="mt-0">{this.props.PastContest.Contest.winner.winner1.prize}</p> */}
                                                                            <h5>{this.props.PastContest.Contest.winner.winner1.prize}</h5>
                                                                        </Col>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-5">
                                                        <Col lg={12} className="mx-auto">
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <Card className="boxShadow border mb-5">
                                                                        <Card.Body>
                                                                            <Row>
                                                                                <Col className="text-center">
                                                                                    <img src={contestDetailsW2} alt="winner" className="winner-img" />
                                                                                </Col>
                                                                                <Col lg={8} sm className="text-lg-left text-center">
                                                                                    <h6 className="mb-0">{this.props.PastContest.Contest.winner.winner2.name}</h6>
                                                                                    {/* <p className="mt-0">{this.props.PastContest.Contest.winner.winner1.prize}</p> */}
                                                                                    <h5>{this.props.PastContest.Contest.winner.winner2.prize}</h5>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <Card className="boxShadow border">
                                                                        <Card.Body>
                                                                            <Row>
                                                                                <Col className="text-center">
                                                                                    <img src={contestDetailsW3} alt="winner" className="winner-img" />
                                                                                </Col>
                                                                                <Col lg={8} sm className="text-lg-left text-center">
                                                                                    <h6 className="mb-0">{this.props.PastContest.Contest.winner.winner3.name}</h6>
                                                                                    {/* <p className="mt-0">{this.props.PastContest.Contest.winner.winner1.prize}</p> */}
                                                                                    <h5>{this.props.PastContest.Contest.winner.winner3.prize}</h5>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </section>
                                    : '' : ''
                            : <Progress /> : ''
                }
                {this.state.hasURL ?
                    <section className="hmeOurBenefit">
                        <Col lg={10} className="mx-auto">
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <h2 className="text-center mb-0">
                                        Rankings
                                    </h2>
                                    <Row className="mt-5">
                                        <div className="table-responsive">
                                            <Table borderless hover>
                                                <thead className="border-bottom font-weight">
                                                    <tr>
                                                        <td>Position</td>
                                                        <td>Nickname</td>
                                                        <td>Account Number</td>
                                                        <td>Equity</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        !this.state.isLoadingRank ?
                                                            this.props.RankList.data ?
                                                                this.props.RankList.data.map((row, index) => {
                                                                    return this.renderRow(row, (index + 1) + ((this.props.RankList.current_page - 1) * 10))
                                                                }) : <Alert severity="info">
                                                                    <AlertTitle>Info</AlertTitle>
                                                                    Ranks are not Available!
                                                                </Alert>
                                                            : <Progress />
                                                    }


                                                </tbody>
                                            </Table>
                                            <div className="float-right mt-3">
                                                {
                                                    !this.state.isLoadingRank && this.props.RankList.current_page ?
                                                        <Pagination count={this.props.RankList.last_page}
                                                            page={this.props.RankList.current_page}
                                                            onChange={this.handlePageChange}
                                                            variant="outlined"
                                                            shape="rounded"
                                                            color="primary" />
                                                        : ''
                                                }
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </section> : ''
                }
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        PastContest: state.pastContestList, RankList: state.rankContestList
    }
};
export default connect(
    mapStateToProps,
    {
        getDemoContestList, getDemoContestRank
    })(ContestDetails);