import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import French from '../lang/FR.json';
import Arabic from '../lang/AR.json';
import English from '../lang/US.json';
import { saveToStorage, getFromStorage } from '../utils/sessionStorage';

export const Context = React.createContext();

const local = getFromStorage('locale') ? getFromStorage('locale') : navigator.language;

let lang;
if (local === 'ar') {
    lang = Arabic;
} else {
    if (local === 'fr') {
        lang = French;
    } else {
        lang = English;
    }
}

const Wrapper = (props) => {
    const [locale, setLocale] = useState(local);

    const [messages, setMessages] = useState(lang);

    function selectLanguage(e) {
        const newLocale = e.target.value;
        setLocale(newLocale);
        saveToStorage('locale', newLocale);
        if (newLocale === 'en') {
            setMessages(English);
        } else {
            if (newLocale === 'fr') {
                setMessages(French);
            } else {
                setMessages(Arabic);
            }
        }
    }

    return (
        <Context.Provider value={{ locale, selectLanguage }}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>

    );
}


export default Wrapper;