import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';

import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Input,
    // Grid,
    // FormHelperText
} from "@material-ui/core";
const styles = {
    formControl: {
        width: "100%",
        "& label span": {
            color: "red"
        }
    },
};
const ForexCalForm = props => {
    const {
        form,
        handleInput,
        classes,
        fxData,
        isCalculating,
        isRefreshing
    } = props;
    const [isValidate, setIsValidate] = useState(false);
    const { symbol, currency, leverage, lot } = form;

    const onSubmit = (e) => {
        if (symbol === '') {
            setIsValidate(true);
            return false;
        }
        props.handleSubmit(e);
    };

    const getErrorMessage = () => {
        if (isValidate && symbol === '') {
            return (<div className="mt-1"><small className="text-error">Please select a currency pair.</small></div>)
        }
    };

    return (
        <Row>
            <Col className="selectOption forexCal">
                <Row>
                    <Col lg={6}>
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                style={{ disableAnimation: false }}
                                disableAnimation={false}
                                htmlFor="CurrencyPair"
                            >
                                Currency Pair
                            </InputLabel>
                            <Select
                                value={symbol}
                                onChange={event => handleInput(event, "symbol")}
                                input={<Input name="symbol" id="CurrencyPair" />}
                            >
                                {
                                    fxData.instrument.map((item) => {
                                        return (
                                            <MenuItem key={'ietm' + item.symbol.toString()}
                                                value={item.symbol}>
                                                {item.symbol}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            {/* <FormHelperText>Some important helper text</FormHelperText> */}
                        </FormControl>
                        {getErrorMessage()}
                    </Col>
                    <Col lg={6}>
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                style={{ disableAnimation: false }}
                                disableAnimation={false}
                                htmlFor="accountCurrency"
                            >
                                Account Currency
                            </InputLabel>
                            <Select
                                value={currency}
                                onChange={event => handleInput(event, "currency")}
                                input={<Input name="currency" id="accountCurrency" />}
                            >
                                {
                                    fxData.currency.map((item) => {
                                        return (<MenuItem key={item.id.toString()} value={item.id}>{item.val}</MenuItem>)
                                    })
                                }
                            </Select>
                            {/* <FormHelperText>Some important helper text</FormHelperText> */}
                        </FormControl>
                    </Col>
                    <Col lg={6}>
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                style={{ disableAnimation: false }}
                                disableAnimation={false}
                                htmlFor="leverageMargin"
                            >
                                Leverage* for margin
                            </InputLabel>
                            <Select
                                value={leverage}
                                onChange={event => handleInput(event, "leverage")}
                                input={<Input name="leverage" id="leverageMargin" />}
                            >
                                {
                                    fxData.leverage.map((item) => {
                                        return (
                                            <MenuItem key={item.id.toString()} value={item.id}>{item.val}</MenuItem>)
                                    })
                                }
                            </Select>
                            {/* <FormHelperText>Some important helper text</FormHelperText> */}
                        </FormControl>
                    </Col>
                    <Col lg={6}>
                        <TextField type="number" id="possize"
                            label="Position Size:"
                            name="lot"
                            onChange={event => handleInput(event, "lot")}
                            value={lot} />
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col lg={6}>
                        <TextField id="swapLong"
                            label="Swap Long"
                            value={fxData.sl ? fxData.sl : '0'}
                            disabled />
                    </Col>
                    <Col lg={6}>
                        <TextField id="swapShort"
                            label="Swap Short"
                            value={fxData.ss ? fxData.ss : '0'}
                            disabled />
                    </Col>
                    <Col lg={6}>
                        <TextField id="avgSpread"
                            label="Avg. Spread on MT4"
                            value={fxData.avg_spread ? fxData.avg_spread : '0'}
                            disabled />
                    </Col>
                    <Col lg={6}>
                        <TextField id="mpf"
                            label="Minimum price fluctuation"
                            value={fxData.mpf ? fxData.mpf : '0'}
                            disabled />
                    </Col>
                </Row>

                <Row className="justify-content-md-center my-5">
                    <Col className="text-center">
                        {
                            isCalculating ?
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                :
                                <button disabled={isRefreshing} onClick={onSubmit}
                                    className="primary-button-lg my-2"
                                    type="button">Calculate
                                </button>
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
export default withStyles(styles)(ForexCalForm);
