import React, { Component } from 'react'
// import { Link } from 'react-router-dom';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { getClientSentiments } from "../store/actions/clientSentimentAction";
import Progress from "../components/Progress";

const META = [
    { name: 'author', content: "LeoPrime" },
    {
        name: 'keywords',
        content: "faqs,FAQs,high leverage,leading,forexbroker,retail account,forex,broker,LeoPrime,trader, forex trader, online"
    },
    { name: 'twitter:site', content: "LeoPrime" },
    { name: 'twitter:creator', content: "LeoPrime" },
    { name: 'twitter:title', content: "Forex Client Sentiment - LeoPrime" },
    { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
    { property: 'og:title', content: "Forex Client Sentiment - LeoPrime" },
    { property: 'og:site_name', content: "LeoPrime" },
    { property: 'og:type', content: "Forex Trading" },
    { property: 'og:url', content: "https://leoprime.com/" },
    {
        property: 'og:description',
        content: "LeoPrime is a World-Class  Next-Generation Multi Asset Broker offering quality & honest trading services.Have a question about Forex Trading? Get all your answers from our extensive library of Frequently Asked Questions (FAQs)."
    },
    { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
    { property: 'og:site_name', content: "LeoPrime" },
    { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
];

class ToolClientSentiment extends Component {
    state = {
        isLoading: true
    };

    constructor(props) {
        super(props);
        this.props.getClientSentiments((rs) => {
            this.setState({ isLoading: false })
        });
    }

    renderSentiment(sentiment) {
        return (
            <Row className="my-3" key={'sen-' + sentiment.SYMBOL}>
                <Col lg="1" md={12}>{sentiment.SYMBOL}</Col>
                <Col lg="11" md={12}>
                    <ProgressBar>
                        <ProgressBar variant="success" now={sentiment.buy} label={`${sentiment.buy}%`} />
                        <ProgressBar variant="danger" now={sentiment.sell} label={`${sentiment.sell}%`} />
                    </ProgressBar>
                </Col>
            </Row>
        )
    };

    render() {

        // const active = 58.82;
        // const deactivebar = 41.18;
        return (
            <div>
                <Helmet
                    title="Forex Client Sentiment - LeoPrime"
                    meta={META}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay client-sentiment-banner">
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase ">Client </h1>
                            <h1 className="display-3 text-shadow text-uppercase"><span>Sentiment</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <Col lg={10} className="mx-auto">
                        <div className="container">
                            <Row className="text-center">
                                <Col lg={11} className="mx-auto">
                                    {/* <h2 className="mb-4 w-100">
                                        LeoPrime’s Forex Calculator
                                    </h2> */}
                                    <p className="w-100 mb-5">
                                        Client Sentiment data derived from LeoPrime retail traders with live positions.
                                        Essentially, traders are able to see where the majority of traders are
                                        positioned, whether long or short, when analyzing trades.
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        {
                            !this.state.isLoading ?
                                this.props.clientSentiments.map((item) => this.renderSentiment(item))
                                : <Progress />
                        }
                    </Col>
                </section>
                <ReadyToTrade />
            </div>
        );
    }
}

const mapStateToProps = state => {
    const result = [];
    Object.keys(state.clientSentiment).forEach(key => {
        result.push(state.clientSentiment[key]);
    });

    return {
        clientSentiments: result
    }
};

export default connect(mapStateToProps, { getClientSentiments })(ToolClientSentiment)
