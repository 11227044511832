import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
// import ReadyToTrade from '../components/ReadyToTrade'
import { Helmet } from "react-helmet";

import RO1 from '../assets/img/icons/regional-office-img-1.svg';
import RO2 from '../assets/img/icons/regional-office-img-2.svg';
import RO3 from '../assets/img/icons/regional-office-img-3.svg';



class RegionalOffice extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="Regional Representative|Partnership|LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "partnership,economic,calendar,forextrading,mt4,MT4,MetaTrader, retail,account,forex,broker,LeoPrime,trader, forex trader, online" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Regional Representative|Partnership|LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Regional Representative|Partnership|LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "Complete the form to receive more information about the partnership types and rebate schemes available at LeoPrime. We will contact you at the earliest to discuss your partnership options." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay regional-office-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase ">Regional </h1>
                            <h1 className="display-3 text-shadow text-uppercase"><span>Office</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <Row className="text-center">
                            <h2 className="mb-4 w-100">
                                Join LeoPrime & Setup Partner Office
                            </h2>
                            <p className="w-100">Join one of the industry's most successful referral programs and grow your business with the <br />help of our dedicated conversion specialists.
                            </p>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col className="text-center mt-4">
                                <Link to="/contact-us" className="primary-button-lg">Send Enquiry</Link>
                                {/* <button className="primary-button-lg" type="button">  </button> */}
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="bg-gray">
                    <div className="container">
                        <Row className="justify-content-md-center">
                            <Col xs={12} md={12} lg={6} className="mb-3">
                                <div className="d-flex">
                                    <img src={require('../assets/img/regional-office/regional-office-img.png')} alt="" className="img-fluid" />
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={6} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center">
                                    <h2>
                                        A Regional LeoPrime Office is a Lucrative
                                        Partnership option to Start With!
                                    </h2>
                                    <p>We invite expression of interest from individuals or companies seeking to become a LeoPrime Regional Representative. As a Regional Representative, you will partner with LeoPrime to promote new business in your local region while gaining competitive remuneration and Head Office support to grow your business.
                                    </p>
                                    <p>Potential LeoPrime Regional Representatives typically start as an Introducing Broker (IB) initially. Once one attains high level performance of weekly referrals, they might succeed to be made a Regional Representative.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-5 text-center">
                            <Col xs={12} md={6} lg={4} className="pr-4">
                                <div className="w-100">
                                    <img src={RO1} alt="Metatrader 4" />
                                    <p>Earn at each stage of your client's trading journey.</p>
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="pr-4">
                                <div className="w-100">
                                    <img src={RO2} alt="Metatrader 4" />
                                    <p>
                                        Convert more clients with the help of our partner specialists.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="pr-4">
                                <div className="w-100">
                                    <img src={RO3} alt="Metatrader 4" />
                                    <p>Grow your business with ongoing support from your dedicated conversion specialist.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <Row className="justify-content-md-center">
                            <Col xs={12} md={12} lg={6} className="mb-3">
                                <div className="d-flex flex-column justify-content-center">
                                    <div className="d-flex flex-column justify-content-center">
                                        <h2>
                                            Get Advantage From Partnering With LeoPrime
                                        </h2>
                                        <p>We invite expression of interest from individuals or companies seeking to become a LeoPrime Regional Representative. As a Regional Representative, you will partner with LeoPrime to promote new business in your local region while gaining competitive remuneration and Head Office support to grow your business.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={{ span: 5, offset: 1 }} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center">
                                    <ul className="liststyle">
                                        <li>Office Designs</li>
                                        <li>Consulting Services</li>
                                        <li>Promotional Materials</li>
                                        <li>Publication on Website</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="features-area fix">
                    <div className="container-fluid">
                        <Row className="justify-content-md-center">
                            <Col xs={12} md={12} lg={{ span: 6, offset: 6 }} className="bg-dark-blue">
                                <div className="d-flex flex-column justify-content-center">
                                    <div className="features-info">
                                        <h2 className="pb-3">
                                            Get the Setup You Desire
                                            <span className="d-block">With LeoPrime</span>
                                        </h2>
                                        <ul className="liststyle-white">
                                            <li>Additional Opportunities to Sign-up Clients</li>
                                            <li>Best Trading Conditions for Signed Clients
                                            </li>
                                            <li>24x7 Accessibility to Services
                                            </li>
                                            <li>Increased Partner Rewards
                                            </li>
                                        </ul>
                                        <Row>
                                            <Col>
                                                <a href={('https://www.leoprime.com/register')} className='primary-button-lg mt-2' >
                                                    Sign Up Now
                                                </a>
                                                <p>
                                                    or try free <Link to="/contact-us" className="float-left"> <u>or send us your enquiry</u></Link>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        );
    }
}
export default RegionalOffice;