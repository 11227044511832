import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { makeStyles } from '@material-ui/core/styles';

import IbForm from "../components/IbForm";
import { connect } from "react-redux";
import { sendContactEmail } from "../store/actions/sendContactEmailAction";

const styles = {};

// const META = [
//     {name: 'author', content: "LeoPrime"},
//     {
//         name: 'keywords',
//         content: "high leverage,leading,forexbroker,retail,account,forex,broker,LeoPrime,trader, forex trader, online"
//     },
//     {name: 'twitter:site', content: "LeoPrime"},
//     {name: 'twitter:creator', content: "LeoPrime"},
//     {name: 'twitter:title', content: "Contact Us | Top forex broker | LeoPrime"},
//     {name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg"},
//     {property: 'og:title', content: "Contact Us | Top forex broker | LeoPrime"},
//     {property: 'og:site_name', content: "LeoPrime"},
//     {property: 'og:type', content: "Forex Trading"},
//     {property: 'og:url', content: "https://leoprime.com/"},
//     {
//         property: 'og:description',
//         content: "LeoPrime is a World-Class  Next-Generation Multi Asset Broker offering quality & honest trading services.Find the best way to get in touch according to your needs."
//     },
//     {property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg"},
//     {property: 'og:site_name', content: "LeoPrime"},
//     {name: 'viewport', content: 'width=device-width, maximum-scale=1'},
// ];

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 200,
        },
    }
}));

const emailExpression = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;

class ContactUs extends Component {

    state = {
        form: {
            first_name: '',
            last_name: '',
            email: '',
            mobile: '',
            aycus: '',
            pct: '',
            country: '',
            message: '',
            state: '',
            pagenamecf: 'LeoPrime Contact Form'
        },
        classes: useStyles,
        isSubmitted: false,
        isSending: false,
        isSent: false
    };

    handleInputFieldChange = (event) => {
        const { target } = event;
        this.setState(preState => ({
            ...preState,
            form: {
                ...preState.form,
                [target.name]: target.value
            }
        }));
    };

    handleSubmit = () => {
        this.setState({ isSubmitted: true });
        if (this.validateOnSubmit()) {
            this.setState({ isSending: true });
            this.props.sendContactEmail(this.state.form, (rs) => {
                this.setState({ isSubmitted: false });
                this.setState({ isSent: true });
            });
        }
    };

    validateOnSubmit = () => {
        let isFormValid = true;

        Object.keys(this.state.form).forEach((key) => {
            if (this.state.form[key] === '') {
                isFormValid = false;
            }
        });

        return isFormValid;
    };

    hasInvalid = (key) => {
        if (key === 'email') {
            return (!emailExpression.test(key) && this.state.form[key]) === '' && this.state.isSubmitted
        }
        return this.state.form[key] === '' && this.state.isSubmitted
    };

    render() {
        const { form } = this.state;
        return (
            <div>
                {/* <Helmet
                    title="Contact Us | Top forex broker | LeoPrime"
                    meta={META}
                >
                </Helmet> */}
                <Helmet
                    title="Contact Us | Top forex broker | LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "high leverage,leading,forexbroker,retail,account,forex,broker,LeoPrime,trader, forex trader, online" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Contact Us | Top forex broker | LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Contact Us | Top forex broker | LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "LeoPrime is a World-Class  Next-Generation Multi Asset Broker offering quality & honest trading services.Find the best way to get in touch according to your needs." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >

                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay contact-us-banner">
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase text-dark">
                                Contact
                            </h1>
                            <h1 className="display-3 text-shadow text-uppercase text-dark"><span>US</span></h1>
                        </div>
                    </div>
                </div>
                <section className="bg-gray">
                    <div className="container">
                        <Row>
                            <Col xs={12} md={12} lg={6} className="mb-3">
                                <div className="text-center ">
                                    <img src={require('../assets/img/contact-us/contact-us-map.png')} alt="contact us" className="img-fluid" />
                                    <h3 className="mt-4">Leo Inc Limited
                                    </h3>
                                    <p> Flat F, 31/F, Tower 1, Bailey Garden, 23 Bailey Street, Hunghom, Kowloon, Hong Kong
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={6} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center">
                                    <div className="mb-5">
                                        <h2>
                                            Connect to Leo Inc’s
                                            Top Support
                                        </h2>
                                        <p>Should you have any questions or need assistance, feel free to contact our
                                            all-star Client Support team
                                        </p>
                                    </div>
                                    <div className="mb-5">
                                        <h3>
                                            Client support
                                        </h3>
                                        <h4 className="mt-3"> +971 (0) 444 700 23 </h4>
                                        {/* <h4 className="mt-3">+44 20380 85868 / +971 (0) 444 700 23 </h4> */}
                                        <p>Call Client Support Mon-Fri 07:00-20:00 (GMT)</p>
                                    </div>
                                    {/* <div className="mb-5">
                                        <h3>
                                            Compliance Support
                                        </h3>
                                        <h4 className="mt-3">+44 20380 85868 (EXT - 104)</h4>
                                        <p>Your queries answered within 24 hours time window! (regular business hours).</p>
                                    </div> */}
                                    <div className="mb-5">
                                        <Row>
                                            <Col>
                                                <h3>
                                                    {/* <Link to="/"> Live Chat</Link> */}
                                                    <a href={('mailto:support@leoprime.com')} >support@leoprime.com</a>
                                                </h3>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="text-center py-5">
                    <Col lg={10} className="mx-auto">
                        <Row className="text-center">
                            <Col xs={12} md={6} lg={7} className="mx-auto mb-5">
                                <h2 className="mb-4 w-100">
                                    Send us a Message
                                </h2>
                                <p className="w-100">
                                    Need to know more? Fill in your information and you will be contacted within 24 hours.
                                </p>
                            </Col>
                            <Col xs={12} md={6} lg={8} className="pr-4 mx-auto">
                                {
                                    this.state.isSent ?
                                        <p className="text-success">
                                            Thank you for your interest.
                                            <br /> Please note that we received your inquiry and
                                            will contact you in the shortest delays
                                        </p>
                                        :
                                        <div className="w-100">
                                            <form className={this.state.classes.root} noValidate autoComplete="off">
                                                <Row>
                                                    <Col lg={6}>
                                                        <TextField id="first-name"
                                                            name="first_name"
                                                            label="First Name"
                                                            onChange={this.handleInputFieldChange}
                                                            required={true}
                                                            error={this.hasInvalid('first_name')}
                                                            defaultValue={this.state.form.first_name}
                                                            helperText={
                                                                this.hasInvalid('first_name')
                                                                    ? "Enter first name using words only. No symbols or numbers.First Name" :
                                                                    ''
                                                            } />
                                                    </Col>
                                                    <Col lg={6}>
                                                        <TextField id="last-name"
                                                            name="last_name"
                                                            label="Last Name"
                                                            required={true}
                                                            onChange={this.handleInputFieldChange}
                                                            error={this.hasInvalid('last_name')}
                                                            defaultValue={this.state.form.last_name}
                                                            helperText={
                                                                this.hasInvalid('last_name')
                                                                    ? "Enter last name (i.e. surname) using only words. No numbers or symbols." :
                                                                    ''
                                                            } />
                                                    </Col>
                                                    <Col lg={6}>
                                                        <TextField id="email"
                                                            name="email"
                                                            label="Email"
                                                            required={true}
                                                            onChange={this.handleInputFieldChange}
                                                            error={this.hasInvalid('email')}
                                                            defaultValue={this.state.form.email}
                                                            helperText={
                                                                this.hasInvalid('email')
                                                                    ? "Sorry, that’s not a recognised email address. Please enter a valid email address." :
                                                                    ''
                                                            } />
                                                    </Col>
                                                    <Col lg={6}>
                                                        <TextField id="mobile"
                                                            name="mobile"
                                                            label="Phone Number"
                                                            required={true}
                                                            onChange={this.handleInputFieldChange}
                                                            error={this.hasInvalid('mobile')}
                                                            defaultValue={this.state.form.mobile}
                                                            helperText={
                                                                this.hasInvalid('mobile')
                                                                    ? "Enter phone number without the country code or symbols. Max 15 characters" :
                                                                    ''
                                                            } />
                                                    </Col>
                                                    <Col lg={12}>
                                                        <IbForm
                                                            isSubmitted={this.state.isSubmitted}
                                                            handleInput={this.handleInputFieldChange}
                                                            form={form} />
                                                    </Col>
                                                    <Col lg={6}>
                                                        <TextField id="currentUs"
                                                            name="aycus"
                                                            label="Are you an existing client?"
                                                            required={true}
                                                            onChange={this.handleInputFieldChange}
                                                            error={this.hasInvalid('aycus')}
                                                            defaultValue={this.state.form.aycus}
                                                            helperText={
                                                                this.hasInvalid('aycus')
                                                                    ? "Required" :
                                                                    ''
                                                            } />

                                                    </Col>
                                                    <Col lg={6}>
                                                        <TextField id="preContTime"
                                                            name="pct"
                                                            label="Preferred Contact time"
                                                            required={true}
                                                            onChange={this.handleInputFieldChange}
                                                            error={this.hasInvalid('pct')}
                                                            defaultValue={this.state.form.pct}
                                                            helperText={
                                                                this.hasInvalid('pct')
                                                                    ? "Required" :
                                                                    ''
                                                            } />
                                                    </Col>
                                                    <Col lg={12}>
                                                        <TextField id="message"
                                                            name="message"
                                                            label="Message"
                                                            required={true}
                                                            rows={4}
                                                            multiline
                                                            onChange={this.handleInputFieldChange}
                                                            error={this.hasInvalid('message')}
                                                            defaultValue={this.state.form.message}
                                                            helperText={
                                                                this.hasInvalid('message')
                                                                    ? "Required" :
                                                                    ''
                                                            } />
                                                    </Col>
                                                </Row>
                                                <Row className="justify-content-md-center my-5">
                                                    <Col className="text-center">
                                                        {
                                                            this.state.isSending ?
                                                                <div className="spinner-border text-primary"
                                                                    role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                                : <button onClick={this.handleSubmit}
                                                                    className="primary-button-lg my-2"
                                                                    type="button">
                                                                    Send Enquiry
                                                                </button>
                                                        }
                                                    </Col>
                                                </Row>
                                            </form>
                                        </div>
                                }
                            </Col>
                        </Row>
                    </Col>
                </section>
                {/* <section className="text-center pt-5 pb-0">
                    <Col lg={10} className="mx-auto">
                        <Row className="text-center">
                            <Col xs={12} md={6} lg={7} className="mx-auto mb-5">
                                <h2 className="mb-4 w-100">
                                    FAQ
                                </h2>
                                <p>
                                    Common questions are answered in our FAQ section, please send us your query once you visit our FAQ page.
                                </p>
                                <Link to="/faq" className="py-3">Find most of the answers in our FAQ section</Link>
                            </Col>
                        </Row>
                    </Col>
                </section> */}
            </div>
        );
    }
}

export default connect(
    null,
    { sendContactEmail }
)(withStyles(styles)(ContactUs))
