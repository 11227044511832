import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
import { Helmet } from "react-helmet";
import CustomerAgreement from '../documents/customer_agreement.pdf';
import ComplaintsProcedure from '../documents/complaints_proceedure_for_clients.pdf';
import RiskDisclosure from '../documents/risk_disclosure_and_warning_notice.pdf';
import PartnershipAgreement from '../documents/partnership_agreement.pdf';
import TermsVps from '../documents/terms_vps_hosting.pdf';
import Terms30B from '../documents/30-tradable-bonus.pdf';
class LegalDocuments extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="Legal Documents|Forex with LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "legal,document,leoprime" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Legal Documents|Forex with LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Legal Documents|Forex with LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "List of all legal documents that must be reviewed before starting to trade on the forex market." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay regulation-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase text-dark">Legal </h1>
                            <h1 className="display-3 text-shadow text-uppercase text-dark"><span>Documents</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <Col lg={12}>
                            <Row className="mb-3">
                                <ul class="liststyle">
                                    <li> <a href={CustomerAgreement} target="blank"> Customer Agreement </a> </li>
                                    <li> <a href={ComplaintsProcedure} target="blank"> Complaints Procedure For
                                        Clients </a> </li>
                                    <li> <a href={RiskDisclosure} target="blank"> Risk Disclosure And Warning Notice </a> </li>
                                    <li> <a href={PartnershipAgreement} target="blank"> Partnership Agreement  </a> </li>
                                    <li> <a href={Terms30B} target="blank"> Terms and Conditions for 30% Tradable Bonus </a> </li>
                                    <li> <a href={TermsVps} target="blank"> Terms And Conditions For Vps Hosting Service  </a> </li>
                                    <li> <Link to="/refund-policy">Refund Policy
                                    </Link> </li>
                                </ul>
                            </Row>
                        </Col>
                    </div>
                </section>
                <ReadyToTrade />
            </div>
        );
    }
}
export default LegalDocuments;