import { actionType } from "../actionType";

const fxMarginCalculatorReducer = (state = {}, action) => {
    if (action.type === actionType.POST_FX_MARGIN_CALCULATOR) {
        return { ...state, ...action.payload };
    }
    return state;
};

export default fxMarginCalculatorReducer;
