import { actionType } from "../actionType";
import apiConfig from '../../config/apiCCConfig';

export const getMonitoringList = (param, cb) => async (dispatch) => {
    try {
        const url = 'api/v3/monitoring';
        const response = await apiConfig.post(url, param);
        dispatch({
            type: actionType.FETCH_MONITORING_LIST,
            payload: response.data,
        });
        cb(response.data);
    } catch (error) {
        console.log('error', error.message);
    }
};
