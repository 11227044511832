import { actionType } from "../actionType";
import apiConfig from '../../config/apiCCConfig';

export const getCountries = (param, cb) => async (dispatch) => {
    try {
        const response = await apiConfig.post('api/v3/country', param);
        dispatch({ type: actionType.FETCH_COUNTRIES, payload: response.data });
        cb(response.data);
    } catch (error) {
        console.log(error);
    }
};
