import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
// import ReadyToTrade from '../components/ReadyToTrade'
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXTwitter,
  faInstagram,
  faFacebookF,
  faPinterestP,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
// import IbPromotionTandC from "../documents/ib-programs/introducing-brokers_2021_march.pdf";
import GooglePlayImg from "../assets/img/google-play-icon.png";
import AppStoreImg from "../assets/img/app-store-icon.png";
class CopyTrading extends Component {
  render() {
    return (
      <div>
        <Helmet
          title="LeoPrime Copy Trading App | Best Copy Trading Platform"
          meta={[
            { name: "author", content: "LeoPrime" },
            { name: "keywords", content: "Copy Trading, Copy Trade, Copy Trading App, LeoPrime Copy Trade App, LeoPrime Copy Trading, Best Copy Trading Platform" },
            { name: "twitter:site", content: "LeoPrime" },
            { name: "twitter:creator", content: "LeoPrime" },
            { name: "twitter:title", content: "LeoPrime Copy Trading App | Best Copy Trading Platform" },
            {
              name: "twitter:image",
              content:
                "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg",
            },
            { property: "og:title", content: "LeoPrime Copy Trading App | Best Copy Trading Platform" },
            { property: "og:site_name", content: "LeoPrime" },
            { property: "og:type", content: "Forex Trading" },
            { property: "og:url", content: "https://leoprime.com/" },
            { property: "og:description", content: "Start investing & earn profits using the LeoPrime Copy Trading App. It’s a great app that allows traders to follow & copy the strategies of Pro traders." },
            {
              property: "og:image",
              content:
                "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg",
            },
            { property: "og:site_name", content: "LeoPrime" },
            {
              name: "viewport",
              content: "width=device-width, maximum-scale=1",
            },
          ]}
        ></Helmet>
        <section className="copytrading-section d-flex flex-column justify-content-center">
          <div class="col-md-11 mx-auto py-3">
            <Row>
              <nav class="navbar navbar-light">
                <Link className="header__logo-link navbar-brand" to="/">
                  <div className="logo"></div>
                </Link>
              </nav>
              <a
                href={"https://www.leoprime.com/user"}
                className="btn btnIb-outline-white my-2 ml-auto"
              >
                Login
              </a>
            </Row>
          </div>
          <div className="w-100 d-flex align-items-center">
            <Col lg={11} className="mx-auto">
              <Row>
                <Col
                  lg={5}
                  className="d-flex flex-column justify-content-center"
                >
                  <div className="ct-banner-left">Copy</div>
                  <h5>Expert at Trading?</h5>

                  <h1>Join Copy Trading</h1>
                  <h3>Boost your income.</h3>
                  <div className="pb-5">
                    <p>
                      LeoPrime offers transparent, secured, flexible, and powerful Copy Trading. As a Master, you can build a network of followers by demonstrating your unique trading skills & attract investors to copy your strategies. Thus, you can boost your income & start earning commissions in a fast & secure way.
                    </p>
                  </div>
                  <div className="pb-5">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.leoprime.copytrade"
                      target="blank"
                    >
                      <img
                        src={GooglePlayImg}
                        alt="google play"
                        className="img-fluid mr-3"
                        width="120"
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/in/app/leoprime-copytrade/id1574483334"
                      target="blank"
                    >
                      <img
                        src={AppStoreImg}
                        alt="app store"
                        className="img-fluid"
                        width="120"
                      />
                    </a>
                  </div>
                </Col>
                <Col lg={7}>
                  <img
                    src={require("../assets/img/copy-trading/copy-trading-banner.png")}
                    alt="Copy Trading"
                    className="img-fluid mb-3"
                  />
                  <div className="ct-banner-right">Trading</div>
                </Col>
              </Row>
            </Col>
          </div>
        </section>
        <section className="d-flex ct-section">
          <div className="w-100 d-flex align-items-center">
            <Col
              lg={12}
              className="mx-auto mt-5 d-flex flex-column justify-content-center"
            >
              <Row>
                <Col lg={5} className="d-flex align-items-center">
                  <div className="ct-banner-left">Copy</div>
                  <img
                    src={require("../assets/img/copy-trading/copy-trading-mobile.gif")}
                    alt="IB Promotion"
                    className="img-fluid mb-3"
                  />
                </Col>
                <Col lg={7} className="d-flex flex-column justify-content-center">
                  <div className="py-3">
                    <h5>Turn your strategies into profits</h5>
                    <h3>Start earning now</h3>
                    {/* <h1>freedom</h1> */}
                  </div>
                  <div className="ct-banner-right">Trading</div>
                  <Row>
                    <Col lg={4}>
                      <h6>Become a Master</h6>
                      <p>
                        Plan, create and execute powerful trading strategies.
                      </p>
                    </Col>
                    <Col lg={4}>
                      <h6>Build your Network</h6>
                      <p>
                        Attract more investors with your unique trading skills.
                      </p>
                    </Col>
                    <Col lg={4}>
                      <h6>Earn Commissions</h6>
                      <p>
                        Start earning stable income by letting investors copy your trades.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </div>
        </section>
        <section className="investing-app-section">
          <div className="w-100 d-flex align-items-center">
            <Col
              lg={10}
              className="mx-auto mt-5 d-flex flex-column justify-content-center"
            >
              <div className="ct-banner-left">Copy</div>
              <Row>
                <Col lg={12} className="text-center">
                  <h5>Profit from becoming a </h5>
                  <h1>Copy Trader @ LeoPrime</h1>
                </Col>
              </Row>
              <Row>
                <Col lg={5} className="d-flex flex-column my-auto">
                  <div className="ct-banner-right">Trading</div>

                  <div className="row align-items-center">
                    <h3>1.	Broaden your trading experience </h3>
                  </div>
                  <div className="row align-items-center">
                    <h3>2.	Gain full control over the whole process</h3>
                  </div>
                  <div className="row align-items-center">
                    <h3>3.	Build a unique portfolio</h3>
                  </div>
                  <div className="row align-items-center">
                    <h3>4.	Secure payment methods</h3>
                  </div>
                  <div className="row align-items-center">
                    <h3>5.	Instant order execution</h3>
                  </div>
                  <div className="row align-items-center">
                    <h3>6.	Get started in a few simple steps</h3>
                  </div>
                </Col>
                <Col lg={{ span: 6, offset: 1 }} className="d-flex align-items-center">
                  <img
                    src={require("../assets/img/copy-trading/investing-app-img.png")}
                    alt="Copy Trading"
                    className="img-fluid mb-3 investing-app-middle-img"
                  />
                </Col>

              </Row>

              {/* <Row>
                <Col lg={3} className="d-flex flex-column my-auto">
                  <div className="row align-items-center">
                    <img
                      src={require("../assets/img/copy-trading/instant-withdrawal.gif")}
                      alt="Copy Trading"
                      className="img-fluid mb-3"
                    />
                    <h3 className="textCol">Instant deposits <br />
                      & withdrawals</h3>
                  </div>
                  <div className="row align-items-center">
                    <img
                      src={require("../assets/img/copy-trading/terminal.gif")}
                      alt="Copy Trading"
                      className="img-fluid mb-3"
                    />
                    <h3 className="textCol">A brand-new
                      <br />
                      terminal</h3>
                  </div>
                  <div className="row align-items-center">
                    <img
                      src={require("../assets/img/copy-trading/24x7.gif")}
                      alt="Copy Trading"
                      className="img-fluid mb-3"
                    />
                    <h3 className="textCol">Round-the-clock
                      <br />
                      24/7 support</h3>
                  </div>
                </Col>
                <Col lg={6} className="d-flex align-items-center">
                  <img
                    src={require("../assets/img/copy-trading/investing-app-img.png")}
                    alt="Copy Trading"
                    className="img-fluid mb-3 investing-app-middle-img"
                  />
                </Col>
                <Col lg={3} className="d-flex flex-column my-auto">
                  <div className="ct-banner-right">Trading</div>
                  <div className="row align-items-center">
                    <img
                      src={require("../assets/img/copy-trading/real-time-rates.gif")}
                      alt="Copy Trading"
                      className="img-fluid mb-3"
                    />
                    <h3>Real-time
                      <br />
                      rates </h3>
                  </div>
                  <div className="row align-items-center">
                    <img
                      src={require("../assets/img/copy-trading/registration.gif")}
                      alt="Copy Trading"
                      className="img-fluid mb-3"
                    />
                    <h3 className="textCol">Quick and easy

                      <br />
                      registration</h3>
                  </div>
                  <div className="row align-items-center">
                    <img
                      src={require("../assets/img/copy-trading/real-rates.gif")}
                      alt="Copy Trading"
                      className="img-fluid mb-3"
                    />
                    <h3 className="textCol">Real-time
                      <br />
                      rates </h3>
                  </div>
                </Col>
              </Row> */}
              {/* <Row>
                <Col lg={12} className="text-center pb-5">
                  <h5 className="text-white">What people say</h5>
                </Col>

                <Col lg={{ span: 3, offset: 1 }}>
                  <blockquote>With 200+ instruments, instant deposits and withdrawals, and on-demand support, you can invest with confidence when you are on the go with the Exness app.</blockquote>
                  <p>- ROBERT MORRIS</p>
                </Col>
                <Col lg={{ span: 3, offset: 1 }}>
                  <blockquote>Since I started using the Exness app, I am now in complete control of my orders and  accounts whether I’m sitting on my couch, out socialising with friends or on my way to work.</blockquote>
                  <p>- ERIC MING</p>
                </Col>
                <Col lg={{ span: 3, offset: 1 }}>
                  <blockquote>This great little Exness app makes accessing the markets, managing my accounts and withdrawing my money so easy!</blockquote>
                  <p>- ALEX</p>
                </Col>
              </Row> */}

            </Col>
          </div>
        </section>
        <section className="trust-app-section">
          <div className="w-100 d-flex align-items-center">
            <Col
              lg={12}
              className="mx-auto mt-5 d-flex flex-column justify-content-center"
            >
              <Row>
                <div className="ct-banner-left">Copy</div>
                <Col lg={12} className="text-center">
                  <h5>Experience all the benefits of Copy trading on one App </h5>
                  <h1>Download the Copy Trading App now </h1>

                  <img
                    src={require("../assets/img/copy-trading/trut-img.png")}
                    alt="Copy Trading"
                    className="img-fluid mb-3"
                  />

                </Col>
                <div className="ct-banner-right text-lg-right">Trading</div>
              </Row>
              <Row>
                <div className="pb-5 mx-auto">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.leoprime.copytrade"
                    target="blank"
                  >
                    <img
                      src={GooglePlayImg}
                      alt="google play"
                      className="img-fluid mr-3"
                      width="120"
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/in/app/leoprime-copytrade/id1574483334"
                    target="blank"
                  >
                    <img
                      src={AppStoreImg}
                      alt="app store"
                      className="img-fluid"
                      width="120"
                    />
                  </a>
                </div>
              </Row>
            </Col>
          </div>
        </section>
        <section class="section-padding footer-ct-Section pb-5">
          <div class="text-center">
            <ul className="list-inline sociallinks">
              <li className="list-inline-item">
                <a
                  target="blank"
                  href="https://www.facebook.com/LeoPrimeForexBrokers/"
                >
                  <FontAwesomeIcon icon={faFacebookF} className="fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a target="blank" href="https://twitter.com/LeoPrimeFx">
                  <FontAwesomeIcon icon={faXTwitter} className="fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a target="blank" href="https://www.instagram.com/leoprimefx/">
                  <FontAwesomeIcon icon={faInstagram} className="fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  target="blank"
                  href="https://www.linkedin.com/company/leo-prime"
                >
                  {" "}
                  <FontAwesomeIcon icon={faLinkedinIn} className="fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  target="blank"
                  href="https://in.pinterest.com/LeoPrimeFx/pins/"
                >
                  <FontAwesomeIcon icon={faPinterestP} className="fa-lg" />
                </a>
              </li>
            </ul>
            <p>
              Leo Inc,
              <br />
              G.S.Complex, 1st floor,
              <br />
              Office Number 12, Providence, <br />
              Mahe, Seychelles.
            </p>
            <p>support@leoprime.com</p>
            <p>+44 20380 85868</p>
          </div>
          <div class="container text-center mt-3">
            <p>
              Leo Inc is a Securities Dealer license
              registered in Seychelles with registration number 8424165-1 and
              authorised by the Financial Services Authority (FSA) with licence
              number SD032. The registered office of Leo Inc
              is at G.S.Complex, 1st floor, Office Number 12, Providence, Mahe,
              Seychelles
            </p>
            {/* <p>
              Leo Inc registered in the Marshall Islands
              (registration number 95149) and regulated in accordance with
              Marshall Islands Business Corporation Act. The Company’s address:
              Ajeltake Road, Ajeltake Island, Majuro, Marshall Islands MH96960.
            </p>
            <p>
              Risk Warning: FX and CFDs, which are leveraged products, incur a
              high level of risk and can result in the loss of all your invested
              capital. Therefore, FX and CFDs may not be suitable for all
              investors. You should not risk more than you are prepared to lose.
              Before deciding to trade, please ensure you understand the risks
              involved and take into account your level of experience. Seek
              independent advice if necessary.
            </p> */}
            <p>
              Leo Inc, does not offer its services to the
              residents of certain jurisdictions such as: USA, Belgium, North
              Korea, France, Australia, Israel, and Japan.
            </p>
            <p>© 2022 Leo Prime Services Ltd. - All Rights Reserved</p>
          </div>
        </section>
      </div>
    );
  }
}
export default CopyTrading;
