import React, { Component } from 'react';
import { Row, Col, } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import Star from '../assets/img/icons/awards-star.svg';

class Awards extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="Leoprime - Awards"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "Awards" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Awards" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Awards" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading Awards" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "Awards" },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>

                <section className="bannerBlock  d-flex awards-banner-sec">
                    <div className="awards-overlay"></div>
                    <div className="w-100 d-flex align-items-center mt-5 pt-5">
                        <Col lg={10} className="mx-auto">
                            <Row>
                                <div className="caption text-center col-8 mx-auto">
                                    <h1 className="text-uppercase mb-4 title2">award winning   <span className="d-block">multi-asset broker</span>
                                    </h1>
                                    <p>We're proud to be recognised by a number of key awards bodies around the world for our work in delivering the best customer service, value, pricing, products and support for our clients.</p>
                                    <img src={require('../assets/img/awards/awards.png')} alt="lp bonus" className="img-fluid mt-5" />
                                </div>
                            </Row>
                        </Col>
                    </div>
                </section>
                <section className="awards-section">
                    <div className="leftTxt d-lg-block d-none">Leoprime</div>
                    <img src={require('../assets/img/awards/awards-right.png')} alt="awards" className="img-fluid mt-5 rightImg d-lg-block d-none" />
                    <div class="container"><div class="py-5"><h4 class="h3"><strong class="d-block">Our Awards &amp; Accolades</strong></h4></div>
                        <div class="mb-5 col-lg-12">
                            <div className=" award-head mb-5">
                                <h5>2021
                                    <img src={Star} alt="awards" className="float-right" />
                                </h5>
                            </div>
                            <div className="row">
                                <div className="col-md-3 text-center">
                                    <img src={require('../assets/img/awards/best-transparent-broker-2021.png')} alt="awards - best-transparent-broker-2021" className="img-fluid" />
                                </div>
                                <div className="col-md-9">
                                    <h4>Best Transparent Broker 2021</h4>
                                    <p>We are glad to announce to you all that Leo Inc we have been privileged as Best Transparent Broker for the year 2021 by the FX Daily Info. This is possible by the support of our valuable clients and the dedication of our workforce. We would like to thank everyone who has contributed to our success. We strive to give the best to our clients. </p>
                                    <a target="blank" href={('https://fxdailyinfo.com/forex-brokers-award/results/2021')} className="primary-button-lg my-2" >More..</a>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-3 text-center">
                                    <img src={require('../assets/img/awards/international-investor-awards-2021.png')} alt="awards" className="img-fluid" />
                                </div>
                                <div className="col-md-9">
                                    <h4>Best Multi Asset Broker 2021</h4>
                                    <p>We are honored to announce to you all that Leo Inc is awarded as Best Multi-Asset Broker for the year 2021 by the International Investor(https://www.intinvestor.com/awards). At this auspicious time, we thank our valued clients and our super talented team for supporting us in crossing all the hurdles in our way to achieve this success. We expect your greatest support for us in the future to succeed in all the paths. </p>
                                    <a target="blank" href={('https://www.intinvestor.com/awards')} className="primary-button-lg my-2" >More..</a>
                                </div>
                            </div>
                        </div>
                        <div class="mb-5 col-lg-12">
                            <div className=" award-head mb-5">
                                <h5>2019
                                    <img src={Star} alt="awards" className="float-right" />
                                </h5>
                            </div>
                            <div className="row">
                                <div className="col-md-3 text-center">
                                    <img src={require('../assets/img/awards/awards-2019.png')} alt="awards" className="img-fluid" />
                                </div>
                                <div className="col-md-9">
                                    <h4>Best Execution Broker 2019</h4>
                                    <p>At LeoPrime, we truly believe in success start with putting our customers first and always. We feel proud
                                        for receiving &quot;The Best Execution broker of 2019&quot; award by Forex Awards. We take this golden moment
                                        for thanking each and every one of you for supporting and making this possible.</p>
                                    <a target="blank" href={('https://www.forex-awards.com/forex-awards-winners/?id=25058')} className="primary-button-lg my-2" >More..</a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="rightTxt d-lg-block d-none">Awards</div>
                    <img src={require('../assets/img/awards/awards-right.png')} alt="awards" className="img-fluid leftImg d-lg-block d-none" />
                </section>
                <section class="section-padding pb-5">
                    <div className="container">
                        <Row>
                            <div className="col-md-6 d-flex align-items-center">
                                <Col className="text-center">
                                    <h2>AWARD-WINNING FOREX
                                        TRADING SERVICES</h2>
                                </Col>
                            </div>
                            <div className="col-md-6">
                                <img src={require('../assets/img/awards/awards-2.png')} alt="awards" className="img-fluid mb-5" />
                            </div>
                        </Row>
                    </div>
                </section>


            </div>
        );
    }
}
export default Awards;