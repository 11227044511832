import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardDeck } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
import { Helmet } from "react-helmet";

export default class AccountTypes extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="Leading Forex Broker | Forex with LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "forex,broker,currency,trading" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Leading Forex Broker | Forex with LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Leading Forex Broker | Forex with LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "Whether you're new to currency trading or already a seasoned forex trader, we’ve got something for you." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay account-types-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase ">Account</h1>
                            <h1 className="display-3 text-shadow text-uppercase"><span>Types</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <Row className="justify-content-md-center">
                            <Col xs={12} md={12} lg={6} className="mb-3 d-flex flex-column justify-content-center mt-5">
                                <img src={require('../assets/img/account-types/account-type-left.png')} alt="Account Types" className="img-fluid" />
                                <div className="col text-center mt-5">
                                    <a href={('https://www.leoprime.com/register')} className="primary-button-lg my-2" >Open Live Account</a>
                                    <p>
                                        or try free <a href={('https://www.leoprime.com/register')}> <u>Demo Account</u></a>
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={6} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center pl-lg-5">
                                    <h2 className="mb-4">
                                        Your Trading Journey
                                        <span className="d-block"> Starts Here</span>
                                    </h2>
                                    <p>To begin forex trading with LeoPrime, simply choose and open one of our three flagship accounts shown below and you could be placing your first forex trade within minutes. It’s as simple as that.

                                    </p>
                                    <ul className="liststyle">
                                        <li>Our application process is simple and secure</li>
                                        <li>You can apply for an online account at any time by filling in our online application form for the account you are interested in</li>
                                        <li>Once we’ve successfully completed the verification process, we’ll send you an email to confirm that your account is open</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="hmeOurBenefit pt-0">
                    <Col lg={11} className="mx-auto">
                        <CardDeck className="mb-5">
                            <Card>
                                <Card.Body>
                                    <Card.Title className="text-center my-4">Classic</Card.Title>
                                    <table className="table table-borderless account_types_table">
                                        <tbody>
                                            <tr>
                                                <td>Trading Platform/s</td>
                                                <td>MT5 - MT4 Desktop/ MT4 mobile/ PAMM</td>
                                            </tr>
                                            <tr>
                                                <td>Minimum Deposit (USD)</td>
                                                <td>$10</td>
                                            </tr>
                                            <tr>
                                                <td>Spread from (pips)</td>
                                                <td>1.6* pip</td>
                                            </tr>
                                            <tr>
                                                <td>Leverage</td>
                                                <td>Upto 1:1000</td>
                                            </tr>
                                            <tr>
                                                <td>Commission (per STD Lot)</td>
                                                <td>None</td>
                                            </tr>
                                            <tr>
                                                <td>Minimum Order Volume</td>
                                                <td>0.01</td>
                                            </tr>
                                            <tr>
                                                <td>Maximum Order Volume</td>
                                                <td>100</td>
                                            </tr>
                                            <tr>
                                                <td>Margin Call/Stop Out Level</td>
                                                <td>50%/30%</td>
                                            </tr>
                                            <tr>
                                                <td>Swap-free Islamic account option</td>
                                                <td>YES</td>
                                            </tr>
                                            <tr>
                                                <td>Trading Symbols</td>
                                                <td>35 Currency Pairs, Gold , Silver, Crude &amp; Indices</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Card.Title className="text-center my-4">Pro</Card.Title>
                                    <table className="table table-borderless account_types_table">
                                        <tbody>
                                            <tr>
                                                <td>Trading Platform/s</td>
                                                <td>MT5 - MT4 Desktop/ MT4 mobile/ PAMM</td>
                                            </tr>
                                            <tr>
                                                <td>Minimum Deposit (USD)</td>
                                                <td>$1,000</td>
                                            </tr>
                                            <tr>
                                                <td>Spread from (pips)</td>
                                                <td>0.3* pip</td>
                                            </tr>
                                            <tr>
                                                <td>Leverage</td>
                                                <td>Upto 1:500</td>
                                            </tr>
                                            <tr>
                                                <td>Commission (per STD Lot)</td>
                                                <td>$3</td>
                                            </tr>
                                            <tr>
                                                <td>Minimum Order Volume</td>
                                                <td>0.01</td>
                                            </tr>
                                            <tr>
                                                <td>Maximum Order Volume</td>
                                                <td>100</td>
                                            </tr>
                                            <tr>
                                                <td>Margin Call/Stop Out Level</td>
                                                <td>50%/30%</td>
                                            </tr>
                                            <tr>
                                                <td>Swap-free Islamic account option</td>
                                                <td>NO</td>
                                            </tr>
                                            <tr>
                                                <td>Trading Symbols</td>
                                                <td>35 Currency Pairs, Gold , Silver, Crude &amp; Indices</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Card.Title className="text-center my-4">ECN</Card.Title>
                                    <table className="table table-borderless account_types_table">
                                        <tbody>
                                            <tr>
                                                <td>Trading Platform/s</td>
                                                <td>MT5 - MT4 Desktop/ MT4 mobile/ PAMM</td>
                                            </tr>
                                            <tr>
                                                <td>Minimum Deposit (USD)</td>
                                                <td>$2,000</td>
                                            </tr>
                                            <tr>
                                                <td>Spread from (pips)</td>
                                                <td>0.1* pip</td>
                                            </tr>
                                            <tr>
                                                <td>Leverage</td>
                                                <td>Upto 1:200</td>
                                            </tr>
                                            <tr>
                                                <td>Commission (per STD Lot)</td>
                                                <td>$6</td>
                                            </tr>
                                            <tr>
                                                <td>Minimum Order Volume</td>
                                                <td>0.01</td>
                                            </tr>
                                            <tr>
                                                <td>Maximum Order Volume</td>
                                                <td>100</td>
                                            </tr>
                                            <tr>
                                                <td>Margin Call/Stop Out Level</td>
                                                <td>50%/30%</td>
                                            </tr>
                                            <tr>
                                                <td>Swap-free Islamic account option</td>
                                                <td>YES</td>
                                            </tr>
                                            <tr>
                                                <td>Trading Symbols</td>
                                                <td>35 Currency Pairs, Gold , Silver, Crude &amp; Indices</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Card.Title className="text-center my-4">Cent</Card.Title>
                                    <table className="table table-borderless account_types_table">
                                        <tbody>
                                            <tr>
                                                <td>Trading Platform/s</td>
                                                <td>MT5 - MT4 Desktop/ MT4 Mobile</td>
                                            </tr>
                                            <tr>
                                                <td>Minimum Deposit (USD)</td>
                                                <td>$10</td>
                                            </tr>
                                            <tr>
                                                <td>Spread from (pips)</td>
                                                <td>1.6* Pips</td>
                                            </tr>
                                            <tr>
                                                <td>Leverage</td>
                                                <td>Upto 1:1000</td>
                                            </tr>
                                            <tr>
                                                <td>Commission (per STD Lot)</td>
                                                <td>None</td>
                                            </tr>
                                            <tr>
                                                <td>Minimum Order Volume</td>
                                                <td>0.01</td>
                                            </tr>
                                            <tr>
                                                <td>Maximum Order Volume</td>
                                                <td>100</td>
                                            </tr>
                                            <tr>
                                                <td>Margin Call/Stop Out Level</td>
                                                <td>50%/30%</td>
                                            </tr>
                                            <tr>
                                                <td>Swap-free Islamic account option</td>
                                                <td>NO</td>
                                            </tr>
                                            <tr>
                                                <td>Trading Symbols</td>
                                                <td> 35 Currency Pairs</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Card.Body>
                            </Card>
                        </CardDeck>
                        <p className="text-center">
                            Note:- Since the MT4 server doesn't always count the gap level right/ correct when activating stop and stop loss orders during strong market movements <span className="d-block">(For example : Release in Major Economic News and Market rollover time), news trading with stop orders is not supported for this account type.</span>
                        </p>

                        <p className="text-center">Leverage is applied as mentioned in the Margin and Leverage rules page. <Link to="/margin-leverage-rules">(https://www.leoprime.com/margin-leverage-rules)</Link></p>
                    </Col>
                </section>
                <ReadyToTrade />
            </div>
        );
    }
}
