import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Table, Card } from 'react-bootstrap';
// import ReadyToTrade from '../components/ReadyToTrade'
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faInstagram, faFacebookF, faPinterestP, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import fiftybonuspdf from '../documents/bonus/50bonus.pdf';
import bonus501 from '../assets/img/bonus/50-bonus-new-icon-1.svg';
import bonus502 from '../assets/img/bonus/50-bonus-new-icon-2.svg';
import bonus503 from '../assets/img/bonus/50-bonus-new-icon-3.svg';
import bonusClaim from '../assets/img/bonus/claim-up-img.svg';

class FiftyBonusNew extends Component {

  render() {

    return (
      <div>
        <Helmet
          title="Leoprime - Guaranteed 50% Bonus on Every Deposit"
          meta={[
            { name: 'author', content: "LeoPrime" },
            { name: 'keywords', content: "Bonus" },
            { name: 'twitter:site', content: "LeoPrime" },
            { name: 'twitter:creator', content: "LeoPrime" },
            { name: 'twitter:title', content: "Leoprime - Guaranteed 50% Bonus on Every Deposit" },
            { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
            { property: 'og:title', content: "LeoPrime - Guaranteed 50% Bonus on Every Deposit" },
            { property: 'og:site_name', content: "LeoPrime" },
            { property: 'og:type', content: "Forex Trading" },
            { property: 'og:url', content: "https://leoprime.com/" },
            { property: 'og:description', content: "LeoPrime - Guaranteed 50% Bonus on Every Deposit" },
            { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
            { property: 'og:site_name', content: "LeoPrime" },
            { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
          ]}
        >
          <script type="text/javascript">
            {
              `
                        var xhttp = new XMLHttpRequest();

function GetURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam) {
            return sParameterName[1];
        }
    }
    return 0;
}
if (localStorage.getItem("cpid") === null) {
    localStorage.setItem("cpid", GetURLParameter('cpid'));
}

if (localStorage.getItem("referral") === null) {
    localStorage.setItem("referral", GetURLParameter('referral'));

    xhttp.open("POST", "https://cc.leoprime.com/api/v1/referral", true);
    xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhttp.send("referral=" + GetURLParameter('referral'));

}

if (localStorage.getItem("cpid") == 0) {
    localStorage.setItem("cpid", GetURLParameter('cpid'));
}

if (localStorage.getItem("referral") == 0) {
    localStorage.setItem("referral", GetURLParameter('referral'));
}
                        `
            }
          </script>
        </Helmet>
        <section className="fiftybonus-new-section d-flex flex-column justify-content-center mb-5">
          <div class="col-md-11 mx-auto py-3">
            <Row>
              <nav class="navbar navbar-light">
                <Link className="header__logo-link navbar-brand" to="/">
                  <div className="logo"></div>
                </Link>
              </nav>
              <a
                href={"https://www.leoprime.com/user"}
                className="btn btnIb-outline-white my-2 ml-auto"
              >
                Login
              </a>
            </Row>
          </div>
          <div className="w-100 d-flex align-items-center">
            <Col lg={11} className="mx-auto">
              <Row>
                <Col
                  lg={5}
                  className="d-flex flex-column justify-content-center text-center text-lg-left"
                >
                  <h1>Guaranteed</h1>
                  <h5>50%</h5>
                  <h3>Bonus on Every Deposit</h3>
                </Col>
                <Col lg={7}>
                  <img
                    src={require("../assets/img/bonus/50-bonus-new-banner-img.png")}
                    alt="50 bonus"
                    className="img-fluid mb-3"
                  />
                </Col>
              </Row>
            </Col>
          </div>
        </section>


        <section className="mob-invest-app">
          <div className="w-100 d-flex align-items-center">
            <Col
              lg={8}
              className="mx-auto my-5 py-5 d-flex flex-column justify-content-center text-center"
            >

              <Row>
                <Col lg={4}>
                  <img src={bonus501}
                    alt="Mobile App" className="img-fluid mb-3" />
                  <h6>
                    Power to your Trading Account
                  </h6>
                  <p>Increase your Free Margin and add more power to your trading account with our 50% Bonus program</p>
                </Col>
                <Col lg={4}>
                  <img src={bonus502}
                    alt="Mobile App" className="img-fluid mb-3" />
                  <h6>
                    Withdrawable Bonus
                  </h6>
                  <p>Trade and complete the required trading lots to withdraw your bonus claimed. </p>
                </Col>
                <Col lg={4}>
                  <img src={bonus503}
                    alt="Mobile App" className="img-fluid mb-3" />
                  <h6>
                    Automated Withdrawals
                  </h6>
                  <p>Get your deposits and withdrawal instantly processed through our automated payment processing system. </p>
                </Col>




              </Row>
            </Col>
          </div>
        </section>


        <section className="claim-up-section">
          <div className="w-100 d-flex align-items-center">
            <Col
              lg={11}
              className="mx-auto py-5 d-flex flex-column justify-content-center"
            >
              <Row>
                <div className="mx-auto my-5 text-center">
                  <h5>Claim Up to USD 2000</h5>
                  <p>Incredible offer and benefit with World-Class trading conditions</p>
                </div>

              </Row>
              <Row>
                <Col lg={6} className=" d-flex align-items-center">
                  <img src={bonusClaim}
                    alt="Mobile App" className="img-fluid mb-3 handgif" />
                </Col>
                <Col lg={{ span: 5, offset: 1 }} className="d-flex align-items-center">
                  <ul className="liststyle-bulet">
                    <li>Entirely Withdrawable Bonus Amount. *</li>
                    <li>Bonus Amount to Support Margin*</li>
                    <li>Automated Deposits and Withdrawals*</li>
                    <li>Bonuses on deposits as low as USD 200*</li>
                  </ul>
                </Col>

              </Row>

              <Row>
                <div className="mx-auto mt-5 text-center">
                  <p>valid until</p>
                  <h5>Further Notice</h5>
                  <div class="form-group"><a href="https://www.leoprime.com/register" class="primary-button-lg my-2">Get My Bonus</a></div>
                  <p>*Scroll down to the bottom of this page to see the Complete T&C of this promotion.</p>
                </div>

              </Row>
            </Col>
          </div>
        </section>


        <section className="mob-invest-app">
          <div className="w-100 d-flex align-items-center">
            <Col
              lg={10}
              className="mx-auto my-5 py-5 d-flex flex-column justify-content-center text-center"
            >
              <Row>
                <div className="mx-auto my-5 text-center">
                  <h5>How to Claim Bonus ?</h5>
                </div>
              </Row>
              <Row className="card-deck">
                <Col lg={3} className="card p-0">
                  <div className="fiftybonus-new-step">
                    <img src={require("../assets/img/bonus/50-bonus-new-step-icon-1.png")}
                      alt="" className="img-fluid" />
                    <div className="step-arrow-1"></div>
                    <h3>
                      Step
                    </h3>
                    <p>Register and then log in to your Personal Area Cabinet.</p>
                  </div>
                </Col>
                <Col lg={3} className="card p-0">
                  <div className="fiftybonus-new-step">
                    <img src={require("../assets/img/bonus/50-bonus-new-step-icon-2.png")}
                      alt="" className="img-fluid" />
                    <div className="step-arrow-2"></div>
                    <h3>
                      Step
                    </h3>
                    <p>In the “My Profile” tab, open the “Edit Profile” section and fill all the required fields. Then click on the “Upload Document” section, upload a document showing your proof of address, and a second document that confirms your identity.</p>
                  </div>
                </Col>
                <Col lg={3} className="card p-0">
                  <div className="fiftybonus-new-step">
                    <img src={require("../assets/img/bonus/50-bonus-new-step-icon-3.png")}
                      alt="" className="img-fluid" />
                    <div className="step-arrow-3"></div>
                    <h3>
                      Step
                    </h3>
                    <p>If the account is verified, and you have made your deposit of at least USD 200 to your Trading account, then please reach us via live chat or send an email to support@leoprime.com within 24 hours to claim the bonus.</p>
                  </div>
                </Col>
                <Col lg={3} className="card p-0">
                  <div className="fiftybonus-new-step">
                    <img src={require("../assets/img/bonus/50-bonus-new-step-icon-4.png")}
                      alt="" className="img-fluid" />
                    <div className="step-arrow-4"></div>
                    <h3>
                      Step
                    </h3>
                    <p>Now start trading and make the most of the bonus received. You can withdraw the bonus amount when you have traded the following number of lots</p>
                  </div>
                </Col>

              </Row>
            </Col>
          </div>
        </section>

        <section className="py-0">
          <Col lg={10} className="mx-auto">
            <Row className="justify-content-md-center">
              <Col xs={12} md={12} lg={6} className="mb-3 d-flex align-items-center">
                <div className="d-flex flex-column justify-content-center">
                  <img src={require('../assets/img/calculation.png')} alt="lp bonus" className="img-fluid mt-5" />
                </div>
              </Col>
              <Col xs={12} md={12} lg={6} className="mb-3">
                <Card className="mt-5 boxShadow">
                  <Card.Body>
                    <div className="table-responsive">
                      <Table borderless hover>
                        <tbody>
                          <tr>
                            <td>Deposit amount</td>
                            <td>$200</td>
                          </tr>
                          <tr>
                            <td>50% bonus received</td>
                            <td>$100</td>
                          </tr>
                          <tr>
                            <td>Divide your bonus by 2</td>
                            <td>$100/2</td>
                          </tr>
                          <tr className="text-info">
                            <td>Number of lots to trade </td>
                            <td>50 lots</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="text-center my-5">
                <a href={('https://www.leoprime.com/register')} className="primary-button-lg my-2" >Get My Bonus</a>
                <p>
                  <a href={fiftybonuspdf} target="blank" className="text-dark">  <u>*Terms &amp; Conditions</u></a>
                </p>
              </Col>
            </Row>
          </Col>
        </section>

        <section class="section-padding footer-ct-Section pb-5">
          <div class="text-center">
            <ul className="list-inline sociallinks">
              <li className="list-inline-item">
                <a
                  target="blank"
                  href="https://www.facebook.com/LeoPrimeForexBrokers/"
                >
                  <FontAwesomeIcon icon={faFacebookF} className="fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a target="blank" href="https://twitter.com/LeoPrimeFx">
                  <FontAwesomeIcon icon={faXTwitter} className="fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a target="blank" href="https://www.instagram.com/leoprimefx/">
                  <FontAwesomeIcon icon={faInstagram} className="fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  target="blank"
                  href="https://www.linkedin.com/company/leo-prime"
                >
                  {" "}
                  <FontAwesomeIcon icon={faLinkedinIn} className="fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  target="blank"
                  href="https://in.pinterest.com/LeoPrimeFx/pins/"
                >
                  <FontAwesomeIcon icon={faPinterestP} className="fa-lg" />
                </a>
              </li>
            </ul>
            <p>
              Leo Inc,
              <br />
              G.S.Complex, 1st floor,
              <br />
              Office Number 12, Providence, <br />
              Mahe, Seychelles.
            </p>
            <p>support@leoprime.com</p>
            <p>+44 20380 85868</p>
          </div>
          <div class="container text-center mt-3">
            <p>
              Leo Inc is a Securities Dealer license
              registered in Seychelles with registration number 8424165-1 and
              authorised by the Financial Services Authority (FSA) with licence
              number SD032. The registered office of Leo Inc
              is at G.S.Complex, 1st floor, Office Number 12, Providence, Mahe,
              Seychelles
            </p>
            {/* <p>
              Leo Inc registered in the Marshall Islands
              (registration number 95149) and regulated in accordance with
              Marshall Islands Business Corporation Act. The Company’s address:
              Ajeltake Road, Ajeltake Island, Majuro, Marshall Islands MH96960.
            </p>
            <p>
              Risk Warning: FX and CFDs, which are leveraged products, incur a
              high level of risk and can result in the loss of all your invested
              capital. Therefore, FX and CFDs may not be suitable for all
              investors. You should not risk more than you are prepared to lose.
              Before deciding to trade, please ensure you understand the risks
              involved and take into account your level of experience. Seek
              independent advice if necessary.
            </p> */}
            <p>
              Leo Inc, does not offer its services to the
              residents of certain jurisdictions such as: USA, Belgium, North
              Korea, France, Australia, Israel, and Japan.
            </p>
            <p>© 2022 Leo Prime Services Ltd. - All Rights Reserved</p>
          </div>
        </section>
      </div>
    );
  }
}
export default FiftyBonusNew;
