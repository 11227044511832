import React, { Component } from 'react'
// import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
// import DownloadLink from "react-download-link";
import { Helmet } from "react-helmet";

import BMT1 from '../assets/img/icons/benefit-metatrade-1.svg';
import BMT2 from '../assets/img/icons/benefit-metatrade-2.svg';
import BMT3 from '../assets/img/icons/benefit-metatrade-3.svg';
import BMT4 from '../assets/img/icons/benefit-metatrade-4.svg';
import BMT5 from '../assets/img/icons/benefit-metatrade-5.svg';
import BMT6 from '../assets/img/icons/benefit-metatrade-6.svg';



export default class TradingPlatformMt5 extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="MetaTrader 5 Forex trading platform|Free Download|LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "mt5,demo account,register,retail,forex,broker,open a trading account, trading account, forex broker" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "MetaTrader 5 Forex trading platform|Free Download|LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "MetaTrader 5 Forex trading platform|Free Download|LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "Register with LeoPrime and start downloading the MT5 trading platform which is customized to suit all your devices for free." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay  trading-platform-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase ">Leading Forex</h1>
                            <h1 className="display-3 text-shadow text-uppercase"><span>Trading Platform</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <Row className="justify-content-md-center">
                            <Col xs={12} md={12} lg={3} className="mb-3">
                                {/* <div className="d-flex flex-column justify-content-center">
                                    <div className="iphone-x">
                                        <i></i>
                                        <b></b>
                                        <s>10:24</s>
                                    </div>
                                </div> */}
                                <img src={require('../assets/img/trading-platform/trading-platform-img-2.jpg')} alt="Metatrader 4" className="img-fluid" />
                            </Col>
                            <Col xs={12} md={12} lg={9} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center">
                                    <h2>
                                        Metatrader 5 Platform
                                        {/* <span className="d-block"> </span> */}
                                    </h2>
                                    <p>LeoPrime offers forex trading on both MetaTrader 4 (MT4) and MetaTrader 5 (MT5), two of the industry's leading trading platforms
                                    </p>
                                    <p>Both provide industry's best-automated trading systems with expert advisors for professional trading.MT5's advanced charting tools, sophisticated order types and execution modes offer greater control over trade positions. MT5 has started to become an integral part of the modern trader's journey with its highly customisable charts and powerful technical indicators to automated trading functions.MT5 has 21-time frames which include 11 types of minute charts, 7 types of hourly charts and daily, weekly & yearly time frames, which allows room for more analysis of the market</p>
                                    <Row>
                                        <Col lg>
                                            <ul className="liststyle">
                                                <li>Round-the-clock access to the market</li>
                                                <li>Real-time symbol quotes</li>
                                                <li>80+ technical indicators and graphical objects</li>
                                                <li>4 order execution modes and 6 pending orders</li>
                                                <li>Market Depth</li>
                                                <li className="mb-2">Advanced charting tools</li>
                                            </ul>
                                        </Col>
                                        <Col lg>
                                            <ul className="liststyle">
                                                <li>Multi-threaded strategy tester</li>
                                                <li>Multiple charts in 21 timeframes</li>
                                                <li>Netting allowed</li>
                                                <li>Push notifications</li>
                                                <li>Embedded economic calendar</li>
                                                <li>Access to MQL5 community</li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="bg-gray hmeOurBenefit">
                    <Col lg={10} className="mx-auto">
                        <Row>
                            <Col xs={12} md={4} lg={4} className="d-flex align-items-center">
                                <div>
                                    <h2>
                                        Benefits of
                                        <span className="d-block">Metatrader 5</span>
                                    </h2>
                                    <p>MetaTrader 5 offers leading trading and analytical technologies, as well as additional services and is the superior version of its popular predecessor, MT4.</p>
                                </div>
                            </Col>
                            <Col xs={12} md={8} lg={{ size: 7, offset: 1 }}>
                                <Row className="my-3">
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-5">
                                        <img src={BMT1} alt="Metatrader 4" />
                                        <p>Extremely user-friendly interface</p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-5">
                                        <img src={BMT2} alt="Metatrader 4" />
                                        <p>Advanced charting  <span className="d-block">capabilities</span></p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-5">
                                        <img src={BMT3} alt="Metatrader 4" />
                                        <p>More advanced communication tools</p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-5">
                                        <img src={BMT4} alt="Metatrader 4" />
                                        <p>Highly secure <span className="d-block">platform</span></p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-5">
                                        <img src={BMT5} alt="Metatrader 4" />
                                        <p>Supports all currency <span className="d-block">crosses</span></p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-5">
                                        <img src={BMT6} alt="Metatrader 4" />
                                        <p>Customization</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </section>
                <section className="hmeOurBenefit">
                    <Col lg={6} className="mx-auto">
                        <Row>
                            <Col xs={12} md={12} lg={12} className="text-center">
                                <h2 className="mb-5">
                                    Download Metatrader 5
                                    <span className="d-block">Trading Platform</span>
                                </h2>
                                <img src={require('../assets/img/home/mt4-mt5-image.png')} alt="MT4 and MT5" className="img-fluid" />
                                <Row>
                                    <Col xs={12} lg>
                                        <div className='animated-arrow'>
                                            <span className='the-arrow -left'>
                                                <span className='shaft'></span>
                                            </span>
                                            <span className='main'>
                                                <span className='text'>
                                                    {/* <DownloadLink
                                                        label="For Windows"
                                                        filename="https://download.mql5.com/cdn/web/leo.prime.services/mt5/leoprime5setup.exe"
                                                        exportFile={() => "My cached data"}
                                                    /> */}
                                                    <a href="#" download>For Windows</a>
                                                </span>
                                                <span className='the-arrow -right'>
                                                    <span className='shaft'></span>
                                                </span>
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={12} lg>
                                        <div className='animated-arrow'>
                                            <span className='the-arrow -left'>
                                                <span className='shaft'></span>
                                            </span>
                                            <span className='main'>
                                                <span className='text'>
                                                    {/* <DownloadLink
                                                        label="For Mac"
                                                        filename="https://download.mql5.com/cdn/web/leo.prime.services/mt5/leoprime5setup.exe"
                                                        exportFile={() => "My cached data"}
                                                    /> */}
                                                    <a href="#" download>For Mac</a>
                                                </span>
                                                <span className='the-arrow -right'>
                                                    <span className='shaft'></span>
                                                </span>
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={12} lg>
                                        <div className='animated-arrow'>
                                            <span className='the-arrow -left'>
                                                <span className='shaft'></span>
                                            </span>
                                            <span className='main'>
                                                <span className='text'>
                                                    {/* <DownloadLink
                                                        label="For Android"
                                                        filename="https://download.mql5.com/cdn/web/leo.prime.services/mt5/leoprime5setup.exe"
                                                        exportFile={() => "My cached data"}
                                                    /> */}
                                                    <a href="#" download>For Android</a>
                                                </span>
                                                <span className='the-arrow -right'>
                                                    <span className='shaft'></span>
                                                </span>
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={12} lg>
                                        <div className='animated-arrow'>
                                            <span className='the-arrow -left'>
                                                <span className='shaft'></span>
                                            </span>
                                            <span className='main'>
                                                <span className='text'>
                                                    {/* <DownloadLink
                                                        label="For ios"
                                                        filename="https://download.mql5.com/cdn/web/leo.prime.services/mt5/leoprime5setup.exe"
                                                        exportFile={() => "My cached data"}
                                                    /> */}
                                                    <a href="#" download>For IOS</a>
                                                </span>
                                                <span className='the-arrow -right'>
                                                    <span className='shaft'></span>
                                                </span>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </section>
                <ReadyToTrade />
            </div>
        );
    }
}
