import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Table } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
import TradingInstrumentTabs from '../components/TradingInstrumentTabs'
import { Helmet } from "react-helmet";
export default class TradingInstrumentEnergies extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="Energies Traders | Online Forex Brokers | Energies Trading Platform."
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "retail,forex,broker,LeoPrime,trading, forex trading, online" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Energies Traders | Online Forex Brokers | Energies Trading Platform." },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Energies Traders | Online Forex Brokers | Energies Trading Platform." },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "LeoPrime is an Next-Generation Multi Asset Broker who aims to offer top-notch services that are extensively in need for Forex traders for an experience easier." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay trading-instrument-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase ">Trading</h1>
                            <h1 className="display-3 text-shadow text-uppercase"><span>Instrument</span></h1>
                        </div>
                    </div>
                </div>
                <TradingInstrumentTabs />
                <section className="pt-0">
                    <div className="container">
                        <Row className="justify-content-md-center">
                            <Col xs={12} md={12} lg={4} className="mb-3 d-flex flex-column justify-content-center mt-5">
                                <img src={require('../assets/img/account-types/account-type-left.png')} alt="Account Types" className="img-fluid" />
                            </Col>
                            <Col xs={12} md={12} lg={8} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center pl-lg-5">
                                    <h2 className="mb-4">
                                        Energies Contract Specification
                                                                        </h2>
                                    <p>LeoPrime allows trading of Spot energy contracts including Crude oil, Brent & Natural Gas from your MetaTrader 4 platforms against the US Dollar. Trading Energy contracts as a spot instrument have many advantages for traders who are only interested in price speculation. The Spot price is derived as a combination of the first and second nearby month future contract. This pricing method diminishes the level of volatility.
</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="hmeOurBenefit pt-0">
                    <div className="container">
                        <Col xs={12} lg className="mb-3 trading_table" id="tabs">
                            <Tabs defaultActiveKey="forex" transition={false} className="nav-fill">
                                <Tab eventKey="forex" title="Classic">
                                    <Table borderless responsive hover>
                                        <thead>
                                            <tr className="row m-0 bg-gray">
                                                <th className="d-inline-block col-lg-1">Symbol</th>
                                                <th className="d-inline-block col-lg-3 text-left">Description</th>
                                                <th className="d-inline-block col-lg-1">Average Spread*</th>
                                                <th className="d-inline-block col-lg-3">Margin Requirements
                                                <br />
                                                    <div className="row">
                                                        <div className="col">1:100</div>
                                                        <div className="col">1:500 </div>
                                                        <div className="col">1:1000	</div>
                                                    </div>
                                                </th>
                                                <th className="d-inline-block col-lg-2">Swap
                                                <br />
                                                    <div className="row">
                                                        <div className="col">Long</div>
                                                        <div className="col">Short </div>
                                                    </div>
                                                </th>
                                                <th className="d-inline-block col-lg-2 text-center">Stop Level ***</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="row m-0">
                                                <td class="d-inline-block col-lg-1" data-label="Symbol">XTIUSDs</td>
                                                <td class="d-inline-block col-lg-3 text-left" data-label="Description">Crude Vs US Dollar</td>
                                                <td class="d-inline-block col-lg-1" data-label="Average Spread*">7	</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:100">1%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:500">0.20%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:1000">0.10%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Long">-9</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Short">-2	 </td>
                                                <td class="d-inline-block col-lg-2 text-center" data-label="Stop Level ***">2</td>
                                            </tr>
                                            <tr class="row m-0">
                                                <td class="d-inline-block col-lg-1" data-label="Symbol">XNGUSDs</td>
                                                <td class="d-inline-block col-lg-3" data-label="Description"> US Natural Gas</td>
                                                <td class="d-inline-block col-lg-1" data-label="Average Spread*">4.6</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:100">1%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:500">0.20%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:1000">0.10%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Long">-3.82</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Short"> 1.55 </td>
                                                <td class="d-inline-block col-lg-2 text-center" data-label="Stop Level ***">1</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Tab>
                                <Tab eventKey="pro" title="Pro">
                                    <Table borderless responsive hover>
                                        <thead>
                                            <tr className="row m-0 bg-gray">
                                                <th className="d-inline-block col-lg-1">Symbol</th>
                                                <th className="d-inline-block col-lg-3 text-left">Description</th>
                                                <th className="d-inline-block col-lg-1">Average Spread*</th>
                                                <th className="d-inline-block col-lg-3">Margin Requirements
                                                <br />
                                                    <div className="row">
                                                        <div className="col">1:100</div>
                                                        <div className="col">1:200 </div>
                                                        <div className="col">1:500	</div>
                                                    </div>
                                                </th>
                                                <th className="d-inline-block col-lg-2">Swap
                                                <br />
                                                    <div className="row">
                                                        <div className="col">Long</div>
                                                        <div className="col">Short </div>
                                                    </div>
                                                </th>
                                                <th className="d-inline-block col-lg-2 text-center">Stop Level ***</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="row m-0">
                                                <td className="col-lg-1" data-label="Symbol">XTIUSDp</td>
                                                <td className="d-inline-block col-lg-3" data-label="Description">Crude Vs US Dollar</td>
                                                <td className="col-lg-1" data-label="Average Spread*">3.7</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 1%
                        </td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 0.50%
                        </td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:500"> 0.20%
                        </td>
                                                <td className="col-lg-1" data-label="Swap Long">-9</td>
                                                <td className="col-lg-1" data-label="Swap Short">-2</td>
                                                <td className="d-inline-block col-lg-2 text-center" data-label="Stop Level ***">2</td>
                                            </tr>
                                            <tr className="row m-0">
                                                <td className="col-lg-1" data-label="Symbol">XNGUSDp</td>
                                                <td className="d-inline-block col-lg-3" data-label="Description">US Natural Gas</td>
                                                <td className="col-lg-1" data-label="Average Spread*">3.2</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:100"> 1%
                        </td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:200"> 0.50%
                        </td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:500"> 0.20%
                        </td>
                                                <td className="col-lg-1" data-label="Swap Long">-3.82</td>
                                                <td className="col-lg-1" data-label="Swap Short">1.55</td>
                                                <td className="d-inline-block col-lg-2 text-center" data-label="Stop Level ***">1</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Tab>
                                <Tab eventKey="ecn" title="ECN">
                                    <Table borderless responsive hover>
                                    <thead>
                                 <tr className="row m-0 bg-gray">
                                    <th class="d-inline-block col-lg-1">Symbol</th>
                                    <th class="d-inline-block col-lg-4 text-left">Description</th>
                                    <th class="d-inline-block col-lg-1">Average Spread*</th>
                                    <th class="d-inline-block col-lg-2">
                                       Margin Requirements
                           <br />
                                       <div class="row">
                                          <div class="col">1:100</div>
                                          <div class="col">1:200 </div>
                                       </div>
                                    </th>
                                    <th class="d-inline-block col-lg-2">
                                       Swap
                           <br />
                                       <div class="row">
                                          <div class="col">Long</div>
                                          <div class="col"> Short </div>
                                       </div>
                                    </th>
                                    <th class="d-inline-block col-lg-2 text-center">Stop Level ***</th>
                                 </tr>
                              </thead>
                                        <tbody>
                                            <tr class="row m-0">
                                                <td class="d-inline-block col-lg-1" data-label="Symbol">XTIUSD</td>
                                                <td class="d-inline-block col-lg-4" data-label="Description">Crude	</td>
                                                <td class="d-inline-block col-lg-1" data-label="Average Spread*">3.5</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:100">1%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:200">0.50%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Long">-9</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Short">-2</td>
                                                <td class="d-inline-block col-lg-2 text-center" data-label="Stop Level ***">2</td>
                                            </tr>
                                            <tr class="row m-0">
                                                <td class="d-inline-block col-lg-1" data-label="Symbol">XNGUSD</td>
                                                <td class="d-inline-block col-lg-4" data-label="Description"> US Natural Gas</td>
                                                <td class="d-inline-block col-lg-1" data-label="Average Spread*">3</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:100">1%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 1:200">0.50%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Long">-3.82</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Short"> 1.55 </td>
                                                <td class="d-inline-block col-lg-2 text-center" data-label="Stop Level ***">1</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Tab>
                            </Tabs>
                            <p className="text-lg-right text-center"><small>*Values are subjected to change
</small></p>
                        </Col>
                    </div>
                </section>
                <ReadyToTrade />
            </div>
        );
    }
}
