import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
import { Helmet } from "react-helmet";

class SecurityPolicies extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="Security Policies | Forex with LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "aml,policy,leoprime" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Security Policies | Forex with LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Security Policies | Forex with LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "Start trading with the world-class Next-Generation Multi Asset Broker LeoPrime." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay regulation-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase text-dark">Security </h1>
                            <h1 className="display-3 text-shadow text-uppercase text-dark"><span>Policy</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <Col lg={12}>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                Personal Information
                            </h4>
                                <p className="w-100">Any information about your activities collected by our experts is used only for business purposes and is intended to increase the quality of service, timely provide you with information about new services and products of LeoPrime, thoroughly assess of your financial capabilities and needs, as well as to process transactions.
</p>
                            </Row>
                            <Row className="mb-3">
                            <h4 className="w-100">
                            What kind of information we use
                            </h4>
                                <ul className="liststyle">
                                    <li>Registration information you provided in the application and other documents (first name, middle name, last name, postal address, e-mail, date of birth, occupation, your income and assets).
</li>
                                    <li>Information about your transactions with the partners of LeoPrime and us as well as information that we exchanged, such as account balances, our answers to your questions, you trading activity.
</li>
                                    <li>Information about incoming alerts, notifications, news and subscriptions.
</li>
<li>ID information used to verify your identity (passport or driver’s license, as well as information obtained from credit bureaus, address agencies and other non-LeoPrime sources).
</li>
                                </ul>
                                <p>This data is necessary to protect you and our reputation, and prevent the money laundering.
</p>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                Cookies                            </h4>
                                <p className="w-100 mb-0">Cookies are files containing the information that websites use to register traffic (number of visitors). This information from the website server is temporarily saved on the hard disk of your computer or mobile device when you visit the website for the first time and subsequently, allows the website to recognize your browser. Cookies usually contain some anonymous information such as the Unique Identification Number that is generated at random. Cookies also remember the country and language preferences you selected. All the information received is confidential and not directly associated with a certain client. LeoPrime uses cookies to define what services are the most interesting services for its clients and to measure visitors' activity on the Company’s website. Using cookies, we also identify the most requested advertisements and estimate the popularity of resources related to the potential clients attracting. Using services of our website, you agree to our use of cookies. All the Internet browsers allow changing cookies settings. You can disable or block cookie files; however, some of them are necessary for the proper functioning of our website.
</p>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                Security Technologies
                            </h4>
                                <p className="w-100 mb-0">LeoPrime uses advanced technology for secured connections (SSL) to protect the information you share with us. The SSL Technology provides reliable data protection from interception by third parties. We are constantly improving our security technologies to prevent intrusions into the system.
</p>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                Transmission of information to affiliated company
                            </h4>
                                <p className="w-100 mb-0">We can share the confidential information mentioned above with our business partners to improve the service quality and inform customers about new services of the LeoPrime. The list of partners includes companies that we own or manage.
</p>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                Transmission of information to third parties
                            </h4>
                                <p className="w-100 mb-0">There is no way third parties can get information about our customers, according to the rules described in the Privacy Policy. Third parties can gain access to information only if they are business partners of LeoPrime. If a company is helping us provide clients with quality service and is not our partner, it can only use the information needed to ensure high quality of service. Information may be disclosed only upon your request or with your consent. Remember, LeoPrime values its reputation too much to sell your confidential information.
</p>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                Transmission of information to governing bodies
                            </h4>
                                <p className="w-100 mb-0">In exceptional cases, LeoPrime may disclose customer’s personal information to third parties if such disclosure is required by applicable law or other regulations. For example, we will have to disclose personal information to comply with court orders or official inquiries, as well as in the case of the protection of our rights and property.
</p>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                Refusal to provide information
                            </h4>
                                <p className="w-100 mb-0">You have the right to refuse to provide information requested by our experts. In this event, we cannot give you full support and guarantee quality service for your accounts, since we have to make sure all the information you provide is absolutely accurate and inform you in timely manner of any changes in your information.
</p>
                            </Row>
                            <Row className="mb-3">
                                <h4 className="w-100">
                                Instructions how to opt-out
                            </h4>
                                <p className="w-100 mb-0">You can opt-out of LeoPrime email marketing, by following the instructions included in email messages. Exception applies to the messages, which are related to making payments and the information about the latest services from LeoPrime that you cannot opt-out of. You can also use email to send us your requests relating to marketing materials and your right to forward these materials to third parties with marketing purposes.


</p>
                            </Row>
                        </Col>
                    </div>
                </section>
                <ReadyToTrade />
            </div>
        );
    }
}
export default SecurityPolicies;