import { actionType } from "../actionType";

const blogReducer = (state = {}, action) => {
    if (action.type === actionType.FETCH_BLOG) {
        return { ...state, ...action.payload };
    }
    return state;
};

export default blogReducer;
