import { combineReducers } from 'redux';
import gradeLiquidityReducer from './gradeLiquidityReducer';
import sendContactEmailReducer from "./sendContactEmailReducer";
import forexCalculatorReducer from "./forexCalculatorReducer";
import fxCalculationReducer from "./fxCalculationReducer";
import countryReducer from "./countryReducer";
import stateReducer from "./stateReducer";
import clientSentimentReducer from "./clientSentimentReducer";
import socialTradingRankReducer from "./socialTradingRankReducer";
import demoContestListReducer from "./demoContestListReducer";
import demoMonitoringListReducer from "./demoMonitoringListReducer";
import demoContestRankReducer from "./demoContestRankReducer";
import socialTradingViewReducer from "./socialTradingViewReducer";
import socialTradingStatReducer from "./socialTradingStatReducer";
import socialTradingHistReducer from "./socialTradingHistReducer";
import socialTradingHistCloseReducer from "./socialTradingHistCloseReducer";
import socialTradingStatGrapReducer from "./socialTradingStatGrapReducer";
import socialTradingProfitReducer from "./socialTradingProfitReducer";
import socialTradingBalanceGrapReducer from "./socialTradingBalanceGrapReducer";

import blogsReducer from "./blogsReducer";

export default combineReducers({
    gradeLiquidity: gradeLiquidityReducer,
    sendEmail: sendContactEmailReducer,
    forexCalculator: forexCalculatorReducer,
    fxResult: fxCalculationReducer,
    countries: countryReducer,
    states: stateReducer,
    clientSentiment: clientSentimentReducer,
    socialTradingRanks: socialTradingRankReducer,
    pastContestList: demoContestListReducer,
    monitoringList: demoMonitoringListReducer,
    rankContestList: demoContestRankReducer,
    socialTradingView: socialTradingViewReducer,
    socialTradingStat: socialTradingStatReducer,
    socialTradingHist: socialTradingHistReducer,
    socialTradingHistCls: socialTradingHistCloseReducer,
    socialTradingStatGrap: socialTradingStatGrapReducer,
    socialTradingProfitGrap: socialTradingProfitReducer,
    socialTradingBalanceGrap: socialTradingBalanceGrapReducer,
    blogs: blogsReducer
});
