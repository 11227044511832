import React, { Component } from 'react'
import { Row, Col, Tabs, Tab, Table } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
import TradingInstrumentTabs from '../components/TradingInstrumentTabs'
import { Helmet } from "react-helmet";
export default class TradingInstrumentStocks extends Component {
   render() {
      return (
         <div>
            <Helmet
               title="Stock Trading Instrument | Stock Trading | LeoPrime"
               meta={[
                  { name: 'author', content: "LeoPrime" },
                  { name: 'keywords', content: "Stock Trading, Stocks, Trading Stocks, LeoPrime, Online Trading" },
                  { name: 'twitter:site', content: "LeoPrime" },
                  { name: 'twitter:creator', content: "LeoPrime" },
                  { name: 'twitter:title', content: "Stock Trading Instrument | Stock Trading | LeoPrime" },
                  { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                  { property: 'og:title', content: "Stock Trading Instrument | Stock Trading | LeoPrime" },
                  { property: 'og:site_name', content: "LeoPrime" },
                  { property: 'og:type', content: "Forex Trading" },
                  { property: 'og:url', content: "https://leoprime.com/" },
                  { property: 'og:description', content: "Explore the world’s most popular Stocks with LeoPrime and experience Market Execution on all the Stocks we offer." },
                  { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                  { property: 'og:site_name', content: "LeoPrime" },
                  { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
               ]}
            >
            </Helmet>
            <div className="innerpageBanner">
               <div className="d-flex align-items-center dark-overlay trading-instrument-banner" >
                  <div className="col-xl-10 mx-auto text-white">
                     <h1 className="display-3 text-shadow text-uppercase ">Trading</h1>
                     <h1 className="display-3 text-shadow text-uppercase"><span>Stocks</span></h1>
                  </div>
               </div>
            </div>
            <TradingInstrumentTabs />
            <section className="pt-0">
               <div className="container">
                  <Row className="justify-content-md-center">
                     <Col xs={12} md={12} lg={4} className="mb-3 d-flex flex-column justify-content-center mt-5">
                        <img src={require('../assets/img/account-types/account-type-left.png')} alt="Account Types" className="img-fluid" />
                     </Col>
                     <Col xs={12} md={12} lg={8} className="mb-3 d-flex align-items-center">
                        <div className="d-flex flex-column justify-content-center pl-lg-5">
                           <h2 className="mb-4">
                              Stocks Contract Specification
               </h2>
                           <p>LeoPrime offers you Stocks with Market Execution on some of the world&#39;s most popular stocks for its
                           valuable clientele. Trade Stocks with LeoPrime across the globe including Apple INC., Facebook,
                           Amazon and Microsoft, Citigroup.INC. , etc. with benefits from lowest commission as low as 0.08%.
                           We have no hidden mark-ups, no re-quotes and provided with 12 Major Stocks from the
International Market.</p>
                        </div>
                     </Col>
                  </Row>
               </div>
            </section>
            <section className="hmeOurBenefit pt-0">
                    <div className="container">
                        <Col xs={12} lg className="mb-3 trading_table" id="tabs">
                            <Tabs defaultActiveKey="forex" transition={false} className="nav-fill">
                                <Tab eventKey="forex" title="ECN">
                                    <Table borderless responsive hover>
                                        <thead>
                                            <tr className="row m-0 bg-gray">
                                                <th className="d-inline-block col-lg-2">Instrument  </th>
                                                <th className="d-inline-block col-lg-3 text-left">Description</th>
                                                <th className="d-inline-block col-lg-1">Average <br/>(in points)</th>
                                                <th className="d-inline-block col-lg-2">Margin Requirments(%)
                                                <br />
                                                    <div className="row">
                                                        <div className="col">100</div>
                                                        <div className="col">200 </div>
                                                    </div>
                                                </th>
                                                <th className="d-inline-block col-lg-2">Swap
                                                <br />
                                                    <div className="row">
                                                        <div className="col">Long</div>
                                                        <div className="col">Short </div>
                                                    </div>
                                                </th>
                                                <th className="d-inline-block col-lg-1 text-center">Stop Level</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="row m-0 mt-3">
                                                <td class="d-inline-block col-lg-2" data-label="Instrument">AIG</td>
                                                <td class="d-inline-block col-lg-3 text-left" data-label="Description">American International Group Inc.</td>
                                                <td class="d-inline-block col-lg-1" data-label="Average (Spread)">6.3</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 100">10%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 200">10%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Long">-0.2079</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Short">-0.1672</td>
                                                <td class="d-inline-block col-lg-1 text-center" data-label="Stop Level">5</td>
                                            </tr>

                                            <tr class="row m-0 mt-3">
                                                <td class="d-inline-block col-lg-2" data-label="Instrument">BAC</td>
                                                <td class="d-inline-block col-lg-3 text-left" data-label="Description">Bank of America Corp.</td>
                                                <td class="d-inline-block col-lg-1" data-label="Average (Spread)">5.5</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 100">5%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 200">5%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Long">-0.1661</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Short">-0.1672</td>
                                                <td class="d-inline-block col-lg-1 text-center" data-label="Stop Level">5</td>
                                            </tr>

                                            <tr class="row m-0 mt-3">
                                                <td class="d-inline-block col-lg-2" data-label="Instrument">CSCO</td>
                                                <td class="d-inline-block col-lg-3 text-left" data-label="Description">Cisco Systems, Inc.</td>
                                                <td class="d-inline-block col-lg-1" data-label="Average (Spread)">5.37</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 100">5%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 200">5%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Long">-0.242</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Short">-0.1958</td>
                                                <td class="d-inline-block col-lg-1 text-center" data-label="Stop Level">5</td>
                                            </tr>

                                            <tr class="row m-0 mt-3">
                                                <td class="d-inline-block col-lg-2" data-label="Instrument">F</td>
                                                <td class="d-inline-block col-lg-3 text-left" data-label="Description">Ford Motor Company</td>
                                                <td class="d-inline-block col-lg-1" data-label="Average (Spread)">5.5</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 100">5%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 200">5%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Long">-0.0473</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Short">-0.0385</td>
                                                <td class="d-inline-block col-lg-1 text-center" data-label="Stop Level">5</td>
                                            </tr>

                                            <tr class="row m-0 mt-3">
                                                <td class="d-inline-block col-lg-2" data-label="Instrument">GE</td>
                                                <td class="d-inline-block col-lg-3 text-left" data-label="Description">General Electric Company</td>
                                                <td class="d-inline-block col-lg-1" data-label="Average (Spread)">5.5</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 100">5%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 200">5%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Long">-0.0583</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Short">-0.0484</td>
                                                <td class="d-inline-block col-lg-1 text-center" data-label="Stop Level">5</td>
                                            </tr>

                                            <tr class="row m-0 mt-3">
                                                <td class="d-inline-block col-lg-2" data-label="Instrument">GM</td>
                                                <td class="d-inline-block col-lg-3 text-left" data-label="Description">General Motors Company</td>
                                                <td class="d-inline-block col-lg-1" data-label="Average (Spread)">5.97</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 100">10%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 200">10%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Long">-0.2288</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Short">-0.1848</td>
                                                <td class="d-inline-block col-lg-1 text-center" data-label="Stop Level">5</td>
                                            </tr>

                                            <tr class="row m-0 mt-3">
                                                <td class="d-inline-block col-lg-2" data-label="Instrument">HPQ</td>
                                                <td class="d-inline-block col-lg-3 text-left" data-label="Description">HP Inc.</td>
                                                <td class="d-inline-block col-lg-1" data-label="Average (Spread)">5.18</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 100">5%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 200">5%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Long">-0.1342</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Short">-0.1089</td>
                                                <td class="d-inline-block col-lg-1 text-center" data-label="Stop Level">5</td>
                                            </tr>

                                            <tr class="row m-0 mt-3">
                                                <td class="d-inline-block col-lg-2" data-label="Instrument">IBM</td>
                                                <td class="d-inline-block col-lg-3 text-left" data-label="Description">International Business Machines Corp</td>
                                                <td class="d-inline-block col-lg-1" data-label="Average (Spread)">8.6</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 100">5%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 200">5%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Long">-0.6919</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Short">-0.561</td>
                                                <td class="d-inline-block col-lg-1 text-center" data-label="Stop Level">5</td>
                                            </tr>

                                            <tr class="row m-0 mt-3">
                                                <td class="d-inline-block col-lg-2" data-label="Instrument">INTC</td>
                                                <td class="d-inline-block col-lg-3 text-left" data-label="Description">Intel Corp.</td>
                                                <td class="d-inline-block col-lg-1" data-label="Average (Spread)">5.54</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 100">5%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 200">5%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Long">-0.2772</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Short">-0.2244</td>
                                                <td class="d-inline-block col-lg-1 text-center" data-label="Stop Level">5</td>
                                            </tr>

                                            <tr class="row m-0 mt-3">
                                                <td class="d-inline-block col-lg-2" data-label="Instrument">ORCL</td>
                                                <td class="d-inline-block col-lg-3 text-left" data-label="Description">Oracle Corp.</td>
                                                <td class="d-inline-block col-lg-1" data-label="Average (Spread)">5.88</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 100">5%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 200">5%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Long">-0.3465</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Short">-0.2805</td>
                                                <td class="d-inline-block col-lg-1 text-center" data-label="Stop Level">5</td>
                                            </tr>

                                            <tr class="row m-0 mt-3">
                                                <td class="d-inline-block col-lg-2" data-label="Instrument">QCOM</td>
                                                <td class="d-inline-block col-lg-3 text-left" data-label="Description">QUALCOMM, Inc.</td>
                                                <td class="d-inline-block col-lg-1" data-label="Average (Spread)">7.37</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 100">5%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 200">5%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Long">-0.8349</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Short">-0.6754</td>
                                                <td class="d-inline-block col-lg-1 text-center" data-label="Stop Level">5</td>
                                            </tr>

                                            <tr class="row m-0 mt-3">
                                                <td class="d-inline-block col-lg-2" data-label="Instrument">T</td>
                                                <td class="d-inline-block col-lg-3 text-left" data-label="Description">ATT Inc.</td>
                                                <td class="d-inline-block col-lg-1" data-label="Average (Spread)">5.5</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 100">5%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 200">5%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Long">-0.1606</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Short">-0.1298</td>
                                                <td class="d-inline-block col-lg-1 text-center" data-label="Stop Level">5</td>
                                            </tr>

                                            <tr class="row m-0 mt-3">
                                                <td class="d-inline-block col-lg-2" data-label="Instrument">V</td>
                                                <td class="d-inline-block col-lg-3 text-left" data-label="Description">Visa Inc.</td>
                                                <td class="d-inline-block col-lg-1" data-label="Average (Spread)">22.03</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 100">10%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Margin Requirements 200">10%</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Long">-1.177</td>
                                                <td class="d-inline-block col-lg-1" data-label="Swap Short">-0.9526</td>
                                                <td class="d-inline-block col-lg-1 text-center" data-label="Stop Level">5</td>
                                            </tr>

                                        
                                        </tbody>
                                    </Table>
                                </Tab>
                                
                               
                            </Tabs>
                            <p className="text-lg-right text-center"><small>*Values are subjected to change
</small></p>
                        </Col>
                    </div>
                </section>
            <ReadyToTrade />
         </div>
      );
   }
}