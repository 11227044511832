import { actionType } from "../actionType";

const clientSentimentReducer = (state = [], action) => {
    if (action.type === actionType.FETCH_CLIENT_SENTIMENT) {
        return [...state, action.payload];
    }
    return state;
};

export default clientSentimentReducer;
