import React, { Component } from "react";
import { Row, Col, Media, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { Sparklines, SparklinesLine } from 'react-sparklines';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
// import { format } from 'date-fns'
import {
  getSocialTradingViews,
  getSocialTradingStatistics,
  getSocialTradingTrade,
  getSocialTradingTradeClose,
  getSocialTradingStatisticsGraph,
  getSocialTradingProfitGraph,
  getSocialTradingBalanceGraph,
} from "../store/actions/socialTradingViewAction";
// import { CanvasJSChart } from "canvasjs-react-charts";
import Progress from "../components/Progress";
import Pagination from "@material-ui/lab/Pagination";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faLock, faInfo } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from "recharts";
import { getUrlPart } from "../utils/getUrlPart";
import * as ReactTooltip from 'react-tooltip';
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";

const { afterToday } = DateRangePicker;

const META = [
  { name: "author", content: "LeoPrime" },
  {
    name: "keywords",
    content: "Copy Trader Ranking, Copy Trading Rank, Copy Traders Ranking",
  },
  { name: "twitter:site", content: "LeoPrime" },
  { name: "twitter:creator", content: "LeoPrime" },
  { name: "twitter:title", content: "Copy Traders Ranking | LeoPrime" },
  {
    name: "twitter:image",
    content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg",
  },
  { property: "og:title", content: "Copy Traders Ranking | LeoPrime" },
  { property: "og:site_name", content: "LeoPrime" },
  { property: "og:type", content: "Forex Trading" },
  { property: "og:url", content: "https://leoprime.com/" },
  {
    property: "og:description",
    content:
      "LeoPrime’s Trader Ranking feature helps investors to choose & copy from the list of Master Traders, based on their ranking & performance..",
  },
  {
    property: "og:image",
    content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg",
  },
  { property: "og:site_name", content: "LeoPrime" },
  { name: "viewport", content: "width=device-width, maximum-scale=1" },
];
// const growth = [
//   {
//     date: "2022-02-22",
//     value: 10,
//   },
//   {
//     date: "2022-02-22",
//     value: 8,
//   },
// ];
class SocialTradingRankDetails extends Component {
  state = {
    isLd1: true,
    isLd2: true,
    isLd3: true,
    isLd4: true,
    isLd5: true,
    isLd6: true,
    isLd7: true,
    isLoading: true, // used for loader
    strategy: getUrlPart("id"), // Risk Type ALL is Default
  };

  constructor(props) {
    super(props);
    this.fetchSocialTradingView({
      strategy: this.state.strategy,
    });
    this.fetchSocialTradingStat({
      strategy: this.state.strategy,
    });

    this.fetchSocialTradingOpen({
      strategy: this.state.strategy,
      platform: "MT4",
      tradetype: "Opened",
    });
    this.fetchSocialTradingClosed({
      strategy: this.state.strategy,
      platform: "MT4",
      tradetype: "Closed",
    });

    this.fetchSocialTradingStatisticsGraph({
      strategy: this.state.strategy,
    });
    this.fetchSocialTradingProfitGraph({
      strategy: this.state.strategy,
    });
    this.fetchSocialTradingBalanceGraph({
      strategy: this.state.strategy,
    });
  }

  // Listen a event of a Page Change Event and load a Trading rank
  handlePageChange = (event, value) => {
    const param = {
      page: { number: value },
      strategy: this.state.strategy,
      platform: "MT4",
      tradetype: "Closed",
    };
    this.props.getSocialTradingTradeClose(param, (rs) => { });
  };

  // Listen a event of a Page Change Event
  handleRiskTypeChange = (event) => {
    this.setState({ isLoading: true });
    this.setState({ strategy: event.target.value });
    this.fetchSocialTradingView({
      strategy: event.target.value,
    });
    this.fetchSocialTradingStat({
      strategy: event.target.value,
    });
  };

  // Get Social Trading Ranks from server and load in to table
  fetchSocialTradingView = (param) => {
    this.props.getSocialTradingViews(param, (rs) => {
      this.setState({ isLd1: false });
    });
  };
  fetchSocialTradingStat = (param) => {
    this.props.getSocialTradingStatistics(param, (rs) => {
      this.setState({ isLd2: false });
    });
  };
  fetchSocialTradingOpen = (param) => {
    this.props.getSocialTradingTrade(param, (rs) => {
      this.setState({ isLd3: false });
    });
  };
  fetchSocialTradingClosed = (param) => {
    this.props.getSocialTradingTradeClose(param, (rs) => {
      this.setState({ isLd4: false });
    });
  };
  fetchSocialTradingStatisticsGraph = (param) => {
    this.props.getSocialTradingStatisticsGraph(param, (rs) => {
      this.setState({ isLd5: false });
    });
  };
  fetchSocialTradingProfitGraph = (param) => {
    this.props.getSocialTradingProfitGraph(param, (rs) => {
      this.setState({ isLd6: false });
    });
  };
  fetchSocialTradingBalanceGraph = (param) => {
    this.props.getSocialTradingBalanceGraph(param, (rs) => {
      this.setState({ isLd7: false });
    });
  };

  onGrowth(date, dateString) {
    this.fetchSocialTradingStatisticsGraph({
      strategy: this.state.strategy,
      start_date: moment((date.toString().split(",")[0]).toString().split(" G")[0]).format("YYYY-MM-DD"),
      end_date: moment((date.toString().split(",")[1]).toString().split(" G")[0]).format("YYYY-MM-DD"),
    });
  }
  onProfit(date, dateString) {
    this.fetchSocialTradingStatisticsGraph({
      strategy: this.state.strategy,
      start_date: moment((date.toString().split(",")[0]).toString().split(" G")[0]).format("YYYY-MM-DD"),
      end_date: moment((date.toString().split(",")[1]).toString().split(" G")[0]).format("YYYY-MM-DD"),
    });
  }
  onBalance(date, dateString) {
    this.fetchSocialTradingStatisticsGraph({
      strategy: this.state.strategy,
      start_date: moment((date.toString().split(",")[0]).toString().split(" G")[0]).format("YYYY-MM-DD"),
      end_date: moment((date.toString().split(",")[1]).toString().split(" G")[0]).format("YYYY-MM-DD"),
    });
  }
  render() {
    return (
      <div>
        <Helmet title="Copy Traders Ranking | LeoPrime" meta={META}></Helmet>
        <div className="innerpageBanner">
          <div className="d-flex align-items-center dark-overlay">
            <div className="col-xl-10 mx-auto text-white">
              <h1 className="display-3 text-shadow text-uppercase text-dark">
                Copy
              </h1>

              <h1 className="display-3 text-shadow text-uppercase text-dark">
                <span>Trading</span>
              </h1>
            </div>
          </div>
        </div>
        <section>
          <div className="col-xl-10 mx-auto">
            <Row className="justify-content-md-center">
              <Col>
                <div className="d-flex flex-column flex-md-row align-items-center  mb-3">
                  <h6 className="mr-md-auto pb-3">Master Rating</h6>
                </div>
                <div>
                  {!this.state.isLd1 ? (
                    this.props.socialTradingsView.details.strategy.data ? (
                      this.renderBox(
                        this.props.socialTradingsView.details.strategy.data
                      )
                    ) : (
                      <Alert severity="info">
                        <AlertTitle>Info</AlertTitle>
                        Master Rating Records are not Available!
                      </Alert>
                    )
                  ) : (
                    <Progress />
                  )}
                  <Row>
                    {!this.state.isLd2 ? (
                      this.props.socialTradingsStat.details.strategy.data ? (
                        this.renderBox2(
                          this.props.socialTradingsStat.details.strategy.data
                        )
                      ) : (
                        <Alert severity="info">
                          <AlertTitle>Info</AlertTitle>
                          Master Rating Records are not Available!
                        </Alert>
                      )
                    ) : (
                      <Progress />
                    )}

                    <Col lg={5}>
                      <Tabs
                        defaultActiveKey="growth"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                      >
                        <Tab eventKey="growth" title="Growth">
                          <Row>
                            <Col lg={12} className="text-center">
                              <DateRangePicker onChange={(date, dateString) => this.onGrowth(date, dateString)} disabledDate={afterToday()} dateFromat='YYYY-MM-dd' format='dd-MM-yyyy' locale={(format) => 'dd-MM-yyyy'} />
                            </Col>
                          </Row>
                          <Col>
                            {!this.state.isLd5 ? (
                              this.props.socialTradingsStatGrap ? (
                                this.renderGraph(
                                  this.props.socialTradingsStatGrap.strategy
                                    .data, "Abs.Gain%", "Period"
                                )
                              ) : (
                                <Alert severity="info">
                                  <AlertTitle>Info</AlertTitle>
                                  Master Rating Records are not Available!
                                </Alert>
                              )
                            ) : (
                              <Progress />
                            )}
                          </Col>
                        </Tab>
                        <Tab eventKey="profile" title="Profit">
                          <Row>
                            <Col lg={12} className="text-center">
                              <DateRangePicker onChange={(date, dateString) => this.onProfit(date, dateString)} disabledDate={afterToday()} dateFromat='YYYY-MM-dd' format='dd-MM-yyyy' locale={(format) => 'dd-MM-yyyy'} />
                            </Col>
                          </Row>
                          <Col>
                            {!this.state.isLd6 ? (
                              this.props.socialTradingProfitsGrap ? (
                                this.renderGraph(
                                  this.props.socialTradingProfitsGrap.strategy
                                    .data, "Abs.Gain%", "Period"
                                )
                              ) : (
                                <Alert severity="info">
                                  <AlertTitle>Info</AlertTitle>
                                  Master Rating Records are not Available!
                                </Alert>
                              )
                            ) : (
                              <Progress />
                            )}
                          </Col>
                        </Tab>
                        <Tab eventKey="balance" title="Balance">
                          <Row>
                            <Col lg={12} className="text-center">
                              <DateRangePicker onChange={(date, dateString) => this.onBalance(date, dateString)} disabledDate={afterToday()} dateFromat='YYYY-MM-dd' format='dd-MM-yyyy' locale={(format) => 'dd-MM-yyyy'} />
                            </Col>
                          </Row>
                          <Col>
                            {!this.state.isLd7 ? (
                              this.props.socialTradingBalancesGrap ? (
                                this.renderGraph(
                                  this.props.socialTradingBalancesGrap.strategy
                                    .data, "Balance", "Period"
                                )
                              ) : (
                                <Alert severity="info">
                                  <AlertTitle>Info</AlertTitle>
                                  Master Rating Records are not Available!
                                </Alert>
                              )
                            ) : (
                              <Progress />
                            )}
                          </Col>
                        </Tab>
                      </Tabs>
                    </Col>
                  </Row>
                  <hr />
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <div className="col-xl-10 mx-auto mb-5">
          <Row>
            <Col lg={12}>
              <h6 className="my-4">Trading Activity</h6>
              <Row>
                <Col>
                  <div className="pr-4">
                    <Tabs defaultActiveKey="opentrade" className="mb-3">
                      <Tab eventKey="opentrade" title="Open Trade">
                        <Row className="justify-content-md-center">
                          <Col className="st-trading-activity">
                            <Table>
                              <Thead>
                                <Tr>
                                  <Th>Order ID</Th>
                                  <Th>Time</Th>
                                  <Th>Price</Th>
                                  <Th>Type</Th>
                                  <Th>Symbol</Th>
                                  <Th>Volume</Th>
                                  <Th>Time</Th>
                                  <Th>Price</Th>
                                  <Th>S/L</Th>
                                  <Th>T/P</Th>
                                  <Th>Swap</Th>
                                  <Th>Profit</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {!this.state.isLd3 ? (
                                  this.props.socialTradingsHist.strategy
                                    .data ? (
                                    this.props.socialTradingsHist.strategy.data.data.map(
                                      (row, index) => {
                                        return this.renderActiveTrade(
                                          row,
                                          index +
                                          1 +
                                          (this.props.socialTradingsHist
                                            .strategy.data.current_page -
                                            1) *
                                          10
                                        );
                                      }
                                    )
                                  ) : (
                                    <Alert severity="info">
                                      <AlertTitle>Info</AlertTitle>
                                      Master Rating Records are not Available!
                                    </Alert>
                                  )
                                ) : (
                                  <Progress />
                                )}
                              </Tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Tab>
                      <Tab eventKey="closetrade" title="Close Trade">
                        <Row className="justify-content-md-center">
                          <Col className="st-trading-activity">
                            <Table>
                              <Thead>
                                <Tr>
                                  <Th>Order ID</Th>
                                  <Th>Time</Th>
                                  <Th>Price</Th>
                                  <Th>Type</Th>
                                  <Th>Symbol</Th>
                                  <Th>Volume</Th>
                                  <Th>Time</Th>
                                  <Th>Price</Th>
                                  <Th>S/L</Th>
                                  <Th>T/P</Th>
                                  <Th>Swap</Th>
                                  <Th>Profit</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {!this.state.isLd4 ? (
                                  this.props.socialTradingsHistCls ? (
                                    this.props.socialTradingsHistCls.strategy.data.data.map(
                                      (row, index) => {
                                        return this.renderCloseTrade(
                                          row,
                                          index +
                                          1 +
                                          (this.props.socialTradingsHistCls
                                            .strategy.data.current_page -
                                            1) *
                                          10
                                        );
                                      }
                                    )
                                  ) : (
                                    <Alert severity="info">
                                      <AlertTitle>Info</AlertTitle>
                                      Master Rating Records are not Available!
                                    </Alert>
                                  )
                                ) : (
                                  <Progress />
                                )}
                              </Tbody>
                            </Table>
                            <div className="float-right mt-3">
                              {!this.state.isLd4 &&
                                this.props.socialTradingsHistCls.strategy.data
                                  .current_page ? (
                                <Pagination
                                  count={
                                    this.props.socialTradingsHistCls.strategy
                                      .data.last_page
                                  }
                                  page={
                                    this.props.socialTradingsHistCls.strategy
                                      .data.current_page
                                  }
                                  onChange={this.handlePageChange}
                                  variant="outlined"
                                  shape="rounded"
                                  color="primary"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Tab>
                    </Tabs>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
  renderBox = (row) => {
    return (
      <div>
        <Row className="ct-rank-details mb-4">
          <Col lg={4}>
            <Media>
              <img
                src={row.strategyimage}
                width="50px"
                alt="Copy Trading"
                className="d-lg-block mr-3"
              />
              <Media.Body className="pt-2 ">
                <h3 className="mb-1">{row.nickname}</h3>
                <span>{row.country.name}</span>
              </Media.Body>
            </Media>
          </Col>
          <Col lg={5}>
            <Row>
              <Col lg={3} md={12}>
                <Media>
                  <Media.Body className="pt-2">
                    <h6>Balance</h6>
                    <p className="text-success">
                      {row.details.overview.balance}
                    </p>
                  </Media.Body>
                </Media>
              </Col>
              <Col lg={3} md={12}>
                <Media>
                  <Media.Body className="pt-2">
                    <h6>Equity</h6>
                    <p>{row.details.overview.equity}</p>
                  </Media.Body>
                </Media>
              </Col>
              <Col lg={3} md={12}>
                <Media>
                  <Media.Body className="pt-2">
                    <h6> Profit & Loss</h6>
                    <p>{row.details.overview.profitloss}</p>
                  </Media.Body>
                </Media>
              </Col>
              <Col lg={3} md={12}>
                <Media>
                  <Media.Body className="pt-2">
                    <h6> Strategy Age</h6>
                    <p>{row.details.overview.age}</p>
                  </Media.Body>
                </Media>
              </Col>
            </Row>
          </Col>
          <Col lg={3} className="d-flex align-items-center justify-content-end">
            {/* <button
              className="primary-button-lg-trans mr-3"
              type="button"
            >
              {" "}
              Add to Favourites
            </button> */}
            {/* <button className="primary-button-lg my-2" type="button" to='/copy-trading'>
              {" "}
              Start Copying
            </button> */}
            <Link to="/copy-trading" className="primary-button-lg my-2">
              {" "}
              Start Copying
            </Link>
          </Col>
        </Row>

        <Row>
          <Col lg={6} className="border-right">
            <Row>
              <Col lg={4}>
                <h6>Leverage</h6>
                <p> {row.details.overview.leverage} </p>
              </Col>
              <Col lg={4}>
                <h6>Min. Deposit</h6>
                <p> {row.details.overview.deposit.mininum} </p>
              </Col>
              <Col lg={4}>
                <h6>Recommended Deposit</h6>
                <p> {row.details.overview.deposit.recommended} </p>
              </Col>
            </Row>
          </Col>
          <Col lg={6}>
            <h6>Description</h6>
            <p>{row.about} </p>
          </Col>
        </Row>
      </div>
    );
  };

  renderBox2 = (row2) => {
    return (
      <Col lg={7}>
        <h6 className="my-4">Statistics</h6>
        <Row>
          <Col lg={6}>
            <div className="border-right pr-4">
              <Row>
                <Col>
                  Gain %:
                  <span data-tip data-for="statistics1" className="help">
                    <FontAwesomeIcon icon={faInfo} className="fa-lg" />
                  </span>
                  <ReactTooltip
                    id="statistics1"
                    data-html={true}
                    insecure={true}
                    multiline={true}
                  >
                    <p>
                      The Performance of return on each trade for the USD
                      Invested.
                    </p>
                  </ReactTooltip>
                </Col>
                <Col className="text-right text-success">
                  {row2[0].statistics.gain}
                </Col>
              </Row>
              <Row>
                <Col>
                  Daily Gain %:
                  <span data-tip data-for="statistics2" className="help">
                    <FontAwesomeIcon icon={faInfo} className="fa-lg" />
                  </span>
                  <ReactTooltip
                    id="statistics2"
                    data-html={true}
                    insecure={true}
                    multiline={true}
                  >
                    <p>
                      The Performance of return of each Day for the USD
                      Invested.
                    </p>
                  </ReactTooltip>
                </Col>
                <Col className="text-right">{row2[0].statistics.dailygain}</Col>
              </Row>
              <Row>
                <Col>
                  Monthly Gain %:
                  <span data-tip data-for="statistics3" className="help">
                    <FontAwesomeIcon icon={faInfo} className="fa-lg" />
                  </span>
                  <ReactTooltip
                    id="statistics3"
                    data-html={true}
                    insecure={true}
                    multiline={true}
                  >
                    <p>
                      The Performance of return of each month for the USD
                      Invested.
                    </p>
                  </ReactTooltip>
                </Col>
                <Col className="text-right">
                  {row2[0].statistics.monthlygain}
                </Col>
              </Row>
              <Row>
                <Col>
                  Drawdown:
                  <span data-tip data-for="statistics4" className="help">
                    <FontAwesomeIcon icon={faInfo} className="fa-lg" />
                  </span>
                  <ReactTooltip
                    id="statistics4"
                    data-html={true}
                    insecure={true}
                    multiline={true}
                  >
                    <p>
                      Drawdown represents the percentage of amount you have lost
                      over a trading period from the investment.
                    </p>
                  </ReactTooltip>
                </Col>
                <Col className="text-right">{row2[0].statistics.drawdown}</Col>
              </Row>
              <Row>
                <Col>
                  Account Age:
                  <span data-tip data-for="statistics5" className="help">
                    <FontAwesomeIcon icon={faInfo} className="fa-lg" />
                  </span>
                  <ReactTooltip
                    id="statistics5"
                    data-html={true}
                    insecure={true}
                    multiline={true}
                  >
                    <p>
                      The time period of the trading account from the first
                      deposit.
                    </p>
                  </ReactTooltip>
                </Col>
                <Col className="text-right">
                  {row2[0].statistics.accountage}
                </Col>
              </Row>
              <Row>
                <Col>
                  Balance:
                  <span data-tip data-for="statistics6" className="help">
                    <FontAwesomeIcon icon={faInfo} className="fa-lg" />
                  </span>
                  <ReactTooltip
                    id="statistics6"
                    data-html={true}
                    insecure={true}
                    multiline={true}
                  >
                    <p>
                      The amount of USD which includes the Deposit/Withdraw and
                      Profit/Loss.
                    </p>
                  </ReactTooltip>
                </Col>
                <Col className="text-right">{row2[0].statistics.balance}</Col>
              </Row>
              <Row>
                <Col>
                  Equity:
                  <span data-tip data-for="statistics7" className="help">
                    <FontAwesomeIcon icon={faInfo} className="fa-lg" />
                  </span>
                  <ReactTooltip
                    id="statistics7"
                    data-html={true}
                    insecure={true}
                    multiline={true}
                  >
                    <p>
                      Equity represents the total profit/loss for the trading
                      activity by adding balance in real time.
                    </p>
                  </ReactTooltip>
                </Col>
                <Col className="text-right">{row2[0].statistics.equity}</Col>
              </Row>
              <Row>
                <Col>
                  Profit/Loss:
                  <span data-tip data-for="statistics8" className="help">
                    <FontAwesomeIcon icon={faInfo} className="fa-lg" />
                  </span>
                  <ReactTooltip
                    id="statistics8"
                    data-html={true}
                    insecure={true}
                    multiline={true}
                  >
                    <p>
                      This represents the Profit and Loss happened from the
                      Investment.
                    </p>
                  </ReactTooltip>
                </Col>
                <Col className="text-right">
                  {row2[0].statistics.profitloss}
                </Col>
              </Row>
              <Row>
                <Col>
                  Deposit:
                  <span data-tip data-for="statistics9" className="help">
                    <FontAwesomeIcon icon={faInfo} className="fa-lg" />
                  </span>
                  <ReactTooltip
                    id="statistics9"
                    data-html={true}
                    insecure={true}
                    multiline={true}
                  >
                    <p>The total fund invested in the Trading Account.</p>
                  </ReactTooltip>
                </Col>
                <Col className="text-right text-success">
                  {row2[0].statistics.deposit}
                </Col>
              </Row>
              <Row>
                <Col>
                  Withdrawal:
                  <span data-tip data-for="statistics10" className="help">
                    <FontAwesomeIcon icon={faInfo} className="fa-lg" />
                  </span>
                  <ReactTooltip
                    id="statistics10"
                    data-html={true}
                    insecure={true}
                    multiline={true}
                  >
                    <p>The total funds withdrawn from the Trading account.</p>
                  </ReactTooltip>
                </Col>
                <Col className="text-right text-danger">
                  {row2[0].statistics.withdrawal}
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6}>
            <div className="border-right pr-4">
              <Row>
                <Col>
                  Trades:
                  <span data-tip data-for="statistics11" className="help">
                    <FontAwesomeIcon icon={faInfo} className="fa-lg" />
                  </span>
                  <ReactTooltip
                    id="statistics11"
                    data-html={true}
                    insecure={true}
                    multiline={true}
                  >
                    <p>
                      This shows the total closed position in the Trading
                      Account.
                    </p>
                  </ReactTooltip>
                </Col>
                <Col className="text-right text-success">
                  {row2[0].statistics.trades}
                </Col>
              </Row>
              <Row>
                <Col>
                  Profit Factor:
                  <span data-tip data-for="statistics12" className="help">
                    <FontAwesomeIcon icon={faInfo} className="fa-lg" />
                  </span>
                  <ReactTooltip
                    id="statistics12"
                    data-html={true}
                    insecure={true}
                    multiline={true}
                  >
                    <p>
                      This shows the gaining ratio of the Trader by calculating
                      the gross profit divided by gross loss (including
                      commissions) for the entire trading period.
                    </p>
                  </ReactTooltip>
                </Col>
                <Col className="text-right">
                  {row2[0].statistics.profitfactor}
                </Col>
              </Row>
              <Row>
                <Col>
                  PIPS:
                  <span data-tip data-for="statistics13" className="help">
                    <FontAwesomeIcon icon={faInfo} className="fa-lg" />
                  </span>
                  <ReactTooltip
                    id="statistics13"
                    data-html={true}
                    insecure={true}
                    multiline={true}
                  >
                    <p>
                      The total gross value of the pips gained after deducting
                      the pips lost in the entire trading period.
                    </p>
                  </ReactTooltip>
                </Col>
                <Col className="text-right">{row2[0].statistics.pips}</Col>
              </Row>
              <Row>
                <Col>
                  Lots:
                  <span data-tip data-for="statistics14" className="help">
                    <FontAwesomeIcon icon={faInfo} className="fa-lg" />
                  </span>
                  <ReactTooltip
                    id="statistics14"
                    data-html={true}
                    insecure={true}
                    multiline={true}
                  >
                    <p>
                      This represents the total volume traded in the entire
                      trading period.
                    </p>
                  </ReactTooltip>
                </Col>
                <Col className="text-right">{row2[0].statistics.lots}</Col>
              </Row>
              <Row>
                <Col>
                  Average Win:
                  <span data-tip data-for="statistics15" className="help">
                    <FontAwesomeIcon icon={faInfo} className="fa-lg" />
                  </span>
                  <ReactTooltip
                    id="statistics15"
                    data-html={true}
                    insecure={true}
                    multiline={true}
                  >
                    <p>
                      This shows the average winning trades by calculating Total
                      Profit divided by the Total number of Profit Trades.
                    </p>
                  </ReactTooltip>
                </Col>
                <Col className="text-right">
                  {row2[0].statistics.averagewin}
                </Col>
              </Row>
              <Row>
                <Col>
                  Average Loss:
                  <span data-tip data-for="statistics16" className="help">
                    <FontAwesomeIcon icon={faInfo} className="fa-lg" />
                  </span>
                  <ReactTooltip
                    id="statistics16"
                    data-html={true}
                    insecure={true}
                    multiline={true}
                  >
                    <p>
                      This shows the average loosing trades by calculating Total
                      Loss divided by the Total number of Loss Trades.
                    </p>
                  </ReactTooltip>
                </Col>
                <Col className="text-right">
                  {row2[0].statistics.averageloss}
                </Col>
              </Row>
              <Row>
                <Col>No. of Short/ No. of Long Position:</Col>
                <Col className="text-right">
                  {row2[0].statistics.shortlongposition}
                </Col>
              </Row>
              <Row>
                <Col>
                  Highest Short Trade Profit / Highest Long Trade Profit
                </Col>
                <Col className="text-right">
                  {row2[0].statistics.shortlongwon}
                </Col>
              </Row>
              <Row>
                <Col>
                  Average Trade Length:
                  <span data-tip data-for="statistics17" className="help">
                    <FontAwesomeIcon icon={faInfo} className="fa-lg" />
                  </span>
                  <ReactTooltip
                    id="statistics17"
                    data-html={true}
                    insecure={true}
                    multiline={true}
                  >
                    <p>The average holding time of the Trades performed.</p>
                  </ReactTooltip>
                </Col>
                <Col className="text-right text-success">
                  {row2[0].statistics.averagetradelength}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    );
  };
  renderActiveTrade = (row) => {
    return (
      <Tr>
        <Td>{row.ticket}</Td>
        <Td>
          <FontAwesomeIcon icon={faLock} className="mr-2" />{" "}
        </Td>
        <Td>
          <FontAwesomeIcon icon={faLock} className="mr-2" />{" "}
        </Td>
        <Td>{row.type}</Td>
        <Td>{row.item}</Td>
        <Td>{row.size}</Td>
        <Td>
          <FontAwesomeIcon icon={faLock} className="mr-2" />{" "}
        </Td>
        <Td>
          <FontAwesomeIcon icon={faLock} className="mr-2" />{" "}
        </Td>
        <Td>
          <FontAwesomeIcon icon={faLock} className="mr-2" />{" "}
        </Td>
        <Td>
          <FontAwesomeIcon icon={faLock} className="mr-2" />{" "}
        </Td>
        <Td>
          <FontAwesomeIcon icon={faLock} className="mr-2" />{" "}
        </Td>
        <Td>{row.profit}</Td>
      </Tr>
    );
  };

  renderCloseTrade = (row) => {
    return (
      <Tr>
        <Td>{row.ticket}</Td>
        <Td>{row.open_time}</Td>
        <Td>{row.open_price}</Td>
        <Td>{row.type}</Td>
        <Td>{row.item}</Td>
        <Td>{row.size}</Td>
        <Td>{row.close_time}</Td>
        <Td>{row.close_price}</Td>
        <Td>{row.sl}</Td>
        <Td>{row.tp}</Td>
        <Td>{row.swaps}</Td>
        <Td>{row.profit}</Td>
      </Tr>
    );
  };

  renderGraph = (growth, x, y) => {
    return (
      <LineChart
        width={500}
        height={300}
        data={growth}
        margin={{
          top: 10,
          right: 30,
          left: 10,
          bottom: 30,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          style={{
            fontSize: "1rem",
          }}
        >
          <Label value={x} position="bottom" />
        </XAxis>
        <YAxis>
          <Label value={y} angle={-90} position="left" />
        </YAxis>

        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="value"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    socialTradingsView: state.socialTradingView,
    socialTradingsStat: state.socialTradingStat,
    socialTradingsHist: state.socialTradingHist,
    socialTradingsHistCls: state.socialTradingHistCls,
    socialTradingsStatGrap: state.socialTradingStatGrap,
    socialTradingProfitsGrap: state.socialTradingProfitGrap,
    socialTradingBalancesGrap: state.socialTradingBalanceGrap,
  };
};

export default connect(mapStateToProps, {
  getSocialTradingViews,
  getSocialTradingStatistics,
  getSocialTradingTrade,
  getSocialTradingTradeClose,
  getSocialTradingStatisticsGraph,
  getSocialTradingProfitGraph,
  getSocialTradingBalanceGraph,
})(SocialTradingRankDetails);
