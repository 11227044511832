import React, { Component } from 'react'
import { Row, Col, ListGroup, Card, CardDeck } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
export default class FundingDeposit extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="Instant withdrawal forex broker | Automatic withdrawal forex broker | LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "instantwithdrawal, forexbroker,retail, account,forex,broker,LeoPrime,trader, forex trader, online" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Instant withdrawal forex broker | Automatic withdrawal forex broker | LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Instant withdrawal forex broker | Automatic withdrawal forex broker | LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "Trade with the leading Forex brokers with instant withdrawal facility to provide you the finest trading services. Visit our website leoprime.com to sign up for live trading right away." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay deposit-withdrawal-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase text-dark">Deposit &amp;</h1>
                            <h1 className="display-3 text-shadow text-uppercase text-dark"><span>Withdrawal</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <div className="text-center">
                            <h2 className="mt-5 mb-3 w-100">
                                Fund Your Account With Our Various
                                <span className="d-block">Payment Gateway Options</span>
                            </h2>
                            <p className="w-100">As a trusted safe-keeper, we guarantee the most secured payment gateways for all your
                                deposits and withdrawals. LeoPrime&#39;s client can choose from a range of international e-
                                payment systems, bank cards, wire transfers, and other convenient modes of deposits and
                                withdrawals with trust, safety and confidence. As part of our commitment to offering the best
                                trading conditions available, we cover most payment processing fees. All our systems are built
                                and updated with the client in mind, it&#39;s all straightforward, safe, simple and easy to use.
                            </p>
                            <Col className="text-center mt-3 mb-5">
                                <a href={('https://www.leoprime.com/register')} className="primary-button-lg my-2">
                                    Make Transfer
                                </a>
                            </Col>
                        </div>
                        <Row>
                            <h2 className="my-5 w-100 text-center">
                                Deposit - Payment Gateway
                            </h2>
                            <Col lg={12}>
                                <CardDeck className="mb-5">
                                    <Card>
                                        <Card.Body>
                                            <Card.Title className="mb-3">
                                                <img src={require('../assets/img/payment-logo/rapid-instant-eft-sm.png')} alt="Account Types" className="img-fluid" />
                                            </Card.Title>
                                            <table className="table table-borderless account_types_table">
                                                <tbody>
                                                    <tr>
                                                        <td>Max.Deposit Amount</td>
                                                        <td>$30,000</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Min.Deposit Amount</td>
                                                        <td>$10</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Fees </td>
                                                        <td>Nil</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Timing</td>
                                                        <td>Instant</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title className="mb-3">
                                                <img src={require('../assets/img/payment-logo/bank-wire-sm.png')} alt="Account Types" className="img-fluid" />
                                            </Card.Title>
                                            <table className="table table-borderless account_types_table">
                                                <tbody>
                                                    <tr>
                                                        <td>Max.Deposit Amount</td>
                                                        <td>Nil</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Min.Deposit Amount</td>
                                                        <td>$1000</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Fees ^*</td>
                                                        <td>Nil</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Timing</td>
                                                        <td>2-5 Business Days</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title className="mb-3">
                                                <img src={require('../assets/img/payment-logo/visa-master-card-sm.png')} alt="Account Types" className="img-fluid" />
                                            </Card.Title>
                                            <table className="table table-borderless account_types_table">
                                                <tbody>
                                                    <tr>
                                                        <td>Max. Deposit Amount</td>
                                                        <td>$8000^^*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Min. Deposit Amount</td>
                                                        <td>$10^^*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Fees</td>
                                                        <td>3%</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Timing</td>
                                                        <td>Instant</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card>
                                    {/* <Card>
                                        <Card.Body>
                                            <Card.Title className="mb-3">
                                                <img src={require('../assets/img/payment-logo/netller-sm.png')} alt="Account Types" className="img-fluid" />
                                            </Card.Title>
                                            <table className="table table-borderless account_types_table">
                                                <tbody>
                                                    <tr>
                                                        <td>Max. Deposit Amount</td>
                                                        <td>Nil</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Min. Deposit Amount</td>
                                                        <td>$10</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Fees</td>
                                                        <td>Nil</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Timing</td>
                                                        <td>Instant</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card> */}
                                    {/* <Card>
                                        <Card.Body>
                                            <Card.Title className="mb-3">
                                                <img src={require('../assets/img/payment-logo/skrill-sm.png')} alt="Account Types" className="img-fluid" />
                                            </Card.Title>
                                            <table className="table table-borderless account_types_table">
                                                <tbody>
                                                    <tr>
                                                        <td>Max. Deposit Amount</td>
                                                        <td>Nil</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Min. Deposit Amount</td>
                                                        <td>$10</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Fees</td>
                                                        <td>Nil</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Timing</td>
                                                        <td>Instant</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card> */}
                                    <Card>
                                        <Card.Body>
                                            <Card.Title className="mb-3">
                                                <img src={require('../assets/img/payment-logo/perfect-money-sm.png')} alt="Account Types" className="img-fluid" />
                                            </Card.Title>
                                            <table className="table table-borderless account_types_table">
                                                <tbody>
                                                    <tr>
                                                        <td>Max. Deposit Amount</td>
                                                        <td>Nil</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Min. Deposit Amount</td>
                                                        <td>$10</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Fees</td>
                                                        <td>Nil</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Timing</td>
                                                        <td>Instant</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card>

                                </CardDeck>
                                <CardDeck className="mb-5">
                                    {/* <Card>
                                        <Card.Body>
                                            <Card.Title className="mb-3">
                                                <img src={require('../assets/img/payment-logo/bitcoin-sm.png')} alt="Account Types" className="img-fluid" />
                                            </Card.Title>
                                            <table className="table table-borderless account_types_table">
                                                <tbody>
                                                    <tr>
                                                        <td>Max. Deposit Amount</td>
                                                        <td>$10,000</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Min. Deposit Amount</td>
                                                        <td>$10</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Fees</td>
                                                        <td>Nil</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Timing</td>
                                                        <td>Instant</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card> */}
                                    <Card>
                                        <Card.Body>
                                            <Card.Title className="mb-3">
                                                <img src={require('../assets/img/payment-logo/fasapay-sm.png')} alt="Account Types" className="img-fluid" />
                                            </Card.Title>
                                            <table className="table table-borderless account_types_table">
                                                <tbody>
                                                    <tr>
                                                        <td>Max. Deposit Amount</td>
                                                        <td>$50,000</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Min. Deposit Amount</td>
                                                        <td>$10</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Fees</td>
                                                        <td>Nil</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Timing</td>
                                                        <td>Instant</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title className="mb-3">
                                                <img src={require('../assets/img/payment-logo/malaysia-sm.png')} alt="Account Types" className="img-fluid" />
                                                {/* MALAYSIA */}
                                            </Card.Title>
                                            <table className="table table-borderless account_types_table">
                                                <tbody>
                                                    <tr>
                                                        <td>Max. Deposit Amount</td>
                                                        <td>$12,000***</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Min. Deposit Amount</td>
                                                        <td>$15***</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Fees</td>
                                                        <td>Nil</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Timing</td>
                                                        <td>Instant</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title className="mb-3">
                                                <img src={require('../assets/img/payment-logo/indonesia-sm.png')} alt="Account Types" className="img-fluid" />
                                                {/* INDONESIA */}
                                            </Card.Title>
                                            <table className="table table-borderless account_types_table">
                                                <tbody>
                                                    <tr>
                                                        <td>Max. Deposit Amount</td>
                                                        <td>$14,000***	</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Min. Deposit Amount</td>
                                                        <td>$15***</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Fees</td>
                                                        <td>Nil</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Timing</td>
                                                        <td>Instant</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card>

                                </CardDeck>
                                <CardDeck className="mb-5">
                                    <Card>
                                        <Card.Body>
                                            <Card.Title className="mb-3">
                                                <img src={require('../assets/img/payment-logo/thailand-sm.png')} alt="Account Types" className="img-fluid" />
                                                {/* THAILAND */}
                                            </Card.Title>
                                            <table className="table table-borderless account_types_table">
                                                <tbody>
                                                    <tr>
                                                        <td>Max. Deposit Amount</td>
                                                        <td>$15,000***</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Min. Deposit Amount</td>
                                                        <td>$20***</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Fees</td>
                                                        <td>Nil</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Timing</td>
                                                        <td>Instant</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title className="mb-3">
                                                <img src={require('../assets/img/payment-logo/vietnam-sm.png')} alt="Account Types" className="img-fluid" />
                                                {/* VIETNAM */}
                                            </Card.Title>
                                            <table className="table table-borderless account_types_table">
                                                <tbody>
                                                    <tr>
                                                        <td>Max. Deposit Amount</td>
                                                        <td>$12,000***	</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Min. Deposit Amount</td>
                                                        <td>$15***	</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Fees</td>
                                                        <td>Nil</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Timing</td>
                                                        <td>Instant</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title className="mb-3">
                                                <img src={require('../assets/img/payment-logo/webmoney-sm.png')} alt="Account Types" className="img-fluid" />
                                                {/* WEBMONEY */}
                                            </Card.Title>
                                            <table className="table table-borderless account_types_table">
                                                <tbody>
                                                    <tr>
                                                        <td>Max. Deposit Amount</td>
                                                        <td>$50,000***</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Min. Deposit Amount</td>
                                                        <td>$10***	</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Fees</td>
                                                        <td>Nil</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Timing</td>
                                                        <td>Instant</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title className="mb-3">
                                                <img src={require('../assets/img/payment-logo/china-sm.png')} alt="Account Types" className="img-fluid" />
                                                {/* China Local Bank Transfer P2P  */}
                                            </Card.Title>
                                            <table className="table table-borderless account_types_table">
                                                <tbody>
                                                    <tr>
                                                        <td>Max. Deposit Amount</td>
                                                        <td>$7000^^*	</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Min. Deposit Amount</td>
                                                        <td>$20^^*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Fees</td>
                                                        <td>Nil</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Timing</td>
                                                        <td>Instant</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card>

                                </CardDeck>
                                <CardDeck className="mb-5">
                                    <Card>
                                        <Card.Body>
                                            <Card.Title className="mb-3">
                                                <img src={require('../assets/img/payment-logo/alipay-sm.png')} alt="Account Types" className="img-fluid" />
                                                {/* Alipay    */}
                                            </Card.Title>
                                            <table className="table table-borderless account_types_table">
                                                <tbody>
                                                    <tr>
                                                        <td>Max. Deposit Amount</td>
                                                        <td>$3000^^*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Min. Deposit Amount</td>
                                                        <td>$20^^*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Fees</td>
                                                        <td>Nil</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Timing</td>
                                                        <td>Instant</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title className="mb-3">
                                                <img src={require('../assets/img/payment-logo/union-pay-desktop.png')} alt="Account Types" className="img-fluid" />
                                                {/* China Union Pay Desktop */}
                                            </Card.Title>
                                            <table className="table table-borderless account_types_table">
                                                <tbody>
                                                    <tr>
                                                        <td>Max. Deposit Amount</td>
                                                        <td>$7000^^*	</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Min. Deposit Amount</td>
                                                        <td>$20^^*	</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Fees</td>
                                                        <td>Nil</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Timing</td>
                                                        <td>Instant</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title className="mb-3">
                                                <img src={require('../assets/img/payment-logo/union-pay-mobile.png')} alt="Account Types" className="img-fluid" />
                                                {/* China Union Pay Mobile   */}
                                            </Card.Title>
                                            <table className="table table-borderless account_types_table">
                                                <tbody>
                                                    <tr>
                                                        <td>Max. Deposit Amount</td>
                                                        <td>$700^^*</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Min. Deposit Amount</td>
                                                        <td>$20^^*	</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Fees</td>
                                                        <td>2%</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deposit Timing</td>
                                                        <td>Instant</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card>
                                </CardDeck>
                                <Col className="text-center mt-3 mb-5">
                                    <a href={('https://www.leoprime.com/user')} className="primary-button-lg my-2" >
                                        Deposit
                                    </a>
                                </Col>
                                <div>
                                    <h3 className="mb-3">Some Key Information on Deposit/ Withdrawal
                                    </h3>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>1. * For this deposit/withdrawal method there are limitations on maximum transfer amount without commission.
                                        </ListGroup.Item>
                                        <ListGroup.Item>2. ** Per day limit for an account for Instant Withdrawal. If the withdrawal exceeds the limit, the processing time will be 24Hrs.
                                        </ListGroup.Item>
                                        <ListGroup.Item>3. ***There is no limit on number of transactions per day for these modes.
                                        </ListGroup.Item>
                                        <ListGroup.Item>4. The term “instant” shall be understood to mean that a transaction is carried out within a few seconds without manual processing by financial department specialists.
                                        </ListGroup.Item>
                                        <ListGroup.Item>5. Funds may only be withdrawn to clients’ personal accounts in order to ensure financial security and prevent money laundering.
                                        </ListGroup.Item>
                                        <ListGroup.Item>6. The company will not accept direct payments or payments to third parties; clients are given all necessary information in their personal area when completing a transaction.
                                        </ListGroup.Item>
                                        <ListGroup.Item>7. Deposits and withdrawals may be made 24 hours a day, 7 days a week. If a deposit or withdrawal is not carried out instantly, it will be completed within 24 hours.
                                        </ListGroup.Item>
                                        <ListGroup.Item>8. The company shall not be liable for delays in processing deposits and withdrawals if such delays are caused by the payment system.
                                        </ListGroup.Item>
                                        <ListGroup.Item>9. Internal transfers between accounts of the same type are performed instantly.
                                        </ListGroup.Item>
                                        <ListGroup.Item>10. If a client funds his/her trading account using multiple payment systems or multiple wallets within the same payment system, funds must be withdrawn in proportion to the amounts deposited.
                                        </ListGroup.Item>
                                        <ListGroup.Item>11. The company retains the right to change the processing time for deposits and withdrawals without notifying clients in advance.
                                        </ListGroup.Item>
                                        <ListGroup.Item>12. The company may place limits on the payment systems available to clients from certain countries.
                                        </ListGroup.Item>
                                        <ListGroup.Item>13. ^* The Bank charges and Intermediary Bank Charges must be beared by the client at the time of transfers.
                                        </ListGroup.Item>
                                        <ListGroup.Item>14. ^^* Limit Per Transactions
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        );
    }
}
