import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withStyles } from "@material-ui/core/styles";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Input,
    // Grid,
    // FormHelperText
} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import { connect } from "react-redux";
import { getCountries } from "../store/actions/countryAction";
import { getStates } from "../store/actions/stateAction";

const styles = {
    formControl: {
        width: "100%",
        "& label span": {
            color: "red"
        }
    },
};

const IbForm = props => {
    const [isLoading, setIsLoading] = useState(true);
    const {
        form: { country, state },
        handleInput,
        isSubmitted,
        classes,
        countries,
        states
    } = props;

    useEffect(() => {
        props.getCountries({}, (rs) => {
            setIsLoading(false);
        });
        // eslint-disable-next-line
    }, []);

    const changeCountry = (event) => {
        let id = null;

        countries.forEach(c => {
            if (c.name === event.target.value) {
                id = c.id;
            }
        });

        if (id !== null) {
            props.getStates({ 'country': id }, (rs) => {
            });
        }

        handleInput(event);
    };

    if (isLoading) {
        return '';
    }
    return (
        <Row>
            <Col className="selectOption">
                <Row>
                    <Col sm={6}>
                        <FormControl required className={classes.formControl} error={isSubmitted && country === ''}>
                            <InputLabel
                                style={{ disableAnimation: false }}
                                disableAnimation={false}
                                htmlFor="country"
                            >
                                Search Country
                            </InputLabel>
                            <Select
                                value={country}
                                onChange={event => changeCountry(event)}
                                input={<Input name="country" id="country" />}
                            >
                                {
                                    countries.map((country) => {
                                        return (
                                            <MenuItem value={country.name} key={'c-' + country.name}>
                                                {country.name}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            {
                                isSubmitted && country === '' ?
                                    <FormHelperText>Please select your country from the list.</FormHelperText>
                                    : ''
                            }
                        </FormControl>
                    </Col>
                    <Col sm={6}>
                        <FormControl required className={classes.formControl} error={isSubmitted && state === ''}>
                            <InputLabel
                                style={{ disableAnimation: false }}
                                disableAnimation={false}
                                htmlFor="searchState"
                            >
                                Search State
                            </InputLabel>
                            <Select
                                value={state}
                                required
                                onChange={event => handleInput(event)}
                                input={<Input name="state" id="state" />}
                            >
                                {
                                    states.map((state) => {
                                        return (
                                            <MenuItem value={state.name} key={'c-' + state.name}>
                                                {state.name}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            {
                                isSubmitted && state === '' ?
                                    <FormHelperText>Please select your state from the list.</FormHelperText>
                                    : ''
                            }
                        </FormControl>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

const mapStateToProps = (state) => {
    const countries = [];
    Object.keys(state.countries).forEach(key => {
        countries.push(state.countries[key]);
    });

    const states = [];
    Object.keys(state.states).forEach(key => {
        states.push(state.states[key]);
    });

    return {
        countries: countries,
        states: states
    };
};

export default connect(
    mapStateToProps, {
    getCountries,
    getStates
}
)(withStyles(styles)(IbForm))
