import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
// import ReadyToTrade from '../components/ReadyToTrade'
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  faXTwitter,
  faInstagram,
  faFacebookF,
  faPinterestP,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import GooglePlayImg from "../assets/img/google-play-qrcode.svg";
import AppStoreImg from "../assets/img/app-store-qrcode.svg";
class LeoPrimeMobileApp extends Component {

  render() {

    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div>
        <Helmet
          title="Mobile App ioS, Android |  LeoPrime"
          meta={[
            { name: "author", content: "LeoPrime" },
            { name: "keywords", content: "Mobile App ioS, Android, LeoPrime, Leo Prime" },
            { name: "twitter:site", content: "LeoPrime" },
            { name: "twitter:creator", content: "LeoPrime" },
            { name: "twitter:title", content: "Mobile App ioS, Android |  LeoPrime" },
            {
              name: "twitter:image",
              content:
                "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg",
            },
            { property: "og:title", content: "Mobile App ioS, Android |  LeoPrime" },
            { property: "og:site_name", content: "LeoPrime" },
            { property: "og:type", content: "Forex Trading" },
            { property: "og:url", content: "https://leoprime.com/" },
            { property: "og:description", content: "Mobile App ioS, Android" },
            {
              property: "og:image",
              content:
                "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg",
            },
            { property: "og:site_name", content: "LeoPrime" },
            {
              name: "viewport",
              content: "width=device-width, maximum-scale=1",
            },
          ]}
        ></Helmet>
        <section className="mobile-app-section d-flex flex-column justify-content-center">
          <div class="col-md-11 mx-auto py-3">
            <Row>
              <nav class="navbar navbar-light">
                <Link className="header__logo-link navbar-brand" to="/">
                  <div className="logo"></div>
                </Link>
              </nav>
              <a
                href={"https://www.leoprime.com/user"}
                className="btn btnIb-outline-white my-2 ml-auto"
              >
                Login
              </a>
            </Row>
          </div>
          <div className="w-100 d-flex align-items-center">
            <Col lg={11} className="mx-auto">
              <Row>
                <Col
                  lg={5}
                  className="d-flex flex-column justify-content-center text-center text-lg-left"
                >
                  <h1>Leoprime</h1>
                  <h5>Mobile App</h5>
                  <div className="pb-3">
                    <p>
                      Get exposed to a Brand-New Mobile App with multiple functionalities, advanced tools, and
                      lot more features that are required for your hassle-free trading experience. We have
                      meticulously crafted this app in order to provide you the best mobile application for trading.
                    </p>
                  </div>
                  <div className="pb-3">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.leoprime.android"
                      target="blank"
                    >
                      <img
                        src={GooglePlayImg}
                        alt="google play"
                        className="img-fluid mr-3"
                        width="120"
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/in/app/leoprime/id1543420769"
                      target="blank"
                    >
                      <img
                        src={AppStoreImg}
                        alt="app store"
                        className="img-fluid"
                        width="120"
                      />
                    </a>
                  </div>
                </Col>
                <Col lg={7}>
                  <img
                    src={require("../assets/img/mobile-ios-android/ios-android-banner.png")}
                    alt="Mobile App ioS, Android"
                    className="img-fluid mb-3"
                  />
                </Col>
              </Row>
            </Col>
          </div>
        </section>

        <section className="mob-app-slider">
          <div className="mob-app-slider-bg-text">Trade On <br />the &nbsp; &nbsp;  &nbsp;  go</div>
          <div className="w-100 d-flex align-items-center">
            <Col
              lg={7}
              className="mx-auto my-5 py-5 d-flex flex-column justify-content-center text-center"
            >
              <Row>
                <img
                  src={require("../assets/img/mobile-ios-android/ios-android-test1.png")}
                  alt="Mobile App IOS and Android"
                  className="img-fluid mb-3 mx-auto"
                />
              </Row>


              <Slider
                {...settings}
              >
                <div>
                  <h5>
                    Open trading accounts
                  </h5>
                  <p>
                    Open your Trading Accounts with simple steps of registration
                    process which takes less than a minute.
                  </p>
                </div>
                <div>
                  <h5>
                    Simple funding
                  </h5>
                  <p>
                    With our multiple payment methods that include-transfers via Epayments, Debit/Credit cards, Bank Wire transfers, and many more, you can fund your
                    account with ease.
                  </p>
                </div>
                <div>
                  <h5>
                    Set your Passwords
                  </h5>
                  <p>
                    Set/Change the passwords of your Trading accounts and Personal
                    Area Cabinet even when you are away from your Desktop/Laptop.
                  </p>
                </div>
                <div>
                  <h5>
                    Complete your Profile
                  </h5>
                  <p>
                    Upload and verify your documents with simple steps.
                  </p>
                </div>

                <div>
                  <h5>
                    Leverage settings
                  </h5>
                  <p>
                    Choose your preferred Leverage available for your Trading
                    accounts.
                  </p>
                </div>

                <div>
                  <h5>
                    Smart fund management
                  </h5>
                  <p>
                    Instantly transfer funds between your trading accounts.
                  </p>
                </div>

                <div>
                  <h5>
                    Account Management
                  </h5>
                  <p>
                    Create & manage accounts, update personal information
                    anytime.
                  </p>
                </div>

                <div>
                  <h5>
                    2-step secure authentication
                  </h5>
                  <p>
                    Keep your trading & account data safe & secure with
                    us.
                  </p>
                </div>
              </Slider>



            </Col>
          </div>
        </section>


        <section className="mob-invest-app">
          <div className="w-100 d-flex align-items-center">
            <Col
              lg={8}
              className="mx-auto my-5 py-5 d-flex flex-column justify-content-center text-center"
            >
              <Row>
                <Col lg={8} className="mx-auto">
                  <h5 className="pb-5">Everything
                    you need in one investing app</h5></Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <img src={require("../assets/img/mobile-ios-android/registration.gif")}
                    alt="Mobile App" className="img-fluid mb-3" />
                  <h6>
                    Quick and easy registration process
                  </h6>
                </Col>
                <Col lg={3}>
                  <img src={require("../assets/img/mobile-ios-android/where-ever-you-go.gif")}
                    alt="Mobile App" className="img-fluid mb-3" />
                  <h6>
                    Access on the go
                  </h6>
                </Col>
                <Col lg={3}>
                  <img src={require("../assets/img/mobile-ios-android/24_7.gif")}
                    alt="Mobile App" className="img-fluid mb-3" />
                  <h6>
                    Round the clock support
                  </h6>
                </Col>
                <Col lg={3}>
                  <img src={require("../assets/img/mobile-ios-android/secure.gif")}
                    alt="Mobile App" className="img-fluid mb-3" />
                  <h6>
                    Secured with all new futures
                  </h6>
                </Col>



              </Row>
            </Col>
          </div>
        </section>



        <section className="mob-app-special">
          <div className="w-100 d-flex align-items-center">
            <Col
              lg={11}
              className="mx-auto my-5 py-5 d-flex flex-column justify-content-center"
            >
              <div className="mobile-banner-left">
                Trade <br />
                On <br />
                the go
              </div>
              <Row>
                <Col lg={6}>
                  <h5>Why LeoPrime?</h5>
                  <ul className="liststyle-bulet">
                    <li>FSA Regulated</li>
                    <li>Spread starts from 0.0 PIPS</li>
                    <li>Leverage Upto 1:1000</li>
                    <li>Automatic Withdrawals</li>
                    <li>Instant Execution</li>
                    <li>A wide range of financial derivatives including Currencies, Indices, Metals, Energies,
                      and the list goes on.</li>
                    <li>8 years of excellent service and valuable customer support.</li>
                    <li>Excellent multilingual customer support available 24/5.</li>
                  </ul>
                </Col>
                <img src={require("../assets/img/mobile-ios-android/app-in-hand.gif")}
                  alt="Mobile App" className="img-fluid mb-3 handgif" />
              </Row>

            </Col>
          </div>
        </section>



        <section className="mob-app-review">
          <div className="w-100 d-flex align-items-center">
            <Col
              lg={11}
              className="mx-auto mt-5 pt-5 d-flex flex-column justify-content-center"
            >
              <Row>
                <Col lg={8} className="mx-auto  text-center">
                  <h5 className="text-white pb-5">App Reviews from professional
                    traders</h5>
                </Col>
              </Row>
              <Row>
                <Col lg={5} className="text-center">
                  <img
                    src={require("../assets/img/mobile-ios-android/ios-android-app-review.png")}
                    alt="Mobile App ioS, Android"
                    className="img-fluid mb-3"
                  />
                </Col>
                <Col lg={7}>

                  <Row>
                    <Col lg={4}>
                      <img
                        src={require("../assets/img/mobile-ios-android/ios-android-quote.png")}
                        alt="Mobile App ioS, Android"
                        className="img-fluid mb-3"
                      />
                      <p>Best in class mobile application for all my trading needs. True transparency through their service.</p>
                    </Col>
                    <Col lg={4}>
                      <img
                        src={require("../assets/img/mobile-ios-android/ios-android-quote.png")}
                        alt="Mobile App ioS, Android"
                        className="img-fluid mb-3"
                      />
                      <p>All the trading technology under one application. User friendly and easy accessible mobile application for traders and investors.</p>
                    </Col>
                    <Col lg={4}>
                      <img
                        src={require("../assets/img/mobile-ios-android/ios-android-quote.png")}
                        alt="Mobile App ioS, Android"
                        className="img-fluid mb-3"
                      />
                      <p>Much needed application for traders. </p>
                    </Col>
                  </Row>


                </Col>
              </Row>

            </Col>
          </div>
        </section>



        <section className="mob-app-special">
          <div className="w-100 d-flex align-items-center">
            <Col
              lg={11}
              className="mx-auto my-5 py-5 d-flex flex-column justify-content-center"
            >
              <Row>
                <Col lg={7}>
                  <img src={require("../assets/img/mobile-ios-android/ios-android-app-trust.png")}
                    alt="Mobile App" className="img-fluid mb-3" />
                </Col>
                <Col lg={5} className="d-flex flex-column justify-content-center text-center text-lg-left">
                  <h1>Invest On The Go with a <span>Broker</span> you can trust</h1>
                  <div className="pb-3">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.leoprime.android"
                      target="blank"
                    >
                      <img
                        src={GooglePlayImg}
                        alt="google play"
                        className="img-fluid mr-3"
                        width="120"
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/in/app/leoprime/id1543420769"
                      target="blank"
                    >
                      <img
                        src={AppStoreImg}
                        alt="app store"
                        className="img-fluid"
                        width="120"
                      />
                    </a>
                  </div>
                </Col>

              </Row>

            </Col>
          </div>
        </section>



        <section class="section-padding footer-ct-Section pb-5">
          <div class="text-center">
            <ul className="list-inline sociallinks">
              <li className="list-inline-item">
                <a
                  target="blank"
                  href="https://www.facebook.com/LeoPrimeForexBrokers/"
                >
                  <FontAwesomeIcon icon={faFacebookF} className="fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a target="blank" href="https://twitter.com/LeoPrimeFx">
                  <FontAwesomeIcon icon={faXTwitter} className="fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a target="blank" href="https://www.instagram.com/leoprimefx/">
                  <FontAwesomeIcon icon={faInstagram} className="fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  target="blank"
                  href="https://www.linkedin.com/company/leo-prime"
                >
                  {" "}
                  <FontAwesomeIcon icon={faLinkedinIn} className="fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  target="blank"
                  href="https://in.pinterest.com/LeoPrimeFx/pins/"
                >
                  <FontAwesomeIcon icon={faPinterestP} className="fa-lg" />
                </a>
              </li>
            </ul>
            <p>
              Leo Inc,
              <br />
              G.S.Complex, 1st floor,
              <br />
              Office Number 12, Providence, <br />
              Mahe, Seychelles.
            </p>
            <p>support@leoprime.com</p>
            <p>+44 20380 85868</p>
          </div>
          <div class="container text-center mt-3">
            <p>
              Leo Inc is a Securities Dealer license
              registered in Seychelles with registration number 8424165-1 and
              authorised by the Financial Services Authority (FSA) with licence
              number SD032. The registered office of Leo Inc
              is at G.S.Complex, 1st floor, Office Number 12, Providence, Mahe,
              Seychelles
            </p>
            {/* <p>
              Leo Inc registered in the Marshall Islands
              (registration number 95149) and regulated in accordance with
              Marshall Islands Business Corporation Act. The Company’s address:
              Ajeltake Road, Ajeltake Island, Majuro, Marshall Islands MH96960.
            </p>
            <p>
              Risk Warning: FX and CFDs, which are leveraged products, incur a
              high level of risk and can result in the loss of all your invested
              capital. Therefore, FX and CFDs may not be suitable for all
              investors. You should not risk more than you are prepared to lose.
              Before deciding to trade, please ensure you understand the risks
              involved and take into account your level of experience. Seek
              independent advice if necessary.
            </p> */}
            <p>
              Leo Inc, does not offer its services to the
              residents of certain jurisdictions such as: USA, Belgium, North
              Korea, France, Australia, Israel, and Japan.
            </p>
            <p>© 2022 Leo Prime Services Ltd. - All Rights Reserved</p>
          </div>
        </section>
      </div>
    );
  }
}
export default LeoPrimeMobileApp;
