import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
// import ReadyToTrade from '../components/ReadyToTrade'
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faInstagram, faFacebookF, faPinterestP, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import IbPromotionTandC from '../documents/ib-programs/ib-promotion-global.pdf';
import IbPromotion from '../assets/img/ib-promotion/ib-promotion-banner.svg';
import IBcommission1 from '../assets/img/ib-promotion/commission-icon-1.svg';
import IBcommission2 from '../assets/img/ib-promotion/commission-icon-2.svg';
import IBcommission3 from '../assets/img/ib-promotion/commission-icon-3.svg';

import IbPromotion1 from '../assets/img/icons/ib-promotion-icon-1.svg';
import IbPromotion2 from '../assets/img/icons/ib-promotion-icon-2.svg';
import IbPromotion3 from '../assets/img/icons/ib-promotion-icon-3.svg';
import IbPromotion4 from '../assets/img/icons/ib-promotion-icon-4.svg';

import CLP from '../assets/img/ib-promotion/choose-leoprime-img.svg';

class ThirtyBonus extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="Introducing Broker Program | IB Broker |Bonus Program| LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "Introducing Broker Program, IB Broker Program, Introducing Broker, IB Broker, LeoPrime, Leo Prime" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Introducing Broker Program | IB Broker |Bonus Program| LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Introducing Broker Program | IB Broker |Bonus Program| LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "Become an Introducing Broker (IB) at LeoPrime. Earn limitless rewards every day with our attractive Extra Bonus Program." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <header>
                    <div class="col-md-10 offset-md-1 py-3">
                        <div class="row">
                            <nav class="navbar navbar-light">
                                <Link className="header__logo-link navbar-brand" to="/">
                                    <div className="logo-ib-promotion"></div>
                                </Link>
                            </nav>
                            <a href={('https://www.leoprime.com/user')} className="btn btnIb-outline my-2 ml-auto" >Login</a>
                        </div>
                    </div>
                </header>
                <section className="IbPromotion-section d-flex">
                    <div className="w-100 d-flex align-items-center">
                        <Col lg={10} className="mx-auto mt-5 d-flex flex-column justify-content-center">
                            <Row>
                                <Col lg={6}>
                                    <h5 class="font-weight-bold">The Extra Bonus Program that benefits every</h5>
                                    <img src={require('../assets/img/ib-promotion/banner-caption.png')} alt="IB Promotion" className="img-fluid" />
                                    <div className="pb-5">
                                        <p>LeoPrime offers an exclusive ‘Bonus Program’ to its Introducing Brokers. With our extra special reward scheme, every IB is given the benefit of scaling up their income, in addition to the regular commissions you earn.</p>

                                        <a href={('https://www.leoprime.com/register')} className="btn btnIb" >Register now</a>




                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <img src={IbPromotion} alt="IB Promotion" className="img-fluid mb-3" />
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </section>
                <section className="text-center d-flex ib-section">
                    <Col lg={10} className="mx-auto my-5">
                        <div className="commissionBox">
                            <h5 class="text-white mb-0">Maximize your</h5>
                            <img src={require('../assets/img/ib-promotion/commission-title.png')} alt="IB Promotion" className="img-fluid" />
                            <h5 class="text-white"> with our exclusive program</h5>
                            <div class="row no-gutters justify-content-lg-center mt-5 pt-5">
                                <div class="col-md-4 col-lg-3">
                                    <div class="card pricing-card">
                                        <div class="card-body">
                                            <img src={IBcommission1} alt="IB Promotion" className="img-fluid mb-3" />
                                            <h3 class="pricing-plan-cost">Attractive
                                                Rewards</h3>
                                            <ul class="pricing-plan-features">
                                                <li>With LeoPrime’s attractive ‘Extra Bonus Program’ you can earn limitless rewards every day.</li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-3">
                                    <div class="card pricing-card pricing-card-highlighted">
                                        <div class="card-body">
                                            <img src={IBcommission2} alt="IB Promotion" className="img-fluid mb-3" />
                                            <h3 class="pricing-plan-cost">Extra Benefits</h3>
                                            <ul class="pricing-plan-features">
                                                <li>We offer a lucrative IB commission of up to $8 per lot.  The regular IB commissions will be credited regardless of the extra benefits you earn from the Bonus Program.
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-3">
                                    <div class="card pricing-card">
                                        <div class="card-body">
                                            <img src={IBcommission3} alt="IB Promotion" className="img-fluid" />
                                            <h3 class="pricing-plan-cost">Instant
                                                Withdrawals</h3>
                                            <ul class="pricing-plan-features">
                                                <li>We provide instant withdrawals for both the regular IB commissions & the extra bonus you earn.</li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </section>

                <section className="choose-lp-section d-flex">
                    <div className="w-100 d-flex align-items-center">
                        <Col lg={10} className="mx-auto mt-5 d-flex flex-column justify-content-center">
                            <Row>

                                <Col lg={7} className="d-flex align-items-center box bounce-1">
                                    <img src={CLP} alt="IB Promotion" className="img-fluid mb-3" />
                                </Col>
                                <Col lg={5} className="text-lg-right">
                                    <h5 class="font-weight-bold mb-0">Why choose</h5>
                                    <img src={require('../assets/img/ib-promotion/choose-leoprime-title.png')} alt="IB Promotion" className="img-fluid" />
                                    {/* <h5 class="font-weight-bold">because</h5> */}
                                    <div className="py-3">
                                        <ul className="liststyle-bulet">
                                            <li>FSA regulated</li>
                                            <li>Spread starts from 0.0pips</li>
                                            <li>Leverage up to 1:1000</li>
                                            <li>Ultra- fast, low latency execution</li>
                                            <li>Automatic withdrawals</li>
                                            <li>24*5 customer support</li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </section>


                <section className="d-flex ib-section-bg pb-0">
                    <Col lg={10} className="mx-auto mt-5">
                        <Row className="display-flex justify-content-center text-center mb-5">
                            <div className="px-3">
                                <h5 class="text-white mb-0">How do you become an</h5>
                                <img src={require('../assets/img/ib-promotion/introducing-broker-title.png')} alt="IB Promotion" className="img-fluid" />
                                <h5 class="text-white"> at LeoPrime?</h5>
                            </div>
                        </Row>
                        <Row>
                            <Col lg={3} className="px-5">
                                <img src={IbPromotion1} alt="IB Promotion" className="img-fluid mb-3" />
                                <h5 class="text-white">Open Account</h5>
                                <p className="text-white">Open a live account with LeoPrime to create an IB account</p>
                            </Col>
                            <Col lg={3} className="px-5">
                                <img src={IbPromotion2} alt="IB Promotion" className="img-fluid mb-3" />
                                <h5 class="text-white">Get Partner Link</h5>
                                <p className="text-white">Get the partner link from us and share</p>
                            </Col>
                            <Col lg={3} className="px-5">
                                <img src={IbPromotion3} alt="IB Promotion" className="img-fluid mb-3" />
                                <h5 class="text-white">Active Signed-Up Clients</h5>
                                <p className="text-white">Build up a network of active signed-up clients</p>
                            </Col>
                            <Col lg={3} className="px-5">
                                <img src={IbPromotion4} alt="IB Promotion" className="img-fluid mb-3" />
                                <h5 class="text-white">Earn Commissions</h5>
                                <p className="text-white">Start earning commissions per standard lot</p>
                            </Col>
                            <Col className="my-5 text-center">
                                {/* <a href="/" type="button" className="btn btnIb-yellow">Register now <i className="fas fa-arrow-right"></i></a> */}
                                <a href={('https://www.leoprime.com/register')} className="btn btnIb-yellow" >Register now</a>
                                <a href={IbPromotionTandC} target="blank" className="terms">  <u>*Terms &amp; Conditions</u></a>
                            </Col>
                        </Row>
                    </Col>
                </section>

                <section class="section-padding footer-ib-Section pb-5">
                    <div class="text-center">
                        <ul className="list-inline sociallinks">
                            <li className="list-inline-item">
                                <a target="blank" href="https://www.facebook.com/LeoPrimeForexBrokers/"><FontAwesomeIcon icon={faFacebookF} className="fa-lg" /></a>
                            </li>
                            <li className="list-inline-item">
                                <a target="blank" href="https://twitter.com/LeoPrimeFx"><FontAwesomeIcon icon={faXTwitter} className="fa-lg" /></a>
                            </li>
                            <li className="list-inline-item">
                                <a target="blank" href="https://www.instagram.com/leoprimefx/"><FontAwesomeIcon icon={faInstagram} className="fa-lg" /></a>
                            </li>
                            <li className="list-inline-item">
                                <a target="blank" href="https://www.linkedin.com/company/leo-prime"> <FontAwesomeIcon icon={faLinkedinIn} className="fa-lg" /></a>
                            </li>
                            <li className="list-inline-item">
                                <a target="blank" href="https://in.pinterest.com/LeoPrimeFx/pins/"><FontAwesomeIcon icon={faPinterestP} className="fa-lg" /></a>
                            </li>
                        </ul>
                        <p>Leo Inc,
                            <br />G.S.Complex, 1st floor,<br />
                            Office Number 12, Providence, <br />
                            Mahe, Seychelles.
                        </p>
                        <p>support@leoprime.com</p>
                        <p>+44 20380 85868</p>
                    </div>
                    <div class="container text-center mt-3">
                        <p>
                            Leo Inc is a Securities Dealer license registered in Seychelles with registration number 8424165-1 and authorised by the Financial Services Authority (FSA) with licence number SD032. The registered office of Leo Inc is at G.S.Complex, 1st floor, Office Number 12, Providence, Mahe, Seychelles
                        </p>
                        {/* <p>
                            Leo Inc registered in the Marshall Islands (registration number 95149) and regulated in accordance with Marshall Islands Business Corporation Act. The Company’s address: Ajeltake Road, Ajeltake Island, Majuro, Marshall Islands MH96960.
                    
                        </p>
                        <p>
                            
                                Risk Warning: FX and CFDs, which are leveraged products, incur a high level of risk and can result in the loss of all your invested capital. Therefore, FX and CFDs may not be suitable for all investors. You should not risk more than you are prepared to lose. Before deciding to trade, please ensure you understand the risks involved and take into account your level of experience. Seek independent advice if necessary.
                    
                        </p> */}
                        <p>
                            Leo Inc, does not offer its services to the residents of certain jurisdictions such as: USA, Belgium, North Korea, France, Australia, Israel, and Japan.

                        </p>
                        <p>
                            © 2022 Leo Prime Services Ltd. - All Rights Reserved

                        </p>

                    </div>
                </section>

            </div>
        );
    }
}
export default ThirtyBonus;