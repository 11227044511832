import { actionType } from "../actionType";

const socialTradingViewReducer = (state = {}, action) => {
    if (action.type === actionType.FETCH_SOCIAL_TRADING_VIEW) {
        return { state, ...action.payload };
    }
    return state;
}

export default socialTradingViewReducer;
