import { actionType } from "../actionType";
import apiConfig from '../../config/apiCCConfig';

export const getSocialTradingRanks = (param, cb) => async (dispatch) => {
    let url = 'api/v3/public/strategy/list';

    await apiConfig.post(url, param)
        .then((response) => {
            dispatch({ type: actionType.FETCH_SOCIAL_TRADING_RANK, payload: response.data });
            cb(response.data);
        })
};
