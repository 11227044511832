import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

export default function Error() {
    return (
        <div>
<Helmet
                    title="404 Error | Top forex broker | LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "404, error" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "404 Error | Top forex broker | LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "404 Error | Top forex broker | LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "404 Error" },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                   
                </Helmet>
            <section>
                <div className="container">
                    <Col lg={8} className="mx-auto">
                        <Row className="mb-3 text-center">
                            {/* <h1 className="w-100 mt-5">
                                404
                    </h1>
                            <p className="w-100">Page not found
</p> */}
                            <img src={require('../assets/img/error-404.jpg')} alt="error" />
                            <p>Please verify that the address you entered is correct. If you reached this page by clicking a link on the official LeoPrime website, please inform our support service.</p>
                        </Row>

                    </Col>
                    <Row className="justify-content-md-center">
                        <Col className="text-center mt-4">
                            <Link to="/contact-us">
                                <button className="primary-button-lg" type="button">  Contact Us</button>
                            </Link>

                        </Col>
                    </Row>
                </div>
            </section>
        </div>

    )
}
