import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardDeck } from 'react-bootstrap';
// import ReadyToTrade from '../components/ReadyToTrade'
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faInstagram, faFacebookF, faPinterestP, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import fiftybonussouthafricapdf from '../documents/bonus/50bonus-south-africa.pdf';
import SA501 from '../assets/img/icons/bonus-50-sa-1.svg';
import SA502 from '../assets/img/icons/bonus-50-sa-2.svg';
import SA503 from '../assets/img/icons/bonus-50-sa-3.svg';
import SA504 from '../assets/img/icons/bonus-50-sa-4.svg';

class FiftyBonusNew extends Component {

  render() {

    return (
      <div className="fiftybonus-sa-section">
        <Helmet
          title="Leoprime - Guaranteed 50% Bonus on Every Deposit"
          meta={[
            { name: 'author', content: "LeoPrime" },
            { name: 'keywords', content: "Bonus" },
            { name: 'twitter:site', content: "LeoPrime" },
            { name: 'twitter:creator', content: "LeoPrime" },
            { name: 'twitter:title', content: "Leoprime - Guaranteed 50% Bonus on Every Deposit" },
            { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
            { property: 'og:title', content: "LeoPrime - Guaranteed 50% Bonus on Every Deposit" },
            { property: 'og:site_name', content: "LeoPrime" },
            { property: 'og:type', content: "Forex Trading" },
            { property: 'og:url', content: "https://leoprime.com/" },
            { property: 'og:description', content: "LeoPrime - Guaranteed 50% Bonus on Every Deposit" },
            { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
            { property: 'og:site_name', content: "LeoPrime" },
            { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
          ]}
        >
          <script type="text/javascript">
            {
              `
                        var xhttp = new XMLHttpRequest();

function GetURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam) {
            return sParameterName[1];
        }
    }
    return 0;
}
if (localStorage.getItem("cpid") === null) {
    localStorage.setItem("cpid", GetURLParameter('cpid'));
}

if (localStorage.getItem("referral") === null) {
    localStorage.setItem("referral", GetURLParameter('referral'));

    xhttp.open("POST", "https://cc.leoprime.com/api/v1/referral", true);
    xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhttp.send("referral=" + GetURLParameter('referral'));

}

if (localStorage.getItem("cpid") == 0) {
    localStorage.setItem("cpid", GetURLParameter('cpid'));
}

if (localStorage.getItem("referral") == 0) {
    localStorage.setItem("referral", GetURLParameter('referral'));
}
                        `
            }
          </script>
        </Helmet>
        <section className="fiftybonussabanner d-flex flex-column justify-content-center mb-5 pt-0">
          <div class="col-md-11 mx-auto py-3">
            <Row>
              <nav class="navbar navbar-light">
                <Link className="header__logo-link navbar-brand" to="/">
                  {/* <div className="logo"></div> */}
                  <img
                    src={require("../assets/img/leoinc-logo-white.png")}
                    alt="50 bonus South Africa"
                    className="img-fluid mb-3"
                  />
                </Link>
              </nav>
              <a
                href={"https://www.leoprime.com/user"}
                className="btn btnIb-outline-white my-2 ml-auto"
              >
                Login
              </a>
            </Row>
          </div>
          <div className="w-100 d-flex align-items-center">
            <Col lg={12}>
              <Row>
                <Col lg={6}>
                  <img
                    src={require("../assets/img/bonus/southafrica/banner-img-bonus-sa.png")}
                    alt="50 bonus South Africa"
                    className="img-fluid mb-3 d-none d-lg-block"
                  />
                  <img
                    src={require("../assets/img/bonus/southafrica/banner-img-bonus-sa-mobile.png")}
                    alt="50 bonus South Africa"
                    className="img-fluid mb-3 d-block d-lg-none"
                  />
                </Col>
                <Col
                  lg={6}
                  className="d-flex flex-column justify-content-center text-center text-lg-left BannerHeading"
                >
                  <div className="BannerHeadingTxt">
                    <h3 className='mt-0 d-block d-lg-none'>POWER UP YOUR TRADING ACCOUNT WITH OUR</h3>
                    <h1>50%</h1>
                    {/* <img
                      src={require("../assets/img/bonus/southafrica/50-text.png")}
                      alt="50 bonus South Africa"
                      className="img-fluid mb-3"
                    /> */}
                    <h3>TRADABLE BONUS</h3>

                    <Row>
                      <Col lg={4} className="my-3">

                        <h6>
                          Only for <span>South African</span> Clients
                        </h6>
                        <p>Our Tradable Bonus is exclusively for all our South African Clients who have opened a live trading account & verified it.</p>
                      </Col>
                      <Col lg={4} className="my-3">

                        <h6>
                          Increase your <span>Trading </span> power
                        </h6>
                        <p>Get to trade more by claiming your 50% tradable bonus on the amount you deposit. </p>
                      </Col>
                      <Col lg={4} className="my-3">

                        <h6>
                          Instant  <span>Withdrawals</span>
                        </h6>
                        <p>Get access to our instant deposits & withdrawals through South African local bank transfers or any other payment methods of your choice. </p>
                      </Col>




                    </Row>
                  </div>
                </Col>

              </Row>
            </Col>
          </div>
          <div className="bgTextShadow">TRADABLE</div>
        </section>


        <section className="fiftyusdsection">
          <div className="w-100 d-flex align-items-center flex-column justify-content-center">
            <h3>Claim Tradable bonus of up to </h3>
            <div className="fiftyusdsectionInner">
              <h1>USD 2500 <span>*</span></h1>
            </div>
            <p>Benefit from our incredible offers that come with world-class trading conditions</p>


            <Col className="text-center mb-5 fiftyusdsectionInner">
              <a href={('https://www.leoprime.com/register')} className="yellow-button-lg my-2" >Get My Bonus</a> <br />
              <a href={fiftybonussouthafricapdf} target="blank" className="text-white ">  <u>*Terms &amp; Conditions</u></a>
            </Col>
            <div className="bgTextShadow">BONUS</div>
          </div>

        </section>

        <section className="fiftyleftsection">
          <div className="w-100 d-flex align-items-center">
            <Col
              lg={10}
              className="mx-auto my-5 d-flex flex-column justify-content-center text-lg-right"
            >
              <div className='bgTextShadow-left'>01</div>
              <Row>
                <Col lg={8}>
                  <h6>
                    INSTANT
                  </h6>
                  <h3>DEPOSIT &</h3>
                  <h2>WITHDRAWAL</h2>
                </Col>
                <Col lg={4}>
                  <img src={require("../assets/img/bonus/southafrica/01-deposit.png")}
                    alt="Mobile App" className="img-fluid mb-3 rightImg" />
                </Col>
              </Row>
            </Col>
          </div>
        </section>

        <section className="fiftyrightsection">
          <div className="w-100 d-flex align-items-center">
            <Col
              lg={10}
              className="mx-auto my-5 d-flex flex-column justify-content-center"
            >
              <div className='bgTextShadow-right'>02</div>
              <Row>
                <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} lg={{ span: 4, order: 1 }}>
                  <img src={require("../assets/img/bonus/southafrica/02-trading-account.png")}
                    alt="Mobile App" className="img-fluid mb-3 leftImg" />
                </Col>

                <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} lg={{ span: 8, order: 2 }}>
                  <h6>
                    BONUS FUND THAT ADDS
                  </h6>
                  <h3>power to your</h3>
                  <h2>Trading account</h2>
                </Col>
              </Row>
            </Col>
          </div>
        </section>

        <section className="fiftyleftsection">
          <div className="w-100 d-flex align-items-center">
            <Col
              lg={10}
              className="mx-auto my-5 d-flex flex-column justify-content-center text-lg-right"
            >
              <div className='bgTextShadow-left'>03</div>
              <Row>
                <Col lg={8}>
                  <h6>
                    MINIMUM
                  </h6>
                  <h3>DEPOSIT is</h3>
                  <h2>USD 100</h2>
                </Col>
                <Col lg={4}>
                  <img src={require("../assets/img/bonus/southafrica/03-min-deposit.png")}
                    alt="Mobile App" className="img-fluid mb-3 rightImg" />
                </Col>
              </Row>
            </Col>
          </div>
        </section>

        <section className="fiftyrightsection">
          <div className="w-100 d-flex align-items-center">
            <Col
              lg={10}
              className="mx-auto my-5 d-flex flex-column justify-content-center"
            >
              <div className='bgTextShadow-right'>04</div>
              <Row>

                <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} lg={{ span: 4, order: 1 }}>
                  <img src={require("../assets/img/bonus/southafrica/04-max-deposit.png")}
                    alt="Mobile App" className="img-fluid mb-3 leftImg" />
                </Col>
                <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} lg={{ span: 8, order: 2 }}>
                  <h6>
                    MAXIMUM BONUS
                  </h6>
                  <h3>FOR A PROFILE IS</h3>
                  <h2>USD 2500*</h2>
                </Col>
              </Row>
            </Col>
          </div>
        </section>


        <section className="fiftyHowtocliamsection pb-0">
          <div className="w-100 d-flex align-items-center">
            <Col
              lg={10}
              className="mx-auto mt-5 d-flex flex-column justify-content-center"
            >
              <Row>
                <Col lg={12} className="my-5 text-lg-left text-center">
                  <h3>HOW TO CLAIM YOUR </h3>
                  <h2><span>50%</span> TRADABLE BONUS ? </h2>
                </Col>
                <Col lg={12}>
                  <CardDeck className="mb-5">
                    <Card>
                      <Card.Body className="text-center">
                        <Card.Title className="mb-3">
                          STEP 1
                        </Card.Title>
                        <img src={SA501} alt="How to Claim" className="img-fluid" />
                        <p>Login to your Personal Area Cabinet, once you have opened your trading account
                        </p>
                      </Card.Body>
                    </Card>
                    <Card>
                      <Card.Body className="text-center">
                        <Card.Title className="mb-3">
                          STEP 2
                        </Card.Title>
                        <img src={SA502} alt="How to Claim" className="img-fluid" />
                        <p>Click on ‘Edit Profile’ under the ‘My Profile’ tab and fill all the required details. Then, click on the ‘Upload Document’ section to upload the document showing your proof of address, and another document that confirms your identity.
                        </p>
                      </Card.Body>
                    </Card>
                    <Card>
                      <Card.Body className="text-center">
                        <Card.Title className="mb-3">
                          STEP 3
                        </Card.Title>
                        <img src={SA503} alt="How to Claim" className="img-fluid" />
                        <p>When your account is verified, deposit at least a minimum of USD 100 to your Trading Account through South African Local Bank transfer or any other payment method of your choice.
                        </p>
                      </Card.Body>
                    </Card>
                    <Card>
                      <Card.Body className="text-center">
                        <Card.Title className="mb-3">
                          STEP 4
                        </Card.Title>
                        <img src={SA504} alt="How to Claim" className="img-fluid my-2" />
                        <p>Once completed, please reach us via live chat or send an email to support@leoprime.com within 24 hours to claim the bonus.
                        </p>
                      </Card.Body>
                    </Card>
                  </CardDeck>
                </Col>
              </Row>
            </Col>

          </div>
          <Col className="text-center mb-5">
            <a href={('https://www.leoprime.com/register')} className="yellow-button-lg my-2" >Get My Bonus</a> <br />
            <a href={fiftybonussouthafricapdf} target="blank" className="text-white">  <u>*Terms &amp; Conditions</u></a>
          </Col>
        </section>

        <img src={require("../assets/img/bonus/southafrica/footer-bg.png")}
          alt="Mobile App" className="img-fluid" />
        <section class="section-padding footer-ct-Section pb-5">
          <div class="text-center">
            <ul className="list-inline sociallinks">
              <li className="list-inline-item">
                <a
                  target="blank"
                  href="https://www.facebook.com/LeoPrimeForexBrokers/"
                >
                  <FontAwesomeIcon icon={faFacebookF} className="fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a target="blank" href="https://twitter.com/LeoPrimeFx">
                  <FontAwesomeIcon icon={faXTwitter} className="fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a target="blank" href="https://www.instagram.com/leoprimefx/">
                  <FontAwesomeIcon icon={faInstagram} className="fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  target="blank"
                  href="https://www.linkedin.com/company/leo-prime"
                >
                  {" "}
                  <FontAwesomeIcon icon={faLinkedinIn} className="fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  target="blank"
                  href="https://in.pinterest.com/LeoPrimeFx/pins/"
                >
                  <FontAwesomeIcon icon={faPinterestP} className="fa-lg" />
                </a>
              </li>
            </ul>
            <p>
              Leo Inc,
              <br />
              G.S.Complex, 1st floor,
              <br />
              Office Number 12, Providence, <br />
              Mahe, Seychelles.
            </p>
            <p>support@leoprime.com</p>
            <p>+44 20380 85868</p>
          </div>
          <div class="container text-center mt-3">
            <p>
              Leo Inc is a Securities Dealer license
              registered in Seychelles with registration number 8424165-1 and
              authorised by the Financial Services Authority (FSA) with licence
              number SD032. The registered office of Leo Inc
              is at G.S.Complex, 1st floor, Office Number 12, Providence, Mahe,
              Seychelles
            </p>
            {/* <p>
              Leo Inc registered in the Marshall Islands
              (registration number 95149) and regulated in accordance with
              Marshall Islands Business Corporation Act. The Company’s address:
              Ajeltake Road, Ajeltake Island, Majuro, Marshall Islands MH96960.
            </p>
            <p>
              Risk Warning: FX and CFDs, which are leveraged products, incur a
              high level of risk and can result in the loss of all your invested
              capital. Therefore, FX and CFDs may not be suitable for all
              investors. You should not risk more than you are prepared to lose.
              Before deciding to trade, please ensure you understand the risks
              involved and take into account your level of experience. Seek
              independent advice if necessary.
            </p> */}
            <p>
              Leo Inc, does not offer its services to the
              residents of certain jurisdictions such as: USA, Belgium, North
              Korea, France, Australia, Israel, and Japan.
            </p>
            <p>© 2022 Leo Prime Services Ltd. - All Rights Reserved</p>
          </div>
        </section>

      </div >
    );
  }
}
export default FiftyBonusNew;
