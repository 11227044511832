import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
// import ReadyToTrade from '../components/ReadyToTrade'
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fiftybonuspdf from '../documents/30_pro_accounts.pdf';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  faXTwitter,
  faInstagram,
  faFacebookF,
  faPinterestP,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import GooglePlayImg from "../assets/img/google-play-qrcode.svg";
import AppStoreImg from "../assets/img/app-store-qrcode.svg";
class ThirtyProAccount extends Component {
  render() {

    return (
      <div>
        <Helmet
          title="Mobile App ioS, Android |  LeoPrime"
          meta={[
            { name: "author", content: "LeoPrime" },
            { name: "keywords", content: "Mobile App ioS, Android, LeoPrime, Leo Prime" },
            { name: "twitter:site", content: "LeoPrime" },
            { name: "twitter:creator", content: "LeoPrime" },
            { name: "twitter:title", content: "Mobile App ioS, Android |  LeoPrime" },
            {
              name: "twitter:image",
              content:
                "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg",
            },
            { property: "og:title", content: "Mobile App ioS, Android |  LeoPrime" },
            { property: "og:site_name", content: "LeoPrime" },
            { property: "og:type", content: "Forex Trading" },
            { property: "og:url", content: "https://leoprime.com/" },
            { property: "og:description", content: "Mobile App ioS, Android" },
            {
              property: "og:image",
              content:
                "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg",
            },
            { property: "og:site_name", content: "LeoPrime" },
            {
              name: "viewport",
              content: "width=device-width, maximum-scale=1",
            },
          ]}
        ></Helmet>
        <section className="thirty-bonus-section d-flex flex-column justify-content-center">
          <div class="col-md-11 mx-auto py-3">
            <Row>
              <nav class="navbar navbar-light">
                <Link className="header__logo-link navbar-brand" to="/">
                  <div className="logo"></div>
                </Link>
              </nav>
              <a
                href={"https://www.leoprime.com/user"}
                className="btn btnIb-outline-white my-2 ml-auto"
              >
                Login
              </a>
            </Row>
          </div>
          <div className="w-100 d-flex align-items-center">
            <Col lg={11} className="mx-auto">
              <Row>
                <Col
                  lg={5}
                  className="d-flex flex-column justify-content-center text-center text-lg-left"
                >
                  <img
                    src={require("../assets/img/30-pro-account/30-pro-account-text.png")}
                    alt="Mobile App ioS, Android"
                    className="img-fluid mb-3"
                  />
                  <p className='text-white'>LeoPrime offers an exclusive 30% Tradable Bonus Program to all the new traders who register & start trading using the Pro Account. This program provides a great opportunity for traders to increase their Free Margin with a 30% bonus on each deposit and thus, power up their trading account.</p>
                  <div>
                    <a href={('https://www.leoprime.com/register')} className="btn btnProAcc mt-4" >Register now</a>
                  </div>
                </Col>
                <Col lg={7}>
                  <img
                    src={require("../assets/img/30-pro-account/30-pro-account-banner.png")}
                    alt="Mobile App ioS, Android"
                    className="img-fluid mb-3  d-lg-block d-none"
                  />
                </Col>
              </Row>
            </Col>
          </div>
        </section>
        <section className="step-section bg-gray">
          <div className="w-100 d-flex align-items-center">
            <Col
              lg={9}
              className="mx-auto my-5 py-5 d-flex flex-column justify-content-center text-center"
            >
              <Row>
                <Col lg={6} className="mx-auto">
                  <h2 className="pb-5">Follow these simple steps to claim the Bonus instantly</h2></Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <img src={require("../assets/img/30-pro-account/30-pro-account-icon-1.png")}
                    alt="Mobile App" className="img-fluid mb-3" />
                  <h3>Step 1</h3>
                  <h6>
                    Register and then log in to your Personal Area Cabinet.
                  </h6>
                </Col>
                <Col lg={3}>
                  <img src={require("../assets/img/30-pro-account/30-pro-account-icon-2.png")}
                    alt="Mobile App" className="img-fluid mb-3" />
                  <h3>Step 2</h3>
                  <h6>
                    In the “My Profile” tab, click on the “Edit Profile” and fill in all the required fields. Then click on the “Upload Document” section to upload a document showing your proof of address and another document that confirms your identity.
                  </h6>
                </Col>
                <Col lg={3}>
                  <img src={require("../assets/img/30-pro-account/30-pro-account-icon-3.png")}
                    alt="Mobile App" className="img-fluid mb-3" />
                  <h3>Step 3</h3>
                  <h6>
                    If the account is verified, and you have made your deposit of at least USD 100 to your Trading account, then please reach us via live chat or send an email to support@leoprime.com within 24 hours to claim the bonus.
                  </h6>
                </Col>
                <Col lg={3}>
                  <img src={require("../assets/img/30-pro-account/30-pro-account-icon-4.png")}
                    alt="Mobile App" className="img-fluid mb-3" />
                  <h3>Step 4</h3>
                  <h6>
                    Now start trading and make the best use of the Tradable Bonus you have received.
                  </h6>
                </Col>
              </Row>
            </Col>
          </div>
        </section>
        <section className='worldClass-trading'>
          <div className="w-100 d-flex align-items-center">
            <Col
              lg={11}
              className="mx-auto mt-5 pt-5 d-flex flex-column justify-content-center"
            >
              <Row>
                <Col lg={8} className="mx-auto  text-center">
                  <h5 className="text-white pb-5">App Reviews from professional
                    traders</h5>
                </Col>
              </Row>
              <Row>
                <Col lg={5} className="text-center">
                  <img
                    src={require("../assets/img/30-pro-account/30-pro-account-img-1.png")}
                    alt="Mobile App ioS, Android"
                    className="img-fluid mb-3"
                  />
                </Col>
                <Col lg={7}>
                  <Row>
                    <Col lg={10}><h5 class="font-weight-bold mb-2">Experience incredible benefits with</h5>
                      <img
                        src={require("../assets/img/30-pro-account/30-pro-account-img-2.png")}
                        alt="Mobile App ioS, Android"
                        className="img-fluid"
                      />
                      <div class="py-3">
                        <ul class="liststyle-bulet">
                          <li>This exclusive program is available to all the new traders who open a live Pro Account with LeoPrime.</li>
                          <li>You will get a Maximum Leverage of up to 1:300.</li>
                          <li>You should make a Minimum Deposit of USD 100 to claim this bonus.</li>
                          <li>The maximum amount of total bonus allocated for a profile is USD 1500.</li>
                          <li>The validity period of this Bonus is 90 calendar days & it starts right from the date the bonus is availed.</li>
                        </ul></div>
                      <div>
                        <a href={('https://www.leoprime.com/register')} className="btn btnProAccBlue my-2" >Register now</a>
                      </div>
                      <p>
                        <a href={fiftybonuspdf} target="blank" className="text-dark">  <u>Terms &amp; Conditions*</u></a>
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </div>
        </section>
        <section className="mob-app-special">
          <div className="w-100 d-flex align-items-center">
            <Col
              lg={11}
              className="mx-auto mt-5 pt-5 d-flex flex-column justify-content-center"
            >
              <Row>
                <Col lg={6}>
                  <img src={require("../assets/img/mobile-ios-android/ios-android-app-trust.png")}
                    alt="Mobile App" className="img-fluid" />
                </Col>
                <Col lg={4} className="d-flex flex-column justify-content-center text-center text-lg-left">
                  <h1 className='text-uppercase'>Invest on-the-move with The most trusted Forex broker</h1>
                  <div className="pb-3">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.leoprime.android"
                      target="blank"
                    >
                      <img
                        src={GooglePlayImg}
                        alt="google play"
                        className="img-fluid mr-3"
                        width="120"
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/in/app/leoprime/id1543420769"
                      target="blank"
                    >
                      <img
                        src={AppStoreImg}
                        alt="app store"
                        className="img-fluid"
                        width="120"
                      />
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
          </div>
        </section>
        <section class="section-padding footer-ct-Section pb-5">
          <div class="text-center">
            <ul className="list-inline sociallinks">
              <li className="list-inline-item">
                <a
                  target="blank"
                  href="https://www.facebook.com/LeoPrimeForexBrokers/"
                >
                  <FontAwesomeIcon icon={faFacebookF} className="fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a target="blank" href="https://twitter.com/LeoPrimeFx">
                  <FontAwesomeIcon icon={faXTwitter} className="fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a target="blank" href="https://www.instagram.com/leoprimefx/">
                  <FontAwesomeIcon icon={faInstagram} className="fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  target="blank"
                  href="https://www.linkedin.com/company/leo-prime"
                >
                  {" "}
                  <FontAwesomeIcon icon={faLinkedinIn} className="fa-lg" />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  target="blank"
                  href="https://in.pinterest.com/LeoPrimeFx/pins/"
                >
                  <FontAwesomeIcon icon={faPinterestP} className="fa-lg" />
                </a>
              </li>
            </ul>
            <p>
              Leo Inc,
              <br />
              G.S.Complex, 1st floor,
              <br />
              Office Number 12, Providence, <br />
              Mahe, Seychelles.
            </p>
            <p>support@leoprime.com</p>
            <p>+44 20380 85868</p>
          </div>
          <div class="container text-center mt-3">
            <p>
              Leo Inc is a Securities Dealer license
              registered in Seychelles with registration number 8424165-1 and
              authorised by the Financial Services Authority (FSA) with licence
              number SD032. The registered office of Leo Inc
              is at G.S.Complex, 1st floor, Office Number 12, Providence, Mahe,
              Seychelles
            </p>
            {/* <p>
              Leo Inc registered in the Marshall Islands
              (registration number 95149) and regulated in accordance with
              Marshall Islands Business Corporation Act. The Company’s address:
              Ajeltake Road, Ajeltake Island, Majuro, Marshall Islands MH96960.
            </p>
            <p>
              Risk Warning: FX and CFDs, which are leveraged products, incur a
              high level of risk and can result in the loss of all your invested
              capital. Therefore, FX and CFDs may not be suitable for all
              investors. You should not risk more than you are prepared to lose.
              Before deciding to trade, please ensure you understand the risks
              involved and take into account your level of experience. Seek
              independent advice if necessary.
            </p> */}
            <p>
              Leo Inc, does not offer its services to the
              residents of certain jurisdictions such as: USA, Belgium, North
              Korea, France, Australia, Israel, and Japan.
            </p>
            <p>© 2022 Leo Prime Services Ltd. - All Rights Reserved</p>
          </div>
        </section>
      </div>
    );
  }
}
export default ThirtyProAccount;
