import { actionType } from "../actionType";
import apiConfig from '../../config/apiCCConfig';
export const getClientSentiments = (cb) => async (dispatch) => {
    try {
        const response = await apiConfig.post('api/v3/clientposition', {});
        dispatch({
            type: actionType.FETCH_CLIENT_SENTIMENT,
            payload: response.data,
        });
        cb(response.data);
    } catch (error) {
        console.log('error', error.message);
    }
};
