import { actionType } from "../actionType";
import apiConfig from '../../config/apiCCConfig';

export const getStates = (param, cb) => async (dispatch) => {
    await apiConfig.post('api/v3/state', param)
        .then((response) => {
            dispatch({ type: actionType.FETCH_STATES, payload: response.data });
            cb(response.data);
        })
};
