export const actionType = {
    //GET
    FETCH_GRADE_LIQUIDITY: 'FETCH_GRADE_LIQUIDITY',
    FETCH_FX_CALCULATOR: 'FETCH_FX_CALCULATOR',
    FETCH_COUNTRIES: 'FETCH_COUNTRIES',
    FETCH_STATES: 'FETCH_STATES',
    FETCH_CLIENT_SENTIMENT: 'FETCH_CLIENT_SENTIMENT',
    FETCH_SOCIAL_TRADING_RANK: 'FETCH_SOCIAL_TRADING_RANK',
    FETCH_PAST_CONTEST_LIST: 'FETCH_PAST_CONTEST_LIST',
    FETCH_RANK_CONTEST_LIST: 'FETCH_RANK_CONTEST_LIST',
    FETCH_MONITORING_LIST: 'FETCH_MONITORING_LIST',
    FETCH_BLOG: 'FETCH_BLOG',
    FETCH_SOCIAL_TRADING_VIEW: 'FETCH_SOCIAL_TRADING_VIEW',
    FETCH_SOCIAL_TRADING_STAT: 'FETCH_SOCIAL_TRADING_STAT',
    FETCH_SOCIAL_TRADING_HIST: 'FETCH_SOCIAL_TRADING_HIST',
    FETCH_SOCIAL_TRADING_HIST_CLS: 'FETCH_SOCIAL_TRADING_HIST_CLS',
    FETCH_SOCIAL_TRADING_STAT_GRAP: 'FETCH_SOCIAL_TRADING_STAT_GRAP',
    FETCH_SOCIAL_TRADING_PROFIT_GRAP: 'FETCH_SOCIAL_TRADING_PROFIT_GRAP',
    FETCH_SOCIAL_TRADING_BALANCE_GRAP: 'FETCH_SOCIAL_TRADING_BALANCE_GRAP',

    //post
    POST_SEND_EMAIL: 'POST_SEND_EMAIL',
    POST_FX_CALCULATOR: 'POST_FX_CALCULATOR',
    POST_FX_MARGIN_CALCULATOR: 'POST_FX_MARGIN_CALCULATOR',
};
