import { actionType } from "../actionType";

const socialTradingBalanceGraphReducer = (state = {}, action) => {
    if (action.type === actionType.FETCH_SOCIAL_TRADING_BALANCE_GRAP) {
        return { ...state, ...action.payload };
    }
    return state;
};

export default socialTradingBalanceGraphReducer;
