import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { Row, Col, Table, Card } from 'react-bootstrap';
// import ReadyToTrade from '../components/ReadyToTrade'
// import CustomerAgreement from '../documents/customer_agreement.pdf';

import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faXTwitter, faInstagram, faFacebookF, faPinterestP, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
class CashBackNov extends Component {

    constructor(props) {
        super(props);
        this.state = {
            opened: false,
        };
        this.toggleBox = this.toggleBox.bind(this);
    }
    toggleBox() {
        const { opened } = this.state;
        this.setState({
            opened: !opened,
        });
    }

    render() {
        var { exampletitle } = this.props;
        const { opened } = this.state;
        if (opened) {
            exampletitle = 'Hide Example';
        } else {
            exampletitle = 'Show Example';
        }
        window.scrollTo = () => {
            return false;
        };
        return (
            <div>
                <Helmet
                    title="LeoPrime’s Cashback Bonus Program"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "Bonus" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "LeoPrime’s Cashback Bonus Program" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "LeoPrime’s Cashback Bonus Program" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "LeoPrime’s Cashback Bonus Program." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>

                <section className="bannerwrapper-nov d-flex align-items-center position-relative bg-size-cover bg-position-center overflow-hidden pt-6 pb-lg-5">
                    <header>
                        <div className="col-md-8 offset-md-1 py-3">
                            <div className="row">
                                <nav className="navbar navbar-light">

                                    <Link className="header__logo-link navbar-brand" to="/">
                                        <div className="logo-white"></div>
                                    </Link>
                                </nav>
                            </div>
                        </div>
                    </header>

                    <div className="col-md-5 offset-md-1">
                        <h1 className="mb-3"><span>Explore LeoPrime’s </span> Cashback Bonus Program</h1>
                        <p>Discover and earn limitless Cashback every day. Our exclusive Cashback bonus program not only assists you in scaling-up your profits but also minimizes your trading costs.</p>
                        <a href="https://www.leoprime.com/register/" type="button" className="btn btnWhite">Get Bonus <i className="fas fa-arrow-right"></i></a>
                    </div>


                    <div id="scroll-down-animation">
                        <span className="mouse">
                            <span className="move"></span>
                        </span>
                        <p>Scroll Down</p>
                    </div>

                </section>
                <section className="choose-area section-padding version-1">
                    <div className="col">
                        <div className="row">
                            <div className="col-md-7">
                                <img src={require('../assets/img/caseback/section-img-2.png')} alt="leoprime cashback" className="img-fluid d-lg-block d-none" />

                            </div>
                            <div className="col-lg-4">
                                <p>Starts on </p>
                                <h2 className="unlimitedHeading">December 9th and ends on January 31st</h2>
                                <p>Incredible offer awaits you.</p>
                                {/* <a href="https://www.leoprime.com/register/" type="button" className="btn btnBlue">Get Bonus <i className="fas fa-arrow-right"></i></a> */}
                            </div>
                        </div>
                    </div>

                </section>

                <section className="section-padding calu-bg">
                    <div className="leftBg">
                        <img src={require('../assets/img/caseback/section-img-3-left.png')} alt="leoprime cashback" className="img-fluid d-lg-block d-none" />
                    </div>

                    {/* <h2 className="text-center mb-5 text-white">Calculation formula:</h2> */}
                    <div className=" d-flex">
                        <div className="col-md-8 mx-auto">
                            {/* <div className="col-md-8 mx-auto px-3">
                                <h3 className="mb-5">Lots x Amount of bonus for 1 lot</h3>
                            </div> */}
                            <div className="row">
                                <div className="text-center col-12 section-title aos-init aos-animate" data-aos="fade-zoom-in">
                                    <h1>Grab the Cashback Bonus with simple steps</h1>
                                </div>
                            </div>
                            <div className="row padding-50">
                                <div className="col-lg-4 calBgStep">

                                    <div >
                                        <h3>Step 01</h3>
                                        <div className="calBgstep-01">
                                            <div className="calBgstep-content">
                                                <p>Open a Live Classic account</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-4 calBgStep">

                                    <div >
                                        <h3>Step 02</h3>
                                        <div className="calBgstep-02">
                                            <div className="calBgstep-content">
                                                <p>Login into your personal area and deposit your trading account.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-4 calBgStep">

                                    <div >
                                        <h3>Step 03</h3>
                                        <div className="calBgstep-03">
                                            <div className="calBgstep-content">
                                                <p>Activate your Cashback program. Start earning Cashback with every trade you make.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* <div className="col-lg-4 calBgStep offset-md-2">

                                <div >
                                    <h3>Step 04</h3>
                                    <div className="calBgstep-04">
                                        <div className="calBgstep-content">
                                            <p> You can get in touch with your account manager or access our live chat support for any kind of queries related to the on-going promotion. </p>
                                        </div>
                                    </div>
                                </div>

                            </div> */}
                                {/* <div className="col-lg-4 calBgStep">

                                <div >
                                    <h3>Step 05</h3>
                                    <div className="calBgstep-05">
                                        <div className="calBgstep-content">
                                            <p>Follow our Facebook and Instagram pages, and subscribe to our YouTube channel to get all our exclusive & exciting updates.</p>
                                        </div>
                                    </div>
                                </div>

                            </div> */}

                            </div>

                        </div>
                    </div>

                    <div className="rightBg">
                        <img src={require('../assets/img/caseback/section-img-3-right.png')} alt="leoprime cashback" className="img-fluid d-lg-block d-none" />
                    </div>
                </section>
                <section id="main_features" className="main-features">
                    <div className="container">
                        <p className="text-center">
                            Bonus will be credited for both decimals and integers (Whole numbers). <br />
                                Take a look at the examples below.
                            </p>
                        <div className="text-center">
                            <Link onClick={this.toggleBox} className="mb-3">
                                <p className="downExplan"><FontAwesomeIcon icon={faChevronCircleDown} className="fa-3x" /></p>
                                {exampletitle}
                            </Link>
                            <p>Get Cashback of US$1 for trading 1 Standard Lot in Forex <br />(Major, Minor& Exotic pairs), Gold and Silver*.  <a href="https://www.leoprime.com/tnc/cashback-tnc.pdf" target="blank">T&C Apply</a></p>
                            {opened && (
                                <div className="card card-body text-left">
                                    <b>Direct Client</b>
                                    <p>If a client closed a trade of 1 STD lot in Forex (Major, Cross & Minor pairs)/Gold/Silver.  </p>
                                    <p>As the client is not under an IB,
The client receives a cashback of US$1 for 1 STD lot closed. </p>
                                    <hr />
                                    <b>Client under IB</b>
                                    <p>If a client closed a trade of 1 STD lot in Forex (Major, Cross & Minor pairs)/Gold/Silver. </p>
                                    <p>As the client is under an IB, the 1 lot will be divided by 2.
And the client receives a cashback of 0.50 cents for 1 STD lot closed.</p>

                                </div>
                            )}
                        </div>



                    </div>
                </section>

                <section className="section-padding calu-bg">
                    <div className="col-8 mx-auto">
                        <h2 className="text-center mb-5 text-white">Sign up with our cashback program and keep earning as you trade.
                        </h2>
                        <div className="text-center">
                            <a href="https://www.leoprime.com/register/" type="button" className="btn btnWhite btn-lg"> Register <i className="fa fa-arrow-right"></i></a>

                            <div className="col-md-6 mx-auto">
                                <a href="https://www.leoprime.com/user/" className="py-3 text-white"> Already have an account</a>

                                {/* <p className="text-white"> By clicking on open account, you confirm that you have read & agreed to our <a href="https://www.leoprime.com/tnc/cashback-tnc.pdf" target="blank" className="text-white">terms & conditions</a></p> */}

                                {/* <p className="text-white"> By clicking open account, you confirm
that you have read and agree with our <a href={CustomerAgreement} target="blank" className="text-white">customer agreement</a>.</p> */}
                            </div>

                        </div>
                    </div>
                    {/* <div className="container cashBackForm">
                        <form action="#" className="row">
                            <div className="col-lg-3">
                                <div class="form-group">
                                    <input type="text" required="required" />
                                    <label for="input" class="control-label">Firstname</label><i class="bar"></i>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div class="form-group">
                                    <input type="text" required="required" />
                                    <label for="input" class="control-label">Last Name</label><i class="bar"></i>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div class="form-group">
                                    <input type="text" required="required" />
                                    <label for="input" class="control-label">Email</label><i class="bar"></i>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div class="form-group">
                                    <input type="text" required="required" />
                                    <label for="input" class="control-label">Mobile</label><i class="bar"></i>
                                </div>
                            </div>
                        </form>
                    </div> */}


                </section>




            </div>
        );
    }

}
export default CashBackNov;