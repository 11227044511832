import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
import { NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet";

export default class AccoutTypesEcnAccount extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="Open an ECN Account|ECN Forex Broker|LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "retail,ECN account,forex,broker,LeoPrime,trading, forex trading, online" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Open an ECN Account|ECN Forex Broker|LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Open an ECN Account|ECN Forex Broker|LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "LeoPrime ECN account provides traders with raw, Super-Tight Spread directly from leading liquidity providers with zero markups!" },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay account-types-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase ">Account</h1>
                            <h1 className="display-3 text-shadow text-uppercase"><span>Types</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <Row className="justify-content-md-center">
                            <Col lg={{ span: 4, offset: 1 }} className="mb-3 d-flex flex-column justify-content-center mt-5">
                                <img src={require('../assets/img/account-types/account-type-left-2.png')} alt="AccountTypes" className="img-fluid" />
                            </Col>
                            <Col lg={7} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center pl-lg-5">
                                    <h2 className="mb-4 w-100">
                                        ECN Account
                                    </h2>
                                    <p className="w-100">ECN account is the way to the future World Markets. The ECN account provides our traders with raw, Super-Tight Spread directly from leading liquidity providers with zero markups! The account offers a highly transparent commission-based structure and commissions start from $6 per Standard lot. Our account types can accommodate all type of traders and can also suit scalpers, high-volume traders and those who trade with Expert Advisors (EAs). Explore our ECN account details below.


</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>

                <section className="hmeOurBenefit pt-0">
                    <div className="container">
                        <h2 className="mb-5">
                            In-Depth Details
                                    </h2>
                        <Row>
                            <Col lg="8">
                                <Row className="mb-3">
                                    <Col lg md={6}>
                                        <h6>Trading Platforms</h6>
                                        <p>MetaTrader 4/5, Mobile Trading, PAMM</p>
                                    </Col>
                                    <Col lg md={6}>
                                        <h6>Spread from (pips)</h6>
                                        <p>0.1* Pip</p>
                                    </Col>
                                    <Col lg md={6}>
                                        <h6>Maximum Leverage</h6>
                                        <p>1:200</p>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col lg md={6}>
                                        <h6>Account Currency</h6>
                                        <p>USD</p>
                                    </Col>
                                    <Col lg md={6}>
                                        <h6>Minimum Deposit (USD)</h6>
                                        <p>$2,000</p>
                                    </Col>
                                    <Col lg md={6}>
                                        <h6>Margin Call/Stop Out Level</h6>
                                        <p>50% / 30%</p>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col lg md={6}>
                                        <h6>Commission (per Standard Lot)</h6>
                                        <p>$6</p>
                                    </Col>
                                    <Col lg md={6}>
                                        <h6>Minimum Trade Size</h6>
                                        <p>0.01 lots</p>
                                    </Col>
                                    <Col lg md={6}>
                                        <h6>Max Trade Size</h6>
                                        <p>upto 100 lots</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={4} className="bg-gray mb-3 p-4">
                                <table className="table table-borderless account_types_table mb-0">
                                    <tbody>
                                        <tr>
                                            <td>Order Execution:	</td>
                                            <td>Market</td>
                                        </tr>
                                        <tr>
                                            <td>Fifth Decimal:	</td>
                                            <td>YES</td>
                                        </tr>
                                        <tr>
                                            <td>Islamic / SWAP Free Accounts:	</td>
                                            <td>YES</td>
                                        </tr>
                                        <tr>
                                            <td>Hedging Allowed:	</td>
                                            <td>YES</td>
                                        </tr>
                                        <tr>
                                            <td>EA Compatible:	</td>
                                            <td>YES</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                            <Col>
                                <p>
                                    Note:- Since the MT4/5 server doesn't always count the gap level correctly when activating stop and stop-loss orders during strong market movements (For example, due to a release of Major Economic News and Market rollover time), news trading with stop orders is not supported for this account type.
                        </p>
                                <p>Leverage is applied as mentioned in the Margin and Leverage rules page. </p>
                                <NavLink exact to="/margin-leverage-rules" className=" w-100">(https://www.leoprime.com/margin-leverage-rules)</NavLink>
                            </Col>
                        </Row>
                        <Col className="text-center mt-5">
                            <p>Trade with a Trusted and Regulated Broker
</p>
                            <a href={('https://www.leoprime.com/register')} className="primary-button-lg my-2" >Open ECN Account</a>
                            <p>
                                or try free <a href={('https://www.leoprime.com/register')}> <u>Demo Account</u></a>
                            </p>
                        </Col>
                    </div>
                </section>
                <ReadyToTrade />
            </div>
        );
    }
}
