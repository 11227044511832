import { actionType } from "../actionType";

const fetchSocialTradingStat = (state = {}, action) => {
  if (action.type === actionType.FETCH_SOCIAL_TRADING_STAT) {
    return { ...state, ...action.payload };
  }
  return state;
};

export default fetchSocialTradingStat;
