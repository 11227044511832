import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import ContestBannerMenuLg from '../assets/img/contest-banner/contest-banner-img-lg.png';
import ContestBannerMenuSm from '../assets/img/contest-banner/contest-banner-img-sm.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faInstagram, faFacebookF, faPinterestP, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { connect } from "react-redux";
import { getBlog } from "../store/actions/blogsAction";
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
const Nav = (props) => {
    const { close, blogItems, getBlog } = props;
    useEffect(() => {
        getBlog() // fetch latest blog from server
    }, [getBlog]);
    // Render Single Blog
    const renderBlog = (blog) => {
        return (
            <li key={`${blog.id}`}>
                <a href={blog.link} target="_blank" rel="noopener noreferrer">
                    <div className="newsBlogList pb-0">{blog.title.rendered}</div>
                    <small>
                        <Moment format="MMMM DD, YYYY">{blog.date}</Moment>
                    </small>
                    <p dangerouslySetInnerHTML={{ __html: blog.excerpt.rendered }} className="pargEllipsis" />
                </a>
            </li>
        )
    };
    return (
        <Fade right cascade delay={100}>
            <div className="menu">
                <Col lg={12}>
                    <ul className="row">
                        <li className="col-lg-7 pt-3">
                            <ul className={`main-nav main-nav--`}>
                                <div className="row w-100">
                                    <li className="col-md-4">
                                        <ul>
                                            <li className="dropdown-header">ABOUT</li>
                                            <li>
                                                <NavLink exact to="/why-leo-prime" activeClassName="currentLink"
                                                    onClick={close}>Why Leo</NavLink>
                                            </li>
                                            {/* <li>
                                                <NavLink exact to="/awards" activeClassName="currentLink"
                                                    onClick={close}>Awards</NavLink>
                                            </li> */}
                                            {/* <li>
                                                <NavLink exact to="/regulations" activeClassName="currentLink"
                                                    onClick={close}>Regulations</NavLink>
                                            </li> */}
                                            <li>
                                                <NavLink exact to="/contact-us" activeClassName="currentLink"
                                                    onClick={close}>Contact Us</NavLink>
                                            </li>
                                            {/* <li>
                                                <NavLink exact to="/faq" activeClassName="currentLink"
                                                    onClick={close}>FAQ</NavLink>
                                            </li> */}
                                        </ul>
                                        <ul>
                                            <li className="dropdown-header">
                                                Social Trading
                                                {/* Copy Trading */}
                                            </li>
                                            <li>
                                                <a target="blank" href={('https://social.leoprime.com/portal/login')} onClick={close}>Social Trading</a>
                                            </li>
                                            <li>
                                                <a target="blank" href={('https://socialtrading.leoprime.com/widgets/ratings?widgetKey=social-ratings')} onClick={close}>Ranking</a>
                                            </li>
                                            {/* <li>
                                                <NavLink exact to="/copy-trading-trader" activeClassName="currentLink" onClick={close}>Social Trading</NavLink>
                                            </li> 
                                             <li>
                                                <NavLink className="cursorPoint" exact to="/copy-trading-rank" activeClassName="currentLink" onClick={close}>Trader Ranking</NavLink>
                                            </li>
                                            <li>
                                                <NavLink exact to="/copy-trading-investor" activeClassName="currentLink" onClick={close}>Investor </NavLink>
                                            </li> */}
                                        </ul>
                                        <ul>
                                            <li className="dropdown-header">Platform</li>
                                            <li>
                                                <NavLink exact to="/trading-platform"
                                                    activeClassName="currentLink"
                                                    onClick={close}>Metatrader 4</NavLink>
                                            </li>
                                            {/* <li>
                                                <NavLink exact to="/trading-platform-mt5"
                                                    activeClassName="currentLink" onClick={close}>Metatrader
                                                    5</NavLink>
                                            </li> */}
                                            <li className="divider" />
                                        </ul>
                                        <ul>
                                            <li className="dropdown-header">Margin requirements</li>
                                            <li>
                                                <NavLink exact to="/margin-leverage-rules"
                                                    activeClassName="currentLink" onClick={close}>Margin &
                                                    Leverage Rules</NavLink>
                                            </li>
                                        </ul>
                                        <ul>
                                            {/* <li className="divider"></li>
                                            <li className="dropdown-header">Our Blog's</li>
                                            <li>
                                                <a target="blank" href={('https://blog.leoprime.com/page/')} onClick={close}>Education</a>
                                            </li>
                                            <li>
                                                <a target="blank" href={('https://analysis.leoprime.com/')} onClick={close}>Technical Analysis</a>
                                            </li>
                                            <li>
                                                <a target="blank" href={('https://news.leoprime.com/')} onClick={close}>Company News</a>
                                            </li> */}
                                        </ul>
                                    </li>
                                    <li className="col-md-8">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <ul>
                                                    <li className="dropdown-header">
                                                        <NavLink className="cursorPoint" exact to="/account-types" activeClassName="currentLink" onClick={close}>Account Types</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/classic-account"
                                                            activeClassName="currentLink"
                                                            onClick={close}>Classic Account</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/pro-account" activeClassName="currentLink"
                                                            onClick={close}>Pro Account</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/ecn-account" activeClassName="currentLink"
                                                            onClick={close}>ECN Account</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/cent-account" activeClassName="currentLink"
                                                            onClick={close}>Cent Account</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/demo-account" activeClassName="currentLink"
                                                            onClick={close}>Demo Account</NavLink>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li className="dropdown-header">
                                                        FUNDING
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/payment-options"
                                                            activeClassName="currentLink"
                                                            onClick={close}>Deposit</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/payment-options-withdrawal"
                                                            activeClassName="currentLink"
                                                            onClick={close}>Withdrawal</NavLink>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li className="divider" />
                                                    <li className="dropdown-subheader">Trading Instrument</li>
                                                    <li>
                                                        <NavLink exact to="/trading-instrument-forex"
                                                            onClick={close}>Forex</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/trading-instruments-spot-metal"
                                                            activeClassName="currentLink" onClick={close}>Spot
                                                            Metal</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/trading-instruments-energies"
                                                            activeClassName="currentLink"
                                                            onClick={close}>Energies</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/trading-instruments-indices"
                                                            activeClassName="currentLink"
                                                            onClick={close}>Indices</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/trading-instruments-stocks"
                                                            activeClassName="currentLink"
                                                            onClick={close}>Stocks</NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-5 offset-md-1">
                                                <ul>
                                                    <li className="dropdown-header">Promotions</li>
                                                    {/* <li>
                                                    <NavLink exact to="/contest" activeClassName="currentLink" onClick={close}>Demo Contest</NavLink>
                                                    </li> */}
                                                    <li>
                                                        <NavLink exact to="/contest" activeClassName="currentLink" onClick={close}>Demo Contest</NavLink>
                                                    </li>
                                                    {/* <li>
                                                        <NavLink exact to="/cashback" activeClassName="currentLink" onClick={close}>Cashback</NavLink>
                                                    </li> */}
                                                    {/* <li>
                                                        <NavLink exact to="/50bonus-south-africa" activeClassName="currentLink" onClick={close}>South Africa</NavLink>
                                                    </li> */}
                                                    {/* <li>
                                                        <NavLink exact to="/30bonus" activeClassName="currentLink" onClick={close}>30% Bonus</NavLink>
                                                    </li> */}
                                                    <li>
                                                        <NavLink exact to="/50bonus" activeClassName="currentLink" onClick={close}>50% Bonus</NavLink>
                                                    </li>
                                                    {/* <li>
                                                        <NavLink exact to="/50bonus-new" activeClassName="currentLink" onClick={close}>50% Bonus New</NavLink>
                                                    </li> */}
                                                    {/* <li>
                                                        <NavLink exact to="/ib-promotion" activeClassName="currentLink" onClick={close}>IB Promotions</NavLink>
                                                    </li> */}
                                                    {/* <li>
                                                        <NavLink exact to="/copy-trading" activeClassName="currentLink" onClick={close}>Copy Trading</NavLink>
                                                    </li> */}
                                                    <li>
                                                        <NavLink exact to="/mobile-app-ios-android" activeClassName="currentLink" onClick={close}>Mobile App</NavLink>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li className="dropdown-header">Tools</li>
                                                    <li>
                                                        <NavLink exact to="/economic-calendar"
                                                            activeClassName="currentLink"
                                                            onClick={close}>Economic Calendar</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/forex-calculator"
                                                            activeClassName="currentLink"
                                                            onClick={close}>Forex Calculator</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/client-sentiment"
                                                            activeClassName="currentLink"
                                                            onClick={close}>Client Sentiment</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/vps-hosting" activeClassName="currentLink"
                                                            onClick={close}>VPS Hosting</NavLink>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li className="divider" />
                                                    <li className="dropdown-header">Partnership</li>
                                                    <li>
                                                        <NavLink exact to="/introducing-broker"
                                                            activeClassName="currentLink" onClick={close}>Introducing
                                                            Broker</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/regional-office"
                                                            activeClassName="currentLink"
                                                            onClick={close}>Regional Office</NavLink>
                                                    </li>
                                                    <li className="divider" />
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </div>
                            </ul>
                            <ul>
                                <li className="col-lg-12 border-top my-5">
                                    <ul className="row">
                                        <li className="col-md-6 col-lg-8">
                                            {/* <div className="dropdown-subheader"> Leo Inc</div> */}
                                            <ul className="row">
                                                <li className="col-lg-6">
                                                    {/* <p className="m-0">
                                                        G.S.Complex, 1st floor,<br />
                                                        Office Number 12, Providence,<br />
                                                        Mahe, Seychelles.
                                                    </p> */}
                                                </li>
                                                <li className="col-lg-6">
                                                    <p className="m-0">
                                                        <a href="mailto:support@leoprime.com">support@leoprime.com</a>
                                                    </p>
                                                    <p> <a href="tel:+442038085868"><FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />  +44 20380 85868</a></p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="col-md-6 col-lg-4">
                                            <div className="dropdown-subheader">GET IN TOUCH</div>
                                            <ul className="list-inline sociallinks">
                                                <li className="list-inline-item">
                                                    <a target="blank" href="https://www.facebook.com/LeoPrimeForexBrokers/"><FontAwesomeIcon icon={faFacebookF} className="fa-lg" /></a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a target="blank" href="https://twitter.com/LeoPrimeFx"><FontAwesomeIcon icon={faXTwitter} className="fa-lg" /></a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a target="blank" href="https://www.instagram.com/leoprimefx/"><FontAwesomeIcon icon={faInstagram} className="fa-lg" /></a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a target="blank" href="https://www.linkedin.com/company/leo-prime"> <FontAwesomeIcon icon={faLinkedinIn} className="fa-lg" /></a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a target="blank" href="https://in.pinterest.com/LeoPrimeFx/pins/"><FontAwesomeIcon icon={faPinterestP} className="fa-lg" /></a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="col-lg-5 bg-gray d-none d-lg-block newsBlog">
                            <div className="row">
                                <div className="col-lg-6 pt-3 blog">
                                    <h3 className="dropdown-header text-info">Technical Analysis</h3>
                                    <ul>
                                        {blogItems.map((blog) => renderBlog(blog))}
                                        <li>
                                            <a href="https://analysis.leoprime.com" target="_blank"
                                                rel="noopener noreferrer">
                                                <p className="text-info">Readmore</p>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6 bg-gray">
                                    <NavLink exact to="/contest" onClick={close}> <img src={ContestBannerMenuLg} alt="" className="img-fluid d-none d-lg-block" />
                                        <img src={ContestBannerMenuSm} alt=""
                                            className="img-fluid d-lg-none d-block w-100" />
                                    </NavLink>
                                </div>
                            </div>
                        </li>
                    </ul>
                </Col>
            </div>
        </Fade>
    )
};
const mapStateToProps = (state) => {
    const blogItems = [];
    // prepare a 3 blog
    Object.keys(state.blogs).forEach((item) => {
        if (blogItems.length < 3) {
            blogItems.push(state.blogs[item])
        }
    });
    return {
        blogItems: blogItems
    }
};
export default connect(mapStateToProps, { getBlog })(Nav);
