import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Row, Col, Media } from 'react-bootstrap';
// import ReadyToTrade from '../components/ReadyToTrade'
import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core";
import IbForm from "../components/IbForm";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { sendContactEmail } from "../store/actions/sendContactEmailAction";
import IB1 from '../assets/img/icons/ib-icon-1.svg';
import IB2 from '../assets/img/icons/ib-icon-2.svg';
import IB3 from '../assets/img/icons/ib-icon-3.svg';
import IB4 from '../assets/img/icons/ib-icon-4.svg';

const styles = {};
const META = [
    { name: 'author', content: "LeoPrime" },
    {
        name: 'keywords',
        content: "introducingbroker,IB,economic,calendar,forextrading,mt4,MT4,MetaTrader, retail, account,forex,broker,LeoPrime,trader, forex trader, online"
    },
    { name: 'twitter:site', content: "LeoPrime" },
    { name: 'twitter:creator', content: "LeoPrime" },
    {
        name: 'twitter:title',
        content: "Introducing Broker|Best forex Broker IB|Forex Introducing Broker"
    },
    {
        name: 'twitter:image',
        content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg"
    },
    {
        property: 'og:title',
        content: "Introducing Broker|Best forex Broker IB|Forex Introducing Broker"
    },
    { property: 'og:site_name', content: "LeoPrime" },
    { property: 'og:type', content: "Forex Trading" },
    { property: 'og:url', content: "https://leoprime.com/" },
    {
        property: 'og:description',
        content: "The Introducing Broker (IB) Program rewards clients who help build LeoPrimes’ network. Clients can earn commissions and extra bonus for introducing and referring new business."
    },
    {
        property: 'og:image',
        content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg"
    },
    { property: 'og:site_name', content: "LeoPrime" },
    { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
];

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 200,
        },
    }
}));

// used for email validation
const emailExpression = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;

class PartnershipIntroducingBroker extends Component {
    state = {
        form: {
            first_name: '',
            last_name: '',
            email: '',
            mobile: '',
            aycus: '',
            pct: '',
            country: '',
            message: '',
            state: '',
            pagenamecf: 'LeoPrime Introducing Broker Form'
        },
        classes: useStyles,
        isSubmitted: false,
        isSending: false,
        isSent: false // used for display success msg
    };

    // Detect field change and update form
    handleInputFieldChange = (event) => {
        const { target } = event;
        this.setState(preState => ({
            ...preState,
            form: {
                ...preState.form,
                [target.name]: target.value
            }
        }));
    };

    // Sending a Enquiry mail
    handleSubmit = () => {
        this.setState({ isSubmitted: true });
        if (this.validateOnSubmit()) {
            this.setState({ isSending: true });
            this.props.sendContactEmail(this.state.form, (rs) => {
                this.setState({ isSubmitted: false });
                this.setState({ isSent: true });
            });
        }
    };

    // validate form while submitting a form
    validateOnSubmit = () => {
        let isFormValid = true;

        Object.keys(this.state.form).forEach((key) => {
            if (this.state.form[key] === '') {
                isFormValid = false;
            }
        });

        return isFormValid;
    };

    // checking filed is valid or not
    hasInvalid = (key) => {
        if (key === 'email') {
            return (!emailExpression.test(key) && this.state.form[key]) === '' && this.state.isSubmitted;
        }

        return this.state.form[key] === '' && this.state.isSubmitted;
    };

    render() {
        const { form } = this.state;
        return (
            <div>
                <Helmet
                    title="Introducing Broker|Best forex Broker IB|Forex Introducing Broker"
                    meta={META}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay introducing-broker-banner">
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase text-dark">Introducing </h1>
                            <h1 className="display-3 text-shadow text-uppercase text-dark"><span>Broker</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <Row>
                            <Col lg={4} className="text-center">
                                <img src={require('../assets/img/introducing-broker/ib-img.png')} alt="Metatrader 4"
                                    className="img-fluid" />
                            </Col>
                            <Col lg={8}>
                                <h2 className="mb-4 w-100">
                                    Enhance Your Business with
                                    <span className="d-block">LeoPrime as an Introducing
                                        Broker</span>
                                </h2>
                                <p className="w-100">
                                    LeoPrime partners with people and businesses around the world to grow the network of clients
                                    by rewarding the introducer for referring clients. As an Introducing Broker, all you have to do is
                                    refer clients to LeoPrime by leveraging our global brand name, extensive resources and the
                                    support of our highly skilled and experienced team. The Introducer Broker program comes at no
                                    additional cost and is available to anyone who signs up with LeoPrime and is a perfect step
                                    towards earning stable income by introducing clients to a leading forex broker. Each time one of
                                    your referred clients makes a trade, you will receive a commission and as long as they keep
                                    trading, you keep earning. LeoPrime offers industry-leading attractive and flexible rebate scheme
                                    where the rebates awarded to Introducing Broker depends on the trading volume of their clients -
                                    more the volume, bigger the rebates.
                                </p>
                                <a href={('https://www.leoprime.com/register')} className="primary-button-lg my-3" >Become an IB</a>
                            </Col>
                        </Row>

                    </div>
                </section>

                <section className="bg-gray hmeOurBenefit">
                    <Col lg={10} className="mx-auto">
                        <Row>
                            <Col xs={12} md={4} lg={6} className="d-flex align-items-center">
                                <div>
                                    <h2 className="mb-4 w-100">
                                        Benefits of joining our
                                        <span className="d-block">introducing broker</span>
                                    </h2>
                                    <p className="w-100">
                                        On receiving your Introducing Broker (IB) status you can begin
                                        to invite clients to register trading accounts with the company. By doing this,
                                        you earn additional income by using your relationship link as a partner. Build
                                        up your network of active signed-up clients and gain along with your network.
                                        You will also receive a personal offer of partnership conditions depending on
                                        your operating region, trading volume of signed-up clients and activity.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} md={8} lg={{ size: 5, offset: 1 }}>
                                <Row>
                                    <Col xs={12} md={6} lg={6} className="pr-4 mb-5">
                                        <img src={IB1} alt="Metatrader 4" />
                                        <p>Instant withdrawals</p>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} className="pr-4 mb-5">
                                        <img src={IB2} alt="Metatrader 4" />
                                        <p>Reward is credited regardless of the volume of transactions
                                        </p>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} className="pr-4 mb-5">
                                        <img src={IB3} alt="Metatrader 4" />
                                        <p>One-of-a-Kind
                                            <span className="d-block">Partner Rewards</span>
                                        </p>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} className="pr-4 mb-5">
                                        <img src={IB4} alt="Metatrader 4" />
                                        <p>There is no limit on how much
                                            reward you can earn
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </section>

                <section className="hmeOurBenefit">
                    <Col lg={10} className="mx-auto">
                        <Row>
                            <Col xs={12} md={8} lg={6}>

                                <Row className="my-3">
                                    <h2 className="mb-5 w-100">
                                        How to become an

                                        <span className="d-block">Introducing Broker (IB)?</span>
                                    </h2>
                                    <Col xs={12} md={6} lg={6} className="pr-4 mb-3">
                                        <Media>
                                            <h1 className="mr-3">1</h1>
                                            <Media.Body>
                                                <h6 className="text-muted">Open Account</h6>
                                                <p>
                                                    Open a live account with
                                                    LeoPrime to create an IB account
                                                </p>
                                            </Media.Body>
                                        </Media>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} className="pr-4 mb-3">
                                        <Media>
                                            <h1 className="mr-3">2</h1>
                                            <Media.Body>
                                                <h6 className="text-muted">Get Partner Link</h6>
                                                <p>
                                                    Get the partner link from us and share
                                                </p>
                                            </Media.Body>
                                        </Media>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} className="pr-4 mb-3">
                                        <Media>
                                            <h1 className="mr-3">3</h1>
                                            <Media.Body>
                                                <h6 className="text-muted">Active Signed-Up Clients</h6>
                                                <p>
                                                    Build up a network of active
                                                    signed-up clients
                                                </p>
                                            </Media.Body>
                                        </Media>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} className="pr-4 mb-3">
                                        <Media>
                                            <h1 className="mr-3">4</h1>
                                            <Media.Body>
                                                <h6 className="text-muted">Commission</h6>
                                                <p>
                                                    Commission per standard lot
                                                </p>
                                            </Media.Body>
                                        </Media>
                                    </Col>

                                </Row>
                            </Col>

                            <Col xs={12} md={4} lg={{ size: 5, offset: 1 }} className="d-flex align-items-center">
                                <div>
                                    <img src={require('../assets/img/introducing-broker/ib-img-2.jpg')}
                                        alt="Metatrader 4" className="img-fluid" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </section>
                <section className="text-center py-5 bg-gray">
                    <Col lg={10} className="mx-auto">
                        <Row className="text-center">
                            <Col xs={12} md={6} lg={8} className="mx-auto mb-5">
                                <h2 className="mb-4 w-100">
                                    Become a LeoPrime Partner
                                </h2>
                                <p className="w-100">Complete the form below to receive more information about the
                                    partnership types and rebate schemes available at LeoPrime. We will contact you at
                                    the earliest to discuss your partnership options.
                                </p>
                            </Col>
                            <Col xs={12} md={6} lg={8} className="pr-4 mx-auto">
                                {
                                    this.state.isSent ?
                                        <p className="text-success">
                                            Thank you for your interest.
                                            <br /> Please note that we received your inquiry and
                                            will contact you in the shortest delays
                                        </p>
                                        :
                                        <div className="w-100">
                                            <form className={this.state.classes.root} noValidate autoComplete="off">
                                                <Row>
                                                    <Col lg={6}>
                                                        <TextField id="first-name"
                                                            name="first_name"
                                                            label="First Name"
                                                            onChange={this.handleInputFieldChange}
                                                            required={true}
                                                            error={this.hasInvalid('first_name')}
                                                            defaultValue={this.state.form.first_name}
                                                            helperText={
                                                                this.hasInvalid('first_name')
                                                                    ? "Enter first name using words only. No symbols or numbers.First Name" :
                                                                    ''
                                                            } />
                                                    </Col>
                                                    <Col lg={6}>
                                                        <TextField id="last-name"
                                                            name="last_name"
                                                            label="Last Name"
                                                            required={true}
                                                            onChange={this.handleInputFieldChange}
                                                            error={this.hasInvalid('last_name')}
                                                            defaultValue={this.state.form.last_name}
                                                            helperText={
                                                                this.hasInvalid('last_name')
                                                                    ? "Enter last name (i.e. surname) using only words. No numbers or symbols." :
                                                                    ''
                                                            } />
                                                    </Col>
                                                    <Col lg={6}>
                                                        <TextField id="email"
                                                            name="email"
                                                            label="Email"
                                                            required={true}
                                                            onChange={this.handleInputFieldChange}
                                                            error={this.hasInvalid('email')}
                                                            defaultValue={this.state.form.email}
                                                            helperText={
                                                                this.hasInvalid('email')
                                                                    ? "Sorry, that’s not a recognised email address. Please enter a valid email address." :
                                                                    ''
                                                            } />
                                                    </Col>
                                                    <Col lg={6}>
                                                        <TextField id="mobile"
                                                            name="mobile"
                                                            label="Phone Number"
                                                            required={true}
                                                            onChange={this.handleInputFieldChange}
                                                            error={this.hasInvalid('mobile')}
                                                            defaultValue={this.state.form.mobile}
                                                            helperText={
                                                                this.hasInvalid('mobile')
                                                                    ? "Enter phone number without the country code or symbols. Max 15 characters" :
                                                                    ''
                                                            } />
                                                    </Col>
                                                    <Col lg={12}>
                                                        <IbForm
                                                            isSubmitted={this.state.isSubmitted}
                                                            handleInput={this.handleInputFieldChange}
                                                            form={form} />
                                                    </Col>
                                                    <Col lg={6}>
                                                        <TextField id="currentUs"
                                                            name="aycus"
                                                            label="Are you an current customer?"
                                                            required={true}
                                                            onChange={this.handleInputFieldChange}
                                                            error={this.hasInvalid('aycus')}
                                                            defaultValue={this.state.form.aycus}
                                                            helperText={
                                                                this.hasInvalid('aycus')
                                                                    ? "Required" :
                                                                    ''
                                                            } />

                                                    </Col>
                                                    <Col lg={6}>
                                                        <TextField id="preContTime"
                                                            name="pct"
                                                            label="Preferred Contact time"
                                                            required={true}
                                                            onChange={this.handleInputFieldChange}
                                                            error={this.hasInvalid('pct')}
                                                            defaultValue={this.state.form.pct}
                                                            helperText={
                                                                this.hasInvalid('pct')
                                                                    ? "Required" :
                                                                    ''
                                                            } />
                                                    </Col>
                                                    <Col lg={12}>
                                                        <TextField id="message"
                                                            name="message"
                                                            label="Message"
                                                            required={true}
                                                            rows={4}
                                                            multiline
                                                            onChange={this.handleInputFieldChange}
                                                            error={this.hasInvalid('message')}
                                                            defaultValue={this.state.form.message}
                                                            helperText={
                                                                this.hasInvalid('message')
                                                                    ? "Required" :
                                                                    ''
                                                            } />
                                                    </Col>
                                                </Row>
                                                <Row className="justify-content-md-center my-5">
                                                    <Col className="text-center">
                                                        {
                                                            this.state.isSending ?
                                                                <div className="spinner-border text-primary"
                                                                    role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                                : <button onClick={this.handleSubmit}
                                                                    className="primary-button-lg my-2"
                                                                    type="button">
                                                                    Send Enquiry
                                                                </button>
                                                        }
                                                    </Col>
                                                </Row>
                                            </form>
                                        </div>
                                }
                            </Col>
                        </Row>
                    </Col>
                </section>
                <section className="text-center py-5">
                    <Col lg={10} className="mx-auto">
                        <Row className="text-center">
                            <Col xs={12} md={6} lg={7} className="mx-auto">
                                <h2 className="mb-4 w-100">
                                    To know about Partnership
                                    <span className="d-block">Promotional Materials</span>
                                </h2>
                                <Row className="justify-content-md-center mt-5">
                                    <Col className="text-center">
                                        <a href={('https://www.leoprime.com/register')} className="primary-button-lg my-2" >Register for IB</a>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </Col>
                </section>

                {/* <ReadyToTrade /> */}
            </div>
        );
    }
}

export default connect(
    null,
    { sendContactEmail }
)(withStyles(styles)(PartnershipIntroducingBroker))
