import React, { Component } from 'react'
import { Row, Col, Media, Table, Card } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import GooglePlayImg from '../assets/img/google-play-icon.png';
import AppStoreImg from '../assets/img/app-store-icon.png';
import { Helmet } from "react-helmet";

export default class SocialTradingTrader extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="Best Master Trading Platform | Copy Trading Trader | LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "Copy Trading Trader, Master Trader, Master Trading, Best Master Trading Platform" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Best Master Trading Platform | Copy Trading Trader | LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Best Master Trading Platform | Copy Trading Trader | LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "LeoPrime’s Copy Trading Platform allows Master Traders to attract investors to copy their trades and earn commissions in a fast and secure way." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >

                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay social-trading-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase text-dark">Copy</h1>
                            <h1 className="display-3 text-shadow text-uppercase text-dark"><span>Trading</span></h1>
                            <Row>
                                <Col lg={6}>
                                    <p>LeoPrime's Copy Trading is a most sought-after trader's community where you can invest in the successful strategies of pro traders to earn profits as an investor or earn a stable passive income by displaying your successful trading strategies as a trader.</p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <Row className="justify-content-md-center">
                            <Col lg={{ span: 4, offset: 1 }} className="mb-3 d-flex flex-column justify-content-center mt-5">
                                <img src={require('../assets/img/social-trading/social-trading-img-1.png')} alt="Copy Trading" className="img-fluid" />
                            </Col>
                            <Col lg={7} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center pl-lg-5">
                                    <h2 className="w-100">
                                        Expert at Trading?
                                    </h2>
                                    <h2>Become a strategy provider & enjoy various benefits</h2>
                                    <p>Investors choose to follow a strategy provider after complete analyzes of their performance table and trading statistics. As a Master, you can build your network of followers by demonstrating your unique trading skills & attract investors to copy your trades. Thus, you can earn commissions in a fast & secure way.</p>
                                    <ul>
                                        <li>•	Plan & execute your trades</li>
                                        <li>•	Exhibit your trading skills</li>
                                        <li>•	Start receiving a stable income</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        {/* <Row className="mt-5 text-center">
                            <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                <div className="w-100">
                                    <img src={require('../assets/img/icons/social-trading-icon-1.svg')} alt="Copy Trading" />
                                    <p>Option to display multiple strategies via several accounts.</p>
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                <div className="w-100">
                                    <img src={require('../assets/img/icons/social-trading-icon-2.svg')} alt="Copy Trading" />
                                    <p>Monitor and view in-depth stats of trade orders and also view your income generated while others copy your trades.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                <div className="w-100">
                                    <img src={require('../assets/img/icons/social-trading-icon-3.svg')} alt="Copy Trading" />
                                    <p>Grow your income as more investors follow your strategies and benefit from zero commission charged by LeoPrime for showcasing your strategy.
                                    </p>
                                </div>
                            </Col>
                        </Row> */}
                    </div>
                </section>

                <section className="hmeOurBenefit">
                    <Col lg={10} className="mx-auto">
                        <Row className="my-3">
                            <h2 className="mb-5 w-100 text-center">
                                How do you become a Master?
                            </h2>
                            <Col xs={12} md={6} lg={5} className="pr-4 mb-3">
                                <Media>
                                    <h1 className="mr-3">1</h1>
                                    <Media.Body>
                                        <h6 className="text-muted">Create Master account</h6>
                                        <p>
                                            Login to the Client Cabinet area on the web.</p>
                                    </Media.Body>
                                </Media>
                            </Col>
                            <Col xs={12} md={6} lg={7} className="pr-4 mb-3 text-center">
                                <img src={require('../assets/img/social-trading/social-trading-img-2.png')} alt="Copy Trading" className="img-fluid" />
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col xs={12} md={6} lg={5} className="pr-4 mb-3">
                                <Media>
                                    <h1 className="mr-3">2</h1>
                                    <Media.Body>
                                        <h6 className="text-muted">Account Settings</h6>
                                        <p>Set the revenue, Minimum & recommended deposit.</p>
                                    </Media.Body>
                                </Media>
                            </Col>
                            <Col xs={12} md={6} lg={7} className="pr-4 mb-3">
                                <Card className="boxShadow">
                                    <Card.Body>
                                        <div className="table-responsive">
                                            <Table borderless hover className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Total Profit</th>
                                                        <th>DD</th>
                                                        <th>Risk Type</th>
                                                        <th>Equity</th>
                                                        <th>Account Age</th>
                                                        <th>Commission %</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="text-success">+10882.61</td>
                                                        <td>50%</td>
                                                        <td>Low</td>
                                                        <td>$872.48</td>
                                                        <td>311 Days</td>
                                                        <td>10%</td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td colSpan="6" className="py-0 px-2">
                                                            <a href={('https://www.leoprime.com/register')} className="primary-button my-2 w-100" target="blank">
                                                                Start Copying
                                                            </a>
                                                        </td>
                                                    </tr> */}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col xs={12} md={6} lg={5} className="pr-4 mb-3">
                                <Media>
                                    <h1 className="mr-3">3</h1>
                                    <Media.Body>
                                        <h6 className="text-muted">Monitor & Earn</h6>
                                        <p>Keep track of the people who follow you and the profits you’ve made.</p>
                                    </Media.Body>
                                </Media>
                            </Col>
                            <Col xs={12} md={6} lg={7} className="pr-4 mb-3 text-center d-flex align-items-center">
                                <Row className="w-100">
                                    <Col lg md>
                                        <h5 className="mb-0 text-success">$12</h5>
                                        <Sparklines data={[5, 10, 5, 13, 8, 3]} width={100} height={20} margin={5}>
                                            <SparklinesLine color="green" />
                                        </Sparklines>
                                        <p>Gain</p>
                                    </Col>
                                    <Col lg md>
                                        <h5 className="mb-0 text-success">$456</h5>
                                        <Sparklines data={[5, 10, 5, 13, 8, 3, 5, 12, 1, 20, 5, 7]} width={100} height={20} margin={5}>
                                            <SparklinesLine color="green" />
                                        </Sparklines>
                                        <p>Profit</p>
                                    </Col>
                                    <Col lg md>
                                        <h5 className="mb-0 text-success">$650</h5>
                                        <Sparklines data={[5, 12, 1, 20, 5, 7]} width={100} height={20} margin={5}>
                                            <SparklinesLine color="green" />
                                        </Sparklines>
                                        <p>Balance</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </section>
                <section className="bg-gray">
                    <div className="container">
                        <Row className="justify-content-md-center">
                            <h2 className="mb-4 w-100 text-center">
                                Copy Trading from
                                <span className="d-block">The Mobile App!</span>
                            </h2>
                            <Col lg={6} className="mb-3 d-flex flex-column justify-content-center mt-5">
                                <img src={require('../assets/img/social-trading/social-trading-img-3.png')} alt="Copy Trading" className="img-fluid" />
                            </Col>
                            <Col lg={6} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center pl-lg-5">
                                    <ul className="liststyle">
                                        <li>Create Trader Accounts on the go at your convenience</li>
                                        <li>View your strategy stats and your earnings in real-time</li>
                                        <li>View, edit and manage your fee(commissions) from your existing or new followers on the go</li>
                                    </ul>
                                    <Row className="mt-4">
                                        <a href="https://play.google.com/store/apps/details?id=com.leoprime.copytrade" target="blank" rel="noreferrer" >
                                            <img src={GooglePlayImg} alt="google play" className="img-fluid mr-3" width="120" />
                                        </a>
                                        <a href="https://apps.apple.com/in/app/leoprime-copytrade/id1574483334" target='_blank' rel="noreferrer" >
                                            <img src={AppStoreImg} alt="app store" className="img-fluid" width="120" />
                                        </a>

                                    </Row>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </section>
            </div>
        );
    }
}
