import { actionType } from "../actionType";
import apiConfig from '../../config/apiCCConfig'; //
import apiConfigProd from '../../config/apiConfig';

export const getForexCalculator = (cb) => async (dispatch) => {
    try {
        const response = await apiConfig.post('api/v3/fxcalculator', {});
        dispatch({
            type: actionType.FETCH_FX_CALCULATOR,
            payload: response.data,
        });
        cb(response.data);
    } catch (error) {
        console.log(error);
    }
};

export const calculateFx = (params, cb) => async (dispatch) => {
    try {
        const response = await apiConfig.post('api/v3/fxcalculator', params);
        dispatch({
            type: actionType.POST_FX_CALCULATOR,
            payload: response.data,
        });
        cb(response.data);
    } catch (error) {
        console.log(error);
    }
};

export const calculateFxMargin = (params, cb) => async (dispatch) => {
    try {
        const response = await apiConfigProd.post('quotes/cq.php', params);
        dispatch({
            type: actionType.POST_FX_MARGIN_CALCULATOR,
            payload: response.data,
        });
        cb(response.data);
    } catch (error) {
        console.log(error);
    }
};
