import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
import { Helmet } from "react-helmet";

import ListIcon from '../assets/img/icons/list-icon.svg';
import EaseOfTrade from  '../assets/img/icons/why-leo-prime-icon-1.svg';
import ExpertTeam from  '../assets/img/icons/why-leo-prime-icon-2.svg';
import WideAngleApproach from  '../assets/img/icons/why-leo-prime-icon-3.svg';
import OurBusinessValues from  '../assets/img/icons/why-leo-prime-icon-4.svg';
import MutualInterest from  '../assets/img/icons/why-leo-prime-icon-5.svg';
import ClientFocus from  '../assets/img/icons/why-leo-prime-icon-6.svg';
import LD4 from  '../assets/img/why-choose-leoprime/equinix-logo.svg';

class WhyLeoPrime extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="Trade with best forex broker LeoPrime"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "retail,forex,broker,LeoPrime,trading, forex trading, online" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Trade with best forex broker LeoPrime" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Trade with best forex broker LeoPrime" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "LeoPrime is an Next-Generation Multi Asset Broker who aims to offer top-notch services that are extensively in need for Forex traders for an experience easier." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay why-leo-prime-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase text-dark">Why choose  </h1>
                            <h1 className="display-3 text-shadow text-uppercase text-dark"><span>Leo ?</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container">
                        <Col className=" text-center mb-5">
                            <h2 className="mb-4 w-100">
                                The World-Class
                                <span className="d-block">Next-Generation Multi Asset Broker</span>
                            </h2>
                            <p>Leo is a Multi-Asset Broker that accommodates a wide range of trading services, including-Custom solutions & Ultra-fast execution. With years of gathered expertise, we provide a user-friendly trading environment for the both retail and institutional clientele. </p>
                        </Col>
                        <Row>
                            <Col lg={7}>
                                <Row className="my-3">
                                    <Col xs={12} md={6} lg={6} className="mb-4">
                                        <div className="pr-3">
                                            <h4><span> <img src={ListIcon} alt="Transparency" /></span> Transparency </h4>
                                            <p>We offer direct market access with transparent trading environment. This is done by partnering with top-tier liquidity providers to present the best perspective of the market and its happenings. By complying with regulations, We take pride in clarity of services that result in thorough currency trading solutions </p>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} className="mb-4">
                                        <div className="pr-3">
                                            <h4><span> <img src={ListIcon} alt=" Cost Effective " /></span> Cost Effective </h4>
                                            <p>We understand the value of your time and money. By providing instant deposits and withdrawals to our clients, we separate ourselves from competition unanimously and have cut down your waiting time. With no additional fees on your deposits and withdrawals we assure that all your money makes its worth.</p>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} className="mb-4">
                                        <div className="pr-3">
                                            <h4><span> <img src={ListIcon} alt="Strategy" /></span> Strategy </h4>
                                            <p>We analyze, formulate and then provide you with the best possible trading environment based on our long years of expertise and market conditions. With tight Spread and unmatched liquidity, our client-centric strategies are convenient and compatible with the pace of the market and its trends.</p>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} className="mb-4">
                                        <div className="pr-3">
                                            <h4><span> <img src={ListIcon} alt="Technology" /></span> Technology </h4>
                                            <p>We offer superior technology that uses low-latency execution, low-cost Spread and is fine-tuned to provide swift execution as per our clients' needs. Experience these features on the Meta Trader 4 platform (MT4) terminal as synced to Leo's liquidity pools.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={5} className="d-flex align-items-center">
                                <img src={require('../assets/img/why-choose-leoprime/why-choose-img.png')} alt="" className="img-fluid" />
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="bg-gray hmeOurBenefit">
                    <Col lg={10} className="mx-auto">
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <Row className="my-3">
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-4 text-lg-left text-center">
                                        <img src={EaseOfTrade} alt="Money" />
                                        <h4>Ease of Trade
                                        </h4>
                                        <p>We render a client-centric trading experience to every trader that is unmatched by any others.</p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-4 text-lg-left text-center">
                                        <img src={ExpertTeam} alt="Expert Team" />
                                        <h4>Expert Team
                                        </h4>
                                        <p>We have a knowledgeable team of passionate traders-cum-professionals who have understood your needs.</p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-4 text-lg-left text-center">
                                        <img src={WideAngleApproach} alt="Wid eAngle Approach" />
                                        <h4>Wide-Angle Approach
                                        </h4>
                                        <p>We operate in a highly synchronized environment that is open to all legitimate trading strategies.</p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-4 text-lg-left text-center">
                                        <img src={OurBusinessValues} alt="Our Business Values" />
                                        <h4>Our Business Values</h4>
                                        <p>We believe in enforcing integral business values for our successful journey.
                                        </p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-4 text-lg-left text-center">
                                        <img src={MutualInterest} alt="Mutual Interest" />
                                        <h4>Mutual Interest</h4>
                                        <p>Your success reflects our success. Our conviction is to be benefited mutually.
                                        </p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-4 text-lg-left text-center">
                                        <img src={ClientFocus} alt="Client Focus" />
                                        <h4>Client Focus</h4>
                                        <p>Client's needs are our first and foremost priority. Hence, we value and attend to clients.</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </section>
                <div className="container">
                    <Row className="justify-content-md-start">
                        <Col xs={12} md={12} lg={7} className="mb-3  text-lg-left text-center">
                            <Row>
                                <Col>
                                    <h3 className="mt-5  w-100">
                                        Trading Server Located - LD4
                                    </h3>
                                    <img src={LD4} alt="Trading Server Located - LD4" className="my-3" />
                                    <p>Our trading server is located at Equinix LD4 nearest our Liquidity Provider, to make better and faster execution. With increased proximity to our liquidity providers, we can serve you uninterrupted under 0.1ms of time on your execution.
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h3 className="mt-5">
                                        HK2(Hong
                                        Kong)
                                    </h3>
                                    <p>Considering the serving proximity of our Asian clients, we have servers located at HK2(Hong Kong). These data center colocation facilities are situated in one of Asia's busiest centers to accommodate and serve the needs of international trading and finance providing clients with the fastest possible execution speeds and ultra-low latency.
                                    </p>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={12} md={12} lg={{ span: 3, }} className="mb-3 d-flex align-items-center">
                            <div className="d-flex flex-column justify-content-center">
                                <img src={require('../assets/img/why-choose-leoprime/why-choose-leoprime-map.png')} alt="" className="d-lg-block d-none" />
                            </div>
                        </Col>
                    </Row>
                </div>
                <ReadyToTrade />
            </div>
        );
    }
}
export default WhyLeoPrime;