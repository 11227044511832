import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import ReadyToTrade from '../components/ReadyToTrade'
import { Helmet } from "react-helmet";
import TermsVps from '../documents/terms_vps_hosting.pdf';

import VpsIcon1 from '../assets/img/icons/vps-icon-1.svg';
import VpsIcon2 from '../assets/img/icons/vps-icon-2.svg';
import VpsIcon3 from '../assets/img/icons/vps-icon-3.svg';
import VpsIcon4 from '../assets/img/icons/vps-icon-4.svg';
import VpsIcon5 from '../assets/img/icons/vps-icon-5.svg';
import VpsIcon6 from '../assets/img/icons/vps-icon-6.svg';
import VpsIcon7 from '../assets/img/icons/vps-icon-7.svg';
import VpsIcon8 from '../assets/img/icons/vps-icon-8.svg';
import VpsIcon9 from '../assets/img/icons/vps-icon-9.svg';
import VpsIcon10 from '../assets/img/icons/vps-icon-10.svg';

class VpsHosting extends Component {
    render() {
        return (
            <div>
                <Helmet
                    title="Free VPS Hosting|Leoprime’s VPS Server"
                    meta={[
                        { name: 'author', content: "LeoPrime" },
                        { name: 'keywords', content: "VPS,free,hosting,vpshosting,mt4,MT4,MetaTrader, retail,account,forex,broker,LeoPrime,trader, forex trader, online" },
                        { name: 'twitter:site', content: "LeoPrime" },
                        { name: 'twitter:creator', content: "LeoPrime" },
                        { name: 'twitter:title', content: "Free VPS Hosting|Leoprime’s VPS Server" },
                        { name: 'twitter:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:title', content: "Free VPS Hosting|Leoprime’s VPS Server" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { property: 'og:type', content: "Forex Trading" },
                        { property: 'og:url', content: "https://leoprime.com/" },
                        { property: 'og:description', content: "LeoPrime’s Virtual Private Server (VPS) gives you continuous access to trading platforms with no added costs by connecting into the closest trading server’s remote terminal." },
                        { property: 'og:image', content: "https://www.leoprime.com/assets/img/leoprime_logo_thumbnail.jpg" },
                        { property: 'og:site_name', content: "LeoPrime" },
                        { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                    ]}
                >
                </Helmet>
                <div className="innerpageBanner">
                    <div className="d-flex align-items-center dark-overlay vps-hosting-banner" >
                        <div className="col-xl-10 mx-auto text-white">
                            <h1 className="display-3 text-shadow text-uppercase">VPS
                            </h1>
                            <h1 className="display-3 text-shadow text-uppercase"><span>Hosting</span></h1>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container text-center">
                        <Col lg={6} className="mx-auto">
                            <Row>
                                <h2 className="mb-4 w-100">
                                    Get Your Free VPS Hosting
                                    from LeoPrime
                                </h2>
                                <p className="w-100">Are you trading for life ? We recommend you free VPS server
                                    so you can trade more fast and secure.
                                </p>
                                <a href={('https://www.leoprime.com/register')} className="primary-button-lg mx-auto" >order vps</a>
                            </Row>
                        </Col>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <Row className="justify-content-md-center">
                            <Col xs={12} md={12} lg={5} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center">
                                    <h2>
                                        What is VPS Hosting
                                    </h2>
                                    <p>A VPS (Virtual Private Server) is your own private server hosted in the cloud or on the Internet – 24/7 and constantly online. A VPS allows you to run automated algorithmic strategies (EAs) around the clock on a Remote Server independently from your own computer without any effort from your side.</p>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={{ span: 6, offset: 1 }}>
                                <Row className="my-3">
                                    <Col xs={12} md={6} lg={6} className="pr-4 mb-4 text-center">
                                        <img src={VpsIcon1} alt="Money" />
                                        <p>MT4 Trading Server
                                        </p>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} className="pr-4 mb-4 text-center">
                                        <img src={VpsIcon2}  alt="Money2" />
                                        <p>Internet</p>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} className="pr-4 mb-4 text-center">
                                        <img src={VpsIcon3}  alt="Money" />
                                        <p>VPS Server</p>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} className="pr-4 mb-4 text-center">
                                        <img src={VpsIcon4} alt="Money" />
                                        <p>Your Computer
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="bg-gray">
                    <div className="container">
                        <Row className="justify-content-md-center">
                            <Col xs={12} md={12} lg={5}>
                                <Row className="my-3">
                                    <div className="d-flex flex-column justify-content-center">
                                        <img src={require('../assets/img/vps-hosting/vps-hosting-img.jpg')} alt="Money" className="img-fluid" />
                                    </div>
                                </Row>
                            </Col>
                            <Col xs={12} md={12} lg={{ span: 6, offset: 1 }} className="mb-3 d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center">
                                    <h2>
                                        VPS keeps you just
                                        in time
                                    </h2>
                                    <p>LeoPrime’s Virtual Private Server (VPS) gives you continuous access to trading platforms for no added costs by connecting into the closest trading server’s remote terminal. Enjoy trading on all Windows operating system similar to working on your PC, while our team relentlessly works to optimize the model on other systems.
                                    </p>
                                    <p>Contact Us for activating your VPS Hosting now!</p>
                                    <p>
                                        <a href={('mailto:support@leoprime.com')} className="secondary-button-outline my-2 mr-2  float-left" >support@leoprime.com</a>
                                        {/* <button className="secondary-button-outline my-2 float-left" type="button">Live Chat</button> */}
                                    </p>
                                    <p class="mt-2">To download terms and condition on VPS Hosting &nbsp;
                                        <a href={TermsVps} target="blank"> <u>CLICK HERE</u></a>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="hmeOurBenefit">
                    <Col lg={10} className="mx-auto">
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <h2 className="text-center">
                                    Advantages of LeoPrime’s
                                    <span className="d-block">  VPS Server</span>
                                </h2>
                                <Row>
                                    <Col className="text-center">
                                        <p>Our traders can get free VPS Hosting by connecting to the closest trading server’s remote terminal. You get the familiarity of using trading terminal similar to trading from your PC. The remote server is mainly intended to maximize overall stability, uptime and performance of forex trading using independent modules (experts, advisors).
                                        </p>
                                        <p>VPS Hosting enables entire trading operations in parallel through both (i.e. the main and remote) terminals despite few restrictions in usage of VPS hosting.
                                        </p>
                                        <p>1. Your initial deposit must be at least 1000USD , 2. Restricted to install any software on the remote terminal.</p>
                                    </Col>
                                </Row>
                                <Row className="mt-5">
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                        <img src={VpsIcon5} alt="Money" />
                                        <h5>Uninterrupted Trading</h5>
                                        <p>With the assistance of advisors and experts in the market, you can get to trade even when your own computer is not turned on.</p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                        <img src={VpsIcon6} alt="Money2" />
                                        <h5>Constancy</h5>
                                        <p>VPS has nothing to do with your internet connection and hence the quality of your Internet connection will not deter the stability.
                                        </p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                        <img src={VpsIcon7} alt="Money" />
                                        <h5>Mobility</h5>
                                        <p>Being anywhere, you get to access your account and trade on the forex market at any time zone in the world without installing any software.
                                        </p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                        <img src={VpsIcon8} alt="Money" />
                                        <h5>Rapidity</h5>
                                        <p>Sending notifications to a trading server is exceptionally fast as the VPS servers are located in the same data center as the trading servers.
                                        </p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                        <img src={VpsIcon9} alt="Money" />
                                        <h5>Paralleled access</h5>
                                        <p>You can access both your trading account through installed software as well as trade on the forex market through a remote terminal.</p>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} className="pr-4 mb-4">
                                        <img src={VpsIcon10} alt="Money" />
                                        <h5>VPS hosting from LeoPrime
                                        </h5>
                                        <p>In order to provide the best to our clients, we go above and beyond to offer free access to a trading terminal. VPS hosting is highly efficient for the traders to receive quotes and experience their orders getting executed without delay</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </section>
                <ReadyToTrade />
            </div>
        );
    }
}
export default VpsHosting;