import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import forex from '../assets/img/icons/trading-instrument-forex.svg';
import spotmetal from '../assets/img/icons/trading-instrument-spotmetal.svg';
import energies from '../assets/img/icons/trading-instrument-energies.svg';
import indices from '../assets/img/icons/trading-instrument-indices.svg';
import stocks from '../assets/img/icons/trading-instrument-stocks.svg';

export default function TradingInstrumentTabs() {
    return (
        <div>
            <Col lg={10} className="mx-auto mt-5">
                <Row className="justify-content-md-center text-center tradInstList">
                    <Col xs={6} md lg className="mb-4 tradInstListView">
                        <NavLink to="/trading-instrument-forex" activeClassName="ti-active">
                            <img src={forex} alt="Trading Instrument" />
                            <p>Forex</p>
                        </NavLink>
                    </Col>
                    <Col xs={6} md lg className="mb-4 tradInstListView">
                        <NavLink to="/trading-instruments-spot-metal" activeClassName="ti-active">
                            <img src={spotmetal} alt="Trading Instrument" />
                            <p>Spot Metal</p>
                        </NavLink>
                    </Col>

                    <Col xs={6} md lg className="mb-4 tradInstListView">
                        <NavLink to="/trading-instruments-energies" activeClassName="ti-active">
                            <img src={energies} alt="Trading Instrument" />
                            <p>Energies</p>
                        </NavLink>
                    </Col>
                    <Col xs={12} md lg className="mb-4 tradInstListView">
                        <NavLink to="/trading-instruments-indices" activeClassName="ti-active">
                            <img src={indices} alt="Trading Instrument" />
                            <p>Indices</p>
                        </NavLink>
                    </Col>
                    <Col xs={12} md lg className="mb-4 tradInstListView">
                        <NavLink to="/trading-instruments-stocks" activeClassName="ti-active">
                            <img src={stocks} alt="Trading Instrument" />
                            <p>Stocks</p>
                        </NavLink>
                    </Col>
                </Row>
            </Col>
        </div>
    )
}
