import { actionType } from "../actionType";
import apiConfig from '../../config/apiCCConfig';

export const getPastDemoContest = (param, cb) => async (dispatch) => {
    try {
        let url = 'api/v3/contest';
        const response = await apiConfig.post(url, {});
        dispatch({ type: actionType.FETCH_PAST_CONTEST_LIST, payload: response.data });
        cb(response.data);
    } catch (error) {
        console.log(error);
    }
};

export const getDemoContestList = (param, cb) => async (dispatch) => {
    try {
        let url = 'api/v3/contest/details';
        const response = await apiConfig.post(url, param);
        dispatch({ type: actionType.FETCH_PAST_CONTEST_LIST, payload: response.data });
        cb(response.data);
    } catch (error) {
        console.log(error);
    }
};

export const getDemoContestRank = (param, cb) => async (dispatch) => {
    try {
        let url = 'api/v3/contest/ranks';
        const response = await apiConfig.post(url, param);
        dispatch({ type: actionType.FETCH_RANK_CONTEST_LIST, payload: response.data });
        cb(response.data);
    } catch (error) {
        console.log(error);
    }
};
