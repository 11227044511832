import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-flags-select/scss/react-flags-select.scss';
import "react-alice-carousel/lib/alice-carousel.css";
// import 'react-rangeslider/lib/index.css'
import 'react-accessible-accordion/dist/fancy-example.css';
import './index.scss';
import Home from './pages/Home'
import TradingPlatform from './pages/TradingPlatform'
import AccoutTypes from './pages/AccountTypes'
import AccoutTypesClassicAccount from './pages/AccoutTypesClassicAccount'
import AccoutTypesProAccount from './pages/AccoutTypesProAccount'
import AccoutTypesEcnAccount from './pages/AccoutTypesEcnAccount'
import AccoutTypesCentAccount from './pages/AccoutTypesCentAccount'
import AccoutTypesDemoAccount from './pages/AccoutTypesDemoAccount'
import TradingInstrumentForex from './pages/TradingInstrumentForex'
import TradingInstrumentSpotMetal from './pages/TradingInstrumentSpotMetal'
import TradingInstrumentEnergies from './pages/TradingInstrumentEnergies'
import TradingInstrumentIndices from './pages/TradingInstrumentIndices'
import TradingInstrumentStocks from './pages/TradingInstrumentStocks'
import FundingDeposit from './pages/FundingDeposit'
import FundingWithdrawal from './pages/FundingWithdrawal'
import TradingPlatformMt5 from './pages/TradingPlatformMt5'
import ToolEconomicCalendar from './pages/ToolEconomicCalendar'
import ToolClientSentiment from './pages/ToolClientSentiment'
import PartnershipIntroducingBroker from './pages/PartnershipIntroducingBroker'
import RegionalOffice from './pages/RegionalOffice'
import WhyLeoPrime from './pages/WhyLeoPrime'
import ContactUs from './pages/ContactUs'
import Regulations from './pages/Regulations'
import Faq from './pages/Faq'
import PrivacyPolicy from './pages/PrivacyPolicy'
import AmlPolicy from './pages/AmlPolicy'
import SecurityPolicies from './pages/SecurityPolicies'
import LegalDocuments from './pages/LegalDocuments'
import TermsConditions from './pages/TermsConditions'
import RefundPolicy from './pages/RefundPolicy'
import ForexCalculator from './pages/ForexCalculator'
import VpsHosting from './pages/VpsHosting'
import MarginLeverageRules from './pages/MarginLeverageRules'
import Monitoring from './pages/Monitoring'
import SocialTradingTrader from './pages/SocialTradingTrader'
import SocialTradingInvestor from './pages/SocialTradingInvestor'
import SocialTradingRank from './pages/SocialTradingRank'
import SocialTradingRankDetails from './pages/SocialTradingRankDetails'
// import ThirtyBonus from './pages/30bonus'
import FiftyBonus from './pages/50bonus'
import CashBack from './pages/Cashback'
// import CashBackNov from './pages/CashbackNov'
import Awards from './pages/Awards'
import Contest from './pages/Contest'
import ContestDetails from './pages/ContestDetails'
import Error from './pages/Error'
import Footer from './components/Footer'
import Header from './components/Header'
import ScrollToTopRoute from './components/ScrollToTopRoute'
// import HeaderEmpty from './components/HeaderEmpty'
import { Helmet } from "react-helmet"
import IbPromotion from './pages/IbPromotion'
import CopyTrading from './pages/CopyTrading'
import LeoPrimeMobileApp from './pages/LeoPrimeMobileApp'
import ThirtyProAccount from './pages/30ProAccount'
// import FiftyBonusNew from './pages/50bonusNew'
import FiftyBonusSA from './pages/50bonusSouthAfrica'




const App = (props) => {

  // window.scrollTo(0, 0)

  return (
    <div className="App">
      <Helmet>

        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-109306875-1" > </script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];                            
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-109306875-1');
            gtag('config', 'AW-822587407');
        `}
        </script>
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-PPJ98P3');
        `}
        </script>

        {/* // <!-- Facebook Pixel Code --> */}
        <script>
          {`
          
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
                      n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
           fbq('init', '1939674053022958');
          fbq('track', 'PageView');
          `}
        </script>


        {/* // <!-- Pixel Code 12Dec2020--> */}
        <script>
          {`
          
          adroll_adv_id = "P5Y4SXAQ6NHCZMQDDQMGYP";
          adroll_pix_id = "5XMLDHCVYNDJFERARDU5AV";
          adroll_version = "2.0";

          (function(w, d, e, o, a) {
          w.__adroll_loaded = true;
          w.adroll = w.adroll || [];
          w.adroll.f = [ 'setProperties', 'identify', 'track' ];
          var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id
          + "/roundtrip.js";
          for (a = 0; a < w.adroll.f.length; a++) {
          w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) {
          return function() {
          w.adroll.push([ n, arguments ])
          }
          })(w.adroll.f[a])
          }

          e = d.createElement('script');
          o = d.getElementsByTagName('script')[0];
          e.async = 1;
          e.src = roundtripUrl;
          o.parentNode.insertBefore(e, o);
          })(window, document);
          adroll.track("pageView");
          `}
        </script>
        <noscript>
          {`
            <img height="1" width="1"
            src="https://www.facebook.com/tr?id=1939674053022958&ev=PageView
&noscript=1"/>
          `}
        </noscript>
        {/* <!-- End Facebook Pixel Code --> */}

      </Helmet>


      {
        props.location.pathname !== '/30bonus' && props.location.pathname !== '/50bonus' && props.location.pathname !== '/ib-promotion' && props.location.pathname !== '/copy-trading' && props.location.pathname !== '/mobile-app-ios-android' && props.location.pathname !== '/30proaccount' && props.location.pathname !== '/50bonus-south-africa' ? <Header /> : ''
      }
      <Switch>
        <Route path="/" component={Home} exact />
        <ScrollToTopRoute path="/trading-instrument-forex" component={TradingInstrumentForex} exact />
        <ScrollToTopRoute path="/trading-instruments-spot-metal" component={TradingInstrumentSpotMetal} exact />
        <ScrollToTopRoute path="/trading-instruments-energies" component={TradingInstrumentEnergies} exact />
        <ScrollToTopRoute path="/trading-instruments-indices" component={TradingInstrumentIndices} exact />
        <ScrollToTopRoute path="/trading-instruments-stocks" component={TradingInstrumentStocks} exact />
        <Route path="/payment-options" component={FundingDeposit} exact />
        <Route path="/payment-options-withdrawal" component={FundingWithdrawal} exact />
        <ScrollToTopRoute path="/account-types" component={AccoutTypes} exact />
        <ScrollToTopRoute path="/classic-account" component={AccoutTypesClassicAccount} exact />
        <ScrollToTopRoute path="/pro-account" component={AccoutTypesProAccount} exact />
        <ScrollToTopRoute path="/ecn-account" component={AccoutTypesEcnAccount} exact />
        <ScrollToTopRoute path="/cent-account" component={AccoutTypesCentAccount} exact />
        <ScrollToTopRoute path="/demo-account" component={AccoutTypesDemoAccount} exact />
        <ScrollToTopRoute path="/trading-platform" component={TradingPlatform} exact />
        <ScrollToTopRoute path="/trading-platform-mt5" component={TradingPlatformMt5} exact />
        <ScrollToTopRoute path="/economic-calendar" component={ToolEconomicCalendar} exact />
        <ScrollToTopRoute path="/client-sentiment" component={ToolClientSentiment} exact />
        <ScrollToTopRoute path="/introducing-broker" component={PartnershipIntroducingBroker} exact />
        <ScrollToTopRoute path="/regional-office" component={RegionalOffice} exact />
        <ScrollToTopRoute path="/contact-us" component={ContactUs} exact />
        <ScrollToTopRoute path="/regulations" component={Regulations} exact />
        <ScrollToTopRoute path="/faq" component={Faq} exact />
        <ScrollToTopRoute path="/privacy-policy" component={PrivacyPolicy} exact />
        <ScrollToTopRoute path="/aml-policy" component={AmlPolicy} exact />
        <ScrollToTopRoute path="/security-policies" component={SecurityPolicies} exact />
        <ScrollToTopRoute path="/legal-doc" component={LegalDocuments} exact />
        <ScrollToTopRoute path="/terms-conditions" component={TermsConditions} exact />
        <ScrollToTopRoute path="/refund-policy" component={RefundPolicy} exact />
        <ScrollToTopRoute path="/forex-calculator" component={ForexCalculator} exact />
        <ScrollToTopRoute path="/vps-hosting" component={VpsHosting} exact />
        <ScrollToTopRoute path="/margin-leverage-rules" component={MarginLeverageRules} exact />
        <ScrollToTopRoute path="/monitoring" component={Monitoring} exact />
        <ScrollToTopRoute path="/copy-trading-trader" component={SocialTradingTrader} exact />
        <ScrollToTopRoute path="/copy-trading-investor" component={SocialTradingInvestor} exact />
        <ScrollToTopRoute path="/copy-trading-rank" component={SocialTradingRank} exact />
        <ScrollToTopRoute path="/copy-trading-rank-details" component={SocialTradingRankDetails} exact />
        <ScrollToTopRoute path="/why-leo-prime" component={WhyLeoPrime} exact />
        <ScrollToTopRoute path="/50bonus" component={FiftyBonus} exact />
        {/* <Route path="/30bonus" component={ThirtyBonus} exact /> */}
        <ScrollToTopRoute path="/cashback" component={CashBack} exact />
        {/* <Route path="/cashbacknov" component={CashBackNov} exact /> */}
        <ScrollToTopRoute path="/awards" component={Awards} exact />
        <ScrollToTopRoute path="/contest" component={Contest} exact />
        <ScrollToTopRoute path="/contest-details" component={ContestDetails} exact />
        <ScrollToTopRoute path="/ib-promotion" component={IbPromotion} exact />
        <ScrollToTopRoute path="/copy-trading" component={CopyTrading} exact />
        <ScrollToTopRoute path="/mobile-app-ios-android" component={LeoPrimeMobileApp} exact />
        {/* <ScrollToTopRoute path="/50bonus-new" component={FiftyBonusNew} exact /> */}
        <ScrollToTopRoute path="/30proaccount" component={ThirtyProAccount} exact />

        <ScrollToTopRoute path="/50bonus-south-africa" component={FiftyBonusSA} exact />
        <Route path="*">
          <Error />
        </Route>
      </Switch>
      {/* <Footer /> */}
      {
        props.location.pathname !== '/30bonus' && props.location.pathname !== '/50bonus' && props.location.pathname !== '/ib-promotion' && props.location.pathname !== '/copy-trading' && props.location.pathname !== '/mobile-app-ios-android' && props.location.pathname !== '/30proaccount' && props.location.pathname !== '/50bonus-south-africa' ? <Footer /> : ''
      }
    </div>
  )
};
export default withRouter(App);
