import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import SliderFooter from '../components/SliderFooter'
import { Row, Col, Form } from 'react-bootstrap';
// import ReactFlagsSelect from 'react-flags-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { faXTwitter, faInstagram, faFacebookF, faPinterestP, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { connect } from "react-redux";
import { sendContactEmail } from "../store/actions/sendContactEmailAction";
import GooglePlayImg from '../assets/img/google-play-icon.png';
import AppStoreImg from '../assets/img/app-store-icon.png';
// import GooglePlayCTImg from '../assets/img/copy-trading-android-icon.png';
// import AppStoreCTImg from '../assets/img/copy-trading-apple-icon.png';
import { Helmet } from "react-helmet";
// used for email validation
const emailExpression = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
const Footer = (props) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isAgree, setIsAgree] = useState(false);
    const [isValidate, setIsValidate] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const handleNameInputChange = (event) => {
        setName(event.target.value);
    };
    const handleEmailInputChange = (event) => {
        setEmail(event.target.value);
    };
    const handleAgreementInputChange = () => {
        setIsAgree(!isAgree);
    };
    const handleSubmit = () => {
        const param = {
            first_name: name,
            email: email,
            pagenamecf: 'Subscription'
        };
        if (name === '' || email === '' || !emailExpression.test(email) || !isAgree) {
            setIsValidate(true);
            return;
        }
        setIsSubmitted(true);
        props.sendContactEmail(param, (res) => {
            setName('');
            setEmail('');
            setIsSubmitted(false);
            setIsValidate(false);
            setIsSent(true);
        });
    };
    const getNameErrorMessage = () => {
        if (isValidate && name === '') {
            return (<small className="text-error text-danger">Enter Full name.</small>)
        }
    };
    const getEmailErrorMessage = () => {
        if (isValidate && email === '') {
            return (<small className="text-error text-danger">Please enter a valid email address.</small>)
        }
    };
    return (
        <div>
            <Helmet>
                <script>
                    {`
            var Tawk_API = Tawk_API || {},
			Tawk_LoadStart = new Date();
		( function () {
			var s1 = document.createElement( "script" ),
				s0 = document.getElementsByTagName( "script" )[ 0 ];
			s1.async = true;
			s1.src = 'https://embed.tawk.to/5a02ca7dbb0c3f433d4c7deb/default';
			s1.charset = 'UTF-8';
			s1.setAttribute( 'crossorigin', '*' );
			s0.parentNode.insertBefore( s1, s0 );
        } )();
        `}
                </script>
                <script type="text/javascript">
                    {
                        `
                        var xhttp = new XMLHttpRequest();

function GetURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam) {
            return sParameterName[1];
        }
    }
    return 0;
}
if (localStorage.getItem("cpid") === null) {
    localStorage.setItem("cpid", GetURLParameter('cpid'));
}

if (localStorage.getItem("emp") === null) {
    localStorage.setItem("emp", GetURLParameter('emp'));
}

if (localStorage.getItem("referral") === null) {
    localStorage.setItem("referral", GetURLParameter('referral'));

    xhttp.open("POST", "https://cc.leoprime.com/api/v1/referral", true);
    xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhttp.send("referral=" + GetURLParameter('referral'));

}

if (localStorage.getItem("cpid") == 0) {
    localStorage.setItem("cpid", GetURLParameter('cpid'));
}
if (localStorage.getItem("emp") == 0) {
    localStorage.setItem("emp", GetURLParameter('emp'));
}

if (localStorage.getItem("referral") == 0) {
    localStorage.setItem("referral", GetURLParameter('referral'));
}
                        `
                    }
                </script>
            </Helmet>

            <div className="footer_content">
                <div className="footerSvg">
                    <svg viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M-0.84,148.52 C149.99,150.00 350.17,106.08 502.54,-1.47 L500.00,150.00 L-1.41,149.50 Z" ></path></svg>
                </div>
                {/* <Col lg={10} className="mx-auto">
                    <SliderFooter />
                </Col> */}
                <div className="w-100 ">
                    <Col lg={10} className="mx-auto pt-3 pb-2">
                        <Row>
                            <Col lg={12} className="text-lg-right text-center">
                                {/* Authorised and Regulated: <Link to="/regulations">FSA SC </Link> */}
                            </Col>
                            {/* <Col lg={6} className="text-lg-right text-center">Next-Generation Multi Asset Broker</Col> */}
                        </Row>
                    </Col>
                </div>
                <Col lg={11} className="mx-auto my-3">
                    <Row>
                        <Col lg={7} className="mb-3">
                            <Row className="mb-4">
                                <Col xs={6} lg={3} >
                                    <h4>Company</h4>
                                    <ul>
                                        <li><Link to="/why-leo-prime"> Why Leo</Link></li>
                                        <li><a target="blank" href={('https://news.leoprime.com/')}>Company News</a></li>
                                        <li><Link to="/faq">FAQs</Link></li>
                                        <li><Link to="/contact-us">Contact Us</Link></li>
                                    </ul>
                                </Col>
                                <Col xs={6} lg={3} >
                                    <h4>Account Types</h4>
                                    <ul>
                                        <li><Link to="/classic-account">Classic Account</Link></li>
                                        <li><Link to="/pro-account">Pro Account</Link></li>
                                        <li><Link to="/ecn-account">ECN Account</Link></li>
                                        <li><Link to="/cent-account">Cent Account</Link></li>
                                        <li><Link to="/demo-account">Demo Account</Link></li>
                                    </ul>
                                </Col>
                                <Col xs={6} lg={3} >
                                    <h4>Trading Tools </h4>
                                    <ul>
                                        <li><Link to="/economic-calendar">Economic Calendar</Link></li>
                                        <li><Link to="/vps-hosting">VPS Hosting</Link></li>
                                        <li><Link to="/trading-platform">MetaTrader 4</Link></li>
                                        {/* <li><Link to="/trading-platform-mt5">MetaTrader 5</Link></li> */}
                                    </ul>
                                </Col>
                                <Col xs={6} lg={3} className="footer_links">
                                    <h4>Support Links </h4>
                                    <ul className="list-inline ">
                                        <li className="list-inline-item"> <Link to="/privacy-policy"> Privacy Policy</Link></li>
                                        <li className="list-inline-item"> <Link to="/aml-policy"> AML &amp; KYC Policy</Link></li>
                                        <li className="list-inline-item"> <Link to="/security-policies"> Security Policies</Link></li>
                                        <li className="list-inline-item"> <Link to="/legal-doc"> Legal Document</Link></li>
                                        <li className="list-inline-item"> <Link to="/terms-conditions"> Terms and conditions</Link></li>
                                    </ul>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={4}>

                                    <h4 className='w-100'>Trading App</h4>

                                    <a href="https://play.google.com/store/apps/details?id=com.leoprime.android" target="blank" rel="noreferrer">
                                        <img src={GooglePlayImg} alt="google play" className="img-fluid mr-1" width="100" />
                                    </a>
                                    <a href="https://apps.apple.com/in/app/leoprime/id1543420769" target='_blank' rel="noreferrer">
                                        <img src={AppStoreImg} alt="app store" className="img-fluid" width="100" />
                                    </a>

                                </Col>
                                <Col lg={6}>
                                    <h4>Copy Trading App</h4>
                                    <a href="https://play.google.com/store/apps/details?id=com.leoprime.copytrade" target="blank" rel="noreferrer">
                                        <img src={GooglePlayImg} alt="google play" className="img-fluid mr-1" width="100" />
                                    </a>
                                    <a href="https://apps.apple.com/in/app/leoprime-copytrade/id1574483334" target='_blank' rel="noreferrer">
                                        <img src={AppStoreImg} alt="app store" className="img-fluid" width="100" />
                                    </a>
                                </Col>
                            </Row>
                            <p>Leo Inc Limited - Registration Number: 1833611, having its registered office at Flat F, 31/F, Tower 1, Bailey Garden, 23 Bailey Street, Hunghom, Kowloon, Hong Kong

                            </p>
                            <p>Operation: #1101, 11th Floor, Westburry Business Tower, Business Bay, Dubai, UAE</p>
                            {/* <p>
                                Leo Prime Services Limited, G.S.Complex, 1st floor, Office Number 12,
                                Providence, Mahe, Seychelles </p> */}
                            {/* <p>Leo Inc registered in the Marshall Islands (registration number 95149) and
                                regulated in accordance with Marshall Islands Business Corporation Act. The Company’s address:
                                Ajeltake Road, Ajeltake Island, Majuro, Marshall Islands MH96960.
                            </p> */}
                            <p> Disclaimer: Information on this site is not directed at residents in any country or jurisdiction where such distribution or use would be contrary to local law or regulation. You are strongly advised to obtain independent financial, legal and tax advice before proceeding with any currency or spot metals trade. Nothing in this site should be read or construed as constituting advice on the part of Leo Inc. Limited or any of its affiliates, directors, officers or employees.
                            </p>
                            <p>Risk Warning: FX and CFDs, which are leveraged products, incur a high level of risk and can result in the loss of all your invested capital. Therefore, FX and CFDs may not be suitable for all investors. You should not risk more than you are prepared to lose. Before deciding to trade, please ensure you understand the risks involved and take into account your level of experience. Seek independent advice if necessary.</p>
                            <p>Leo Inc Limited, does not offer its services to the residents of certain jurisdictions such as: USA, Belgium, North Korea, France, Australia, Israel, and Japan.</p>
                            <div className="copyright  mt-4">
                                <p>&copy; {(new Date().getFullYear())} <span>Leo Inc Limited </span> - All Rights Reserved.</p>
                            </div>
                        </Col>
                        <Col lg={5}>


                            {
                                isSent ?
                                    <Col lg={12}>
                                        <div className="alert alert-success text-center font-weight-bold">
                                            Thanks for Subscribing
                                        </div>
                                    </Col>
                                    :
                                    <Col lg={12}>
                                        <h4>Subscribe to our updates</h4>
                                        <Form>
                                            <Row>
                                                <Col lg={9} className="mb-2">
                                                    <Form.Control placeholder="Full name" value={name}
                                                        onChange={handleNameInputChange} />
                                                    {
                                                        getNameErrorMessage()
                                                    }
                                                </Col>
                                                <Col lg={9} className="mb-2">
                                                    <Form.Control type={email}
                                                        placeholder="Email ID"
                                                        value={email} onChange={handleEmailInputChange} />
                                                    {
                                                        getEmailErrorMessage()
                                                    }
                                                </Col>
                                                <Col lg={3} className="mb-2">
                                                    {isSubmitted ?
                                                        <div className="spinner-border text-primary" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                        :
                                                        <button className="primary-button" type="button"
                                                            onClick={handleSubmit}> Subscribe</button>
                                                    }
                                                </Col>
                                            </Row>
                                            <Form.Group controlId="formBasicCheckbox" className="mt-2">
                                                <Form.Check value={isAgree}
                                                    id={isValidate && !isAgree ? 'agree-checkbox' : ''}
                                                    type="checkbox"
                                                    label="I agree to submit my information to Leo Inc Limited to communicate via email and phone calls regarding products and other offers. I have read and I agree to the Leo Inc Limited."
                                                    onChange={handleAgreementInputChange} />
                                            </Form.Group>
                                        </Form>
                                    </Col>
                            }

                            <Col lg={12} className="my-3">
                                <h4>Contact Us</h4>
                                <p>
                                    {/* Leo Inc, <br />
                                    G.S.Complex, 1st floor,
                                    Office Number 12, Providence, <br />
                                    Mahe, Seychelles.
                                    <br /> <br /> */}
                                    <a href="mailto:support@leoprime.com"><img src={require('../assets/img/support_email1.png')} alt="Support" /></a>
                                    <br />
                                    {/* <a href="tel:+442038085868"><FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />  +44 20380 85868</a> */}
                                </p>
                            </Col>

                            {/* <Col lg={12} className="my-4">
                                <h4>Stay Connected</h4>
                                <ul className="list-inline sociallinks">
                                    <li className="list-inline-item">
                                        <a target="blank" href="https://www.facebook.com/LeoPrimeForexBrokers/"><FontAwesomeIcon icon={faFacebookF} className="fa-lg" /></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a target="blank" href="https://twitter.com/LeoPrimeFx"><FontAwesomeIcon icon={faXTwitter} className="fa-lg" /></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a target="blank" href="https://www.instagram.com/leoprimefx/"><FontAwesomeIcon icon={faInstagram} className="fa-lg" /></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a target="blank" href="https://www.linkedin.com/company/leo-prime"> <FontAwesomeIcon icon={faLinkedinIn} className="fa-lg" /></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a target="blank" href="https://in.pinterest.com/LeoPrimeFx/pins/"><FontAwesomeIcon icon={faPinterestP} className="fa-lg" /></a>
                                    </li>
                                </ul>
                            </Col> */}

                            {/* <Col lg={12} className="footer_links">
                                <ul className="list-inline ">
                                    <li className="list-inline-item"> <Link to="/privacy-policy"> Privacy Policy</Link></li>
                                    <li className="list-inline-item"> <Link to="/aml-policy"> AML &amp; KYC Policy</Link></li>
                                    <li className="list-inline-item"> <Link to="/security-policies"> Security Policies</Link></li>
                                    <li className="list-inline-item"> <Link to="/legal-doc"> Legal Document</Link></li>
                                    <li className="list-inline-item"> <Link to="/terms-conditions"> Terms and conditions</Link></li>
                                </ul>
                            </Col> */}
                        </Col>
                    </Row>
                </Col>
            </div >

            <div className="footerBlue">
                <ul className="sociallinks">
                    <li>
                        <a target="blank" href="https://www.facebook.com/LeoPrimeForexBrokers/"><FontAwesomeIcon icon={faFacebookF} className="fa-lg" /></a>
                    </li>
                    <li>
                        <a target="blank" href="https://twitter.com/LeoPrimeFx"><FontAwesomeIcon icon={faXTwitter} className="fa-lg" /></a>
                    </li>
                    <li>
                        <a target="blank" href="https://www.instagram.com/leoprimefx/"><FontAwesomeIcon icon={faInstagram} className="fa-lg" /></a>
                    </li>
                    <li>
                        <a target="blank" href="https://www.linkedin.com/company/leo-prime"> <FontAwesomeIcon icon={faLinkedinIn} className="fa-lg" /></a>
                    </li>
                    <li>
                        <a target="blank" href="https://in.pinterest.com/LeoPrimeFx/pins/"><FontAwesomeIcon icon={faPinterestP} className="fa-lg" /></a>
                    </li>
                </ul>
                <div className="container">
                    <Row className="justify-content-md-center bannerBottom">
                        <Col xs={6} md={4} lg className="my-3">
                            <h5>Spread</h5>
                            <p className="borderFirst">from 0.0 Pips</p>
                        </Col>
                        <Col xs={6} md={4} lg className="my-3">
                            <h5>$10</h5>
                            <p>Min. Deposit</p>
                        </Col>
                        <Col xs={6} md={4} lg className="my-3">
                            <h5>Zero Fees </h5>
                            <p>Funding &amp; withdrawal </p>
                        </Col>

                        <Col xs={6} md={4} lg className="my-3">
                            <h5>MT4</h5>
                            <p>Platforms</p>
                        </Col>
                        <Col xs={6} md={4} lg className="my-3">
                            <h5>Upto  1:1000</h5>
                            <p>Leverage</p>
                        </Col>
                        <Col xs={6} md={4} lg className="my-3">
                            <h5>100+</h5>
                            <p>Tradable instruments</p>
                        </Col>
                    </Row>
                </div>
            </div>
        </div >
    );
};
const mapStateToProps = (state) => {
    return state;
};
export default connect(mapStateToProps, { sendContactEmail })(Footer);
